import { TECH_1, TECH_2, TECH_3, TECH_4, TRANSFORM_1, TRANSFORM_2, TRANSFORM_3, TRANSFORM_4} from '../assets/images';
export const business_intelligence_heading = [
    {
        title: "Business Intelligence: Transforming"      
    },
    {
        title: "Data into Actionable Insights"
    },
  ];
  export const CoreServices = [
    {
  
    title : "BI Consulting",
    content :"Our BI consulting services help you design and implement effective BI strategies tailored to your business needs. We guide you through selecting the right tools, setting up data warehouses, and developing custom dashboards and reports.",
    image: TRANSFORM_1,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Expert advice on BI strategy and tool choice'},
      {items: 'Customized BI solutions tailored to your business'},
      {items: 'Improved data management and analysis'}
    ],
    button_name: "Schedule a BI Consultation Today"
},
{
  
    title : "BI Implementation",
    content :"We offer a full range of BI implementation services, from integrating and warehousing data to developing dashboards and reports. Our team ensures that your BI systems are seamlessly integrated and optimized for performance.",
    image: TRANSFORM_2,
    keytitile: "Technologies Used",
    Key_technologies: [
      {items: 'Tools for data visualisation: Power BI, Tableau, QlikView'},
      {items: 'Data Warehousing: SQL Server, Amazon Redshift, Google BigQuery'},
      {items: 'Machine Learning and AI: TensorFlow, Azure ML, AWS SageMaker'},
      {items: 'ETL Tools: Informatica, Talend, Apache NiFi'}
    ],
    button_name: "Implement Your BI Solution with Us",
    className: "row-reverse"
},
{
  
    title : "BI Maintenance and Support",
    content: "Our maintenance and support services ensure that your BI systems run smoothly and efficiently. We offer ongoing monitoring, performance tuning, and updates to keep your BI environment optimized and reliable.",
    image: TRANSFORM_3,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Continuous monitoring and support'},
      {items: 'Performance tuning and optimization'},
      {items: 'Regular updates and maintenance'}
    ],
    button_name: "Keep Your BI Systems Optimized",
    
},
{
    title : "Advanced Analytics and AI Integration",
    content: "We help you take your BI capabilities to the next level by integrating advanced analytics and AI. Our solutions enable predictive modeling, machine learning, and real-time data processing to provide deeper insights and drive innovation.",
    image: TRANSFORM_4,
    keytitile: "Key Technologies",
    Key_technologies: [
      {items: 'Machine Learning, AI'},
      {items: 'Real-Time Data Processing'},
      {items: 'Predictive Analytics'}
    ],
    button_name: "Drive Innovation with Predictive Analytics",
    className: "row-reverse"
},
]
export const WhyTechno = [
  {
    title: "Expertise and Experience",
    content: "Our team has extensive experience in delivering BI solutions across various industries. We leverage our expertise to provide tailored BI strategies that meet your unique business needs.",
    serialno: "01"
  },
  {
    title: "Customer-Centric Approach",
    content: "We focus on understanding your business goals and delivering BI solutions that drive success. Our customer-centric approach ensures that every solution is aligned with your objectives.",
    serialno: "02"
  },
  {
    title: "Cutting-Edge Technology",
    content: "We use the latest technologies and best practices to deliver innovative BI solutions that keep your business ahead of the competition.",
    serialno: "03"
  },
 
];

export const TestimonialData = [
  {
      content: '“Our partnership with Techno Genesis has transformed our business operations. Their innovative IT solutions and expertise have driven significant growth.”',
      client: 'Client A'
  }
]
  export const BusinessIntelligenceAccord = [
    {
      id:1,
      accHead:"What is Business Intelligence?",
      accContent:"o	Business intelligence (BI) refers to the technologies and practices for the collection, integration, analysis, and presentation of business data to support better decision making."
  
    },
    {
      id:2,
      accHead:"How can BI benefit my business?",
      accContent:"o	BI helps improve decision-making, increase efficiency, enhance customer insights, gain competitive advantage, and better manage finances."
  
    },
    {
      id:3,
      accHead: "What services do you offer for BI?",
      accContent:"We offer BI consulting, implementation, maintenance, and support, as well as advanced analytics and AI integration."
    },
    {
      id:4,
      accHead: "What technologies do you use for BI implementation?",
      accContent:"We use SQL, NoSQL databases, ETL tools (Informatica, Talend), and BI platforms (Tableau, Power BI, Qlik)."
    },
    {
      id:5,
      accHead: "Do you provide ongoing support for BI systems?",
      accContent: "Yes, we provide ongoing support and maintenance to help keep your BI systems up and running."
    }
  ]