import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Fade, Roll } from 'react-awesome-reveal';
import "./whatwedo.css";

const WhatweDo = ({ data }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleEnter = (index) => {
    setHoveredIndex(index);
    console.log("index indexindexindexindex", index )
  };

  const handleLeave = () => {
    setHoveredIndex(null);
  };

  const handledivLeave = () => {
    setHoveredIndex(0);
  };

  useEffect(() => {
    setHoveredIndex(0);
  }, []);

  return (
    <div className="mt-100">
      <Container>
        <div className="d-flex img_cards" onMouseLeave={handledivLeave}>
          {data?.map((item, index) => (
            
            <div
              className={hoveredIndex === index ? "what_do expanded" : "what_do"}
              key={index}
              onMouseEnter={() => handleEnter(index)}
             
            >
              <Fade direction='up' duration={0}>
              <div className="content">
                <h1>{item?.ques}</h1>
                <p>{item?.ans}</p>
              </div>
              </Fade>
              <img className="w-100" src={item?.image} alt="what we do" />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default WhatweDo;
