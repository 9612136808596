import React, { useState } from 'react'
import { BsArrowRight } from 'react-icons/bs';
import "./services.css"
import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';


const Services = ({ data }) => {

    const [hovered, setHovered] = useState(null)
     const navigate = useNavigate()

    return (
        <div>
            {data?.map((services, index) => {
                return (    
                    <div key={index} className="services_bg">
                        <div
                            className={`d-flex align-item-center order_prop justify-content-center position-relative  ${services?.reverseClass}`}>
                             <Fade direction='up' duration={0}>   
                            <div id='order_2' className='service-content'>
                                <h1 className="">{services.title}</h1>
                                <p>{services.content}</p>
                                {services.listItems && services.listItems.length > 0 && (
                                    <ul className="services_unordered">
                                        {services.listItems?.map((subItem, subIndex) => (
                                            <li onClick={()=> navigate(`${subItem?.link}`) }  
                                            className={hovered  === subIndex  ? "ishoved_list" : ""} key={subIndex}> {subItem?.name} <BsArrowRight className='arrow-icon' /> </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            </Fade>
                            <Fade direction='up' duration={0}>   
                            <div id='order_1'>
                                <img id={services?.id}
                                    className="service_img_style"
                                    src={services?.img}
                                    alt="image"/>
                            </div>
                            </Fade>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Services