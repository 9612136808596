import React, { useEffect, useRef } from 'react'
import { motion } from 'framer-motion';
import Newnavigation from '../../../components/new-navigation/new_navigation';
import Footer from '../../../components/footer/Footer';
import ContactNew from '../../../components/contact-form/ContactNew';
import AboutusBanner from '../../../components/Aboutus-banner/AboutusBanner';
import { saocardInfo, soaFAQ, soaTestimonials, soa_consulting, soa_solutions_banner } from '../../../utils/soa-solutions';
import { RETAIL_ECOMMERCE, SOA_BANNER } from '../../../assets/images';
import CoreServicesList from '../../../components/core-serices/core-services';
import RetailProgress from '../../../components/RetailProgress/RetailProgress';
import ComAccord from '../../../components/Accordian/ComAccord';
import Testimonials from '../../../components/Testimonials/Testimonials';
import HeaderSeo from '../../../components/HeaderSeo/Headerseo';
import { ServiceSolutionsseo } from '../../../utils/Seo';

const SoaSolutions = () => {

    const sectionRef = useRef()
    const refcustom = sectionRef.current
      useEffect(() => {
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        }, []);

  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, transition: { duration: 1 } }}
  >
    <div className='service-page' ref={sectionRef}>
    <Newnavigation routeName="services"/>
    <HeaderSeo data={ServiceSolutionsseo}/>
    <AboutusBanner bg={SOA_BANNER} data={soa_solutions_banner}/>
    <CoreServicesList  data={soa_consulting} title="Our Core SOA Services"/>
    <RetailProgress steps={saocardInfo} heading={"Why Choose Techno Genesis for SOA Solutions?"}/>
    <Testimonials data={soaTestimonials} />
    <div className="mb-50">
              <ComAccord data={soaFAQ} />
            </div>
    <ContactNew />
    <Footer />
    </div>
    </motion.div>
  )
}

export default SoaSolutions