import React, { useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ContactForm from '../contactus-popup/ContactForm';
import './page-banner.css';
import { MOBILEBG } from '../../assets/images';
import { AttentionSeeker, Fade } from "react-awesome-reveal";

const PageBanner = (data) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className='banner-section page' style={{ background: `url(${data?.bg || MOBILEBG })` }}>
        <Container>
        
          <Row>
            <Col lg='9'>
              <h1 className='page-title'>{data?.title}</h1>
              <p className='page-content'>{data?.content}</p>
              <Button className='nav_bar_btn ml-0 banner_btn mt-3' variant="outline-primary" onClick={handleShow}>
                {data?.contact}
              </Button>
            </Col>
          </Row>
       
        </Container>
      </div>
      <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
    </>
  );
};

export default PageBanner;
