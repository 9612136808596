import {
    AI_BASED_DETAIL,
    CONTROL_CENTRE_DETAIL,
    COMPUTER_VISION_DETAIL,
    DIGITAL_TWIN_DETAIL,
  } from "../assets/images/index";


export const supplychaindetail = [
    {
      title: "AI Based Demand Planning & Forecasting",
      img: AI_BASED_DETAIL,
      id: "ai_based_planning",
      listItems: [
        "Welcome to the forefront of Supply Chain, Retail & Logistics Delivery, where our expertise shines in crafting innovative solutions through AI-Based Demand Planning & Forecasting software development. ",
        "Dive into the future of data-driven decision-making for optimized supply chain operations.",
        "Our software development team specializes in creating robust systems that harness the power of artificial intelligence. ",
        "Explore the efficiency of our solutions, where machine learning algorithms analyze data to provide accurate demand forecasts, helping businesses stay ahead in dynamic markets.",
      ],
    },
    {
      title: "Control Centre Automation",
      img: CONTROL_CENTRE_DETAIL,
      reverseClass: "flex_reverse",
      id: "control_centre",
      listItems: [
        "Welcome to the forefront of Supply Chain, Retail & Logistics Delivery, where our expertise converges with cutting-edge software development to bring you Control Centre Automation.",
        "Dive into the future of streamlined operations and heightened efficiency.",
        "Our specialized software development team excels in creating robust systems for Control Centre Automation.  ",
        "Explore the power of our solutions, where automation meets precision to optimize logistics, enhance visibility, and ensure real-time control over your supply chain operations.",
        "Discover the efficiency of our software in managing complex logistics networks, reducing lead times, and enhancing overall operational agility."
      ],
    },

    {
      title: "Merchandising using Computer Vision",
      img: COMPUTER_VISION_DETAIL,
      id: "computer_vision",
      listItems: [
        "Step into the future of Supply Chain, Retail & Logistics Delivery with our specialized expertise in Merchandising using Computer Vision through software development. ",
        "Explore the seamless integration of cutting-edge technology to redefine how products are managed and displayed.",
        "Our software development team excels in crafting robust systems for Merchandising using Computer Vision.",
        "Discover the power of our solutions, where computer vision algorithms analyze visual data to optimize merchandising strategies, improve shelf visibility, and enhance overall retail experiences.",
        "Experience the efficiency of our software in automating inventory management, ensuring shelf compliance, and providing valuable insights for strategic decision-making.",
      ],
    },
    {
      title: "Digital Twin",
      img: DIGITAL_TWIN_DETAIL,
      reverseClass: "flex_reverse",
      id: "digital_twin",
      listItems: [
        "Welcome to the forefront of Supply Chain, Retail & Logistics Delivery, where our expertise shines in crafting innovative solutions through Digital Twin technology via software development. ",
        "Dive into the future of virtualized operations and enhanced decision-making.",
        "Our software development team specializes in creating robust Digital Twin systems for supply chain, retail, and logistics. ",
        "Explore the power of our solutions, where virtual replicas of physical processes enable real-time monitoring, simulation, and optimization.",
        
      ],
    },

 


  ];