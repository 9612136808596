import { FRAME_1, FRAME_2, FRAME_3 } from "../assets/images";

export const insuranceDetail = [
  {
     bg: FRAME_3,
     heading: "Integrated Customer Journey",
     listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs."
     ]  
  },
  {
     bg: FRAME_2,
     heading: "Transforming Insurance Operations for the Future",
     listItems: [
        "Step into the future with our 'Transforming Insurance Operations for the Future' page.",
        "Here, we're not just selling policies; we're redefining the insurance landscape.",
        "Embrace innovation as we leverage cutting-edge technology to streamline operations, making insurance not just a purchase but an experience.",
        "Discover how our automated processes enhance efficiency, reducing paperwork and processing times.",
        "Dive into the world of data analytics, where insights drive better decision-making for both us and you.",
     ]
  },
  {
     bg: FRAME_1,
     heading: "Modernizing the Insurance Workforce",
     listItems: [
        "Welcome to our 'Modernizing the Insurance Workforce' page—a testament to our commitment to staying at the forefront of industry evolution.",
        "Here, we're not just selling policies; we're transforming the very core of how insurance works.",
        "Explore how we're investing in our team, blending traditional expertise with cutting-edge skills.",
        "Our workforce is not just adapting to change; they're driving it.",
        "From digital upskilling initiatives to fostering a culture of innovation, we're ensuring that our team is not just keeping pace with the industry; they're setting the pace.",
     ]
  }
]