import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./Real-time-example.css"

function Realtimeexample({ data, cards }) {
    return (

        <Container>
            <div className='real-time-example-sec'>

                {data.map((item, i) => {
                    return (
                        <div className='real-time-example'>
                            <h1 key={i}>{item.title}</h1>
                            <p key={i}> <b>{item.boldContent}</b> {item.content}</p>
                        </div>
                    )
                }
                )}

                <div >
                    <Row>
                        {cards.map((cardItem, i) =>{
                            return(
                                <Col lg={6} className='real-time-cards'  style={{ '--bg-image': `url(${cardItem.cardBg})` }}>
                                    <div className='card-content'>
                                <p>{cardItem.content}</p>
    
                                    <div className='card-icon'>
                                        <img src={cardItem.cardIcon} alt="analytics-icon" />
                                    </div>
    
                                    <h2>{cardItem.title}</h2>
                                    <span className='highlight-line'></span>
                                    </div>
                            </Col>
                            )
                        }
                        )}
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export default Realtimeexample