import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import './insurance-detail.css'
import Slider from 'react-slick';
import ContactForm from '../contactus-popup/ContactForm';
import { insuranceDetail } from '../../utils/insuranceDetail';
const InsuranceDetails = () => {
   const Image = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 5000,
      arrows: false,
      draggable: true
   };
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   
   return (
      <div className='mt_134 mb-100' >
         <Slider  {...Image} >
            {
               insuranceDetail.map((item, index) => {
                  return (
                     <div key={index}>
                        <div className='section-bg-img' style={{ backgroundImage: `url(${item.bg})` }}>
                           <Container>
                              <div className='insurance-content'>
                                 <h1>{item.heading}</h1>
                                 {item.listItems && item.listItems.length > 0 && (
                                    <ul>
                                       {item.listItems.map((subItem, subIndex) => (
                                          <li key={subIndex}>{subItem}</li>
                                       ))}
                                    </ul>
                                 )}
                                 <Button className='nav_bar_btn' variant="outline-primary" onClick={handleShow}> Contact Us </Button>
                              </div>
                              <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
                           </Container>
                        </div>
                     </div>
                  )
               }
               )
            }
         </Slider>
      </div>
   )
}
export default InsuranceDetails