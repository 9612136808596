import React, { useEffect } from "react";
import Banner from "../../components/banner/banner";
import { CASE_BG_1, CASE_IMG_1, PHARM_SUBBANNER, WORKCARD_BG_2, WORKCARD_IMG_2 } from "../../assets/images";
import Newnavigation from "../../components/new-navigation/new_navigation";
import { Col, Container, Row } from "react-bootstrap";
import RelatedCaseStudy from "../../components/case-studies/RelatedCaseStudy";
import ContactNew from "../../components/contact-form/ContactNew";
import Footer from "../../components/footer/Footer";
import HeaderSeo from "../../components/HeaderSeo/Headerseo";
import { CaseStudySeo } from "../../utils/Seo";
import { motion } from "framer-motion";
import { Fade } from "react-awesome-reveal";
import { SimpleCard } from "../../components/simple-card/SimpleCard";
import "./case-study.css";
import CaseResults from "../../components/case-study-result/CaseResults";
import CaseCard from "../../components/case-card/CaseCard";
import ServiceSubBanner from "../../components/service-sub-banner/ServiceSubBanner";

const ShareMarket = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const simplecardData = [
    {
      title: "Automated Trading Platform",
      content:
        "Introduced automation, enabling seamless trades, reducing manual intervention, and eliminating execution delays.",
    },
    {
      title: "Portfolio Management Solution",
      content:
        "Enhanced engagement with a solution for monitoring investments, tracking performance, and receiving personalized recommendations.",
    },
    {
      title: "Real-Time Market Insights",
      content:
        "Integrated real-time data feeds, empowering traders with up-to-the-minute insights for informed decisions and optimized returns.",
    },
    {
      title: "Instant Notifications",
      content:
        "Provided real-time mobile notifications for market updates, price shifts, and portfolio changes to keep investors informed.",
    },
  ];

  const card_data = [
    {
      id: "01",
      title: "Efficient Trading",
      content:
        "Introduced automation, enabling seamless trades, reducing manual intervention, and eliminating execution delays.",
    },
    {
      id: "02",
      title: "Client Engagement",
      content:
        "Enhanced engagement with a solution for monitoring investments, tracking performance, and receiving personalized recommendations.",
    },
    {
      id: "03",
      title: "Informed Decisions",
      content:
        "Integrated real-time data feeds, empowering traders with up-to-the-minute insights for informed decisions and optimized returns.",
    },
    {
      id: "04",
      title: "Enhanced Accessibility",
      content:
        "Provided real-time mobile notifications for market updates, price shifts, and portfolio changes to keep investors informed.",
    },
   
  ];

  const caseCardData = [
    {
      title: "Inefficient Trade Execution",
      content:
        "Manual trade execution processes resulted in delays and inefficiencies.",
    },
    {
      title: "Portfolio Management Dilemma",
      content:
        "Ensuring secure trading experiences for clients was a top priority.",
    },
    {
      title: "Real-Time Market Insights",
      content:
        "The absence of real-time market data hindered traders' decision-making.",
    },
    {
        title: "Timely Notifications",
        content:
          "Investors struggled to stay updated on market changes and portfolio movements in real-time.",
      },
      {
        title: "Security Concerns",
        content:"Clients lacked effective tools to monitor their investments and receive personalized recommendations.",
      },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className="work_page ">
        <HeaderSeo data={CaseStudySeo} />
        <Newnavigation isblack={true} />
        <div id="Overview">
          <Banner
            width={"85%"}
            bg={WORKCARD_BG_2}
            smalltext={"Case Study - 2"}
            title={"Share Market"}
            img={WORKCARD_IMG_2}
            posistion={"end"}
          />
        </div>
        <section className="mt-75 case_study">
          <Container>
            <Row>
              <Col lg={12} className="">
                <div className="content-view">
                  <div id="Overview" className="overview_content">
                    <Fade direction="up" duration={0}>
                      <div>
                        <p>
                        A prominent share market platform, catering to a diverse range of investors, sought to overcome challenges in executing trades efficiently, managing client portfolios, and providing real-time market insights. Manual analysis limited their ability to deliver timely recommendations, hampering their competitiveness in a fast-paced market.
                        </p>
                      </div>
                    </Fade>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="case_study_section mt-75 ">
              <h2 className="case_study_main_title">Challenges & Potential</h2>

              <h1 className="case_study_main_subtitle">
              Navigating the Complexities of <br /> E-commerce
              </h1>
              <div className="mt-50">
                <CaseCard cardInfo={caseCardData} />
              </div>
            </div>

            <div className="case_study_section  mt-75 ">
              <h2 className="case_study_main_title">
                Solution & Implementation
              </h2>

              <h1 className="case_study_main_subtitle">
              Pioneering Automation in <br /> Share Trading
              </h1>
              <div className="mt-50">
                <Row>
                  {simplecardData?.map((item, index) => (
                    <Col lg={6} key={index} className="mb-24">
                      <SimpleCard
                        title={item?.title}
                        content={item?.content}
                        height={"100%"}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>

            <div className="case_study_section  mt-75 mb-60">
              <h2 className="case_study_main_title">Results</h2>

              <h1 className="case_study_main_subtitle">
                Revolutionizing E Commerce
              </h1>
              <div className="mt-50">
                <CaseResults data={card_data} />
              </div>
            </div>
          </Container>
        </section>
        <div className="case_page">
          <ServiceSubBanner
            bg={PHARM_SUBBANNER}
            title="Kickstart Your Dream Project With Us"
            contact="Contact Us"
            contentPadding="20%"
            padding="20%"
          />
        </div>
        <RelatedCaseStudy filterCol="Share Market" />
        <ContactNew />
        <Footer />
      </div>
    </motion.div>
  );
};

export default ShareMarket;

//
{
  /* */
}
