import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { ACCORD_EDUCATION } from "../../assets/images";
import "./comaccord.css";
import { Fade } from "react-awesome-reveal";

const ComAccord = ({ data }) => {
  return (
    <div className="mt-5">
      <Container>
        <h1 className="section_title mb-5">Frequently Asked Questions</h1>
        <Row>
          <Col lg={4}>
            <img
              className="w-100 mb-30"
              src={ACCORD_EDUCATION}
              alt="Image of Accord"
            />
          </Col>
          <Col lg={8}>
            <Fade direction="right" duration={0} cascade>
            <Accordion defaultActiveKey="0" flush alwaysOpen>
              {data?.map((item, index) => {
                return (
                  <Accordion.Item key={index} eventKey={index}>
                    <Accordion.Header>
                      <span>{item?.accHead}</span>
                    </Accordion.Header>
                    <Accordion.Body>{item?.accContent}</Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ComAccord;
