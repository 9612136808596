import React from 'react'
import './case-result.css'

const CaseResults = ({data}) => {
  return (
    <div>
        <div className='case_result_row'>

            {
                data?.map((item, index)=>(

                    <div key={index} className='case_result_card'>

                         <div className="case_result_card_content">
                            <h2>{item?.id}</h2>
                            <h3>{item?.title}</h3>
                            <p>{item?.content}</p>
                        
                        </div>




                    </div>

                ))

            }



        </div>

        
    </div>
  )
}

export default CaseResults