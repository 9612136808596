import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './services-key.css'

const ServicesKey = ({data}) => {
  return (
    <div className='service_key_section'>
        <Container>

               <h3 className='services-key-title'>Key Services</h3>

            <Row>

                {
                    data?.map((item, index)=>(

                        <Col className='mt24'  key={index} lg={4} md={6}>

                        <div className='service_key_service_card'>
                            <div className='service_key_service_card_content'>
                                <div className='service_key_service_card_content_main'>
                                    <h3>{item?.title}</h3>
                                     <p>{item?.content}</p>

                                </div>
                                <div className='services_key_service_image'>
                                <img  src={item?.image} alt={item?.name} />
                                </div>
                                



                            </div>


                        </div>

                        </Col>
                    ))
                }

               



            </Row>



        </Container>


    </div>
  )
}

export default ServicesKey