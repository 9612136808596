import React from 'react'

const Logo = () => {
  return (
    // technoblackcolor
  <svg className='logo' xmlns="http://www.w3.org/2000/svg" width="740" height="214" viewBox="0 0 734 214" fill="none">
<g clip-path="url(#clip0_317_25)">
<path d="M691.945 19.246V4.50998H686.977V2.44598H699.433V4.50998H694.489V19.246H691.945Z" fill="#131313"/>
<path d="M700.733 19.246V2.44598H703.733L709.421 13.63L715.085 2.44598H718.085V19.246H715.541V6.83798L710.405 16.846H708.437L703.277 6.86198V19.246H700.733Z" fill="#131313"/>
<path d="M324.888 92.2459V15.8299H298.776V2.64594H367.512V15.8299H341.272V92.2459H324.888Z" fill="#131313"/>
<path d="M389.653 93.7819C383.253 93.7819 377.579 92.4166 372.629 89.6859C367.68 86.9553 363.797 83.1153 360.981 78.1659C358.165 73.2166 356.757 67.4993 356.757 61.0139C356.757 54.4433 358.123 48.5979 360.853 43.4779C363.669 38.3579 367.509 34.3899 372.373 31.5739C377.323 28.6726 383.125 27.2219 389.781 27.2219C396.011 27.2219 401.515 28.5873 406.293 31.3179C411.072 34.0486 414.784 37.8033 417.429 42.5819C420.16 47.2753 421.525 52.5233 421.525 58.3259C421.525 59.2646 421.483 60.2459 421.397 61.2699C421.397 62.2939 421.355 63.3606 421.269 64.4699H373.013C373.355 69.4193 375.061 73.3019 378.133 76.1179C381.291 78.9339 385.088 80.3419 389.525 80.3419C392.853 80.3419 395.627 79.6166 397.845 78.1659C400.149 76.6299 401.856 74.6673 402.965 72.2779H419.605C418.411 76.2886 416.405 79.9579 413.589 83.2859C410.859 86.5286 407.445 89.0886 403.349 90.9659C399.339 92.8433 394.773 93.7819 389.653 93.7819ZM389.781 40.5339C385.771 40.5339 382.229 41.6859 379.157 43.9899C376.085 46.2086 374.123 49.6219 373.269 54.2299H404.885C404.629 50.0486 403.093 46.7206 400.277 44.2459C397.461 41.7713 393.963 40.5339 389.781 40.5339Z" fill="#131313"/>
<path d="M457.449 93.7819C450.964 93.7819 445.247 92.3739 440.297 89.5579C435.348 86.7419 431.423 82.8166 428.521 77.7819C425.705 72.7473 424.297 66.9873 424.297 60.5019C424.297 54.0166 425.705 48.2566 428.521 43.2219C431.423 38.1873 435.348 34.2619 440.297 31.4459C445.247 28.6299 450.964 27.2219 457.449 27.2219C465.556 27.2219 472.383 29.3553 477.929 33.6219C483.476 37.8033 487.017 43.6059 488.553 51.0299H471.273C470.42 47.9579 468.713 45.5686 466.153 43.8619C463.679 42.0699 460.735 41.1739 457.321 41.1739C452.799 41.1739 448.959 42.8806 445.801 46.2939C442.644 49.7073 441.065 54.4433 441.065 60.5019C441.065 66.5606 442.644 71.2966 445.801 74.7099C448.959 78.1233 452.799 79.8299 457.321 79.8299C460.735 79.8299 463.679 78.9766 466.153 77.2699C468.713 75.5633 470.42 73.1313 471.273 69.9739H488.553C487.017 77.1419 483.476 82.9019 477.929 87.2539C472.383 91.6059 465.556 93.7819 457.449 93.7819Z" fill="#131313"/>
<path d="M494.135 92.2459V0.0859375H510.519V38.4859C512.653 34.9873 515.511 32.2566 519.095 30.2939C522.765 28.2459 526.946 27.2219 531.639 27.2219C539.49 27.2219 545.549 29.6966 549.815 34.6459C554.167 39.5953 556.343 46.8486 556.343 56.4059V92.2459H540.087V57.9419C540.087 52.4806 538.978 48.2993 536.759 45.3979C534.626 42.4966 531.213 41.0459 526.519 41.0459C521.911 41.0459 518.071 42.6673 514.999 45.9099C512.013 49.1526 510.519 53.6753 510.519 59.4779V92.2459H494.135Z" fill="#131313"/>
<path d="M563.05 92.2459V28.7579H577.514L578.794 39.5099C580.757 35.7553 583.573 32.7686 587.242 30.5499C590.997 28.3313 595.392 27.2219 600.426 27.2219C608.277 27.2219 614.378 29.6966 618.73 34.6459C623.082 39.5953 625.258 46.8486 625.258 56.4059V92.2459H608.874V57.9419C608.874 52.4806 607.765 48.2993 605.546 45.3979C603.328 42.4966 599.872 41.0459 595.178 41.0459C590.57 41.0459 586.773 42.6673 583.786 45.9099C580.885 49.1526 579.434 53.6753 579.434 59.4779V92.2459H563.05Z" fill="#131313"/>
<path d="M662.429 93.7819C656.285 93.7819 650.739 92.3739 645.789 89.5579C640.925 86.7419 637.043 82.8593 634.141 77.9099C631.325 72.8753 629.917 67.0726 629.917 60.5019C629.917 53.9313 631.368 48.1713 634.269 43.2219C637.171 38.1873 641.053 34.2619 645.917 31.4459C650.867 28.6299 656.413 27.2219 662.557 27.2219C668.616 27.2219 674.077 28.6299 678.941 31.4459C683.891 34.2619 687.773 38.1873 690.589 43.2219C693.491 48.1713 694.941 53.9313 694.941 60.5019C694.941 67.0726 693.491 72.8753 690.589 77.9099C687.773 82.8593 683.891 86.7419 678.941 89.5579C673.992 92.3739 668.488 93.7819 662.429 93.7819ZM662.429 79.5739C666.696 79.5739 670.408 77.9953 673.565 74.8379C676.723 71.5953 678.301 66.8166 678.301 60.5019C678.301 54.1873 676.723 49.4513 673.565 46.2939C670.408 43.0513 666.739 41.4299 662.557 41.4299C658.205 41.4299 654.451 43.0513 651.293 46.2939C648.221 49.4513 646.685 54.1873 646.685 60.5019C646.685 66.8166 648.221 71.5953 651.293 74.8379C654.451 77.9953 658.163 79.5739 662.429 79.5739Z" fill="#131313"/>
<path d="M343.192 213.782C334.744 213.782 327.363 211.862 321.048 208.022C314.734 204.182 309.827 198.849 306.328 192.022C302.83 185.11 301.08 177.089 301.08 167.958C301.08 158.742 302.872 150.635 306.456 143.638C310.126 136.555 315.288 131.051 321.944 127.126C328.686 123.115 336.664 121.11 345.88 121.11C356.376 121.11 365.08 123.627 371.992 128.662C378.904 133.697 383.384 140.651 385.432 149.526H367.128C365.763 145.345 363.288 142.102 359.704 139.798C356.12 137.409 351.512 136.214 345.88 136.214C336.92 136.214 330.008 139.073 325.144 144.79C320.28 150.422 317.848 158.187 317.848 168.086C317.848 177.985 320.238 185.665 325.016 191.126C329.88 196.502 336.451 199.19 344.728 199.19C352.835 199.19 358.936 197.014 363.032 192.662C367.214 188.225 369.731 182.422 370.584 175.254H347.928V162.966H387.096V212.246H371.992L370.712 200.47C367.811 204.737 364.184 208.022 359.832 210.326C355.48 212.63 349.934 213.782 343.192 213.782Z" fill="#131313"/>
<path d="M424.528 213.782C418.128 213.782 412.454 212.417 407.504 209.686C402.555 206.955 398.672 203.115 395.856 198.166C393.04 193.217 391.632 187.499 391.632 181.014C391.632 174.443 392.998 168.598 395.728 163.478C398.544 158.358 402.384 154.39 407.248 151.574C412.198 148.673 418 147.222 424.656 147.222C430.886 147.222 436.39 148.587 441.168 151.318C445.947 154.049 449.659 157.803 452.304 162.582C455.035 167.275 456.4 172.523 456.4 178.326C456.4 179.265 456.358 180.246 456.272 181.27C456.272 182.294 456.23 183.361 456.144 184.47H407.888C408.23 189.419 409.936 193.302 413.008 196.118C416.166 198.934 419.963 200.342 424.4 200.342C427.728 200.342 430.502 199.617 432.72 198.166C435.024 196.63 436.731 194.667 437.84 192.278H454.48C453.286 196.289 451.28 199.958 448.464 203.286C445.734 206.529 442.32 209.089 438.224 210.966C434.214 212.843 429.648 213.782 424.528 213.782ZM424.656 160.534C420.646 160.534 417.104 161.686 414.032 163.99C410.96 166.209 408.998 169.622 408.144 174.23H439.76C439.504 170.049 437.968 166.721 435.152 164.246C432.336 161.771 428.838 160.534 424.656 160.534Z" fill="#131313"/>
<path d="M461.22 212.246V148.758H475.684L476.964 159.51C478.927 155.755 481.743 152.769 485.412 150.55C489.167 148.331 493.562 147.222 498.596 147.222C506.447 147.222 512.548 149.697 516.9 154.646C521.252 159.595 523.428 166.849 523.428 176.406V212.246H507.044V177.942C507.044 172.481 505.935 168.299 503.716 165.398C501.498 162.497 498.042 161.046 493.348 161.046C488.74 161.046 484.943 162.667 481.956 165.91C479.055 169.153 477.604 173.675 477.604 179.478V212.246H461.22Z" fill="#131313"/>
<path d="M560.983 213.782C554.583 213.782 548.909 212.417 543.959 209.686C539.01 206.955 535.127 203.115 532.311 198.166C529.495 193.217 528.087 187.499 528.087 181.014C528.087 174.443 529.453 168.598 532.183 163.478C534.999 158.358 538.839 154.39 543.703 151.574C548.653 148.673 554.455 147.222 561.111 147.222C567.341 147.222 572.845 148.587 577.623 151.318C582.402 154.049 586.114 157.803 588.759 162.582C591.49 167.275 592.855 172.523 592.855 178.326C592.855 179.265 592.813 180.246 592.727 181.27C592.727 182.294 592.685 183.361 592.599 184.47H544.343C544.685 189.419 546.391 193.302 549.463 196.118C552.621 198.934 556.418 200.342 560.855 200.342C564.183 200.342 566.957 199.617 569.175 198.166C571.479 196.63 573.186 194.667 574.295 192.278H590.935C589.741 196.289 587.735 199.958 584.919 203.286C582.189 206.529 578.775 209.089 574.679 210.966C570.669 212.843 566.103 213.782 560.983 213.782ZM561.111 160.534C557.101 160.534 553.559 161.686 550.487 163.99C547.415 166.209 545.453 169.622 544.599 174.23H576.215C575.959 170.049 574.423 166.721 571.607 164.246C568.791 161.771 565.293 160.534 561.111 160.534Z" fill="#131313"/>
<path d="M623.915 213.782C618.283 213.782 613.334 212.886 609.067 211.094C604.801 209.217 601.387 206.657 598.827 203.414C596.267 200.171 594.731 196.417 594.219 192.15H610.731C611.243 194.625 612.609 196.758 614.827 198.55C617.131 200.257 620.075 201.11 623.659 201.11C627.243 201.11 629.846 200.385 631.467 198.934C633.174 197.483 634.027 195.819 634.027 193.942C634.027 191.211 632.833 189.377 630.443 188.438C628.054 187.414 624.726 186.433 620.459 185.494C617.729 184.897 614.955 184.171 612.139 183.318C609.323 182.465 606.721 181.398 604.331 180.118C602.027 178.753 600.15 177.046 598.699 174.998C597.249 172.865 596.523 170.262 596.523 167.19C596.523 161.558 598.742 156.822 603.179 152.982C607.702 149.142 614.017 147.222 622.123 147.222C629.633 147.222 635.606 148.971 640.043 152.47C644.566 155.969 647.254 160.79 648.107 166.934H632.619C631.681 162.241 628.139 159.894 621.995 159.894C618.923 159.894 616.534 160.491 614.827 161.686C613.206 162.881 612.395 164.374 612.395 166.166C612.395 168.043 613.633 169.537 616.107 170.646C618.582 171.755 621.867 172.779 625.963 173.718C630.401 174.742 634.454 175.894 638.123 177.174C641.878 178.369 644.865 180.203 647.083 182.678C649.302 185.067 650.411 188.523 650.411 193.046C650.497 196.971 649.473 200.513 647.339 203.67C645.206 206.827 642.134 209.302 638.123 211.094C634.113 212.886 629.377 213.782 623.915 213.782Z" fill="#131313"/>
<path d="M664.797 138.902C661.811 138.902 659.336 138.006 657.373 136.214C655.496 134.422 654.557 132.161 654.557 129.43C654.557 126.699 655.496 124.481 657.373 122.774C659.336 120.982 661.811 120.086 664.797 120.086C667.784 120.086 670.216 120.982 672.093 122.774C674.056 124.481 675.037 126.699 675.037 129.43C675.037 132.161 674.056 134.422 672.093 136.214C670.216 138.006 667.784 138.902 664.797 138.902ZM656.605 212.246V148.758H672.989V212.246H656.605Z" fill="#131313"/>
<path d="M707.37 213.782C701.738 213.782 696.789 212.886 692.522 211.094C688.256 209.217 684.842 206.657 682.282 203.414C679.722 200.171 678.186 196.417 677.674 192.15H694.186C694.698 194.625 696.064 196.758 698.282 198.55C700.586 200.257 703.53 201.11 707.114 201.11C710.698 201.11 713.301 200.385 714.922 198.934C716.629 197.483 717.482 195.819 717.482 193.942C717.482 191.211 716.288 189.377 713.898 188.438C711.509 187.414 708.181 186.433 703.914 185.494C701.184 184.897 698.41 184.171 695.594 183.318C692.778 182.465 690.176 181.398 687.786 180.118C685.482 178.753 683.605 177.046 682.154 174.998C680.704 172.865 679.978 170.262 679.978 167.19C679.978 161.558 682.197 156.822 686.634 152.982C691.157 149.142 697.472 147.222 705.578 147.222C713.088 147.222 719.061 148.971 723.498 152.47C728.021 155.969 730.709 160.79 731.562 166.934H716.074C715.136 162.241 711.594 159.894 705.45 159.894C702.378 159.894 699.989 160.491 698.282 161.686C696.661 162.881 695.85 164.374 695.85 166.166C695.85 168.043 697.088 169.537 699.562 170.646C702.037 171.755 705.322 172.779 709.418 173.718C713.856 174.742 717.909 175.894 721.578 177.174C725.333 178.369 728.32 180.203 730.538 182.678C732.757 185.067 733.866 188.523 733.866 193.046C733.952 196.971 732.928 200.513 730.794 203.67C728.661 206.827 725.589 209.302 721.578 211.094C717.568 212.886 712.832 213.782 707.37 213.782Z" fill="#131313"/>
<path d="M25.8131 85.8615H200.548L174.974 128.628H0.238281L25.8131 85.8615Z" fill="#3A65FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M161.268 43.2149C175.096 43.2149 187.9 47.598 198.367 55.0508L225.301 22.9851C207.523 9.45441 185.334 1.42151 161.268 1.42151C119.689 1.4215 83.7096 25.4019 66.4067 60.2867H117.738C129.162 49.692 144.459 43.2149 161.268 43.2149ZM66.4012 154.203C83.7032 189.09 119.684 213.072 161.265 213.072C219.712 213.072 267.092 165.691 267.092 107.245L225.298 107.246C225.298 142.61 196.63 171.279 161.265 171.279C144.454 171.279 129.155 164.8 117.73 154.203H66.4012Z" fill="#3A65FF"/>
</g>
<defs>
<clipPath id="clip0_317_25">
<rect width="734" height="214" fill="white"/>
</clipPath>
</defs>
</svg>
  
   
  
    
  )
}

export default Logo