import React from "react";
import { Row, Col } from "react-bootstrap";
import { Fade, Roll } from 'react-awesome-reveal';
import "./What-Sets-Us-Apart.css";

const What_sets_us = ({ data, heading }) => {
    const cardInfo = [
        {
            title: "Innovation at ",
            title2:"Heart",
            content: "We stay ahead of the curve, leveraging the latest technologies and industry best practices to deliver transformative results.",
        },
        {
            title: "Personalized ",
            title2:"Solutions",
            content: "Recognizing the individuality of each business, we provide tailored solutions that are designed to meet your specific requirements and objectives.",
        },
        {
            title: "Expert ",
            title2:"Team",
            content: "Our talented professionals bring a wealth of experience and knowledge to every project, ensuring top-notch quality and performance.",
        },
        {
            title: "Commitment to ",
            title2:"Excellence",
            content: "We are dedicated to your success. Our comprehensive support and continuous innovation ensure you stay ahead in your industry.",
        },
    
    ];

    return (
        <div className="What_sets_us_container What_sets_us_row mt-5  pb-5">
            <Fade direction='up' duration={0}>
            <div className='What_sets_us_header p-3'>
                <h2 className="text-center p-5">Why Choose Us?</h2>
            </div>
            <Row className="What_sets_us_row w-100 ps-5 pe-5 pb-5">
                {cardInfo.map(({ title, content, title2 }, index) => (
                    <Col className={` pe-2 border-right `} lg={3} key={index}>
                        <Fade direction='up' duration={0}>
                        <div className={`What_sets_us_card p-4 h-100`}>
                            <div className="What_sets_us_card_content">
                                <h2>{title}</h2>
                                <h2>{title2}</h2>
                                <p className="mt-3">{content}</p>
                            </div>
                        </div>
                        </Fade>
                    </Col>
                ))}
            </Row>
            </Fade>
        </div>
    );
};

export default What_sets_us;
