import {
    LOAN_MANAGEMENT_DETAIL,
    COLLECTION_MODULE_DETAIL,
    DIGITAL_KYC_DETAIL,
    BORDER_PAYMENT_DETAIL,
  } from "../assets/images/index";


export const fintechdetail = [
    {
      title: "Loan Managemnet",
      img: LOAN_MANAGEMENT_DETAIL,
      id: "loan-management",
      listItems: [
        "Step into the future of finance with our 'Expertise in Fintech Delivery: Loan Management' software development. ",
        "We specialize in crafting robust, cutting-edge solutions that redefine the loan management landscape.",
        "Our software development team is adept at creating platforms that streamline the entire loan lifecycle.",
        "From intuitive application processes to advanced risk assessment algorithms, we blend technology and financial expertise for a seamless borrower experience.",
        "Explore the efficiency of our systems, designed to handle diverse lending models—from traditional loans to emerging fintech trends.",
      ],
    },
    {
      title: "Collection Module",
      img: COLLECTION_MODULE_DETAIL,
      reverseClass: "flex_reverse",
      id: "collection-module",
      listItems: [
        "Welcome to the forefront of Fintech Delivery, where our expertise shines in crafting cutting-edge Collection Modules through software development. ",
        "Dive into the future of efficient and strategic debt collection processes.",
        "Our software development team specializes in creating robust Collection Modules designed to optimize the entire debt recovery lifecycle. ",
        "From intelligent automation to personalized communication strategies, we integrate technology seamlessly to enhance your collection efforts.",
      ],
    },

    {
      title: "Digital KYC",
      img: DIGITAL_KYC_DETAIL,
      id: "digital_kyc",
      listItems: [
        "Step into the future of Fintech Delivery with our specialized expertise in Digital KYC through software development. ",
        "We're at the forefront of reshaping Know Your Customer processes, leveraging cutting-edge technology for a seamless and secure identity verification experience.",
        "Our software development team is dedicated to crafting robust Digital KYC solutions that transcend traditional methods. ",
        "Explore the efficiency of our systems, where biometrics, AI, and machine learning converge to streamline customer onboarding and verification processes.",
        "Discover the power of our Digital KYC software in ensuring compliance and security while providing a frictionless experience for users. ",
      ],
    },
    {
      title: "Cross Border Payment",
      img: BORDER_PAYMENT_DETAIL,
      reverseClass: "flex_reverse",
      id: "border-payment",
      listItems: [
        "Welcome to the forefront of Fintech Delivery, where our expertise shines in crafting cutting-edge solutions for Cross-Border Payments through software development. ",
        "Dive into the future of seamless and secure international transactions.",
        "Our software development team specializes in creating robust systems for Cross-Border Payments, designed to optimize speed, security, and efficiency. ",
        "Explore the power of our solutions, where blockchain, real-time processing, and innovative APIs converge to redefine how the world moves money across borders.",
        
      ],
    },

 


  ];