import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Newnavigation from '../../components/new-navigation/new_navigation';
import AboutusBanner from '../../components/Aboutus-banner/AboutusBanner';
import ContactNew from '../../components/contact-form/ContactNew';
import Footer from '../../components/footer/Footer';
import { KICKSTART_SUBBANNER, RETAIL_ECOMMERCE, SERVICE_SUB_BG} from '../../assets/images';
import { retail_heading } from '../../utils/retailande-commerce';
import {  Retail_Ecommerce} from '../../utils/Seo';
import Everything_eCommerce from '../../components/Everything-eCommerce/Everything-eCommerce';
import Ecommerce_app_idea from '../../components/Ecommerce-app-idea/Ecommerce-app-idea';
import Award_winning_team from '../../components/Award-winning-team/Award-winning-team';
import Ecommerce_retail_print_app from '../../components/Ecommerce-retail-print-app/Ecommerce-retail-print-app';
import RetailProgress from '../../components/RetailProgress/RetailProgress';
import HeaderSeo from '../../components/HeaderSeo/Headerseo';
import Realtimeexample from '../../components/Real-time-example/Real-time-example'
import { retail_realtime } from '../../utils/retail-realtime';
import { retail_realtime_cards } from '../../utils/retail-realtime-cards';
import TimeLine from '../../components/timeline/TimeLine';
import ServiceSubBanner from '../../components/service-sub-banner/ServiceSubBanner';
import { awardwinning_title, awarwinning_bg } from '../../utils/awardwinning_title';



const RetailandEcommerce = () => {
  const sectionRef = useRef()
  const refcustom = sectionRef.current
    useEffect(() => {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }, []);
    return (
        <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
        <div className='service-page' ref={sectionRef}>
            <Newnavigation routeName="solutions"/>
            <HeaderSeo data={Retail_Ecommerce}/>
            <AboutusBanner bg={RETAIL_ECOMMERCE} data={retail_heading}/>
            {/* <Everything_eCommerce /> */}
            {/* <Ecommerce_app_idea /> */}
            <Award_winning_team data={awardwinning_title} bgImg={awarwinning_bg}/>
            <TimeLine customRef={refcustom}/>
            <Realtimeexample data={retail_realtime} cards={retail_realtime_cards}/>
            <ServiceSubBanner
                bg={KICKSTART_SUBBANNER}
                title="Kickstart Your Dream Project With Us"
                content="The integration of omnichannel strategies, seamless e-commerce migration, and the adoption of 3D virtual stores represent the zenith of current e-commerce innovation, especially in diverse industries such as healthcare and medicines. These advancements not only drive operational efficiency but also significantly enhance the customer experience, setting a new standard in the digital commerce landscape."
                contact="Contact Us"
                padding="20%"
                contentPadding= "18%" />

            {/* <RetailProgress /> */}
            {/* <Ecommerce_retail_print_app /> */}

            
            <ContactNew />
            <Footer />
        </div>
        </motion.div>
    )
}

export default RetailandEcommerce