import React from 'react'
import './casecard.css'
import { Col, Row } from 'react-bootstrap'


const CaseCard = ({cardInfo}) => {
  return (
    <div>
        <div className='shadow_card_row'>
            {
                cardInfo?.map((item, i)=>(

                    <div  className='px-0 shadow_col' 
                    style={cardInfo?.length == 3 ? { flexBasis: "33.3%"} : cardInfo?.length == 4 ? {flexBasis:"25%"} : cardInfo?.length == 5 ? {flexBasis:"20%"} : {}}>
                        <div className='shadow_card'>
                            <h3>{item?.title}</h3>
                            <p>{item?.content}</p>

                        </div>


                    </div>

                ))
            }
            <div>
            
            </div>

        </div>

    </div>
  )
}

export default CaseCard