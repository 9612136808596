import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { IoChevronForward } from "react-icons/io5";
import "./keyServices.css";
import { Fade } from "react-awesome-reveal";
import ContactForm from "../contactus-popup/ContactForm";

const KeyServices = ({ data, heading }) => {
  const isOddLength = data.length % 2 !== 0;
  const lastColumnIndex = data.length - 1;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="mt-100">
        <h1 className="section_title text-center">{heading}</h1>
        <div className="mt-50">
          <Container>
            <Row>
              {data.map(({ title, Image, content }, index) => {
                const isLastColumn = isOddLength && index === lastColumnIndex;
                return (
                  <Col
                  className="key_serv"
                  lg={isLastColumn ? 12 : 6}
                  key={index}
                >
                  {index%2==0?
                  <Fade direction="left" duration={0}>
                    <div className= { isLastColumn ? 'key_card key_cardlast' :'key_card'}>
                      <div className="key_card_content">
                        <Image className="key_icons" />
                        <h2>{title}</h2>
                        <h3>{content}</h3>
                        <p onClick={handleShow}>
                          Get in touch <IoChevronForward />
                        </p>
                      </div>
                    </div>
                  </Fade>:
                  <Fade direction="right" duration={0}>
                    <div className="key_card">
                      <div className="key_card_content">
                        <Image className="key_icons" />
                        <h2>{title}</h2>
                        <h3>{content}</h3>
                        <p  onClick={handleShow}>
                          Get in touch <IoChevronForward />
                        </p>
                      </div>
                    </div>
                  </Fade>
                }
                </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </div>
      <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
    </>
  );
};

export default KeyServices;
