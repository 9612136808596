import React, { useEffect, useState } from 'react';
import './services-steps.css';
import { Col, Container, Row } from 'react-bootstrap';

const ServiceSteps = ({title, stepsData}) => {

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleEnter = (index) => {
      setHoveredIndex(index);
    };
  
    const handleLeave = () => {
      setHoveredIndex(null);
    };
  
    const handledivLeave = () => {
      setHoveredIndex(1);
    };
  
    useEffect(() => {
      setHoveredIndex(1);
    }, []);


  return (
    <div className='services_steps mt-75 mb-50'>
        <Container>
            <Row>
                <Col onMouseEnter={handledivLeave}>
                   <Row>
                    <Col lg={9}>
                    <h2 className='service_steps_title'>{title}</h2>
                    </Col>
                    </Row>
                     <div className='services_steps_slides' onMouseLeave={handledivLeave}>
                        {
                            stepsData.map((step, index) => {
                                return (
                                    <div  
                                    onMouseEnter={() => handleEnter(index)}
                                    
                                    className={hoveredIndex === index ? "services_steps_slide expanded" : "services_steps_slide"}  key={index}>
                                        <div className='services_steps_slide_content'>
                                            <h2 className='services_steps_slide_number'>{step.id}</h2>
                                            <h3 className='services_steps_slide_title'>{step.title}</h3>
                                            <p className='services_steps_slide_description'>{step.content}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }




                     </div>

                
                
                
                </Col>


            </Row>


        </Container>


        
    </div>
  )
}

export default ServiceSteps