import React, { useState, useRef, useEffect } from "react";
import { Row, Col, } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import "./Award-winning-team.css";
import Slider from "react-slick";
import { useLocation } from "react-router-dom";


const Award_winning_team = ({ data, bgImg, titleCount }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const caro = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      gotoNext();
    }, 7200);
    return () => clearInterval(interval);
  }, []);


  const Image = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    fade: true,
    draggable: false
  };




  const gotoNext = () => {
    caro.current?.slickNext();

  };



  return (
    <Fade direction="up" duration={0}>





      <Row className="mt-5 mb-5 w-100">
        {data.map((item, i) => {

          return (
            <Col lg={6} md={12} className="mt-2 mb-2" key={i}>
              <div className="left-div d-flex flex-column justify-content-center pe-5 ms-5">
                <h1 className="ps-4" >
                  {item.title} {" "}
                  <span className="span" >
                    {item.title_2}
                  </span>
                </h1>
                <p className="mt-3 pe-5 ps-4" >
                  {item.content}
                </p>
              </div>
            </Col>
          )
        })}


        <Col lg={6} md={12} className="p-0 mt-2 mb-2 left-content">
          <Slider ref={caro} {...Image} beforeChange={(currentSlide, nextSlide) => setCurrentIndex(nextSlide)}>
            {bgImg.map((items, i) => {
              return (
                <div className="right-div" style={{ backgroundImage: `url(${items.bgUrl})` }} key={i}>
                  <div className="div-loader ps-5">
                    <img src={items.bgUrl} alt="" />
                    <h1>{items.title}</h1>
                  </div>
                </div>
              )
            })}
          </Slider>
          {
            titleCount == 4 ? <ul className="title-loader">
              <li className={currentIndex === 0 ? 'panel-loader active' : 'panel-loader'} ></li>
              <li className={currentIndex === 1 ? 'panel-loader active' : 'panel-loader'} ></li>
              <li className={currentIndex === 2 ? 'panel-loader active' : 'panel-loader'} ></li>
              <li className={currentIndex === 3 ? 'panel-loader active' : 'panel-loader'}></li>
            </ul> : <ul className="title-loader">
              <li className={currentIndex === 0 ? 'panel-loader active' : 'panel-loader'} ></li>
              <li className={currentIndex === 1 ? 'panel-loader active' : 'panel-loader'} ></li>
              <li className={currentIndex === 2 ? 'panel-loader active' : 'panel-loader'} ></li>
            </ul>
          }



        </Col>



      </Row>

    </Fade>







  );
};

export default Award_winning_team;
