import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Newnavigation from "../../components/new-navigation/new_navigation";
import PageBanner from "../../components/page-banner/page-banner";
import LearnMore from "../../components/common-learn-more/LearnMore";
import InsuranceDetails from "../../components/insurance-detail/InsuranceDetail";
import Footer from "../../components/footer/Footer";
import { INSURANCE_DETAIL_BG } from "../../assets/images/index";
import { insurance } from "../../utils/insurancelearnmore";

const InsuranceDetail = () => {
  const type = useParams()?.type;
  useEffect(() => {
    document.getElementById(type).scrollIntoView();
  }, []);

  return (
    <div className="insurance_detail">
      <Newnavigation />
      <PageBanner bg={INSURANCE_DETAIL_BG} title="Insurance" />
      <LearnMore data={insurance} />
      <InsuranceDetails />
      <Footer />
    </div>
  );
};

export default InsuranceDetail;
