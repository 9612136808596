import React from 'react'
import { OUR_TEAM2 } from '../../assets/images';
import { Card, Container } from 'react-bootstrap';
import './Our-team-about.css'
import { Fade } from 'react-awesome-reveal';

const OurTeam = () => {
    return (
        <div className='our-team-overall-div w-100 mt-5'>
            <Fade duration={0} direction="left">
            <img src={OUR_TEAM2} alt='our team' className='w-100 group_pic'/>
            </Fade>
            <Card className='our-team-card'>
                <Card.Body className='our-team-card-body p-5'>
                    <Card.Title className='our-team-card-title'>Our Team</Card.Title>
                    <Card.Text className='our-team-card-text pt-3'>
                        We leverage the magic of technology to solve problems and deliver innovative solutions and services that our customers love, day after day.
                    </Card.Text>
                </Card.Body>
            </Card>

        </div>
    )
}
export default OurTeam;