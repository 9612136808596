import React, { useEffect } from "react";
import Newnavigation from "../../components/new-navigation/new_navigation";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/footer/Footer";
import './static.css'
import ContactNew from "../../components/contact-form/ContactNew";

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }, []);

  return (
    <div className="static_content">
      <Newnavigation />
      <Container>
        <Row>
          <Col lg={{ span: 12 }}>
            <div className="letter_box">
              <h2 className="main_head">Technogenesis Privacy Policy</h2>
              <h6>
                We at technogenesis are committed to protecting your privacy.
                This policy provides you with the details of how any personal
                data we collect from you or that you provide us will be used and
                processed. We periodically update this Privacy Policy. We will
                post any privacy policy changes on this page and, if the changes
                are significant, we will provide a more prominent notice by
                sending you an email notification.
              </h6>

              <div className="main_content">
                <div>
                  <h3>Consent</h3>
                  <p>
                    Technogenesis privacy policy describes how we collect and
                    use information in connection with your access to and use of
                    our website and any of its subdomains, mobile applications,
                    and related services (collectively, the ‘Services’).
                  </p>
                </div>
                <div>
                  <h3>Who We Are</h3>
                  <p>
                    We are a global technology company with offices in India and
                    USA. <br />
                    For the purposes of the Data Protection Act (‘DPA’) and
                    in-line with the EU General Data Protection Regulation (the
                    ‘GDPR’), Mobisoft Infotech is the ‘Data Controller’ and is
                    responsible for your personal data. <br />
                    If you have any concerns about the way we use your
                    information or any questions about this Privacy Policy,
                    please let us know. We can be contacted via email at
                    info@technogenesis.in
                  </p>
                </div>
                <div>
                  <h3>Information We Collect</h3>
                  <p>
                  You are free to explore the Websites without providing any Personal Information about yourself. When you visit the Website or register for the Subscription Service, we request that you provide Personal Information about yourself, and we collect Navigational Information. <br />
We may collect and process the following aspects of data about you: <br />
Information that you provide us– this is information you give us by filling in forms on our website or by <br /> corresponding with us by phone, email or otherwise or by applying for jobs via our website.
When You Visit our Website- whenever you visit our website, we will automatically collect technical information and information about your visit.  <br />
"Personal Information" <br />
This refers to any information that you voluntarily submit to us and that identifies you personally, including contact information, such as your name, e-mail address, company name, address, phone number, and other information about yourself or your business. Personal Information can also include information about you that is available on the internet, such as from Facebook, LinkedIn, Twitter and Google, or publicly available information that we acquire from service providers. <br />
Personal Information also includes Navigational Information where such information can directly or indirectly identify an individual. Navigational information refers to information about your computer and your visits to this website such as your IP address, geographical location, browser type, referral source, length of visit, and pages viewed. Except for this, we do not collect Sensitive Information from you.
                  </p>
                </div>
                <div>
                  <h3>Cookies</h3>
                  <p>
                  Our website uses cookies to distinguish you from other users of our website. This helps us provide you with a good experience when you browse our website and also allows us to improve our site.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
       <ContactNew />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
