import React, { useEffect, useRef } from 'react'
import Newnavigation from '../../components/new-navigation/new_navigation'
import ContactNew from '../../components/contact-form/ContactNew'
import Footer from '../../components/footer/Footer'
import { motion } from 'framer-motion';
import AboutusBanner from '../../components/Aboutus-banner/AboutusBanner'
import Award_winning_team from '../../components/Award-winning-team/Award-winning-team'
import { KICKSTART_SUBBANNER, LOGISTICS_BANNER } from '../../assets/images'
import { comprehensive_bg, comprehensive_title } from '../../utils/awardwinning_title'
import { logictics_heading } from '../../utils/transportation-logistics'
import Realtimeexample from '../../components/Real-time-example/Real-time-example'
import { logictics_usecase } from '../../utils/retail-realtime'
import { logistics_cards } from '../../utils/retail-realtime-cards'
import ServiceSubBanner from '../../components/service-sub-banner/ServiceSubBanner'
import LogisticsTimeLine from '../../components/timeline/LogisticsTimeLine'
import { Col, Container, Row } from 'react-bootstrap'

const TransportationandLogistics = () => {
  const sectionRef = useRef()
  const refcustom = sectionRef.current
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, transition: { duration: 1 } }}
  >
    <div>
      <Newnavigation routeName="solutions" />
      <AboutusBanner bg={LOGISTICS_BANNER} data={logictics_heading} />
      <Award_winning_team data={comprehensive_title} bgImg={comprehensive_bg} titleCount={4} />
      <Realtimeexample data={logictics_usecase} cards={logistics_cards} />
      <div className='mb-5'>
        <Container>
          <div className='real-time-example'>
            <h1>Use Case 2</h1>
            <p> <b>Addressing Real-Time Issues with Supply Chain Visibility</b> and dissatisfied customers.
              Here's how our comprehensive solution can address these issues</p>
          </div>
          <Row>
            <Col xl='6' lg='6' className='usecase-problems'>
              <h2>Problems</h2>
              <p>By implementing sophisticated analytics tools, we obtained insights into fleet
                performance, delivery patterns, and customer preferences. This data was
                instrumental in optimizing routes, enhancing delivery times, and improving
                overall service efficiency.</p>
            </Col>
            <Col xl='6' lg='6' className='usecase-solutions'>
              <h2>Solutions</h2>
              <ul>
                <li><b>Integrated Logistics:</b> Implementing an integrated logistics platform that provides end-to-end visibility across the entire supply chain.</li>
                <li><b>Advanced Analytics:</b> Utilizing advanced analytics tools to monitor supply chain performance, predict potential disruptions, and optimize logistics operations.</li>
                <li><b>Omnichannel Communication:</b> Enhancing customer communication through an omnichannel approach.</li>
                <li><b>Interactive Dashboards:</b> Creating interactive digital dashboards that provide stakeholders with a comprehensive view of the supply chain.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <LogisticsTimeLine customRef={refcustom} />
      <ServiceSubBanner
        bg={KICKSTART_SUBBANNER}
        title="Transform Your Logistics Services Today!"
        content="Ready to elevate your logistics operations? Discover how our innovative solutions can help you achieve your goals. *Contact us now* for a personalized consultation and let's drive the future of logistics together."
        contact="Contact Us"
        padding="20%"
        contentPadding="25%" />
      <ContactNew />
      <Footer />
    </div>
    </motion.div>
  )
}

export default TransportationandLogistics