import { TECH_1, TECH_2, TECH_3, TECH_4} from '../assets/images';
export const transforming_heading = [
    {
        title: "Transforming Businesses with"      
    },
    {
        title: "Innovative IT Solutions"
    },
  ];
      export const CoreServices = [
        {
      
        title : "Custom Software Development",
        content :"We create robust, scalable, and custom software solutions tailored to meet your unique business requirements. Our development process ensures high-quality software that enhances operational efficiency and drives growth.",
        image: TECH_1,
        keytitile: "Key Technologies",
        Key_technologies: [
          {items: 'Java, Python, PHP'},
          {items: 'Javascript, Angular, React.js'},
          {items: 'Flutter, GoLang, MEAN stack'}
        ],
        button_name: "Get Your Custom Software Solution Today"
    },
    {
      
        title : "Cloud Solutions",
        content :"Our wide range of cloud services can help you harness the power of the cloud. From cloud migration to cloud-native application development, we help you maximize the benefits of cloud technology.",
        image: TECH_2,
        keytitile: "Key Technologies",
        Key_technologies: [
          {items: 'AWS, Azure, Google Cloud'},
          {items: 'SaaS, PaaS, IaaS'}
        ],
        button_name: "Seamlessly Migrate to the Cloud",
        className: "row-reverse"
    },
    {
      
        title : "Data Science & AI",
        content: "Tap into data powerhouse with our data science and AI services. We provide advanced analytics, machine learning models, and AI solutions that drive informed decision-making and innovation.",
        image: TECH_3,
        keytitile: "Key Technologies",
        Key_technologies: [
          {items: 'Machine Learning, Deep Learning'},
          {items: 'Predictive Analytics, Big Data'}
        ],
        button_name: "Drive Innovation with AI & Data Science"
    },
    {
        title : "Blockchain Development",
        content: "Implement secure and transparent blockchain solutions that enhance trust and efficiency. Our blockchain services include smart contract development, blockchain integration, and consulting.",
        image: TECH_4,
        keytitile: "Key Technologies",
        Key_technologies: [
          {items: 'Ethereum, Hyperledger, Corda'}
        ],
        button_name: "Secure your Business with Blockchain",
        className: "row-reverse"
    },
    ]
  
  export const TransformingAccord = [
    {
      id:1,
      accHead:"What services do you offer?",
      accContent:"We offer custom software development, cloud solutions, data science & AI and blockchain development."
  
    },
    {
      id:2,
      accHead:"How can insurance businesses benefit from digital insurance systems?",
      accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  
    },
    {
      id:3,
      accHead: "How can claim management systems contribute to fraud prevention?",
      accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
      
  
    },
    {
      id:4,
      accHead: "What benefits of efficient insurance application processing?",
      accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
    }
  ]

  export const ITLandscapeFAQ = [
    {
      id:1,
      accHead:"What services do you offer?",
      accContent:"We offer custom software development, cloud solutions, data science & AI and blockchain development."
  
    },
    {
      id:2,
      accHead:"How can your services benefit my business?",
      accContent:"Our services help your business innovate, scale, and thrive in the digital era, ensuring maximum ROI and efficiency."
  
    },
    {
      id:3,
      accHead: "Do you provide customized IT strategies?",
      accContent:"Yes, we offer tailored IT strategies to meet your unique business needs and goals."
      
  
    },
    {
      id:4,
      accHead: "What is the process for implementing advanced technologies?",
      accContent:"Our team works closely with you to seamlessly implement advanced technologies aligned with your business objectives."
    },
    {
      id:5,
      accHead:"Can you help optimize our existing IT infrastructure?",
      accContent:"Yes, we can enhance your existing IT infrastructure to boost performance and efficiency."
  
    },
    {
      id:6,
      accHead: "Do you provide ongoing support after implementation?",
      accContent:"We offer continuous support and maintenance services to ensure the smooth operation of your IT solutions."
      
  
    },
    {
      id:7,
      accHead: "What sets you apart from other IT consulting companies?",
      accContent:"Our expertise, customer-centric approach, and use of cutting-edge technologies set us apart in the industry."
    }
  ]


  export const cardsInfo = [
    {
      title: "Expertise and Experience",
      content: "With years of experience in the IT industry, our team of experts delivers top-notch solutions tailored to your business needs, ensuring business growth and success.",
      serialno: "01"
    },
    {
      title: "Customer-Centric Approach",
      content: "We prioritize our clients' needs, ensuring that every solution we deliver aligns with your business goals and drives success through digital transformation.",
      serialno: "02"
    },
    {
      title: "Cutting-Edge Technology",
      content: "We leverage the latest technologies to provide innovative IT solutions that keep your business ahead of the competition.",
      serialno: "03"
    },
   
  ];

  export const TestimonialData = [
    {
        content: '"Our partnership with Techno Genesis has transformed our business operations. Their innovative IT solutions and expertise have driven significant growth."',
        client: 'Client A'
    },
    {
        content: "“Techno Genesis's cloud solutions have revolutionized our IT infrastructure, providing unmatched scalability and efficiency.”",
        client: 'Client B'
    }
  ]