import React, { useEffect, useRef } from 'react'
import Newnavigation from '../../components/new-navigation/new_navigation'
import Footer from '../../components/footer/Footer'
import { motion } from 'framer-motion';
import AboutusBanner from '../../components/Aboutus-banner/AboutusBanner'
import { EDUCTECH_BG, KICKSTART_SUBBANNER } from '../../assets/images'
import ContactNew from '../../components/contact-form/ContactNew'
import { edutech_heading } from '../../utils/edutech'
import FourTimeLine from '../../components/timeline/FourTimeLine'
import Realtimeexample from '../../components/Real-time-example/Real-time-example'
import TimeLine from '../../components/timeline/TimeLine'
import { edutech_usecase, retail_realtime } from '../../utils/retail-realtime'
import { retail_realtime_cards, usecase_cards } from '../../utils/retail-realtime-cards'
import Award_winning_team from '../../components/Award-winning-team/Award-winning-team'
import { modern_bg, modern_title } from '../../utils/awardwinning_title'
import ServiceSubBanner from '../../components/service-sub-banner/ServiceSubBanner'

function Edutech() {

  const sectionRef = useRef()
  const refcustom = sectionRef.current
  useEffect(() => {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }, []);
  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, transition: { duration: 1 } }}
  >
    <div>
        <Newnavigation routeName="solutions"/>
        <AboutusBanner bg={EDUCTECH_BG} data={edutech_heading}/>
        <Award_winning_team data={modern_title} bgImg={modern_bg}/>
        <Realtimeexample data={edutech_usecase} cards={usecase_cards}/>
        <FourTimeLine customRef={refcustom} />
        <ServiceSubBanner
                bg={KICKSTART_SUBBANNER}
                title="Let's Revolutionize Education Together!"
                content="Ready to take your educational services to the next level? Discover how our solutions can help you achieve your goals. Contact us today for a personalized consultation and let's create a brighter future for education together."
                contact="Contact Us"
                padding="20%"
                contentPadding= "23%" />
        <ContactNew />
        <Footer />
    </div>
    </motion.div>
  )
}

export default Edutech