import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import ContactForm from '../contactus-popup/ContactForm';
import { MOBILEBG } from '../../assets/images';
import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';

const ServiceSubBanner = (data) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    return (
        
        <div className='mb-75'>
            <Fade direction='up' duration={0}>
            <div className='banner-section h-unset pt-5 text-center' style={{ background: `url(${data?.bg || MOBILEBG})`, }}>
                <Container>
                    <Row>
                        <Col lg='12' >
                            <h1 className='page-title' style={{padding:`0 ${data.padding}`}}>{data?.title}</h1>
                            <p className='page-content' style={{padding:`0 ${data.contentPadding}`,color:'#ffffff', marginTop: '30px'}}>{data?.content}</p>
                            <p className='page-content' style={{padding:`0 ${data.contentPadding}`,color:'#ffffff', marginTop: '30px'}}>{data?.subContent}</p>
                            <Button className='nav_bar_btn ml-0 mb-5 mt-3 banner_btn' variant="outline-primary" onClick={handleShow}>
                                {data?.contact}
                            </Button>
                            {
                                data?.carrersbtn === "true" ?

                                <Button className='nav_bar_btn ml-2 mb-5 mt-3 banner_btn careers' variant="outline-primary" onClick={()=> navigate('/Careers')}>
                                Careers
                            </Button> : ""
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
            </Fade>
        </div>
       
    )
}

export default ServiceSubBanner