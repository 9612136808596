import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import './Whoweareabout.css';
import { Fade } from 'react-awesome-reveal';

const Who_we_are = () => {
  const cardInfo = [
    {
      title: "Who We Are?",
      content: "We are a team of passionate tech enthusiasts, creative thinkers, and industry experts dedicated to pushing the boundaries of what's possible. Our diverse talents and collective expertise allow us to tackle complex challenges and deliver customized solutions that meet your unique needs. We thrive on innovation, and our commitment to excellence drives everything we do.",
      behind_text: 'Who'
    },
    {
      title: "What We Do Best?",
      content: "We are a digital-first company providing comprehensive technology solutions across various industries. We help businesses transform their operations, enhance customer experiences, and grow their impact throughout their lifecycle in a more effective, efficient, and modern way. Our expertise spans multiple sectors, including retail, fintech, travel and hospitality, healthcare, education, insurance, and blockchain technology.",
      behind_text: 'What'
    }
  ];

  return (
    <div className="box">
      <Container className="mt-80 mb-5">
        <Row className='d-flex flex-wrap'>
          {cardInfo.map((cardInfo, index) => (
            <Col sm={12} md={12} lg={6} key={index}>
              {index === 1 ? (
                <Fade direction='right' duration={0}>
                  <Card className="about_realworld_card">
                    <Card.Body>
                      <div className='about_realworld'>
                        <h2 className='behind_text ms-5'>{cardInfo.behind_text}</h2>
                        <h1>{cardInfo.title}</h1>
                        <p className='pt-1 pe-5 mb-5'>{cardInfo.content}</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Fade>
              ) : (
                <Fade direction='left' duration={0}>
                  <Card className="about_realworld_card">
                    <Card.Body>
                      <div className='about_realworld'>
                        <h2 className='behind_text ms-5'>{cardInfo.behind_text}</h2>
                        <h1>{cardInfo.title}</h1>
                        <p className='pt-1 pe-5 mb-5'>{cardInfo.content}</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Fade>
              )}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Who_we_are;
