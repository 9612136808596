
import React, { useEffect } from 'react';
import { motion } from "framer-motion";
import { GenerativeSeo, ServicesSeo } from '../../utils/Seo';
import HeaderSeo from '../../components/HeaderSeo/Headerseo';
import Newnavigation from '../../components/new-navigation/new_navigation';
import ContactNew from '../../components/contact-form/ContactNew';
import Footer from '../../components/footer/Footer';
import {  GENERATIVE_AI_bg,  } from '../../assets/images';
import { GENERATIVE_AIS_servicesBanner, } from '../../utils/servicebanner';
import AboutusBanner from '../../components/Aboutus-banner/AboutusBanner';
import ServiceSteps from '../../components/service-steps/ServiceSteps';
import { Generative_AI_OurMissons, Generative_AI_keyService, Generative_AI_stepsdata } from '../../utils/services';
import OurMissions from '../../components/Our-missions/OurMissions';
import ServicesKey from '../../components/services-key/ServicesKey';

const GenerativeAI = () => {
    useEffect(() => {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }, []);
      return (
        <motion.div initial={{ opacity: 0 }}animate={{ opacity: 1, transition: { duration: 1 } }}>
        <div className='service-page '>
            <HeaderSeo data={GenerativeSeo}/>
            <Newnavigation routeName="services"/>
            <AboutusBanner bg={GENERATIVE_AI_bg} data={GENERATIVE_AIS_servicesBanner}/>
            <ServiceSteps title="Innovate, Automate, and Transform" stepsData={Generative_AI_stepsdata}/>
             <OurMissions  data={Generative_AI_OurMissons} />
              <ServicesKey data={Generative_AI_keyService} />
            <ContactNew />
            <Footer />
        </div>
        </motion.div>
    )
}

export default GenerativeAI