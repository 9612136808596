import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { ABOUT_OURMISSION } from '../../assets/images';
import { Fade } from 'react-awesome-reveal';
import './Ourmissioninabout.css';

const Ourmissioninabout = () => {
  const cardInfo = [
    {
      title: "Our Mission",
     
      content: "Our mission is to empower your business with innovative, technology-driven solutions that make operations smoother, customer experiences richer, and growth sustainable. We're here to be the driving force behind your digital transformation, helping you adapt and thrive in an ever-evolving technological landscape.",
      classname: 'our-mission-card'
    },
    {
      title: "Our Vision",
     
      content: "Our vision is to be your go-to partner for technological innovation, known for our ability to simplify complex challenges into elegant solutions. We aim to create a future where technology seamlessly integrates with every aspect of your business, driving progress and making a real impact.",
      classname: 'our-story-card'
    },
  ];

  return (
    <div>
      <Fade direction='top' duration={0}>
   
        <section className='mission_vission'>

        {cardInfo.map((card, index) => (
            
              <Card key={index} className={card.classname}>
                <Card.Body className="p-0">
                  <div className='our-mission-content'>
                    <h1>{card.title}</h1>
                    
                    <p className='content'>{card.content}</p>
                  </div>
                </Card.Body>
              </Card>
          
          ))}
            <div className='image_flex'>
            <img src={ABOUT_OURMISSION} className='w-100' alt="Our Mission" />
          </div>


        </section>

      </Fade>
    </div>
  );
}

export default Ourmissioninabout;
