

export const LOGO = require('./main_logo.png')
export const DIGITALSECIMG = require('./digital_1.png')
export const ENTERPRICEAPP = require('./enterprise-application.png')
export const ENTERPRICEBG = require('./enter_price_bg.png')
export const FILEUPLOAD = require('./fileupload.png')
export const MOBILEBG = require('./mobiledev_bg.png')
export const AIBG = require('./ai_bg.png')
export const TESTBG = require('./test_bg.png')
export const BLOCKBG = require('./blockchain_bg.png')
export const SHAREPOINTBG = require('./sharepoint.png')
export const SALESFORCEBG = require('./salesforce_bg.png')
export const SERVICENOWBG = require('./service-now-bg.png')
export const NETSUITEBG = require('./netsuite_bg.png')
export const ORACLEBG = require('./oracle_bg.png')
export const OPENSOURCEBG = require('./opensource_bg.png')
export const CONGITIVEBG = require('./congtive_bg.png')
export const BUSINESSINTEL = require('./business_intel_bg.png')
export const ARVRBG = require('./ar-vr-bg.png')
export const CLOUDBG = require('./cloud_bg.png')
export const CAREERBG = require('./career_bg.png')
export const ABOUTBG = require('./about_bg.png')
export const DIGITALBG = require('./digital_bg.png')
export const EXPERTISEBG = require('./expertise_bg.png')
export const APISERVICE = require('./api.png')
export const STAR = require('./StarFour.png')
export const LINE_1 = require ('./line1.png')
export const FOOTER_LOGO = require('./footerlogo.png')
export const UNI_CLIENTS_1 = require('./clients/NRI.png')
export const UNI_CLIENTS_2 = require('./clients/arc.png')
export const UNI_CLIENTS_3 = require('./clients/buywoods.png')
export const UNI_CLIENTS_4 = require('./clients/citadel-trading.png')
export const UNI_CLIENTS_5 = require('./clients/dyooti.png')
export const UNI_CLIENTS_6 = require('./clients/evolving-tech.png')
export const UNI_CLIENTS_7 = require('./clients/jcs.png')
export const UNI_CLIENTS_8 = require('./clients/krcollege.png')
export const UNI_CLIENTS_9 = require('./clients/musk.png')
export const UNI_CLIENTS_10 = require('./clients/theprintapp.png')
export const UNI_CLIENTS_11 = require('./clients/vs.png')
export const UNI_CLIENTS_12 = require('./clients/Suzhiyam.png')
export const UNI_CLIENTS_HEAD = require('./clients/clients.png')
export const LOGO_NEW = require('./logo.svg')
export const SPECTRUM_bg = require('./specrum-bg.png')   
export const SPECTRUM_batch = require('./Batch.png')   
export const SPECTRUM_star = require('./mdi_star-four-points.png')   
export const SPECTRUM_1 = require('./spectrum-1.png')   
export const SPECTRUM_2 = require('./PenNib.png')
export const SPECTRUM_3 = require('./AppWindow.png')
export const SPECTRUM_4 = require('./TestTube.png')
export const SPECTRUM_5 = require('./Robot.png')
export const SPECTRUM_6 = require('./ShieldCheck.png')
export const SPECTRUM_7 = require('./Devices.png')
export const SPECTRUM_8 = require('./ClockCountdown.png')
export const SPECTRUM_9 = require('./MegaphoneSimple.png')
export const SPECTRUM_10 = require('./GoogleCardboardLogo.png')
export const SPECTRUM_11 = require('./CubeFocus.png')
export const SPECTRUM_12 = require('./Scan.png')
export const SPECTRUM_13 = require('./Cloud.png')
export const SPECTRUM_14 = require('./Coin.png')
export const SPECTRUM_15 = require('./ChartDonut.png')
export const ProductImg = require('./prod-img.png')
export const Shadow = require('./shadow.png')
export const Vector = require('./vector-round.png')
export const VIDEO = require('../video/banner_video.mp4')
export const  HEDIMER= require('./new/heidmar_logo.png')
export const  ARC= require('./new/arc_logo.png')
export const  PUPPY= require('./new/puppy_logo.png')
export const  ACCUPAYD= require('./new/accupayd_logo.png')
export const  ALWAQT= require('./new/alwaqt_logo.png')
export const  MUSK= require('./new/musk_logo.png')
export const  RAONE= require('./new/raone_logo.png')
export const  PRINTAPP= require('./new/printapp_logo.png')
export const  PETAL= require('./new/petal_logo.png')
export const  NRI= require('./new/nri_logo.png')
export const  WIPRO= require('./new/wipro_logo.png')
export const  MURUGAPPA= require('./new/murugappa_logo.png')
export const  IMPIGER= require('./new/impiger_logo.png')
export const  ECHO= require('./new/echo_logo.png')
export const  SILVI= require('./new/silvi_logo.png')
export const  ECOM= require('./products/e_commerce solutions.png')
export const  SECURED= require('./products/secured_messaging_system.png')
export const  PARKING= require('./products/parking_solutions.png')
export const  FOOD= require('./products/food_ordering_app.png')
export const  TICKET= require('./products/ticketing_solution.png')
export const  CUSTOM= require('./products/customs_management_system.png')
export const  HELATH= require('./products/healthcare_communication_system.png')
export const  STUDENT= require('./products/student_education_app.png')
export const  VECHILE= require('./products/vehicle_tracking_app.png')
export const  PROCESS= require('./products/process_tracking_system.png')
export const  TRACK= require('./products/tracking_solutions.png')
export const  PHONE= require('./phone_logo.svg')
export const  EMAIL= require('./EnvelopeSimpleOpen.svg')
export const  THANKYOU= require('./thank_you_logo.gif')
export const  ACCOUNTABLE= require('./accountable.png')
export const  TRUSTEDPARTNER= require('./trusted-person.png')
export const  RIGHTECH= require('./right-tech.png')
export const  DESIGNTHINKING= require('./digital-thinking.png')
export const  SALESDELIVERY= require('./sales-delivery.png')
export const  SALESFORCE= require('./salesforce.png')
export const  SERVICENOWTHUMB= require('./servicenow-thumb.png')
export const  SERVICENOW= require('./service-now.png')
export const  NETSUITETHUMB= require('./netsuite-thumb.png')
export const  NETSUITE= require('./netsuite.png')
export const  ORACLETHUMB= require('./oracle-thumb.png')
export const  ORACLE= require('./oracle.png')
export const  OPENSOURCETHUMB= require('./opensource-thumb.png')
export const  OPENSOURCE= require('./opensource.png')
export const  BLOCKCHAIN= require('./blockchain.png')
export const  MISSION= require('./mission.png')
export const  VISION= require('./vision.png')
export const  STRATEGY= require('./strategy.png')
export const  SMART= require('./smart.png')
export const  FRAMEWORK= require('./framework.png')
export const  WALLET= require('./wallet.png')
export const  CRYPTO= require('./crypto.png')
export const  PRIVATE= require('./Private.png')
export const  ICO= require('./Ico.png')
export const  COGNITIVE= require('./cognitive.png')
export const AI = require('./ai.png')
export const  BUSINESS= require('./business.png')
export const CLOUD = require('./cloud1.png')
export const JOBTYPE = require('./jobtype.png')
export const WORKCARD_BG_1 = require('./work_card_bg_1.png')
export const WORKCARD_IMG_1 = require('./work_card_img_1.png')
export const WORKCARD_BG_2 = require('./work_card_bg_2.png')
export const WORKCARD_IMG_2 = require('./work_card_img_2.png')
export const WORKCARD_BG_3 = require('./work_card_bg_3.png')
export const WORKCARD_IMG_3 = require('./work_card_img_3.png')
export const WORKCARD_BG_4 = require('./work_card_bg_4.png')
export const WORKCARD_IMG_4 = require('./work_card_img_4.png')
export const WORKCARD_BG_5 = require('./work_card_bg_5.png')
export const WORKCARD_IMG_5 = require('./work_card_img_5.png')
export const WORKCARD_BG_6 = require('./work_card_bg_6.png')
export const WORKCARD_IMG_6 = require('./work_card_img_6.png')
export const CASE_BG_1 = require('./case_bg_1.png')
export const CASE_IMG_1 = require('./case_img_1.png')
export const CASE_ECOM = require('./case_study_ecom.png')
export const  EXP= require('./exp.png')
export const MAP = require('./map.png')
export const UIUX = require('./uiux.png')
export const APIINTEGRATION = require('./api_integration.png')
export const  Loader= require('./loader.gif')
// export const FORM_IMG = require('./form_img.png')
export const FORM_IMG_NEW = require('./form_img_new.png')
export const FORM_IMG = require('./form_img.png')
export const PRODUCT_IMG_NEW = require('./product_healthcare.png')

export const RIGHT_ARROW = require('./right_arrow.png')
export const HEALTH_CARE = require('./healthcare.png')
export const CASH_MANAGEMENT = require('./cash_management.png')
export const FINTECH = require('./fintech.png')
export const JEWELLERY = require('./jewellery.png')
export const MARKET = require('./market.png')
export const E_COMMERCE = require('./e_commerce.png')
export const Services = require('./services.png')
export const Retail_Ecommerce = require('./service-2.png')
export const Transportation_Logistics = require('./Transportation & Logistics.png')
export const Sports_Entertainment = require('./Sports & Entertainment.png')
export const Learning_Training = require('./Learning & Training.png')
export const healthcare = require('./healthcareserviece.png')
export const Services3 = require('./service-3.png')
export const Services4 = require('./service-4.png')
export const TECHNOLOGY = require('./technology.png')
export const OUTSOURCING = require('./outsourcing.png')
// export const  Loader= require('./loader.gif')

export const RELATED_HEALTH_CARE = require('./related_health_care.png')
export const RELATED_CASE_MANAGEMENT = require('./related_case_management.png')
export const RELATED_FINANCE = require('./related_finance.png')
export const RELATED_JEWELLERY = require('./related_jewellery.png')
export const RELATED_MARKET = require('./related_market.png')
export const RELATED_ECOMMERCE = require('./related_ecommerce.png')
export const HEALTHTECK = require('./healthtech.png')
export const MEDICAL_TRANSPORT = require('./medical-transport.png')
export const APPOINTMENT = require('./appointment.png')
export const TELEMEDICINE = require('./telemedicine.png')
export const MOBILE_LEARNING = require('./mobile-learning.png')
export const MEDICAL_SALES = require('./medical-sales.png')
export const LEARNMORE_IMG = require('./learnmore_img.png')


export const COULDDEVOPS = require('./coulddevops.png')
export const BIG_DATA = require('./big-data.png')
export const IOT = require('./iot.png')
export const GPS_TECH = require('./gps-tech.png')
export const B_CHAIN = require('./b-chain.png')


export const FINANCE_BG = require('./finance-bg.png')
export const FINANCE_BG_3 = require('./finance-bg-3.png')
export const FINANCE_BG_2 = require('./finance-bg-2.jpg')
export const INDUSTRIES_BG_1 = require('./industries-bg-1.png')
export const INDUSTRIES_BG_2 = require('./industries-bg-2.png')
export const INDUSTRIES_BG_3 = require('./industries-bg-3.png')
export const INDUSTRIES_BG_4 = require('./industries-bg-4.png')
export const INDUSTRIES_BG_5 = require('./industries-bg-5.png')
export const INDUSTRIES_BG_6 = require('./industries-bg-6.png')

//insurance
export const VEHICLE = require('./industry/insurance/vehicle.png')
export const BG1 = require('./bg1.jpg')
export const BG2 = require('./bg2.png')
export const BG3 = require('./bg3.png')
export const BG4 = require('./bg4.jpg')
export const BG5 = require('./bg5.png')
export const BG6 = require('./bg6.png')



export const TAX_CARD = require('./tax_card.png')
export const TAX_ELIGIBILITY = require('./tax_eligibility.png')
export const TAX_AUTOMATED = require('./tax_automated.png')
export const TAX_VEHICLE = require('./tax_vehicle.png')

export const INSURANCE_OPERATION = require('./insurance_operation.png')
export const INSURANCE_CUSTOMER_JOURNEY = require('./insurance_customer_journey.png')
export const INSURANCE_WORKFORCE = require('./insurance_workforce.png')

export const SMART_CONTRACT = require('./smart_contract.png')
export const CRYPTOCURRENCY = require('./cryptocurrency.png')
export const TRADE_EXCHANGE = require('./trade_exchange.png')
export const PRIVATE_BLOCKCHAIN = require('./private_blockchain.png')
// export const ICO_STO = require('./ico-sto.png')

export const PHARMACY_BENEFIT = require('./pharmacy_benefit.png')
export const FINANCIAL_RECOVERY = require('./financial_recovery.png')
export const MEDICAL_MANAGEMENT = require('./medical_management.png')
export const DATA_TRANSPARENCY = require('./data_transparency.png')
export const VISITOR_COMPLAINT = require('./visitor-complaint.png')
export const DOCTOR_COMMUNICATION = require('./doctor-communication.png')
export const DIGITAL_THERAPY = require('./digital-therapy.png')
export const HEALTH_TOKEN = require('./health-token.png')

export const FRAME_3 = require('./Frame-3.png')
export const FRAME_2 = require('./Frame-2.png')
export const FRAME_1 = require('./Frame-1.png')



export const LOAN_MANAGEMENT = require('./loan_management.png')
export const COLLECTION_MODULE = require('./collection_module.png')
export const DIGITAL_KYC = require('./digital_kyc.png')
export const BORDER_PAYMENT = require('./border_payment.png')

export const AI_BASED = require('./ai_based.png')
export const CONTROL_CENTRE = require('./control_centre.png')
export const COMPUTER_VISION = require('./computer_vision.png')
export const DIGITAL_TWIN = require('./digital_twin.png')
export const CONSULTING = require('./consulting.png')

export const LEARNING_MANAGEMENT = require('./learning_management.png')
export const VIRTUAL_CLASSROOM = require('./virtual_classroom.png')
export const LEARNING_TOOLS = require('./learning_tools.png')
export const ONLINE_PLATFORM = require('./online_platforms.png')

export const COVER_WHITE = require('./right_arrow_cover-white.png')

export const PRODUCT_EDUCTAION = require('./product_education.png')
export const PRODUCT_FINTECH = require('./product_banking_fintech.png')
export const PRODUCT_BLOCKCHAIN = require('./product_blockchain.png')
export const PRODUCT_INSURANCE = require('./product_insurance.png')
export const PRODUCT_TRAVEL = require('./product_travel.png')
export const PRODUCT_CHAIN = require('./product_supply.png')
export const INSURANCE_MENU = require('./insurance-menu.png')
export const INSURANCE_BG = require('./insurance_banner.png')
export const LOGISTICS_MENU = require('./logistics-menu.png')
export const FINTECH_MENU = require('./fintech-menu.png')
export const BLOCKCHAIN_MENU = require('./blockchain-menu.png')
export const BLOCKCHAIN_BG = require('./Blockchain_banner.png')
export const ICO_STO = require('./ico-sto.png')
export const EDUCATION = require('./education.png')
export const EDUCATION_BG = require('./education_banner.png')
export const HEALTH_CARE_BG = require('./HEALTHCARE_banner.png')
export const RETAIL_BG = require('./RETAIL.png')
export const FINTECH_BG = require('./financeTechnology-bg.png')
export const OURMISSION_EDUCATION = require('./../images/industry/Education/education_mission.png')
export const OURMISSION_HEALTHCARE = require('./../images/industry/healthcare/healthcare_mission.png')
export const OURMISSION_RETAIL = require('./../images/industry/logistics/OURMISSION_RETAIL.png')
export const OURMISSION_FINTECH = require('./../images/industry/fintech/fintech_mission.png')
export const OURMISSION_INSURANCE = require('./../images/industry/insurance/insurance_mission.png')
export const OURMISSION_TRAVEL = require('./../images/industry/travel&hospitality/travel_mission.png')
export const OURMISSION_BLOCKCHAIN = require('./../images/industry/blockchain/blockchain_mission.png')
export const ACCORD_EDUCATION = require('./../images/industry/Education/edu_acc.png')
export const WHATWEDOEDUCATION = require('./../images/whatwedo_1.png')
export const WHATWEDOEDUCATION_1 = require('./../images/whatwedo_2.png')
export const WHATWEDOEDUCATION_2 = require('./../images/whatwedo_3.png')
export const TRAVEL_BG = require('./travel_banner.png')


export const TECHNOLOGY_MENU = require('./technology-list.png')
export const OUTSOURCING_MENU = require('./outsourcing-list.png')

export const FINANCIAL_RECOVERY_NEW = require('./financial_recovery_new.png')
export const MEDICAL_MANAGEMENT_NEW = require('./medical_management_new.png')
export const DATA_PRICING = require('./data_pricing.png')
export const VISITOR_COMPLAINT_NEW = require('./visitors_complaint_new.png')
export const DOCTOR_COMMUNICATION_NEW = require('./doctor_communication_new.png')
export const DIGITAL_THERAPY_NEW = require('./digital_therapy.png')
export const TOKEN_CREATION = require('./token_creation.png')

export const DETAIL_VEHICLE_INSURANCE = require('./detail_vehicle_insurance.png')
export const DETAIL_AUTOMATED_E_FILLING = require('./detail_automated_e_filling.png')
export const DETAIL_ELIGIBILITY_MODULE = require('./detail_eligiblility_module.png')
export const DETAIL_CLAIM_MODULE = require('./detail_claim_module.png')

export const STO_DEVELOPMENT_DETAIL = require('./sto_development_detail.png')
export const PRIVATE_BLOCKCHAIN_DETAIL = require('./private_blockchain_detail.png')
export const CRYPTOTRADE_EXCHANGE = require('./cryptotrade_exchange.png')
export const CRYPTOCURRENCY_DETAIL = require('./cryptocurrency_detail.png')
export const SMART_CONTRACT_DETAIL = require('./smart_contract_detail.png')
export const BLOCKCHAIN_DETAIL = require('./blockchain_detail_bg.png')

export const HEALTHCARE_DETAIL_BG = require('./healthcare_detail_bg.png')
export const INSURANCE_DETAIL_BG = require('./insurance_detail_bg.png')
export const BLOCKCHAIN_DETAIL_BG = require('./blockchain-detail-bg.png')
export const FINTECH_DETAIL_BG = require('./fintech-detail-bg.png')
export const EDUCATION_DETAIL_BG = require('./education-detail-bg.png')
export const LOGISTICS_DETAIL_BG = require('./logistics-detail-bg.png')
export const LOAN_MANAGEMENT_DETAIL = require('./loan_management_detail.png')
export const COLLECTION_MODULE_DETAIL = require('./collection_module_detail.png')
export const DIGITAL_KYC_DETAIL = require('./digital_kyc_detail.png')
export const BORDER_PAYMENT_DETAIL = require('./border_payment_detail.png')



export const SUPPLY_CHAIN_BG = require('./supply_chain_bg.png')
export const CONTROL_CENTRE_DETAIL = require('./control_centre_detail.png')
export const COMPUTER_VISION_DETAIL = require('./computer_vision_detail.png')
export const DIGITAL_TWIN_DETAIL = require('./digital_twin_detail.png')
export const AI_BASED_DETAIL = require('./ai_based_detail.png')

// Education Learn more page

export const EDUCATION_LEARNMORE = require('./education-learnmore.png')
export const LEARNING_MANAGE = require('./learning-menage.png')
export const VIRTUALCLASS = require('./virtualclass.png')
export const E_LEARNING = require('./e-learning.png')
export const ONLINE_ASSESSMENT = require('./online-assessment.png')

export const SERVICE_BG = require('./service-banner.png')
export const CONSULTING_IMG = require('./consulting-img.png')
export const TECHNOLOGY_IMG = require('./technology-img.png')
export const OUTSOURCING_IMG = require('./outsource-img.png')
export const SERVICE_SUB_BG = require('./service-sub-bg.png')

//education 
export const WHYCHOOSETGSS = require('./whychoosetgss.png')

//About us
export const ABOUTUS_BG = require('./about-banner.png')
export const ABOUT_OURMISSION = require('./Ourmission people.png')
export const OurHistoryImg = require('./Our company.png')

export const DIRECTOR = require('./Company leaders/DIRECTOR & CO-FOUNDER.png')
export const VP_SERVICE = require('./Company leaders/VP- SERVICE DELIVERY.png')
export const MANAGER = require('./Company leaders/MANAGER TECHNOLOGY.png')
export const OUR_TEAM2 = require('./ourteam about 2.png')

export const CEO = require('./Company leaders/siva_ramer_ceo.webp')
export const HeadOfDigitalMarketing = require('./Company leaders/soundarya_head_digital_marketing.webp')
export const HeadOfLegacyTech = require('./Company leaders/raja_pandi_head_of legacy_tech.webp')





//education
export const BG7 = require('./bg7.png')

//solutions

export const RETAIL_ECOMMERCE = require('./retail-banner.png')
export const QUICK_COMMERCEBG = require('./quick-commerceBG.png')
export const PRINTAPP_LOGO = require('./print-app logo.png')
// retail & Ecommerce
export const OMNICHANNEL = require('./Omnichannel_Engagement.png')
export const SEAMLESS = require('./seamless_migration.png')
export const VIRTUAL_STORES = require('./virtual_stores.png')
export const ANALYTICS = require('./Analytics.png')
export const MARKETING = require('./marketing.png')
export const ENHANCED_USER = require('./Enhanced_User.png')
export const EASE_OF_USE = require('./Ease_of_Use.png')
export const OMNICHANNEL_MARKTING = require('./Omnichannel_Marketing.png')
export const ADVANCE_ANALYTICS = require('./Advanced_Analytics_bg.png')
export const ENHANCED_USER_BG = require('./Enhanced_User-Experience_bg.png')
export const EASE_OF_USE_BG = require('./Ease_of_Use_bg.png')
export const KICKSTART_SUBBANNER = require('./kickstart-subbanner.png')

// Edutech
export const EDUCTECH_BG = require('./edutech-bg.png')
export const MORDEN_BG_1 = require('./modern_bg_1.png')
export const MORDEN_BG_2 = require('./modern_bg_2.png')
export const MORDEN_BG_3 = require('./modern_bg_3.png')
export const USE_CASE_1 = require('./use_case_1.png')
export const USE_CASE_2 = require('./use_case_2.png')
export const USE_CASE_3 = require('./use_case_3.png')
export const USE_CASE_4 = require('./use_case_4.png')
export const USE_CASE_5 = require('./use_case_5.png')
export const USE_CASE_1_ICON = require('./Line.png')
export const USE_CASE_3_ICON = require('./omni_marketing.png')
export const USE_CASE_4_ICON = require('./emce-user.png')

// Logistics
export const LOGISTICS_BANNER = require('./logictics-banner.png')
export const LOGISTICS_BG_1 = require('./logistics_bg-1.png')
export const LOGISTICS_BG_2 = require('./logistics_bg-2.png')
export const LOGISTICS_BG_3 = require('./logistics_bg-3.png')
export const LOGISTICS_BG_4 = require('./logistics_bg-4.png')
export const LOGISTICS_ICON_1 = require('./Integrated_Logistics.png')
export const LOGISTICS_2 = require('./Advanced_Analytics.png')
export const LOGISTICS_CARD_BG = require('./logistic-card-bg.png')

// healthcare
export const DIGI_SOLUTIONS_1 = require('./digisolution-bg-1.png')
export const DIGI_SOLUTIONS_2 = require('./digisolution-bg-2.png')
export const DIGI_SOLUTIONS_3 = require('./digisolution-bg-3.png')
export const DIGI_SOLUTIONS_4 = require('./digisolution-bg-4.png')

//Technology
export const ITSOLUTIONBANNER = require('./technology/it-banner.png')
export const SOA_BANNER = require('./technology/soa-banner.png')
export const WAREHOUSING_BANNER = require('./technology/warehousing-banner.png')
export const DATAMINING_BANNER = require('./technology/data-mining-banner.png')
export const TRANSFORMING_BANNER = require('./technology/transformin-banner.png')
export const UNIX_BANNER = require('./technology/unix-banner.png')
export const TECH_1 = require('./technology/custom-software-development.png')
export const TECH_2 = require('./technology/cloud-solutions.png')
export const TECH_3 = require('./technology/datascience-ai.png')
export const TECH_4 = require('./technology/blockchain-development.png')
export const SOA_1 = require('./technology/soa-1.png')
export const SOA_2 = require('./technology/soa-2.png')
export const SOA_3 = require('./technology/soa-3.png')
export const SOA_4 = require('./technology/soa-4.png')
export const AVATER = require('./technology/avater.png')
export const UNIX_1 = require('./technology/unix-1.png')
export const UNIX_2 = require('./technology/unix-2.png')
export const UNIX_3 = require('./technology/unix-3.png')
export const UNIX_4 = require('./technology/unix-4.png')
export const DATA_MINING_1 = require('./technology/datamining-1.png')
export const DATA_MINING_2 = require('./technology/datamining-2.png')
export const DATA_MINING_3 = require('./technology/datamining-3.png')
export const DATA_MINING_4 = require('./technology/datamining-4.png')
export const WAREHOUSE_1 = require('./technology/warehouse-1.png')
export const WAREHOUSE_2 = require('./technology/warehouse-2.png')
export const WAREHOUSE_3 = require('./technology/warehouse-3.png')
export const WAREHOUSE_4 = require('./technology/warehouse-4.png')
export const TRANSFORM_1 = require('./technology/transform-1.png')
export const TRANSFORM_2 = require('./technology/transform-2.png')
export const TRANSFORM_3 = require('./technology/transform-3.png')
export const TRANSFORM_4 = require('./technology/transform-4.png')

// Outsorcing
export const INFO_TECH_BG = require('./outsourcing/info-tech-bg.png')
export const BUSINESS_PROC_BG = require('./outsourcing/bussiness-proc-bg.png')
export const PROG_MANAGE_BG = require('./outsourcing/prog-mange-bg.png')
export const INFO_TECH_1 = require('./outsourcing/info-tech-1.png')
export const INFO_TECH_2 = require('./outsourcing/info-tech-2.png')
export const INFO_TECH_3 = require('./outsourcing/info-tech-3.png')
export const INFO_TECH_4 = require('./outsourcing/info-tech-4.png')
export const INFO_TECH_5 = require('./outsourcing/info-tech-5.png')
export const PROG_MANAGE_1 = require('./outsourcing/prog-manage-1.png')
export const PROG_MANAGE_2 = require('./outsourcing/prog-manage-2.png')
export const PROG_MANAGE_3 = require('./outsourcing/prog-manage-3.png')
export const PROG_MANAGE_4 = require('./outsourcing/prog-manage-4.png')
export const BUSINESS_PROC_1 = require('./outsourcing/business-pro-1.png')
export const BUSINESS_PROC_2 = require('./outsourcing/business-pro-2.png')
export const BUSINESS_PROC_3 = require('./outsourcing/business-pro-3.png')
export const BUSINESS_PROC_4 = require('./outsourcing/business-pro-4.png')
export const BUSINESS_PROC_5 = require('./outsourcing/business-pro-5.png')

// Career
export const CAREER_BG = require('./career-bg.png')
export const EXPLORE_LIFE = require('./carrers_img.png')
export const NO_OPENINGS = require('./no-openings.png')



//pharam

export const PHARM_BG = require('./pharam-bg.png');
export const PHARM_SUBBANNER = require('./pharam-banner.png')

//services 

// digital 

export const DIGITAL_BG = require('./digital_banner.png')
export const DIGITAL_Img = require('./digitalservices-conent-img.png');
export const DIGITAL_KEY_SERVICE_1 = require('./digital_key_service_digital_transformation_strategy_development.png')
export const DIGITAL_KEY_SERVICE_2 = require('./digital_key_service_technology_an_platform_selection.png')
export const DIGITAL_KEY_SERVICE_3 = require('./digital_key_service_process_automation_and_optimization.png')
export const DIGITAL_KEY_SERVICE_4 = require('./digital_key_service_data_analytics.png')
export const DIGITAL_KEY_SERVICE_5 = require('./digital_key_service_customer_experience_enhancement.png')
export const DIGITAL_KEY_SERVICE_6 = require('./digita_key_service_change_management_and_training.png')


//Strategy and Architecture 

export const Architecture_bg = require('./architecture_bg.png')
export const Architecture_Img = require('./architecture_misson_bg.png');
export const Arch_KEY_SERVICE_1 = require('./architecture_key_strategic_IT_roadmap_development.png')
export const Arch_KEY_SERVICE_2 = require('./architecture_key_IT_architecture_design_and_optimization.png')
export const Arch_KEY_SERVICE_3 = require('./architecture_key_technology_integration_and_implementation.png')
export const Arch_KEY_SERVICE_4 = require('./achitecture_key_enterprise_architecture_frameworks.png')
export const Arch_KEY_SERVICE_5 = require('./achitecture_key_IT_governance_and_risk_management.png')
export const Arch_KEY_SERVICE_6 = require('./achitecture_key_innovation_and_emerging_technologies.png')


//business transformation 

export const BUSINESS_bg = require('./business_transfermation_bg.png')
export const BUSINESS_Img = require('./business_transformation_mission.png');
export const BUSINESS_KEY_SERVICE_1 = require('./business_transformation_comprehensive_process_redesign.png')
export const BUSINESS_KEY_SERVICE_2 = require('./business_transformation_holistic_change_management.png')
export const BUSINESS_KEY_SERVICE_3 = require('./business_transformation_advanced_digital_integration.png')
export const BUSINESS_KEY_SERVICE_4 = require('./business_transformation_strategic_vision_crafting.png')
export const BUSINESS_KEY_SERVICE_5 = require('./business_transformation_customer_experience_reimagining.png')
export const BUSINESS_KEY_SERVICE_6 = require('./business_transformation_continuous_performance_improvement.png')


//Enterprise 

export const ENTERPRISE_bg = require('./enterprise_banner_bg.png')
export const ENTERPRISE_Img = require('./enterprise_mission_img.png');
export const ENTERPRISE_KEY_SERVICE_1 = require('./enterprise_comprehensive_process_assessment.png')
export const ENTERPRISE_KEY_SERVICE_2 = require('./enterprise_workflow_automation_solutions.png')
export const ENTERPRISE_KEY_SERVICE_3 = require('./enterprise_advanced_technology_integration.png')
export const ENTERPRISE_KEY_SERVICE_4 = require('./enterprise_performance_metrics.png')
export const ENTERPRISE_KEY_SERVICE_5 = require('./enterprise_lean and_six_sigma_implementation.png')
export const ENTERPRISE_KEY_SERVICE_6 = require('./enterprise_change_management_and_employee_training.png')


//change and learnings

export const CHANGE_AND_LEARNING_bg = require('./change_and_learning_bg.png')
export const CHANGE_AND_LEARNING_Img = require('./change_and_learning_img.png');
export const CHANGE_AND_LEARNING_KEY_SERVICE_1 = require('./change_and_learning_custom_change_management_strategy.png')
export const CHANGE_AND_LEARNING_KEY_SERVICE_2 = require('./change_and_learning_cultural_transformation_programs.png')
export const CHANGE_AND_LEARNING_KEY_SERVICE_3 = require('./change_and_learing_personalized_employee_training_programs.png')
export const CHANGE_AND_LEARNING_KEY_SERVICE_4 = require('./change_and_learning_leadership_coaching_and_mentoring.png')
export const CHANGE_AND_LEARNING_KEY_SERVICE_5 = require('./change_and_learning_continuous_learning_ecosystems.png')
export const CHANGE_AND_LEARNING_KEY_SERVICE_6 = require('./change_and_learning_data_driven_performance_feedback_systems.png')


// Geneative AI

export const GENERATIVE_AI_bg = require('./Generative_AI_BG.png')
export const GENERATIVE_AI_Img = require('./Generative_AI_MISSION.png');
export const GENERATIVE_AI_KEY_SERVICE_1 = require('./Generative_AI_Custom_Model_Development.png')
export const GENERATIVE_AI_KEY_SERVICE_2 = require('./Generative_AI_Enterprise_Search_and_Discovery.png')
export const GENERATIVE_AI_KEY_SERVICE_3 = require('./Generative_AI_Knowledge_Management.png')
export const GENERATIVE_AI_KEY_SERVICE_4 = require('./Generative_AI_Advisory_and_Mentoring_Services.png')
export const GENERATIVE_AI_KEY_SERVICE_5 = require('./Generative_AI_Generative_AI_for_Customer_Engagement.png')
export const GENERATIVE_AI_KEY_SERVICE_6 = require('./Generative_AI_Driven_Automation.png')


//realword new


export const REAL_WORLD_1 = require('./real_world_case_study_finance.png')
export const REAL_WORLD_2 = require('./real_world_case_study_sharemarket.png')
export const REAL_WORLD_3 = require('./real_world_case_study_ecommerce.png')
export const REAL_WORLD_4 = require('./real_world_case_study_healthcare.png')
export const REAL_WORLD_5 = require('./real_world_case_study_legal.png')
export const REAL_WORLD_6 = require('./real_word_case_study_manufature.png')

export const HOME_SOLUTIONS_1 = require('./home_solutions_retail.png')
export const HOME_SOLUTIONS_2 = require('./home_solutions_edutech.png')
export const HOME_SOLUTIONS_3 = require('./home_solutions_transportation_ logistics.png')
export const HOME_SOLUTIONS_4 = require('./home_solutions_healthcare.png')
//error
export const NotFundImg = require('./error.png')

export const SUCCESSGIF = require('./check_animation.gif')