

 export const heading = [
  {
      title: "We Build"
  },
  {
      title: "Digital Products",
      id: "color-text"
  },
  {
      title: "That Help Your"
  },
  {
      title: "Business Scale"
  }
];

export const DigitalServicesBanner = [
    {
        title : "Digital Transformation "
    },
    {
        title : "Consulting Services"
    }
]
 
export const ArchitectureServicesBanner = [
    {
        title : "Strategy and Architecture",
        col_size: ""
    },
    {
        title : "Consulting Services"
    }
]
 

export const BusinessServicesBanner = [
    {
        title : "Business Transformation",
        col_size: ""
    },
    {
        title : "Consulting Services"
    }

]
export const EnterpriseServicesBanner = [
    {
        title : "Enterprise Processes ",
        col_size: ""
    },
    {
        title : "Consulting Services"
    }

]

export const ChangeAndLearnServicesBanner = [
    {
        title : "Change and Learning ",
        col_size: ""
    },
    {
        title : "Consulting Services"
    }

]

export const GENERATIVE_AIS_servicesBanner = [
    {
        title : "Generative AI ",
        col_size: ""
    },
    {
        title : "Consulting Services"
    }

]