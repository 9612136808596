import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import './WorkCard.css'


const WorkCard = ({bg, smalltext,  title, content, link, image, icon, text_clr}) => {

  const navigate = useNavigate()

  const caseStudyPage = () =>{

    navigate(`case-study/${link}`)

  }

  return (
    <div className={"workcard_style"}  style={{backgroundImage : `url(${bg})`, backgroundColor:'#000000c9' }}>
        <div className='h-100 w-100'>
            <Row className='h-100'>
                <Col lg={6} style={{alignSelf:"center"}}>
                    <div className='h-100 ps-5 work_card_cont' >

                        <p className='workcard_study' style={{color: text_clr}}>{smalltext}</p>
                        <h2 className='workcard_title'>{title}</h2>
                        <p className='workcard_content' >{content}</p>
                        {/* <button className='case_study_btn d-flex arrow-button align-items-center' onClick={caseStudyPage}>View Case Study   <span class="arrow"></span>  <img className='ml-10 right_arrow_white' src={icon} alt="" /></button> */}
                        <button className='case_study_btn d-flex arrow-button align-items-center' onClick={caseStudyPage}>View Case Study   <span class="arrow ml-10"></span> </button>
  


                    </div>

                </Col>
                <Col className='p-0 center_img image_col' lg={6} style={{alignSelf:'end'}}>
                    <div className='h-100 img_end'>
                        <img src={image} alt="pic" />
                    </div>
                </Col>
            </Row>

        </div>


    </div>
  )
}

export default WorkCard