import { ReactComponent as Training } from "./../assets/images/industry/insurance/training_apps.svg";
import { ReactComponent as Induction } from "./../assets/images/industry/insurance/induction.svg";
import { ReactComponent as Engagement } from "./../assets/images/industry/insurance/engagement_apps.svg";
import { ReactComponent as Booking } from "./../assets/images/industry/insurance/skill_booking.svg";
import { ReactComponent as Tution } from "./../assets/images/industry/insurance/tution_apps.svg";
import { ReactComponent as Virtual } from "./../assets/images/industry/insurance/virutual_classroom.svg";
import { ReactComponent as E_LEARN } from "./../assets/images/industry/insurance/e-learning.svg";
import { ReactComponent as VIDEO } from "./../assets/images/industry/insurance/video-player.svg";
import { ReactComponent as VIDEO_PLATER } from "./../assets/images/industry/insurance/video-player-2.svg";
import { ReactComponent as NOTIFI } from "./../assets/images/industry/logistics/promotion_1077221 1.svg";
import { ReactComponent as SUPPLY } from "./../assets/images/industry/logistics/syringe_1550066 1.svg";
import { ReactComponent as BUY } from "./../assets/images/industry/logistics/buy_12226131 1.svg";
import { ReactComponent as SEARCH } from "./../assets/images/industry/logistics/analysis_809497 1.svg";
import { ReactComponent as RETINF } from "./../assets/images/industry/logistics/syringe_1550066 15.svg";
import { OURMISSION_RETAIL, WHATWEDOEDUCATION, WHATWEDOEDUCATION_1, WHATWEDOEDUCATION_2 } from "../assets/images";


export const LogisticsWhatwedo = [
  {
    image: WHATWEDOEDUCATION,
    ques:"What We Do?",
    ans : "Techno Genesis offers innovative solutions that redefine the customer experience, enhance supply chain visibility, optimize e-commerce operations, and provide data-driven business intelligence to retail businesses."
  },
  {
    image: WHATWEDOEDUCATION_1,
    ques: "Who We Do It For?",
    ans : "Our services are tailored for retail businesses of all sizes, from startups to established enterprises, seeking to transform their operations and gain a competitive edge in the market."
  },
  {
    image: WHATWEDOEDUCATION_2,
    ques: "What They Get?",
    ans : "By choosing Techno Genesis, businesses can expect enhanced customer experiences, streamlined supply chain processes, improved e-commerce interactions, data-driven insights for strategic decisions, and fortified information security to safeguard their business and customer data."
  },
]

export const LogisticsAccord = [
  {
    id:1,
    accHead:"What makes Techno Genesis's solutions innovative?",
    accContent:"Techno Genesis's solutions are innovative because they leverage cutting-edge technologies and industry best practices to deliver transformative results."

  },
  {
    id:2,
    accHead:"Who can benefit from Techno Genesis's services?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:3,
    accHead: "How do Techno Genesis's solutions improve customer experiences?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
    

  },
  {
    id:4,
    accHead: "How can Techno Genesis optimize supply chain processes?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  },
  {
    id:5,
    accHead:"What type of data-driven insights can Techno Genesis provide? ",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:6,
    accHead:"How does Techno Genesis ensure information security? ",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:7,
    accHead:"Can Techno Genesis integrate with existing retail systems?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:8,
    accHead:"How long does it take to implement Techno Genesis's solutions?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:9,
    accHead:"Can Techno Genesis provide ongoing support and maintenance?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  },
  {
    id:10,
    accHead:"What sets Techno Genesis apart from other retail solution providers?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  }

]

export const LogisticsMissons = {
  title :"Our Missions",
  content:"At Techno Genesis, our mission is to transforming the retail industry through our comprehensive suite of services. Our innovative solutions redefine the customer experience, empower businesses with data-driven insights, and optimize their operations for the future. With Techno Genesis, retailers can expect enhanced customer experiences, streamlined supply chains, improved e-commerce interactions, and fortified information security. We understand that every business is unique, and our services align seamlessly with the specific needs of each retailer, offering a transformative and future-proof solution for their retail operations.",
  Image : OURMISSION_RETAIL
}
export const KeyserviceLogistics = [
  {
    Image: NOTIFI,
    title: "Omnichannel Marketing Solutions",
    content :"With our omnichannel marketing solutions, we enable retailers to seamlessly integrate and deliver consistent experiences across multiple channels, including online, mobile, and physical stores. By adopting an omnichannel approach, businesses can effectively target and engage customers, increasing brand loyalty and driving sales."
  },
  {
    Image:SUPPLY,
    title:"Supply Chain Visibility Solutions",
    content:"Our supply chain visibility solutions provide retailers with real-time insights into their supply chain processes, optimizing inventory management, reducing costs, and improving customer satisfaction. With our robust tracking systems and advanced analytics, businesses can identify bottlenecks, eliminate inefficiencies, and ensure timely product delivery."

  },
  {
    Image:BUY,
    title:"E-commerce Solutions",
    content :"Our e-commerce solutions elevate online retail experiences by providing intuitive interfaces, secure payment gateways, and personalized shopping experiences. With our expertise, businesses can create user-friendly e-commerce platforms that attract and retain customers, leading to increased sales and brand loyalty."
  },
  {
    Image:SEARCH,
    title:"Data Analysis & Business Intelligence Solutions",
    content :"Our data analysis and business intelligence solutions empower retailers with actionable insights derived from comprehensive data analysis. By leveraging advanced analytics tools, businesses can make informed decisions, optimize operations, and identify opportunities for growth."
  },
  {
    Image:RETINF,
    title:"Information Security Solutions",
    content :"Our information security solutions safeguard retailers' business and customer data from cyber threats and unauthorized access. By implementing robust security measures, businesses can build trust with their customers and maintain compliance with industry regulations."
  }


]

export const logistics = [
    {
        title: "Corporate Training Apps",
        Image: Training
      },
      {
        title: "Induction and Orientation Apps",
        Image: Induction
      },
      {
        title: "Employee Engagement Apps",
        Image: Engagement
      },
      {
        title: "Skill Booking Apps",
        Image: Booking
      },
      {
        title: "Tuition Apps ",
        Image: Tution
      },
      {
        title: "Virtual Classrooms",
        Image: Virtual
      },
      {
        title: "LMS Development",
        Image: E_LEARN
      },
      {
        title: "eLearning Apps for Disabled",
        Image: VIDEO
      },
      {
        title: "On-demand eLearning Apps",
        Image: VIDEO_PLATER
      }
    ]

    export const whyChooseTgssDetails = [
      {
      Title:"Why Choose Techno Genesis?",
      Details: [{
        List: "Expertise",
        ListItem: "Our team takes pride in housing seasoned developers and instructional designers who possess extensive expertise in the field of education and edutech."
      },
      {
        List: "Innovation",
        ListItem: "Our commitment to innovation is evident in our proactive approach, where we leverage the newest technologies and stay abreast of emerging learning trends, resulting in the development of solutions that push the boundaries of what's possible."
      },
      {
        List: "Customization",
        ListItem: "We customize our solutions to align seamlessly with your distinct requirements and financial parameters, guaranteeing an ideal match for your one-of-a-kind learning setting."
      },
      {
        List: "Scalability",
        ListItem: "Our solutions are crafted to scale alongside your requirements, adjusting seamlessly to accommodate a growing user base or evolving educational objectives."
      },
      {
        List: "Support",
        ListItem: "We provide continuous support and maintenance to guarantee the seamless operation of your eLearning solution, ensuring it consistently delivers optimal results."
      },
      {
        List: "Omnichannel Marketing Solutions",
        ListItem: "Boost your brand's impact with Techno Genesis Omnichannel Marketing Solutions. We craft seamless strategies across all channels, ensuring a unified and effective approach. Elevate your online and offline presence with us, your trusted partner for comprehensive marketing success."
      },]
    }
    ]   