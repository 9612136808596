import React, { useEffect } from "react";
import NewNavigation from "../../components/new-navigation/new_navigation";
import PageBanner from "../../components/page-banner/page-banner";
import PageSmallBanner from "../../components/page-small-banner/page-small-banner";
import KeyServices from "../../components/key-services/KeyServices";
import OurMissions from "../../components/Our-missions/OurMissions";
import ComAccord from "../../components/Accordian/ComAccord";
import WhatweDo from "../../components/what-we-do-img/whatweDo";
import ContactNew from "../../components/contact-form/ContactNew";
import Footer from "../../components/footer/Footer";
import {
  BlockchainWhatwedo,
  BlockchainOurMissons,
  KeyserviceBlockchains,
  BlockchainAccord
} from "../../utils/blockchain";
import { BG7, BLOCKCHAIN_BG } from "../../assets/images/index";
import { BlockChainSeo } from "../../utils/Seo";
import HeaderSeo from "../../components/HeaderSeo/Headerseo";
import { motion } from "framer-motion";

const Blockchains = (props) => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
    <div>
      <HeaderSeo data={BlockChainSeo}/>
      <NewNavigation routeName="Blockchain" />
      <PageBanner
        bg={BLOCKCHAIN_BG}
        title="Transform Your Blockchain Business with Techno Genesis"
        contact="Contact Us"
      />
      <WhatweDo data={BlockchainWhatwedo} />
      <OurMissions data={BlockchainOurMissons} />
      <KeyServices data={KeyserviceBlockchains} heading={"Key Services"} />
      <div className="mt-100 mb-50">
        <PageSmallBanner
          isBtn={true}
          btn_text="Contact Us"
          title="Techno Genesis stands as your trusted partner in navigating the ever-evolving landscape of blockchain technology. With our expertise and commitment to excellence, we empower businesses to embrace decentralized solutions and unlock new possibilities."
          bg={BG7}
          height="357px"
        />
      </div>
      <div className="mb-50">
        <ComAccord data={BlockchainAccord} />
      </div>
      <ContactNew />
      <Footer />
    </div>
    </motion.div>
  );
};

export default Blockchains;
