import { ReactComponent as Booking } from "./../assets/images/industry/insurance/skill_booking.svg";
import { ReactComponent as Tution } from "./../assets/images/industry/insurance/tution_apps.svg";
import { ReactComponent as Virtual } from "./../assets/images/industry/insurance/virutual_classroom.svg";
import { ReactComponent as E_LEARN } from "./../assets/images/industry/insurance/e-learning.svg";
import { ReactComponent as VIDEO } from "./../assets/images/industry/insurance/video-player.svg";
import { ReactComponent as VIDEO_PLATER } from "./../assets/images/industry/insurance/video-player-2.svg";
import { ReactComponent as ONLINE_TRAIN } from "./../assets/images/industry/travel&hospitality/touch.svg";
import { ReactComponent as MOBILE_DEV } from "./../assets/images/industry/travel&hospitality/operator.svg";
import { ReactComponent as COURSE } from "./../assets/images/industry/travel&hospitality/user.svg";
import { ReactComponent as EDUAPP } from "./../assets/images/industry/travel&hospitality/artificial-intelligence.svg";
import { ReactComponent as Training } from "./../assets/images/industry/travel&hospitality/setting.svg";
import { ReactComponent as Induction } from "./../assets/images/industry/travel&hospitality/mobile-development.svg";
import { ReactComponent as Engagement } from "./../assets/images/industry/travel&hospitality/layers.svg";
import { OURMISSION_TRAVEL, WHATWEDOEDUCATION, WHATWEDOEDUCATION_1, WHATWEDOEDUCATION_2 } from "../assets/images";


export const TravelandHospitalityWhatwedo = [
  {
    image: WHATWEDOEDUCATION,
    ques:"What We Do?",
    ans : "Techno Genesis delivers comprehensive solutions in software development, mobile app development, digital experiences, consulting, IT solutions, and AI solutions, exclusively designed for the Travel and Hospitality Industry."
  },
  {
    image: WHATWEDOEDUCATION_1,
    ques: "Who We Serve?",
    ans : "Tailoring to businesses within the Travel and Hospitality sector, from startups to enterprises, seeking enhanced digital presence and customer experiences."
  },
  {
    image: WHATWEDOEDUCATION_2,
    ques: "What Sets Us Apart?",
    ans : "Choosing Techno Genesis ensures tailored solutions, leveraging cutting-edge technology for innovation, efficiency, and growth in the dynamic Travel and Hospitality landscape."
  },
]

export const TravelandHospitalityAccord = [
  {
    id:1,
    accHead:"How can Techno Genesis enhance the security of customer data for travel and hospitality businesses?",
    accContent:"We implement robust security measures, including encryption, access controls, and continuous monitoring, to safeguard customer data from cyber threats and unauthorized access, ensuring compliance with industry regulations."

  },
  {
    id:2,
    accHead:"Can Techno Genesis's solutions adapt to the dynamic nature of the Travel and Hospitality Industry?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:3,
    accHead: "What level of personalization can travel and hospitality businesses achieve with Techno Genesis's AI solutions?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
    

  },
  {
    id:4,
    accHead: "How does Techno Genesis support the digital transformation of travel and hospitality businesses?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  },
  {
    id:5,
    accHead: "Can Techno Genesis assist in optimizing the operational efficiency of hospitality services?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
    

  },
  {
    id:6,
    accHead: "Is Techno Genesis's focus exclusively on large hospitality enterprises, or do you cater to smaller businesses as well?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  }
]

export const TravelandHospitalityOurMissons = {
  title :"Our Mission",
  content:"At Techno Genesis, we're devoted to reshaping the Travel and Hospitality Industry through our suite of advanced services. Our solutions are meticulously crafted to enhance the digital journey of travelers and boost the operational efficiency of hospitality businesses. With Techno Genesis, clients anticipate a seamless blend of technology and hospitality, delivering unparalleled experiences to customers while optimizing internal processes for success.",
  Image : OURMISSION_TRAVEL
}

export const KeyserviceTravelandHospitality = [
  {
    Image: ONLINE_TRAIN,
    title: "Digital Experience Solutions",
    content :"Our digital experience solutions empower travel and hospitality businesses to create immersive digital journeys. From intuitive booking platforms to interactive guest experiences, every touchpoint leaves a lasting impression, driving customer loyalty and satisfaction."
  },
  {
    Image:MOBILE_DEV,
    title:"Consulting Services",
    content:"Our consulting services provide strategic guidance and expertise to navigate the complexities of the digital landscape. Whether developing a digital transformation roadmap or optimizing existing processes, our team is committed to tangible results and maximizing ROI for our clients."

  },
  {
    Image:COURSE,
    title:"Omnichannel Solutions",
    content :"At Techno Genesis, we empower your business with an Omnichannel strategy, ensuring a synchronized and compelling presence across every customer touchpoint. Elevate your connectivity, enhance customer satisfaction, and stay ahead in the dynamic Travel and Hospitality Industry. Explore our Omnichannel solutions for a new era of digital synergy."
  },
  {
    Image:EDUAPP,
    title:"AI Solutions",
    content :"Techno Genesis excels in AI solutions tailored for the Travel and Hospitality Industry. From intelligent chatbots to personalized recommendations, we harness AI to automate tasks, enhance customer interactions, and deliver hyper-personalized experiences, driving engagement and loyalty."
  },
  {
    Image:Training,
    title:"IT Solutions",
    content:"Designed to empower travel and hospitality businesses, our IT solutions provide robust and scalable technology infrastructure. From cloud computing to cybersecurity, clients have the tools to operate efficiently, and securely, and adapt to evolving industry trends."

  },
  {
    Image:Induction,
    title:"Mobile App Development",
    content :"Stay ahead in the mobile era with Techno Genesis's top-notch mobile app development services. We design and develop custom mobile applications catering to the unique needs of travel and hospitality businesses, enhancing customer engagement, and loyalty, and driving revenue growth."
  },
  {
    Image:Engagement,
    title:"Software Development",
    content :"Techno Genesis offers bespoke software development services tailored to the specific requirements of the Travel and Hospitality Industry. Whether a reservation management system or a loyalty program platform, our team delivers innovative solutions, streamlining operations and elevating the guest experience"
  }

]

export const Education = [
    {
        title: "Corporate Training Apps",
        Image: Training
      },
      {
        title: "Induction and Orientation Apps",
        Image: Induction
      },
      {
        title: "Employee Engagement Apps",
        Image: Engagement
      },
      {
        title: "Skill Booking Apps",
        Image: Booking
      },
      {
        title: "Tuition Apps ",
        Image: Tution
      },
      {
        title: "Virtual Classrooms",
        Image: Virtual
      },
      {
        title: "LMS Development",
        Image: E_LEARN
      },
      {
        title: "eLearning Apps for Disabled",
        Image: VIDEO
      },
      {
        title: "On-demand eLearning Apps",
        Image: VIDEO_PLATER
      }
    ]
     