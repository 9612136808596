import React, { useEffect } from "react";
import Banner from "../../components/banner/banner";
import { CASE_BG_1, CASE_IMG_1, PHARM_SUBBANNER, WORKCARD_BG_3, WORKCARD_BG_4, WORKCARD_BG_5, WORKCARD_IMG_3, WORKCARD_IMG_4, WORKCARD_IMG_5 } from "../../assets/images";
import Newnavigation from "../../components/new-navigation/new_navigation";
import { Col, Container, Row } from "react-bootstrap";
import RelatedCaseStudy from "../../components/case-studies/RelatedCaseStudy";
import ContactNew from "../../components/contact-form/ContactNew";
import Footer from "../../components/footer/Footer";
import HeaderSeo from "../../components/HeaderSeo/Headerseo";
import { CaseStudySeo } from "../../utils/Seo";
import { motion } from "framer-motion";
import { Fade } from "react-awesome-reveal";
import { SimpleCard } from "../../components/simple-card/SimpleCard";
import './case-study.css'
import CaseResults from "../../components/case-study-result/CaseResults";
import CaseCard from "../../components/case-card/CaseCard";
import ServiceSubBanner from "../../components/service-sub-banner/ServiceSubBanner";

const CaseManagement = () => {

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const simplecardData = [
    {
      title: "Case Tracking & Management",
      content:
        "Introduced a module that enabled the firm to track case details, deadlines, tasks, and appointments, ensuring better case progress tracking and management.",
    },
    {
      title: "Document Management",
      content:
        "Implemented a cloud-based system for organizing and securely storing case-related documents, simplifying document accessibility.",
    },
    {
      title: "Calendar Integration",
      content:
        "Enabled the firm to schedule case appeal details and set reminders, improving efficiency and meeting deadlines",
    },
    {
      title: "Reports & Analytics",
      content:
        "Introduced a system that provided insights into case details and status, allowing informed decision-making.",
    },
   
  ];

  const card_data = [
    {
        id:"01",
        title:"Improved Efficiency",
        content:"Digitalizing the overall case management process led to increased efficiency in case handling and report management"
    },
    {
        id:"02",
        title:"Document Accessibility",
        content:"Centralized document storage reduced document search time, enabling quick access to case-related information, thus streamlining case progression."
    },
    {
        id:"03",
        title:"Reduced Traveling Costs",
        content:"The software contributed to reduced costs and time spent on managing client appeals at various locations."
    },
    {
        id:"04",
        title:"Streamlined Reporting",
        content:"Customized reporting and analytics provided valuable insights into case details and status, contributing to better decision-making."
    },


  ]

   const caseCardData =[

    {
        title:"Manual Processes",
        content:"Heavy reliance on manual methods led to inefficiencies and difficulties in tracking case progress."
    },
    {
        title:"Document Management",
        content:"Lack of a centralized system made it challenging to locate and share case-related documents, causing delays and confusion."
    },
    {
        title:"Communication",
        content:"Effective communication among team members was hindered by the absence of a digital platform.."
    },
    {
      title:"User Prioritization",
      content:"Determining priority cases for users listed in multiple cases was a cumbersome and time-consuming task."
  },
    

   ]


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className="work_page ">
        <HeaderSeo data={CaseStudySeo} />
        <Newnavigation isblack={true} />
        <div id="Overview">
        <Banner width={'80%'} bg={WORKCARD_BG_5} smalltext={"Case Study - 5"} title={"Case Management"} img={WORKCARD_IMG_5} posistion={'end'} />
        </div>
        <section className="mt-75 case_study">
          <Container>
            <Row>
              <Col lg={12} className="">
                <div className="content-view">
                  <div id="Overview" className="overview_content">
                    <Fade direction="up" duration={0}>
                      <div>
                        <p>
                        An established Case Management firm streamlined operations with digital solutions, improving case tracking, document management, communication, and user prioritization, resulting in enhanced efficiency and productivity. Improved efficiency and document accessibility, resulting in a more productive and streamlined operational model for a Case Management Firm.
                        </p>
                      </div>
                    </Fade>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="case_study_section mt-75 ">
              <h2 className="case_study_main_title">Challenges & Potential</h2>

              <h1 className="case_study_main_subtitle">Navigating the Complexity of <br /> Case Management</h1>
              <div className="mt-50">
               <CaseCard cardInfo={caseCardData} />
              </div>
            
            </div>





            <div className="case_study_section  mt-75 ">
              <h2 className="case_study_main_title">Solution & Implementation</h2>

              <h1 className="case_study_main_subtitle">Streamlining Case Management</h1>
              <div className="mt-50">
              <Row>
                {simplecardData?.map((item, index) => (
                  <Col lg={6} key={index} className="mb-24">
                    <SimpleCard
                      title={item?.title}
                      content={item?.content}
                      height={"100%"}
                    />
                  </Col>
                ))}
              </Row>
              </div>
            
            </div>

            <div className="case_study_section  mt-75 mb-60">
              <h2 className="case_study_main_title">Results</h2>

              <h1 className="case_study_main_subtitle">Enhancing Efficiency and <br /> Productivity</h1>
              <div className="mt-50">

                <CaseResults data={card_data}/>
              
              </div>
            
            </div>
          </Container>


        </section>
        <div className="case_page">
        <ServiceSubBanner bg={PHARM_SUBBANNER} title="Kickstart Your Dream Project With Us" contact="Contact Us"
        contentPadding="20%"
        padding = "20%" /> 
         </div>
        <RelatedCaseStudy filterCol="Case Management" />
            <ContactNew />
        <Footer />

      </div>
    </motion.div>
  );
};

export default CaseManagement;












