import {
    DETAIL_CLAIM_MODULE,
    DETAIL_ELIGIBILITY_MODULE,
    DETAIL_AUTOMATED_E_FILLING,
    DETAIL_VEHICLE_INSURANCE,
  } from "../assets/images/index";

export const insurance = [
    {
      title: "Claims Module",
      img: DETAIL_CLAIM_MODULE,
      id: "claim-module",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    
    },
    {
      title: "Eligibility Module",
      img: DETAIL_ELIGIBILITY_MODULE,
      reverseClass: "flex_reverse",
      id: "eligibililty-module",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
 
    },

    {
      title: "Automated IRS E-Filing",
      img: DETAIL_AUTOMATED_E_FILLING,
      id: "automated-filling",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
     
  

    },
    {
      title: "Vehicle & Insurance Market Place Platform",
      img: DETAIL_VEHICLE_INSURANCE,
      reverseClass: "flex_reverse",
      id: "vehicle-insurance",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
 
    },
  ];