import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { WHYCHOOSETGSS } from "../../assets/images";
import "./whyChooseTgss.css";

export const WhyChooseTgss = ({ data }) => {
  const [{ Details, Title }] = data;
  // console.log(data, Details, Title);

  return (
    <Container className="tgss-bg">
      <div className="mt-100 p-50">
        <div className="m-auto">
          <Row>
            <Col lg="7">
              <h1 className="section_title text-left mb-50">{Title}</h1>

              <div className="section_content text-left mt-20">
                {Details.map((detail, index) => (
                  <div className="tgss-content mt-20" key={index}>
                    <h6>{detail.List}</h6>
                    <p>{detail.ListItem}</p>
                  </div>
                ))}
              </div>
            </Col>
            <Col lg="5">
              <img className="img w-100 quest_img" src={WHYCHOOSETGSS} alt="Why Choose Tgss" />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};
