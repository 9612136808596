import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import "./new_navigation.css";
import Button from "react-bootstrap/Button";
import Logo from "../logo/Logo";
import BlackLogo from "../black-logo/BlackLogo";
import ContactForm from "../contactus-popup/ContactForm";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { Col, Row } from "react-bootstrap";
import { HiChevronDown } from "react-icons/hi";
import {
  Services,
  Retail_Ecommerce,
  Transportation_Logistics,
  Sports_Entertainment,
  Learning_Training,
  healthcare,
  Services3,
  Services4,
  EDUCATION,
  INSURANCE_MENU,
  LOGISTICS_MENU,
  FINTECH_MENU,
  BLOCKCHAIN_MENU,
  TECHNOLOGY_MENU,
  OUTSOURCING_MENU,
  HEALTH_CARE,
} from "../../assets/images";
import { NavLink, useLocation } from "react-router-dom";

const Newnavigation = ({ isblack, routeName }) => {
  const imagePath = {
    Insurance: INSURANCE_MENU,
    Blockchain: BLOCKCHAIN_MENU,
    Healthcare: Services3,
    Fintech: FINTECH_MENU,
    Logistics: LOGISTICS_MENU,
    Education: EDUCATION,
    Travel: LOGISTICS_MENU,
    Work: INSURANCE_MENU,
    Home: INSURANCE_MENU,
    Retail: Retail_Ecommerce,
    Transportation: Transportation_Logistics,
    Sports: Sports_Entertainment,
    Learning: Learning_Training,
    Healthcare: healthcare,  
  };
  const [useDefaultImage, setUseDefaultImage] = useState(true);
  const [classfy, setClassfy] = useState(false);
  const dropref = useRef(null);
  const location = useLocation();

  const [consult, setConsultList] = useState(true);
  const [technology, setTechnologyList] = useState(false);
  const [outsourcing, setOutsourceList] = useState(false);


  const [retail, setRetailList] = useState(true);
  const [transportation, setTransportationList] = useState(false);
  const [sports, setSportsList] = useState(false);
  const [learning, setLearningList] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [toggle, setToggle] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const [image, setImage] = useState(imagePath.Insurance);
  const [Solutionimage, setSolutionimage] = useState(imagePath.Retail);
  // console.log(routeName);
  

  const services = [
    {
      list: "Consulting",
    },
    {
      list: "Technology",
    },
    {
      list: "Outsourcing",
    },
  ];

  useLayoutEffect(() => {
    window.addEventListener("scroll", chanagebg);

    return () => {
      window.removeEventListener("scroll", chanagebg);
    };
  }, []);

  const chanagebg = () => {
    if (window.scrollY > 10) {
      setToggle(true);
    } else if (window.scrollY < 10) {
      setToggle(false);
    }
  };

  

  const [showed, setShowed] = useState(false);
  const [showed2, setShowed2] = useState(false);
  const [showed3, setShowed3] = useState(false);
  const [showed4, setShowed4] = useState(false);
  const [clr, setclr] = useState(null)

  const naviagte = useNavigate()
  const showDropdown = (e) => {
    setShowed(!show);
  };
  const hideDropdown = (e) => {
    setShowed(false);
  };
  const showDropdown2 = (e) => {
    setShowed2(!show);
  };
  const hideDropdown2 = (e) => {
    setShowed2(false);
  };
  const showDropdown3 = (e) => {
    setShowed3(!show);
  };
  const hideDropdown3 = (e) => {
    setShowed3(false);
  };
  const showDropdown4 = (e) => {
    setShowed4(!show);
  };
  const hideDropdown4 = (e) => {
    setShowed4(false);
  };

  const addMenutype = (type) =>{
    setclr(type)
  }


  return (
    <div className="navbar_section">
      <Navbar
        expand="lg"
        className={
          toggle === true
            ? "newNavebar  navbar_section_navbar"
            : " navbar_section_navbar"
        }
      >
        <Container fluid className="nav_padding">
          <a href="/">
            {toggle === false && routeName === "Home" ? (
              <BlackLogo />
            ) : (
              <Logo />
            )}
          </a>
          <Link to="/"></Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" exact>
           


              <div
                className="d-flex align-items-center svg_icon"
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
              >
                <NavDropdown
                 className={
                  classfy === true ||
                  location.pathname === "/services" || 
                  location.pathname === "/services/consulting/digital-transformation" || 
                  location.pathname === "/services/consulting/strategy-and-architecture" || 
                  location.pathname === "/services/consulting/business-transformation" || 
                  location.pathname === "/services/consulting/enterprise-processes" || 
                  location.pathname === "/services/consulting/change-and-learning" ||
                  location.pathname === "/services/consulting/generative-ai" ||
                  location.pathname === "/services/technology/IT-landscape" ||
                  location.pathname === "/services/technology/soa-solutions" ||
                  location.pathname === "/services/technology/business-intelligence" ||
                  location.pathname === "/services/technology/data-warehousing" ||
                  location.pathname === "/services/technology/data-mining-crm" ||
                  location.pathname === "/services/technology/unix-microsoft-paltform" ||
                  location.pathname === "/services/outsourcing/ITO" ||
                  location.pathname === "/services/outsourcing/PMO" ||
                  location.pathname === "/services/outsourcing/BPO"
                    ? "desktop_menu active"
                    : "desktop_menu"
                }
                class={routeName === 'services' ? "active" : ""}
                  activeClassName="active"
                  title={
                  <Link to="/services" className="nav-link">Services</Link>
                  }
                  show={showed}
                  
                >
                  <div className="desktop_megamenu">
                    <Container fluid className="nav_padding">
                      <Row>
                        <Col lg={7}>
                          <div className="nav-content">
                            {
                              <img
                                src={
                                  consult
                                    ? Services
                                    : technology
                                    ? TECHNOLOGY_MENU
                                    : outsourcing
                                    ? OUTSOURCING_MENU
                                    : Services
                                }
                                alt="Healthcare"
                              />
                            }

                            <div className="service-list">
                            <p 
                              className={
                                // consult === true || technology === false || outsourcing === false ||
                  // location.pathname === "/services/consulting/digital-transformation" || 
                  // location.pathname === "/services/consulting/strategy-and-architecture" || 
                  // location.pathname === "/services/consulting/business-transformation" || 
                  // location.pathname === "/services/consulting/enterprise-processes" || 
                  // location.pathname === "/services/consulting/change-and-learning" ||
                  // location.pathname === "/services/consulting/generative-ai"  
                  clr == 1
                  ? "active"
                  : ""   
                }
                                onMouseEnter={() => {
                                  setTechnologyList(false);
                                  setConsultList(true);
                                  setOutsourceList(false);
                                }}
                                onClick={() => {
                                  setTechnologyList(false);
                                  setConsultList(true);
                                  setOutsourceList(false);
                                }}
                              >
                                
                                  Consulting
                                
                              </p>
                              <p
                               className={
                                // technology === true || consult === false || outsourcing === false ||
                              // location.pathname === "/services/technology/IT-landscape" ||
                              // location.pathname === "/services/technology/soa-solutions" ||
                              // location.pathname === "/services/technology/business-intelligence" ||
                              // location.pathname === "/services/technology/data-warehousing" ||
                              // location.pathname === "/services/technology/data-mining-crm" ||
                              // location.pathname === "/services/technology/unix-microsoft-paltform"
                              clr == 2
                                ? "active"
                                : "" 
                               }
                                onMouseEnter={() => {
                                  setTechnologyList(true);
                                  setConsultList(false);
                                  setOutsourceList(false);
                                }}
                                onClick={() => {
                                  setTechnologyList(true);
                                  setConsultList(false);
                                  setOutsourceList(false);
                                }}
                              >
                                Technology
                              </p>
                              <p
                               className={
                                // outsourcing === true || technology === false || consult === false ||
                                // location.pathname === "/services/outsourcing/ITO" ||
                                // location.pathname === "/services/outsourcing/PMO" ||
                                // location.pathname === "/services/outsourcing/BPO"
                                clr == 3
                                ? "active"
                                : "" 
                               }
                                onMouseEnter={() => {
                                  setTechnologyList(false);
                                  setConsultList(false);
                                  setOutsourceList(true);
                                }}
                                onClick={() => {
                                  setTechnologyList(false);
                                  setConsultList(false);
                                  setOutsourceList(true);
                                }}
                              >
                                Outsourcing
                              </p>
                              </div>
                              </div>
                              </Col>
                              {consult ? (
                                <Col lg={5} onMouseLeave={()=> setclr(null)}>
                                  <div className="sub-list">
                                    <p>
                                  <Link  to="/services/consulting/digital-transformation" onMouseOver={()=>addMenutype(1)}>
                                  Digital Transformation
                                </Link>
                                </p>
                                  <p>
                                  <Link  to="/services/consulting/strategy-and-architecture" onMouseOver={()=>addMenutype(1)}>
                                  Strategy and Architecture
                                  </Link>
                                  </p>
                                    <p>
                                  <Link  to="/services/consulting/business-transformation" onMouseOver={()=>addMenutype(1)}>
                                  Business Transformation
                                  </Link>
                                    </p>
                                    <p>
                                  <Link  to="/services/consulting/enterprise-processes" onMouseOver={()=>addMenutype(1)}>
                                  Enterprise Processes
                                  </Link>
                                     </p>
                                  
                                    <p>
                                  <Link  to="/services/consulting/change-and-learning" onMouseOver={()=>addMenutype(1)}>
                                  Change and Learning
                                  </Link>
                                      </p>
                                      <p>
                                  <Link  to="/services/consulting/generative-ai" onMouseOver={()=>addMenutype(1)}>
                                  Generative AI
                                  </Link>
                                      </p>
                                  </div>
                                </Col>
                              ) : technology ? (
                                <Col lg={5} onMouseLeave={()=> setclr(null)}>
                                  <div className="sub-list">
                                 
                                    <p>
                                  <Link  to="/services/technology/transforming-business" onMouseOver={()=>addMenutype(2)}>
                                  Transforming Businesses with Innovative IT Solutions
                                  </Link>
                                      </p>
                                    <p>
                                  <Link  to="/services/technology/soa-solutions" onMouseOver={()=>addMenutype(2)}>
                                  Service-Oriented Architecture (SOA)
                                  </Link>
                                      </p>
                                    <p>
                                  <Link  to="/services/technology/business-intelligence" onMouseOver={()=>addMenutype(2)}>
                                  Transforming Data into Actionable Insights
                                  </Link>
                                      </p>
                                    <p>
                                  <Link  to="/services/technology/data-warehousing" onMouseOver={()=>addMenutype(2)}>
                                  Comprehensive Data Warehousing
                                  </Link>
                                      </p>
                                    <p>
                                  <Link  to="/services/technology/data-mining-crm" onMouseOver={()=>addMenutype(2)}>
                                  Comprehensive Data Mining and CRM
                                  </Link>
                                      </p>
                                   
                                    <p>
                                  <Link  to="/services/technology/unix-microsoft-paltform" onMouseOver={()=>addMenutype(2)}>
                                  UNIX & Microsoft Platforms Design
                                  </Link>
                                      </p>
                                  </div>
                                </Col>
                              ) : outsourcing ? (
                                <Col lg={5} onMouseLeave={()=> setclr(null)}>
                                  <div className="sub-list">
                                  
                                    <p>
                                    <Link  to="/services/outsourcing/ITO" onMouseOver={()=>addMenutype(3)}>
                                    Information Technology Outsourcing (ITO)
                                  </Link>
                                      </p>
                                      <p>
                                    <Link  to="/services/outsourcing/PMO" onMouseOver={()=>addMenutype(3)}>
                                    Program Management Outsourcing (PMO)
                                  </Link>
                                      </p>
                                      <p>
                                    <Link  to="/services/outsourcing/BPO" onMouseOver={()=>addMenutype(3)}>
                                    Business Process Outsourcing (BPO)
                                  </Link>
                                      </p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                      </Row>
                    </Container>
                  </div>
                </NavDropdown>
              </div>
              <div
                className="d-flex align-items-center svg_icon"
                onMouseEnter={showDropdown2}
                onMouseLeave={hideDropdown2}
              >
                <NavDropdown
                className={
                  classfy === true ||
                  location.pathname === "/solutions/retail" || location.pathname === "/solutions/edutech" || location.pathname === "/solutions/transportation" || location.pathname === "/solutions/healthcare-pharmaceutical"
                    ? "desktop_menu active"
                    : "desktop_menu"
                }
                  activeClassName="active"
                  title="Solutions"
                  id="basic-nav-dropdown"
                  show={showed2}
                >
                  <div className="desktop_megamenu solution-menu">
                    <Container fluid className="nav_padding">
                      <Row>
                        <Col lg={7} xl={8}>
                          <div className="nav-content">
                            <img src={Solutionimage} alt="Solution image" />
                            <div className="service-list">
                            <p
                              onMouseEnter={() => {
                                setSolutionimage(imagePath.Retail);
                                setRetailList(true);
                                setTransportationList(false);
                                setSportsList(false);
                                setLearningList(false);
                              }}
                              onClick={() => {
                                setSolutionimage(imagePath.Retail);
                                setRetailList(true);
                                setTransportationList(false);
                                setSportsList(false);
                                setLearningList(false);
                              }}
                            >
                              <NavLink
                                activeClassName="active"
                                to="/solutions/retail"
                              >
                                Retail & E-commerce
                              </NavLink>
                            </p>

                            <p
                              onMouseEnter={() => {
                                setSolutionimage(imagePath.Sports);
                                setRetailList(false);
                                setTransportationList(false);
                                setSportsList(true);
                                setLearningList(false);
                              }}
                              onClick={() => {
                                setSolutionimage(imagePath.Sports);
                                setRetailList(false);
                                setTransportationList(false);
                                setSportsList(true);
                                setLearningList(false);
                              }}
                            >
                                 
                                 <NavLink
                                activeClassName="active"
                                to="/solutions/edutech"
                              >
                              Edutech
                              </NavLink>
                                </p>

                                <p
                              onMouseEnter={() => {
                                setSolutionimage(imagePath.Transportation);
                                setRetailList(false);
                                setTransportationList(true);
                                setSportsList(false);
                                setLearningList(false);
                              }}
                              onClick={() => {
                                setSolutionimage(imagePath.Transportation);
                                setRetailList(false);
                                setTransportationList(true);
                                setSportsList(false);
                                setLearningList(false);
                              }}
                            >
                                <NavLink
                                activeClassName="active"
                                to="/solutions/transportation"
                              >
                              Transportation
                              </NavLink>
                              
                            </p>

                                <p
                                  onMouseEnter={() => {
                                    setSolutionimage(imagePath.Learning);
                                    setRetailList(false);
                                    setTransportationList(false);
                                    setSportsList(false);
                                    setLearningList(true);
                                  }}
                                  onClick={() => {
                                    setSolutionimage(imagePath.Learning);
                                    setRetailList(false);
                                    setTransportationList(false);
                                    setSportsList(false);
                                    setLearningList(true);
                                  }}
                                >
                                <NavLink activeClassName="active" to="/solutions/healthcare-pharmaceutical">
                                Healthcare & Pharmaceutical
                              </NavLink>
                                 
                                </p>

                            </div>
                          </div>
                        </Col>
                     

                      </Row>
                    </Container>
                  </div>
                </NavDropdown>
              </div>
             
              <div
                className="d-flex align-items-center svg_icon"
                onMouseEnter={showDropdown3}
                onMouseLeave={hideDropdown3}
              >
                <NavDropdown
                  className={
                    classfy === true ||
                    location.pathname === "/industry/insurance" ||
                    location.pathname === "/industry/blockchain" ||
                    location.pathname === "/industry/healthcare" ||
                    location.pathname === "/industry/fintech" ||
                    location.pathname === "/industry/education" ||
                    location.pathname === "/industry/education" ||
                    location.pathname === "/industry/travel&hospitality"
                      ? "desktop_menu active"
                      : "desktop_menu"
                  }
                  title="Industries"
                  id="basic-nav-dropdown"
                  activeClassName="active"
                  show={showed3}
                >
                  <div className="desktop_megamenu">
                    <Container fluid className="nav_padding">
                      <Row>
                        <Col lg={9} xl={9}>
                         
                          <div className="nav-content">
                            <img src={image} alt="" />

                            
                            <div className="service-list">
                              <p
                                onMouseEnter={() =>
                                  setImage(imagePath.Insurance)
                                }
                                onMouseLeave={() =>
                                  setImage(imagePath.Insurance)
                                }
                                onClick={() => setImage(imagePath.Insurance)}
                              >
                                <NavLink
                                  activeClassName="active"
                                  to="/industry/insurance"
                                >
                                  Insurance
                                </NavLink>
                              </p>
                              <p
                                onMouseEnter={() =>
                                  setImage(imagePath.Blockchain)
                                }
                                onMouseLeave={() =>
                                  setImage(imagePath.Blockchain)
                                }
                                onClick={() => setImage(imagePath.Blockchain)}
                              >
                                <NavLink
                                  activeClassName="active"
                                  to="/industry/blockchain"
                                >
                                  Blockchain
                                </NavLink>
                              </p>
                              <p
                                onMouseEnter={() =>
                                  setImage(imagePath.Healthcare)
                                }
                                onMouseLeave={() =>
                                  setImage(imagePath.Healthcare)
                                }
                                onClick={() => setImage(imagePath.Healthcare)}
                              >
                                <NavLink
                                  activeClassName="active"
                                  to="/industry/healthcare"
                                >
                                  Healthcare
                                </NavLink>
                              </p>
                              <p
                                onMouseEnter={() => setImage(imagePath.Fintech)}
                                onMouseLeave={() => setImage(imagePath.Fintech)}
                                onClick={() => setImage(imagePath.Fintech)}
                              >
                                <NavLink
                                  activeClassName="active"
                                  to="/industry/fintech"
                                >
                                  Banking & Fintech
                                </NavLink>
                              </p>
                              <p
                                onMouseEnter={() =>
                                  setImage(imagePath.Logistics)
                                }
                                onMouseLeave={() =>
                                  setImage(imagePath.Logistics)
                                }
                                onClick={() => setImage(imagePath.Logistics)}
                              >
                                <NavLink
                                  activeClassName="active"
                                  to="/industry/logistics"
                                >
                                  Supply Chain, Retail & Logistics
                                </NavLink>
                              </p>
                              <p
                                onMouseEnter={() =>
                                  setImage(imagePath.Education)
                                }
                                onMouseLeave={() =>
                                  setImage(imagePath.Education)
                                }
                                onClick={() => setImage(imagePath.Education)}
                              >
                                <NavLink
                                  activeClassName="active"
                                  to="/industry/education"
                                >
                                  Education
                                </NavLink>
                              </p>
                              <p
                                onMouseEnter={() =>
                                  setImage(imagePath.Travel)
                                }
                                onMouseLeave={() =>
                                  setImage(imagePath.Travel)
                                }
                                onClick={() => setImage(imagePath.Travel)}
                              >
                                <NavLink
                                  activeClassName="active"
                                  to="/industry/travel&hospitality"
                                >
                                  Travel & Hospitality
                                </NavLink>
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} xl={3}></Col>
                      </Row>
                    </Container>
                  </div>
                </NavDropdown>
                
              </div>
             
              <div className="mobile_menu">
                <div className="  mob_svg_icon ">
                  <NavDropdown
                    title="Services"
                    id="basic-nav-dropdown"
                    // onClick={()=>naviagte('/services')}
                  >
                    <NavDropdown   className={
                      classfy === true ||
                      location.pathname === "/services/consulting/digital-transformation" ||
                      location.pathname === "/services/consulting/strategy-and-architecture" ||
                      location.pathname === "/services/consulting/business-transformation" ||
                      location.pathname === "/services/consulting/enterprise-processes" ||
                      location.pathname === "/services/consulting/change-and-learning" ||
                      location.pathname === "/services/consulting/generative-ai"  
                        ? "active"
                        : ""
                    } title="Consulting" id="basic-nav-dropdown">
                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active"  to="/services/consulting/digital-transformation">
                      Digital Transformation</NavLink>
                    </p>

                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/consulting/strategy-and-architecture">
                     Strategy and Architecture
                     </NavLink>
                    </p>

                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/consulting/business-transformation">
                     Business Transformation
                     </NavLink>
                    </p>

                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/consulting/enterprise-processes">
                    Enterprise Processes
                     </NavLink>
                    </p>

                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/consulting/change-and-learning">
                    Change and Learning
                     </NavLink>
                    </p>

                
                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/consulting/generative-ai">
                 Generative AI
                     </NavLink>
                    </p>


                    
                    </NavDropdown>

                    <NavDropdown  className={
                      classfy === true ||
                      location.pathname === "/services/technology/transforming-business" ||
                      location.pathname === "/services/technology/data-warehousing" ||
                      location.pathname === "/services/technology/data-mining-crm" ||
                      location.pathname === "/services/technology/business-intelligence" ||
                      location.pathname === "/services/technology/unix-microsoft-paltform" ||
                      location.pathname === "/services/technology/soa-solutions"  
                        ? "active"
                        : ""
                    } 
                    title="Technology" id="basic-nav-dropdown">
                       <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/technology/transforming-business">
             Transforming Businesses with Innovative IT Solutions
                     </NavLink>
                    </p>
                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/technology/soa-solutions">
           Service-Oriented Architecture (SOA)
                     </NavLink>
                    </p>
                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/technology/business-intelligence">
          Transforming Data into Actionable Insights
                     </NavLink>
                    </p>
                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/technology/data-warehousing">
           Comprehensive Data Warehousing
                     </NavLink>
                    </p>
                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/technology/data-mining-crm">
          Comprehensive Data Mining and CRM
                     </NavLink>
                    </p>
                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/technology/unix-microsoft-paltform">
          UNIX & Microsoft Platforms Design
                     </NavLink>
                    </p>
                    </NavDropdown>
                    
                    <NavDropdown className={
                      classfy === true ||
                      location.pathname === "/services/outsourcing/BPO" ||
                      location.pathname === "/services/outsourcing/ITO" ||
                      location.pathname === "/services/outsourcing/PMO"
                        ? "active"
                        : ""
                    } 
                    title="Outsourcing" id="basic-nav-dropdown">
                          <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/outsourcing/ITO">
          Information Technology Outsourcing (ITO)
                     </NavLink>
                    </p>
                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/outsourcing/PMO">
          Program Management Outsourcing (PMO)
                     </NavLink>
                    </p>
                    <p onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/services/outsourcing/BPO">
          Business Process Outsourcing (BPO)
                     </NavLink>
                    </p>
                    </NavDropdown>
                  </NavDropdown>
                </div>

                <div className="  mob_svg_icon ">
                  <NavDropdown
                    className={
                      classfy === true ||
                      location.pathname === "/solutions/retail" ||
                      location.pathname === "/solutions/edutech" ||
                      location.pathname === "/solutions/transportation" ||
                      location.pathname === "/solutions/healthcare-pharmaceutical" 
                        ? "active"
                        : ""
                    }
                    activeClassName="active"
                    title="Solutions"
                    id="basic-nav-dropdown"
                  >

<p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    > <NavLink  activeClassName="active" to="/solutions/retail">
                    Retail & e-commerce
                    </NavLink> </p>
                   
                    <p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/solutions/edutech">Edutech</NavLink></p>
                       <p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/solutions/transportation">Transportation</NavLink></p>
                       <p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    ><NavLink activeClassName="active" to="/solutions/healthcare-pharmaceutical">Healthcare & Pharmaceutical</NavLink></p>
                  
                  </NavDropdown>
                </div>

                <div className="  mob_svg_icon ">
                  <NavDropdown
                    className={
                      classfy === true ||
                      location.pathname === "/industry/insurance" ||
                      location.pathname === "/industry/blockchain" ||
                      location.pathname === "/industry/healthcare" ||
                      location.pathname === "/industry/fintech" ||
                      location.pathname === "/industry/logistics" ||
                      location.pathname === "/industry/education" ||
                      location.pathname === "/industry/travel&hospitality"
                        ? "active"
                        : ""
                    }
                    activeClassName="active"
                    title="Industries"
                    id="basic-nav-dropdown"
                  >
                    <p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    >
                      <NavLink
                        activeClassName="active"
                        to="/industry/insurance"
                      >
                        Insurance
                      </NavLink>
                    </p>
                    <p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    >
                      <NavLink
                        activeClassName="active"
                        to="/industry/blockchain"
                      >
                        Blockchain
                      </NavLink>
                    </p>
                    <p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    >
                      <NavLink
                        activeClassName="active"
                        to="/industry/healthcare"
                      >
                        Healthcare
                      </NavLink>
                    </p>
                    <p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    >
                      <NavLink activeClassName="active" to="/industry/fintech">
                        Banking & Fintech
                      </NavLink>
                    </p>
                    <p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    >
                      <NavLink
                        activeClassName="active"
                        to="/industry/logistics"
                      >
                        Supply Chain, Retail & Logistics
                      </NavLink>
                    </p>
                    <p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    >
                      <NavLink
                        activeClassName="active"
                        to="/industry/education"
                      >
                        Education
                      </NavLink>
                    </p>
                    <p
                      onClick={() => {
                        setClassfy(true);
                      }}
                    >
                      <NavLink
                        activeClassName="active"
                        to="/industry/travel&hospitality"
                      >
                        Travel & Hospitality
                      </NavLink>
                    </p>
                  </NavDropdown>

                </div>

              </div>

              <p className="nav_items">
                <NavLink activeClassName="active" to="/work">
                Case Studies
                </NavLink>
              </p>
              <div
                className="d-flex align-items-center svg_icon"
                onMouseEnter={showDropdown4}
                onMouseLeave={hideDropdown4}
              >
                <p className="nav_items">
                <NavLink activeClassName="active" to="/about-us">
                About Us
                </NavLink>
              </p>
              </div>

              <div>
                <Button
                  className="nav_bar_btn"
                  variant="outline-primary"
                  onClick={handleShow}
                >
                  Contact
                </Button>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
    </div>
  );
};

export default Newnavigation;
