import { BUSINESS_PROC_1, BUSINESS_PROC_2, BUSINESS_PROC_3, BUSINESS_PROC_4, BUSINESS_PROC_5, TECH_1, TECH_2, TECH_3, TECH_4} from '../assets/images';
export const BPO_heading = [
    {
        title: "Business Process"      
    },
    {
        title: "Outsourcing(BPO)"
    },
  ];
  export const CoreServices = [
    {
  
    title : "Customer Support Services",
    content :"Our customer support services ensure that your clients receive top-notch assistance through various channels, including phone, email, and live chat. We help you maintain high customer satisfaction and loyalty.",
    image: BUSINESS_PROC_1,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: '24/7 customer support'},
      {items: 'Multilingual support services'},
      {items: 'Improved customer satisfaction and retention'}
    ],
    button_name: "Enhance Your Customer Support Today",
},
{
  
    title : "Finance and Accounting Services",
    content: "We offer comprehensive finance and accounting services to help you manage your financial operations efficiently. Our accounting and financial analyses ensure accuracy and compliance.",
    image: BUSINESS_PROC_2,
    keytitile: "Technologies Used",
    Key_technologies: [
      {items: 'Accounting Software (QuickBooks, SAP)'},
      {items: 'Financial Management Tools'},
      {items: 'Automated Reporting Systems'}
    ],
    button_name: "Streamline Your Financial Operations",
     className: "row-reverse"
},
{
  title: "Human Resources Services",
  content: "Recruitment, benefits, payroll and compliance management are just some of our HR outsourcing services. We help you manage your workforce effectively and efficiently.",
  image: BUSINESS_PROC_3,
  keytitile: "Key Benefits",
  Key_technologies: [
    {items: 'Efficient recruitment processes'},
    {items: 'Accurate payroll and benefits management'},
    {items: 'Compliance with labor laws and regulations'},
  ],
  button_name: "Optimize Your HR Functions Today"
},
{
  title: "IT Services",
  content: "We provide a range of IT outsourcing services, including IT support, software development, and infrastructure management. Our solutions ensure your IT operations run smoothly and securely.",
  image: BUSINESS_PROC_4,
  keytitile: "Technologies Used",
  Key_technologies: [
    {items: 'IT Support Tools (Zendesk, ServiceNow'},
    {items: 'Development Platforms (Java, .NET, Python)'},
    {items: 'Cloud Services (AWS, Azure)'},
  ],
  button_name: "Secure Your IT Infrastructure Today",
   className: "row-reverse"
},
{
  title: "Back-Office Services",
  content: "Our back-office services improve the efficiency of administrative tasks such as data entry, document management, and order processing. We ensure these processes are handled accurately and promptly.",
  image: BUSINESS_PROC_5,
  keytitile: "Key Benefits",
  Key_technologies: [
    {items: 'Accurate data management'},
    {items: 'Efficient document processing'},
    {items: 'Streamlined order management'},
  ],
  button_name: "Streamline Your Back-Office Operations"
}
]
export const WhyTechno = [
  {
    title: "Expertise and Experience",
    content: "Our team comprises experienced professionals with extensive knowledge in various domains. We can provide you with tailor-made BPO solutions to meet your specific business requirements.",
    serialno: "01"
  },
  {
    title: "Customer-Centric Approach",
    content: "We focus on understanding your business goals and delivering solutions that drive success through effective outsourcing.",
    serialno: "02"
  },
  {
    title: "Cutting-Edge Technology",
    content: "We leverage the latest technologies and best practices to provide innovative BPO solutions that enhance efficiency and productivity.",
    serialno: "03"
  },
 
];
export const TestimonialData = [
  {
      content: '“Techno Genesis has significantly improved our operational efficiency with their BPO services. Their expertise and support have allowed us to focus on our core business activities.”',
      client: 'Client A'
  },
  {
      content: "“Their customer support services have enhanced our customer satisfaction and retention rates. We highly recommend Techno Genesis for BPO solutions”",
      client: 'Client B'
  }
]
  export const BPOAccord = [
    {
      id:1,
      accHead:"What is Business Process Outsourcing (BPO)?",
      accContent:"BPO involves contracting specific business functions and processes to an external service provider to improve efficiency and reduce costs."
  
    },
    {
      id:2,
      accHead:"How can your BPO services benefit my business?",
      accContent:"Our BPO services help you achieve cost savings, access expertise, scale services, focus on core activities, and improve efficiency."
  
    },
    {
      id:3,
      accHead: "What technologies do you use for BPO services?",
      accContent:"We use various technologies, including accounting software (QuickBooks, SAP), IT support tools (Zendesk, ServiceNow), and cloud services (AWS, Azure)."
    },
    {
      id:4,
      accHead: "Do you provide ongoing support for outsourced services?",
      accContent:"Yes, we offer continuous support and maintenance to ensure smooth and efficient operations of outsourced services."
    },
    {
      id:5,
      accHead: "What sets you apart from other BPO service providers?",
      accContent: "Our expertise, customer-centric approach, and use of cutting-edge technologies set us apart in the industry."
    }
  ]