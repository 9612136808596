import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Testimonials.css'
import { AVATER } from '../../assets/images'

const Testimonials = ({ data }) => {
    return (
        <div className='mt-5 mb-5'>
            <Container>
                <h2 className='section_title mb-4'>Testimonials</h2>
                <Row>
                    {data.map((item, i) => {
                        return (
                            <Col xl={data.length == 1 ? 12 : 6} lg={data.length == 1 ? 12 : 6} key={i}>
                                <div className='testimonial-card'>
                                    <p>{item.content}</p>
                                    <div className='testimonials-avater'>
                                        <img src={AVATER} alt="testimonials" />
                                        <p>{item.client}</p>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </div>
    )
}

export default Testimonials