import React, { useState } from 'react'
import './CareerDetailBanner.css'
import { Button, Col, Container, Row } from 'react-bootstrap'
import JobApply from '../job-apply-model/JobApply'

const CareerDetailBanner = ({opeinings}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <>
                <div className='careerbanner-bg'>
                    <Container>
                        <Row>
                            <Col xl='7' className='banner-content'>
                                <p>Technogenesis Pvt. Ltd. | Full time</p>
                                <h1>{opeinings}</h1>
                                <Button className='apply_now_btn' onClick={handleShow} variant="">Apply Now</Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <JobApply show={show} placement='end' onClose={handleClose} onShow={handleShow} />
            </>
        </div>
    )
}

export default CareerDetailBanner 