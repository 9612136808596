import React from "react";
import { useParams } from "react-router-dom";
import { useEffect , useState } from "react";
import "./LearnMore.css";

const LearnMore = ({ data }) => {
  const type = useParams()?.type;
  const [rerender, setRender] = useState(false);
  // console.log(type);
  useEffect(() => {
    document.getElementById(type).scrollIntoView({ behavior: "smooth" });
    setRender(true);
  }, []);

  useEffect(() => {
    document.getElementById(type).scrollIntoView({ behavior: "smooth" });
  }, [rerender]);
  return (
    <div>
      {data?.map((learnmore, index) => {
        return (
          <div key={index} className="learnmore_bg">
            <div 
              className={`d-flex align-item-center  order_prop justify-content-between position-relative  ${learnmore?.reverseClass}`}
            >
              <div id='order_2' >
                <h1>{learnmore.title}</h1>

                {learnmore.listItems && learnmore.listItems.length > 0 && (
                  <ul className="learnmore_unordered">
                    {learnmore.listItems?.map((subItem, subIndex) => (
                      <li key={subIndex}>{subItem}</li>
                    ))}
                  </ul>
                )}
              </div>
              <div id='order_1' >
                <img id={learnmore?.id}
                  className="learnmore_img_style"
                  src={learnmore?.img}
                  alt="image"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LearnMore;
