import React, { useEffect } from "react";
import Newnavigation from "../../components/new-navigation/new_navigation";
import PageBanner from "../../components/page-banner/page-banner";
import ContactNew from "../../components/contact-form/ContactNew";
import Footer from "../../components/footer/Footer";
import PageSmallBanner from "../../components/page-small-banner/page-small-banner";
import KeyServices from "../../components/key-services/KeyServices";
import OurMissions from "../../components/Our-missions/OurMissions";
import ComAccord from "../../components/Accordian/ComAccord";
import WhatweDo from "../../components/what-we-do-img/whatweDo";
import { KeyserviceFintech, FinTechWhatwedo, FinTechMissons, FinTechAccord } from "../../utils/fintech";
import { BG7, FINTECH_BG } from "../../assets/images/index";
import { motion } from "framer-motion";
import HeaderSeo from "../../components/HeaderSeo/Headerseo";
import { FintechSeo } from "../../utils/Seo";

const Fintech = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
    <div>
      <HeaderSeo data={FintechSeo}/>
      <Newnavigation routeName="Fintech" />
      <PageBanner
        bg={FINTECH_BG}
        title="Accelerate Your Financial Future with Techno Genesis"
        contact="Contact Us"
      />
      <WhatweDo data={FinTechWhatwedo} />
      <OurMissions data={FinTechMissons} />
      <KeyServices data={KeyserviceFintech}  heading={"Fintech Solutions"}/>
      <div className="mt-100 mb-50">
        <PageSmallBanner
          isBtn={true}
          btn_text="Contact Us"
          title="Join The Revolution Driving Fintech Future with Techno Genesis"
          bg={BG7}
          height="357px"
        />
      </div>
      <div className="mb-50">
        <ComAccord data ={FinTechAccord}  />
      </div>
      <ContactNew />
      <Footer />
    </div>
    </motion.div>
  );
};
export default Fintech;
