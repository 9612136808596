import React, { useEffect, useRef } from 'react'
import ContactNew from '../../components/contact-form/ContactNew'
import Footer from '../../components/footer/Footer'
import Newnavigation from '../../components/new-navigation/new_navigation'
import { motion } from 'framer-motion';
import { HealthCare_Pharam } from '../../utils/Seo';
import HeaderSeo from '../../components/HeaderSeo/Headerseo';
import AboutusBanner from '../../components/Aboutus-banner/AboutusBanner';
import { pharam_heading } from '../../utils/pharam';
import { PHARM_BG, PHARM_SUBBANNER } from '../../assets/images';
import UseCase from '../../components/timeline/UseCase';
import ServiceSubBanner from '../../components/service-sub-banner/ServiceSubBanner';
import Award_winning_team from '../../components/Award-winning-team/Award-winning-team';
import { digitalsolution_bg, digitalsolution_title, modern_bg, modern_title } from '../../utils/awardwinning_title';

const HealthCarePharam = () => {
    const sectionRef = useRef()
    const refcustom = sectionRef.current
      useEffect(() => {
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        }, []);

  return (
    <motion.div initial={{ opacity: 0 }}animate={{ opacity: 1, transition: { duration: 1 } }}> 
       <div className='service-page ' ref={sectionRef}>
      <Newnavigation routeName="solutions"/>
      <HeaderSeo data={HealthCare_Pharam}/>
      <AboutusBanner bg={PHARM_BG} data={pharam_heading}/>
      <Award_winning_team data={digitalsolution_title} bgImg={digitalsolution_bg} titleCount={4}/>
      <UseCase customRef={refcustom} />
      <ServiceSubBanner
                bg={PHARM_SUBBANNER}
                title="Ready to Elevate Your Healthcare Services?"
                content="Discover how our innovative solutions can help you achieve your goals. *Contact us today* for a personalized consultation and let's drive the future of healthcare together!"
                contact="Contact Us"
                padding="0%"
                contentPadding= "18%" />
       <ContactNew />
       <Footer />
       </div>
        
    </motion.div>
 
  )
}

export default HealthCarePharam