import React, { useEffect } from "react";
import Banner from "../../components/banner/banner";
import { CASE_BG_1, CASE_IMG_1, PHARM_SUBBANNER, WORKCARD_BG_3, WORKCARD_BG_4, WORKCARD_BG_5, WORKCARD_BG_6, WORKCARD_IMG_3, WORKCARD_IMG_4, WORKCARD_IMG_5, WORKCARD_IMG_6 } from "../../assets/images";
import Newnavigation from "../../components/new-navigation/new_navigation";
import { Col, Container, Row } from "react-bootstrap";
import RelatedCaseStudy from "../../components/case-studies/RelatedCaseStudy";
import ContactNew from "../../components/contact-form/ContactNew";
import Footer from "../../components/footer/Footer";
import HeaderSeo from "../../components/HeaderSeo/Headerseo";
import { CaseStudySeo } from "../../utils/Seo";
import { motion } from "framer-motion";
import { Fade } from "react-awesome-reveal";
import { SimpleCard } from "../../components/simple-card/SimpleCard";
import './case-study.css'
import CaseResults from "../../components/case-study-result/CaseResults";
import CaseCard from "../../components/case-card/CaseCard";
import ServiceSubBanner from "../../components/service-sub-banner/ServiceSubBanner";

const CaseManagement = () => {

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const simplecardData = [
    {
      title: "Data Migration",
      content:
        "Digitized and migrated patient information from paper records to the digital platform.",
    },
    {
      title: "Real-Time Access",
      content:
        "Enabled doctors, nurses, and administrative staff to access patient information in real-time, enhancing care coordination",
    },
    {
      title: "Medical Data Sharing",
      content:
        "Allowed patients to share their medical history and documents with healthcare providers before consultations.",
    },
    {
      title: "Quality Control",
      content:
        "Implemented continuous data refinement based on assessments to maintain data accuracy and reliability.",
    },
   
  ];

  const card_data = [
    {
        id:"01",
        title:"Faster Recovery",
        content:"Minimally invasive procedures resulted in quicker patient recovery and reduced hospital stays."
    },
    {
        id:"02",
        title:"Empowered Patients",
        content:"The platform empowered patients to take an active role in managing their health and making informed decisions about their treatment."
    },
    {
        id:"03",
        title:"Holistic Care",
        content:"Healthcare professionals gained a comprehensive view of patients' health, enabling the development of personalized care plans."
    },
    {
        id:"04",
        title:"Improved Patient Outcomes",
        content:"Accurate and timely diagnoses contributed to better patient treatment and outcomes."
    },


  ]

   const caseCardData =[

    {
        title:"Paper-Based Medical Records",
        content:"Managing paper records led to inefficiencies, including difficulties in retrieving and sharing patient information."
    },
    {
        title:"Patient Care Coordination",
        content:"The lack of real-time data access impeded healthcare professionals' ability to coordinate care for patients effectively."
    },
    {
        title:"Data Accessibility",
        content:"Limited accessibility to medical records affected patients' involvement in their healthcare decisions and hindered data sharing among healthcare providers."
    },
]


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className="work_page ">
        <HeaderSeo data={CaseStudySeo} />
        <Newnavigation isblack={true} />
        <div id="Overview">
        <Banner width={'75%'} bg={WORKCARD_BG_6} smalltext={"Case Study - 6"} title={"Healthcare"} img={WORKCARD_IMG_6} posistion={'end'} />
        </div>
        <section className="mt-75 case_study">
          <Container>
            <Row>
              <Col lg={12} className="">
                <div className="content-view">
                  <div id="Overview" className="overview_content">
                    <Fade direction="up" duration={0}>
                      <div>
                        <p>
                        A Healthcare Organization modernized healthcare records, enhancing patient care coordination, data accessibility, and patient engagement, ultimately improving patient outcomes and accessibility. Modernizing healthcare records management and data accessibility for more efficient, patient-centric healthcare system in a Healthcare Organization.
                        </p>
                      </div>
                    </Fade>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="case_study_section mt-75 ">
              <h2 className="case_study_main_title">Challenges & Potential</h2>

              <h1 className="case_study_main_subtitle">Navigating the Complexities of <br /> Healthcare Records</h1>
              <div className="mt-50">
               <CaseCard cardInfo={caseCardData} />
              </div>
            
            </div>





            <div className="case_study_section  mt-75 ">
              <h2 className="case_study_main_title">Solution & Implementation</h2>

              <h1 className="case_study_main_subtitle">Modernizing Healthcare Records</h1>
              <div className="mt-50">
              <Row>
                {simplecardData?.map((item, index) => (
                  <Col lg={6} key={index} className="mb-24">
                    <SimpleCard
                      title={item?.title}
                      content={item?.content}
                      height={"100%"}
                    />
                  </Col>
                ))}
              </Row>
              </div>
            
            </div>

            <div className="case_study_section  mt-75 mb-60">
              <h2 className="case_study_main_title">Results</h2>

              <h1 className="case_study_main_subtitle">Revolutionizing Healthcare <br /> Management</h1>
              <div className="mt-50">

                <CaseResults data={card_data}/>
              
              </div>
            
            </div>
          </Container>


        </section>
        <div className="case_page">
        <ServiceSubBanner bg={PHARM_SUBBANNER} title="Kickstart Your Dream Project With Us" contact="Contact Us"
        contentPadding="20%"
        padding = "20%" /> 
         </div>
        <RelatedCaseStudy filterCol="Healthcare" />
            <ContactNew />
        <Footer />

      </div>
    </motion.div>
  );
};

export default CaseManagement;














