import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Newnavigation from "../../components/new-navigation/new_navigation";
import PageBanner from "../../components/page-banner/page-banner";
import LearnMore from "../../components/common-learn-more/LearnMore";
import Footer from "../../components/footer/Footer";
import { FINTECH_BG } from "../../assets/images/index";
import { fintechdetail } from "../../utils/fintechlearnmore";

const FintechDetail = () => {
  const type = useParams()?.type;
  useEffect(() => {
    document.getElementById(type).scrollIntoView();
  }, []);

  return (
    <div className="fintech_detail">

  
      <Newnavigation />
      <PageBanner bg={FINTECH_BG} title="Banking & Fintech" />
      <LearnMore data={fintechdetail} />
      <Footer />
    </div>
  );
};

export default FintechDetail;
