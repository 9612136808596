import { DATA_MINING_1, DATA_MINING_3, DATA_MINING_4} from '../assets/images';
export const datamining_heading = [
    {
        title: "Comprehensive Data Mining"      
    },
    {
        title: "and CRM Solutions"
    },
  ];
  export const CoreServices = [
    {
  
    title : "Datamining: Unleashing the Power of Data",
    content :"At Techno Genesis, we turn your data into actionable insights with our cutting-edge data mining solutions. Using the latest AI models and big data technologies, we uncover hidden patterns and trends, empowering your business to make smarter decisions. Our solutions now feature real-time data processing and predictive analytics, enabling you to respond quickly to market changes and optimize strategies effectively.",
    image: DATA_MINING_1,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Discover hidden patterns and trends'},
      {items: 'Enhance decision-making with data-driven insights'},
      {items: 'Optimize business processes and strategies'}
    ],
    button_name: "Unlock the Potential of Your Data"
},
{
  
    title : "CRM: Enhancing Customer Relationships",
    content :"At Techno Genesis, our CRM solutions are designed to elevate customer interactions and boost business performance. By integrating data mining with CRM, we provide a comprehensive view of customer interactions, enabling personalized marketing, improved customer service, and increased sales. Our CRM systems now incorporate AI-driven personalization, voice and conversational interfaces, and mobile CRM capabilities to deliver seamless customer experiences.",
    image: DATA_MINING_3,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Personalized customer experiences'},
      {items: 'Improved customer service and support'},
      {items: 'Increased sales and customer retention'}
    ],
    button_name: "Boost Sales with Data-Driven CRM",
    className: "row-reverse"
},
{
  
    title : "Integration: Seamless and Efficient",
    content: "Techno Genesis excels in integrating data mining and CRM systems to provide a unified solution that enhances business intelligence and customer relationship management. Our integration services include cloud-based platforms for scalability and real-time data synchronization, ensuring seamless data flow and efficient operations.",
    image: DATA_MINING_4,
    keytitile: "Integration Benefits",
    Key_technologies: [
      {items: 'Seamless data flow between systems'},
      {items: 'Enhanced data accuracy and consistency'},
      {items: 'U A unified view of your customer data for better insights'},
      {items: 'Improve insights with a unified view of customer data'}
    ],
    button_name: "Achieve Seamless Data Integration Today",
    
}
]
export const WhyTechno = [
  {
    title: "Expertise and Experience",
    content: "With years of experience in the IT industry, our team of experts delivers top-notch solutions tailored to your business needs, ensuring business growth and success.",
    serialno: "01"
  },
  {
    title: "Customer-Centric Approach",
    content: "We prioritize our clients' needs, ensuring that every solution we deliver aligns with your business goals and drives success through digital transformation.",
    serialno: "02"
  },
  {
    title: "Cutting-Edge Technology",
    content: "We leverage the latest technologies to provide innovative IT solutions that keep your business ahead of the competition.",
    serialno: "03"
  },
 
];
export const TestimonialData = [
  {
      content: '"Techno Genesis transformed our ability to analyse data, providing us with actionable insights to help transform our business."',
      client: 'Client A'
  },
  {
      content: '"Their CRM solutions have been a game changer for our customer interactions and our sales performance."',
      client: 'Client B'
  }
]
  export const DataminingAccord = [
    {
      id:1,
      accHead:"What services do you offer for data mining and CRM?",
      accContent:"o	We offer comprehensive data mining and CRM solutions, including consulting, implementation, and ongoing support."
  
    },
    {
      id:2,
      accHead:"How can your data mining services benefit my company?",
      accContent:"o	Our data mining services help you extract valuable insights from your data, leading to better decision-making and optimized business strategies."
  
    },
    {
      id:3,
      accHead: "Do you provide customized CRM solutions?",
      accContent:"o	Yes, we tailor our CRM solutions to meet your unique business needs and goals."
      
  
    },
    {
      id:4,
      accHead: "What is the process for implementing data mining and CRM solutions?",
      accContent:"Our process includes consulting, design, implementation, and continuous support to ensure successful deployment and optimization."
    },
    {
      id:5,
      accHead: "Can you integrate data mining with our existing CRM system?",
      accContent: "Yes, we can seamlessly integrate data mining capabilities with your existing CRM system to enhance its functionality."
    },
    {
      id:6,
      accHead: "Do you offer ongoing support after CRM implementation?",
      accContent: "We provide continuous support and maintenance to ensure the smooth operation of your CRM system."
    }
  ]