import React, { useState, useRef, useEffect } from "react";
import "./contactform.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Row, Col } from "react-bootstrap";
import { SUCCESSGIF, THANKYOU } from "../../assets/images";
import { Loader } from "../../assets/images";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineClose } from "react-icons/ai";
import { apiEnvironment } from "../../api";
import { toast, ToastContainer } from "react-toastify";
/*
const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
const validation = Yup.object({
  name: Yup.string().trim()
    .required("Name is required"),
 
  email: Yup.string()
  .matches(emailReg, "Invalid Email")
   // .email("Invalid email address")
    .required("Email is required"),
    mobile: Yup.string()
    .required("Phone number is required"),
  
    mobileRef: Yup.string()
    .required("Please select country & code"),
    message: Yup.string().trim("Please provide the message")
    .required("Please provide the message"),
})
*/

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  // mobileRef:'',
  message: "",
};

const ContactForm = (props) => {
  const { show, onClose, onShow } = props;
  const [selectOption, setSelectOption] = useState();
  const [mobile, setMobile] = useState();
  const [form, setForm] = useState({});
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [showed, setShowed] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleCloseSecond = () => setShowed(false);

  const formRef = useRef();
  const [formState, setFormState] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showsuccess, setShowSuccess] = useState(false);

  const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const indiaReg = /^[6-9]\d{9}$/; // 6|7|8|9 123456789    -10 digits only allowed
  /*const singaporeReg = /^(8|9)\d{7}$/ //+65 8|9 1234567    -8 digits only allowed
const malasiaReg = /^\d{7,8}$/   //+60 1234567 | 78         -7/8 digits only allowed
const UAEReg = /^5[0-9]\d{7}$/ //+971 50 1234567     -9 digits only allowed
const USReg = /^\d{10}$/ //+1 123 123 1234           -10 digits only allowed
const UKReg = /^[07|7]\d{9}$/ //+44 07|7 123 123456      -10|11 digits only allowed

*/

  const generateValidationSchema = () => {
    //console.log(selectOption)

    /*   (selectOption==='+91') 
      ? 
      Yup.string().trim().matches(indiaReg, "Please provide valid phone no matches India country code").required('Please provide phone no')
      : (selectOption==='+65')
      ? 
      Yup.string().trim().matches(singaporeReg, "Please provide valid phone no matches the Singapore country code").required('Please provide phone no')
      :(selectOption==='+60')
      ?
      Yup.string().trim().matches(malasiaReg, "Please provide valid phone no matches the Malasia country code").required('Please provide phone no')
      :(selectOption==='+971')
      ?
      Yup.string().trim().matches(UAEReg, "Please provide valid phone no matches the UAE country code").required('Please provide phone no')
      :(selectOption==='+1')
      ?
      Yup.string().trim().matches(USReg, "Please provide valid phone no matches the US country code").required('Please provide phone no')
      :(selectOption==='+44')
      ?
      Yup.string().trim().matches(UKReg, "Please provide valid phone no matches the UK country code").required('Please provide phone no')
      :*/

    /*  mobileRef: Yup.string()
      .required("Please select country & code"), */

    return Yup.object({
      mobile: Yup.string()
        .trim()
        .matches(indiaReg, "Please provide valid phone no ")
        .required("Please provide phone no"),
      name: Yup.string().trim().required("Name is required")
          .min(3, " Name must be at least 3 characters")
        .max(100, "Name cannot exceed 100 characters"),
      email: Yup.string()
        .matches(emailReg, "Invalid Email")
        .min(2, "Email Id must be at least 3 characters")
        .max(255, "Email Id  cannot exceed 255 characters")
        .required("Email is required"),
      message: Yup.string()
        .trim("Please provide the message")
        .required("Please provide the message"),
    });
  };

  /*  const handleOptionChange = (event) => {
  const selectedValue = event.target.value;
  setSelectOption(selectedValue);
  console.log(selectedValue)
  resetPhoneNo()
}
 */

  const handleClose = () => {
    onClose();
    formik.values.mobileRef = "";
    formik.resetForm();
  };

  const handleChange = (event) => {
    const value = event.target.value;
    //formik.setFieldValue({[event.target.name]: value });
    //formik.values.mobileRef = value;
    formik.setFieldValue("mobileRef", value);
    formik.setFieldValue("mobile", ``);
    formik.validateForm();
    setSelectOption(value);
    // console.log(value)

    //console.log(event.target.name, event.target.value)
  };

  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: generateValidationSchema(selectOption),
    onSubmit: (values, { resetForm }) => {
      setFormState(values);
      submitHandler(values);
      // setShowed(true)

      resetForm();
    },
  });

  const submitHandler = async (data) => {
    setIsSubmitting(true);

    const host = apiEnvironment?.baseUrl;
    //const host = `https://testtgssapi.technogenesis.in/api/V1/AP`;
    // console.log(data)
    try {
      //"mobile": `${data?.mobileRef} ${data?.mobile}`,
      const bodyData = {
        name: data?.name,
        mobile: data?.mobile,
        email: data?.email,
        message: data?.message,
      };

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(bodyData),
      };
      await fetch(`${host}/contactus/create`, options)
        .then((res) => res.json())
        .then((res) => {

            if (!res) {
            console.error("Error: ", res);
            formik.resetForm();
            setIsSubmitting(false);
            toast.error("Something went wrong.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
  
            return;
          }
          console.log(res);

          if (res?.keyword === "success") {
            setIsSubmitting(false);
            setShowed(true);
            formik.resetForm();
            setShowSuccess(true)
           
          } else {
            setIsSubmitting(false);
            toast.error("Something went wrong.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              progress: undefined,
              theme: "light",
            });
  
            formik.resetForm();
          }



          

       
        });
    } catch (error) {
      formik.resetForm();
      console.error("Error: ", error);
      setIsSubmitting(false);
      toast.error("Something went wrong.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // useEffect(() => {
  //   // Use the useEffect hook to automatically close the modal when isSubmitting is true
  //   if (!isSubmitting) {
  //     onClose();
  //     // console.log(show)
  //     if (show) setShowed(true);
  //   }
  // }, [isSubmitting]);

  const handlekeydown = (evt) => {
    ["e", "E", "+", "-", ".", "ctrlKey", "vKey"].includes(evt.key) &&
      evt.preventDefault();
    if (
      evt.keyCode === 38 ||
      evt.keyCode === 40 ||
      evt.keyCode === 17 ||
      evt.keyCode === 86
    ) {
      evt.preventDefault();
    }
  };



  return (
    <>
      <Modal show={show} onHide={onClose} centered>
        {showsuccess === true ? (
          <div className="success_popup_form"> 
           
                <AiOutlineClose className="close_btn_success" onClick={handleClose}  />
           
            <div className="success_popup_content">
              <img src={SUCCESSGIF} alt="success_gif" />
              <h1>Thank You!</h1>
              <p>We will <span>get back to you shortly</span>. Meanwhile, feel free to <span>explore our website</span>.</p>

            </div>

             

          </div>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>
                Expect guidance for your project. <br /> Chat with us now!{" "}
              </Modal.Title>
              <Button variant="light" onClick={handleClose}>
                <AiOutlineClose />
              </Button>
            </Modal.Header>
            <Form
              ref={formRef}
              onSubmit={formik.handleSubmit}
              validated={validated}
            >
              <Modal.Body>
                <Form.Group className="mb-4" rows={2} controlId="name">
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Name *"
                    onChange={formik.handleChange}
                    value={formik.values?.name}
                    isInvalid={!!formik.errors?.name}
                    autoFocus
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-4" controlId="email">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email ID *"
                    onChange={formik.handleChange}
                    //onBlur={formik.handleBlur}
                    value={formik.values?.email}
                    isInvalid={!!formik.errors?.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.email}
                  </Form.Control.Feedback>
                </Form.Group>

                {/*  <Row> <Col md={7}> <Form.Group className="mb-4" controlId="mobileRef">
        <Form.Select className='' aria-label="Default select example"
        name="mobileRef"
      //  onChange={(e) =>handleChange(e) }
        onChange={(e) => { handleChange(e) }}
           value={formik.values?.mobileRef}
         isInvalid={!!formik.errors?.mobileRef}
         >
  <option value="" selected disabled>Select Country & Code</option>
  <option value="+91">India (+91)</option>
  <option value="+65">Singapore (+65)</option>
  <option value="+60">Malaysia (+60)</option>
  <option value="+971">UAE (+971)</option>
  <option value="+1">US (+1)</option>
  <option value="+44">UK (+44)</option>
  
     </Form.Select>
   
     <Form.Control.Feedback type="invalid">{formik.errors?.mobileRef}</Form.Control.Feedback>
     </Form.Group>
  </Col> </Row>*/}

                <Form.Group className="mb-4" controlId="mobile">
                  <Form.Control
                    type="number"
                    name="mobile"
                    onKeyDown={(evt) => handlekeydown(evt)}
                    placeholder="Phone Number*"
                    onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    value={formik.values?.mobile}
                    //  value ={mobile}
                    isInvalid={!!formik.errors?.mobile}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.mobile}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-4" controlId="message">
                  <Form.Control
                    as="textarea"
                    placeholder="Message*"
                    rows={4}
                    name="message"
                    onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    value={formik.values?.message}
                    isInvalid={!!formik.errors?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="text-center">
                <div className=" btn_shadow">
                  <div className="shadow_1"></div>
                  <div className="polygon_btn_cont">
                    <Form.Group controlId="submit">
                      <Button
                        type="submit"
                        className="polygon_btn"
                        disabled={isSubmitting}
                        variant="outline-primary"
                        onClick={(values) => setFormState(values)}
                      >
                        {isSubmitting ? "Submitting" : "Get in Touch"}
                      </Button>
                    </Form.Group>

                    <div className="polygon_shadow"></div>
                  </div>
                  {isSubmitting ? (
                    <img
                      className="img-fluid loader"
                      src={Loader}
                      alt="loading"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Modal>

      {/* <Modal show={showed} onHide={handleCloseSecond} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center greeting_content">
            <img src={THANKYOU} alt="thankyou" />
            <h2>Thank you contacting us</h2>
            
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default ContactForm;
