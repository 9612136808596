import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Newnavigation from "../../components/new-navigation/new_navigation";
import PageBanner from "../../components/page-banner/page-banner";
import LearnMore from "../../components/common-learn-more/LearnMore";
import Footer from "../../components/footer/Footer";
import { BLOCKCHAIN_DETAIL_BG } from "../../assets/images/index";
import { blockchaindetail } from "../../utils/blockchainlearnmore";

const BlockChainDetail = () => {
  const type = useParams()?.type;
  useEffect(() => {
    document.getElementById(type).scrollIntoView();
  }, []);
  return (
    <div className="blockchain_detail insurance_detail">
      <Newnavigation />
      <PageBanner bg={BLOCKCHAIN_DETAIL_BG} title="Blockchain" />
      <div className="block_chain_head">
      <LearnMore data={blockchaindetail} />
      </div>
      <Footer />
    </div>
  );
};

export default BlockChainDetail;
