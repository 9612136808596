import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Newnavigation from "../../components/new-navigation/new_navigation";
import PageBanner from "../../components/page-banner/page-banner";
import LearnMore from "../../components/common-learn-more/LearnMore";
import Footer from "../../components/footer/Footer";
import { educationDetail } from "../../utils/educationlearnmore";
import { EDUCATION_LEARNMORE } from "../../assets/images/index";

const EducationDetail = () => {
  const type = useParams()?.type;
  useEffect(() => {
    document.getElementById(type).scrollIntoView();
  }, []);

  return (
    <div className="education_detail">
      <Newnavigation />

      <PageBanner
        bg={EDUCATION_LEARNMORE}
        title="Education"
      />

<LearnMore data={educationDetail} />

      <Footer />
    </div>
  );
};

export default EducationDetail;
