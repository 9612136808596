import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Newnavigation from '../../../components/new-navigation/new_navigation';
import AboutusBanner from '../../../components/Aboutus-banner/AboutusBanner';
import ContactNew from '../../../components/contact-form/ContactNew';
import Footer from '../../../components/footer/Footer';
import { RETAIL_ECOMMERCE, TRANSFORMING_BANNER} from '../../../assets/images';
import { business_intelligence_heading, BusinessIntelligenceAccord, CoreServices,WhyTechno,TestimonialData } from '../../../utils/business-intelligence';
import {  Retail_Ecommerce, TransformingBusinessesSeo, TransformingDataSeo} from '../../../utils/Seo';
import HeaderSeo from '../../../components/HeaderSeo/Headerseo';
import ComAccord from '../../../components/Accordian/ComAccord';
import RetailProgress from '../../../components/RetailProgress/RetailProgress';
import CoreServicesList from '../../../components/core-serices/core-services';
import Testimonials from '../../../components/Testimonials/Testimonials';

const BusinessIntelligence = () => {
  const sectionRef = useRef()
  const refcustom = sectionRef.current
    useEffect(() => {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }, []);
    return (
        <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
        <div className='service-page' ref={sectionRef}>
            <Newnavigation routeName="services"/>
            <HeaderSeo data={TransformingDataSeo}/>
            <AboutusBanner bg={TRANSFORMING_BANNER} data={business_intelligence_heading}/>
            <CoreServicesList title="Our Business Intelligence Services" data={CoreServices} />
            <RetailProgress steps={WhyTechno} heading={"Why Choose Techno Genesis for Business Intelligence?"}/>
            <Testimonials data={TestimonialData} />
            <div className="mb-50">
              <ComAccord data={BusinessIntelligenceAccord} />
            </div>
            <ContactNew />
            <Footer />
        </div>
        </motion.div>
    )
}

export default BusinessIntelligence