import { INFO_TECH_1, INFO_TECH_2, INFO_TECH_3, INFO_TECH_4, INFO_TECH_5, TECH_1, TECH_2, TECH_3, TECH_4} from '../assets/images';
export const ITO_heading = [
    {
        title: "Information Technology"      
    },
    {
        title: "Outsourcing(ITO)"
    },
  ];
  export const CoreServices = [
    {
  
    title : "IT Consulting and Strategy",
    content :"Our IT consulting services provide strategic guidance to help you determine the most effective outsourcing solutions for your business. We assess your current IT environment and develop a customized outsourcing plan to meet your specific needs.",
    image: INFO_TECH_1,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Comprehensive IT assessment'},
      {items: 'Strategic outsourcing recommendations'},
      {items: 'Customized solutions to achieve business goals'}
    ],
    button_name: "Schedule an IT Consulting Session Today"
},
{
  
    title : "Infrastructure Management",
    content :"We offer end-to-end infrastructure management services, including monitoring, maintenance, and optimization of your IT infrastructure. Our services ensure high availability, performance, and security of your IT assets.",
    image: INFO_TECH_2,
    keytitile: "Technologies Used",
    Key_technologies: [
      {items: 'Cloud Platforms (AWS, Azure, Google Cloud)'},
      {items: 'Virtualization (VMware, Hyper-V)'},
      {items: 'Network Management Tools'}
    ],
    button_name: "Streamline Your IT Infrastructure with Our Services",
    className: "row-reverse"
},
{
  title : "Custom Software Development",
  content: "Our custom software development services provide you with applications tailored to your business needs. From design and development to deployment and maintenance, we ensure your software solutions are efficient, scalable, and secure.",
  image: INFO_TECH_3,
  keytitile: "Key Benefits",
  Key_technologies: [
    {items: 'Tailored software solutions'},
    {items: 'Enhanced operational efficiency'},
    {items: 'Scalable and secure applications'}
  ],
  button_name:"Develop Custom Software with Our Experts",
},
{
    title : "Cybersecurity Services",
    content: "We provide robust cybersecurity services to protect your data and systems from threats. Our services include risk assessments, vulnerability management, and compliance with industry standards.We provide robust cybersecurity services to protect your data and systems from threats. Our services include risk assessments, vulnerability management, and compliance with industry standards.",
    image: INFO_TECH_4,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Comprehensive security assessments'},
      {items: 'Advanced threat protection'},
      {items: 'Compliance with industry regulations'}
    ],
    button_name:"Secure Your Data and Systems Today",
    className: "row-reverse"
},
{
  title : "Technical Support and Maintenance",
  content: "Our technical support and maintenance services ensure your IT systems run smoothly and efficiently. We offer 24/7 support to address any issues promptly and maintain optimal performance.",
  image: INFO_TECH_5,
  keytitile: "Key Benefits",
  Key_technologies: [
    {items: '24/7 technical support'},
    {items: 'Regular system maintenance'},
    {items: 'Quick issue resolution'}
  ],
  button_name:"Keep Your IT Systems Running Smoothly",
}
]
export const WhyTechno = [
  {
    title: "Expertise and Experience",
    content: "With years of experience in IT outsourcing, our team delivers tailored solutions that meet your specific business needs, ensuring enhanced efficiency and performance.",
    serialno: "01"
  },
  {
    title: "Customer-Centric Approach",
    content: "We prioritize understanding your business goals and delivering outsourcing solutions that drive success through effective IT management.",
    serialno: "02"
  },
  {
    title: "Cutting-Edge Technology",
    content: "We leverage the latest technologies and best practices to provide innovative solutions that keep your business ahead of the competition.",
    serialno: "03"
  },
 
];
export const TestimonialData = [
  {
      content: '“Techno Genesis has transformed our IT operations with their comprehensive outsourcing services. Their expertise and support have allowed us to focus on our core business while ensuring our IT systems are in capable hands.”',
      client: 'Client A'
  }
]
  export const ITOAccord = [
    {
      id:1,
      accHead:"What is Information Technology Outsourcing (ITO)?",
      accContent:"ITO involves contracting external service providers to handle IT-related tasks and services, from infrastructure management to software development and cybersecurity."
  
    },
    {
      id:2,
      accHead:"How can your ITO services benefit my business?",
      accContent:"Our ITO services help you reduce costs, access specialized expertise, scale IT resources, focus on core business activities, and enhance security."
  
    },
    {
      id:3,
      accHead: "What technologies do you use for IT outsourcing?",
      accContent:"We use a range of technologies including cloud platforms (AWS, Azure, Google Cloud), virtualization tools (VMware, Hyper-V), and advanced cybersecurity measures."
      
  
    },
    {
      id:4,
      accHead: "Do you provide ongoing support for outsourced IT services?",
      accContent:"Yes, we provide ongoing support and maintenance to ensure the smooth running of your IT systems."
    },
    {
      id:5,
      accHead: "What sets you apart from other IT outsourcing providers?",
      accContent: "Our expertise, customer-centric approach, and use of cutting-edge technologies set us apart in the industry."
    }
  ]