import React from "react";
import {RELATED_MARKET, RELATED_JEWELLERY ,RELATED_HEALTH_CARE, RELATED_FINANCE,RELATED_CASE_MANAGEMENT, RELATED_ECOMMERCE } from '../../assets/images/index'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import './RelatedCaseStudy.css'
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
const RelatedCaseStudy = ({filterCol}) => {
const cardInfo = [
   {
      title: "E-Commerce",
      content:"The success of an e-commerce platform depends not only on its technical aspects but also on effective marketing, customer service, and continuous improvement based on user feedback and market trends.",
      link:"/work/case-study/e-commerce",
      image:RELATED_ECOMMERCE
 },
{
title: "Share Market",
content:"A prominent share market platform, catering to a diverse range of investors, sought to overcome challenges in executing trades efficiently, managing client portfolios, and providing real-time market insights.",
link:"/work/case-study/share-market",
image:RELATED_MARKET
},
{
title: "Jewellery Manufacturing",
content:"A jewelry manufacturing unit tackled challenges in quality control, vendor onboarding, and defect identification. Streamlined processes led to consistent quality and improved efficiency.",
link:"/work/case-study/jewellery-manufacturing",
image:RELATED_JEWELLERY
},
{
title: "Finance",
content:"A leading finance company addressed a various aspects of personal finance including spent analysis, budget allocation, cash flow management, receipt tracking and managing credit card.",
link:"/work/case-study/finance",
image:RELATED_FINANCE
},
{
title: "Case Management",
content:"An established Case Management firm streamlined operations with digital solutions, improving case tracking, document management, communication, and user prioritization, resulting in enhanced efficiency and productivity.",
link:"/work/case-study/case-management",
image:RELATED_CASE_MANAGEMENT
},
{
title: "Healthcare",
content:"A Healthcare Organization modernized healthcare records, enhancing patient care coordination, data accessibility, and patient engagement, ultimately improving patient outcomes and accessibility.",
link:"/work/case-study/healthcare",
image:RELATED_HEALTH_CARE
},
];
return (
<div className="casestudy_mt_100 ">
   <Container>
   <Fade direction="up" duration={0} triggerOnce>
      <div className=''>
         <h1 className='product-title mb-50'> Related Case Studies</h1>
      </div>
      <Row>
         {cardInfo.filter(cardInfo => cardInfo.title !== filterCol).map((cardInfo, index) => {
         // card section
         return (
         <Col sm={12} md={6} lg={4} key={index}>
         <Card className="position-relative realted_card mb-4">
            <Card.Body className="relatedcasestudy_card" >
               <div className='relatedcase_bg_color '>
                  <img className='related_study_img ' src={cardInfo.image} alt="img" /> 
               </div>
               <div className="mt-4">
                  <h1>{cardInfo.title}</h1>
                  <p className="mt-3" >{cardInfo.content}</p>
                  <Link to={cardInfo.link} className="casestudy_btn mt-2"  >
                  View Case Study
                     </Link>
               </div>
            </Card.Body>
         </Card>
         </Col>
         );
         })}
      </Row>
      </Fade>
   </Container>
</div>
);
}
export default RelatedCaseStudy;