import React from "react";
import "./timeLine.css";
import { Col, Container, Row } from "react-bootstrap";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

const FourTimeLine = ({ customRef }) => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `.time_line_section`,
          start: `top top`,
          end: `100%  bottom`,
          // pin:true,
          scroller: customRef,
          // markers:true
        },
      })
      .to(".second>h1", {
        color: "#3A65FF",

        scrollTrigger: {
          trigger: `.time_line_section`,
          start: `-3% top`,
          end: `10%  top`,
          scrub: true,
          scroller: customRef,
          //   markers:true
        },
      })
      .to(".third>h1", {
        color: "#3A65FF",

        scrollTrigger: {
          trigger: `.time_line_section`,
          start: `15% top`,
          end: `30%  top`,
          scrub: true,
          scroller: customRef,
          //   markers:true
        },
      })
      .to(".four > h1", {
        color: "#3A65FF",

        scrollTrigger: {
          trigger: `.time_line_section`,
          start: `43% top`,
          end: `60%  top`,
          scrub: true,
          scroller: customRef,

        },
      })
      .to(".custom_fill_line", {
        // y:"2000",
        height: "100%",

        scrollTrigger: {
          trigger: `.time_line_section`,
          start: `-15% top`,
          end: `100%  bottom`,
          scrub: true,
          scroller: customRef,
          // markers:true
        },
      });

    return () => { };
  }, []);

  return (
    <div className="time_line_section">
      <Container className='relative'>
        <Row className="responsive_row_odd">
          <Col lg={6}>
            <div className="description_box">
              <p>
                Creating Seamless, Engaging Educational Experiences In today’s
                digital age, integrated learning is key to educational
                excellence and competitiveness. By unifying physical and digital
                environments, institutions can offer seamless learning
                experiences that meet modern demands for accessibility and
                flexibility. Imagine a K-12 school where students can access
                curriculum materials both online and in the classroom, ensuring
                continuous learning anytime, anywhere.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="stages first">
              <h1>01</h1>
              <h3>
                Integrated <br /> Learning
              </h3>
            </div>
          </Col>
        </Row>
        <Row className="time_row responsive_row_even">
          <Col lg={6}>
            <div className="stages even second">
              <h1>02</h1>
              <h3>
                Technology <br /> Migration
              </h3>
            </div>
          </Col>
          <Col lg={6}>
            <div className="description_box even">
              <p>
                Boosting Educational Capabilities Upgrading to advanced
                educational platforms is essential for institutions looking to
                enhance their digital capabilities. For higher education, this
                means adopting systems that support cutting-edge learning
                management, automated grading, and detailed student analytics.
                Such platforms manage high volumes of students and streamline
                administrative processes, ensuring a smooth, error-free
                operation. A well-planned migration supports scalability, boosts
                system performance, and integrates powerful analytical tools for
                deeper insights into student performance and engagement.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="time_row responsive_row_odd">
          <Col lg={6}>
            <div className="description_box">
              <p>
                Connecting with Students Everywhere Omnichannel marketing is
                crucial in today’s education world, ensuring a consistent and
                integrated student experience across all channels. By leveraging
                omnichannel strategies, educational institutions can boost
                student engagement, improve enrollment rates, and streamline
                communication.
              </p>

              <ul>
                <li>Unified Communication</li>
                <li>Personalized Marketing</li>
                <li>Enhanced Student Support</li>
              </ul>
            </div>
          </Col>
          <Col lg={6}>
            <div className="stages third">
              <h1>03</h1>
              <h3>
                {" "}
                Omnichannel <br /> Marketing{" "}
              </h3>
            </div>
          </Col>
        </Row>
        <Row className="time_row responsive_row_even">
          <Col lg={6}>
            <div className="stages even four">
              <h1>04</h1>
              <h3>
                Interactive Digital <br /> Experiences
              </h3>
            </div>
          </Col>
          <Col lg={6}>
            <div className="description_box even">
              <p>
                Enhancing Learning Engagement Interactive digital experiences, such as gamified
                learning platforms and live virtual classrooms, are revolutionizing education.
                These tools provide students with engaging, hands-on experiences that enhance
                understanding and retention. For example, corporate training programs can use
                gamification to make learning more engaging and enjoyable, encouraging active
                participation and knowledge retention.
              </p>
            </div>
          </Col>
        </Row>

        <div className="time-line_path">
          <div className="custom_fill_line"></div>
        </div>
      </Container>

      <div className="time-line_path">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="58"
          height="1781"
          viewBox="0 0 58 1781"
          fill="none"
        >
          <rect x="26" y="35" width="8" height="1746" rx="4" fill="#D1D9F4" />
          <circle cx="29" cy="29" r="29" fill="#D1D9F4" />
          <circle cx="29" cy="29" r="14.5" fill="#3A65FF" />
          <circle cx="29" cy="462" r="29" fill="#D1D9F4" />
          <circle cx="29" cy="462" r="14.5" fill="#3A65FF" />
          <circle cx="29" cy="1513" r="29" fill="#D1D9F4" />
          <circle cx="29" cy="1513" r="14.5" fill="#3A65FF" />
          <circle cx="29" cy="1014" r="29" fill="#D1D9F4" />
          <circle cx="29" cy="1014" r="14.5" fill="#3A65FF" />
        </svg>

        <div className="custom_fill_line"></div>
      </div>
    </div>
  );
};

export default FourTimeLine;
