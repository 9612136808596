import React, { useLayoutEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './homesolution.css'
import Slider from 'react-slick';
import { HOME_SOLUTIONS_1, HOME_SOLUTIONS_2, HOME_SOLUTIONS_3, HOME_SOLUTIONS_4 } from '../../assets/images';
import { Link } from 'react-router-dom';

const HomeSolutions = () => {

    const caro = useRef(null)
    const [currentIndex, setCurrentIndex] = useState(0);

    useLayoutEffect(() => {
        const interval = setInterval(() => {
            gotoNext();
        }, 9200);
        return () => clearInterval(interval);
    }, []);

    const gotoNext = () => {
        caro.current?.slickNext();
    };

    const Image = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        fade: true,
        draggable: false
    };

    const solutions = [
        {
            image: HOME_SOLUTIONS_1,
            title:"Retail & e-commerce",
            content:"In the dynamic world of e-commerce, businesses constantly seek pioneering solutions that not only streamline operations but also enhance customer engagement",
            link:"/solutions/retail"
        },
        {
            image: HOME_SOLUTIONS_2,
            title:"EduTech",
            content:"The education landscape is rapidly evolving, and institutions are on the lookout for groundbreaking solutions to streamline operations and boost student engagement.",
            link:"/solutions/edutech"
        },
        {
            image: HOME_SOLUTIONS_3,
            title:"Transportation & Logistics",
            content:"The transportation and logistics industry is evolving rapidly, and companies need innovative solutions to streamline operations and enhance customer engagement.",
            link:"/solutions/transportation"
        },
        {
            image: HOME_SOLUTIONS_4,
            title:"Healthcare and Pharmaceutical",
            content:"In the rapidly changing world of healthcare, pharmaceutical and healthcare companies are turning to digital solutions to enhance their interactions with healthcare professionals (HCPs)",
            link:"/solutions/healthcare-pharmaceutical"
        },
    ]



  return (
    <div className='home_solution_section'>
        <Container>
            <h2 className='product-title'>Technogenesis Solutions</h2>
            <Row className='mt-50'>
                <Col lg={6} className='pe-0 cont_slid'>

                 <div className='solution_content_home'>

                    <p className={currentIndex === 0 ? 'panel_active' : ''} onClick={() => caro.current?.slickGoTo(0)}>
                        Retail & e-commerce</p>
                    <p className={currentIndex === 1 ? 'panel_active' : ''} onClick={() => caro.current?.slickGoTo(1)}>
                        EduTech
                        </p>
                    <p className={currentIndex === 2 ? 'panel_active' : ''} onClick={() => caro.current?.slickGoTo(2)}>
                        Transportation & Logistics
                        </p>
                    <p className={currentIndex === 3 ? 'panel_active' : ''} onClick={() => caro.current?.slickGoTo(3)}>
                        Healthcare and Pharmaceutical
                        </p>


                 </div>

                
                </Col>
                <Col lg={6} className='ps-0 sliding'>
                  
                   <div className='solution_slider'>
                    <Slider className='slider_solution' ref={caro} {...Image} beforeChange={(currentSlide, nextSlide) => setCurrentIndex(nextSlide)}>
                                    {
                                        solutions.map((item, index) => (
                                            <div key={index} className='solution_image_box position-relative'>
                                                <img className='solution_image_box_img' src={item.image} alt="img" />
                                                
                                            </div>
                                        ))
                                    }
                                </Slider>

                                {
                                     solutions.map((item, index) => (
                                            <div key={index} className={currentIndex === index ? 'solutions_content_box' : 'd-none'}>
                                                <h1>{item.title}</h1>
                                                <p>{item.content}</p>
                                                <Link to={item.link} className='exp_btn'>Explore</Link>
                                            </div>
                                        ))
                                }
                            
                </div>
                
                </Col>


            </Row>


        </Container>



    </div>
  )
}

export default HomeSolutions