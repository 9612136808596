import React, { useRef, useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { PRODUCT_INSURANCE, PRODUCT_TRAVEL, PRODUCT_IMG_NEW, PRODUCT_BLOCKCHAIN, PRODUCT_FINTECH, PRODUCT_CHAIN, PRODUCT_EDUCTAION } from '../../assets/images/index';
import ContactForm from '../contactus-popup/ContactForm';
import './products.css';

const Products = () => {
    const caro = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    useLayoutEffect(() => {
        const interval = setInterval(() => {
            gotoNext();
        }, 7200);
        return () => clearInterval(interval);
    }, []);

    const gotoNext = () => {
        caro.current?.slickNext();
    };

    const Image = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        fade: true,
        draggable: false
    };

    const products = [
        {
            image: PRODUCT_INSURANCE,
            product_head: "Insurance",
            product_text: "Experience the intersection of expertise and technology with Techno Genesis's insurance software development.",
            routeName: "insurance"
        },
        {
            image: PRODUCT_BLOCKCHAIN,
            product_head: "Block Chain",
            product_text: "Experience the power of blockchain technology with Techno Genesis's comprehensive software development solutions.",
            routeName: "blockchain"
        },
        {
            image: PRODUCT_IMG_NEW,
            product_head: "Healthcare",
            product_text: "Leverage Advanced Technology to Enhance Patient Care, Optimize Clinical Operations, and Improve Patient Outcomes",
            routeName: "healthcare"
        },
        {
            image: PRODUCT_FINTECH,
            product_head: "Banking & Fintech",
            product_text: "Elevate Your Financial Services with Techno Genesis's Expertise in Banking and Fintech Software Development.",
            routeName: "fintech"
        },
        {
            image: PRODUCT_EDUCTAION,
            product_head: "Education",
            product_text: "Choose Techno Genesis as Your Trusted Partner for Customized Education Software Solutions.",
            routeName: "education"
        },
        {
            image: PRODUCT_CHAIN,
            product_head: "Supply Chain, Retail & Logistics",
            product_text: "In Partnership with Techno Genesis, Banking and Fintech Software Development Redefines Financial Services.",
            routeName: "logistics"
        },
        {
            image: PRODUCT_TRAVEL,
            product_head: "Travel & Hospitality",
            product_text: "Grow Your Travel & Hospitality Business with Techno Genesis",
            routeName: "travel&hospitality"
        }
    ];

    const handleClick = () => {
            navigate(`/industry/${products[currentIndex].routeName}`);
    };

    return (
        <div id='products'>
            <Container className='mt-100'>
                <Card className='product-card'>
                    <div className=''>
                        <h1 className='product-title'>Generating Value Across</h1>
                        <p className='product-subtitle'>Various Sectors</p>
                    </div>
                    <Row className='pt-2'>
                        <Col sm={12} md={12} lg={12}>
                            <div className='product-list product_list_head'>
                                <ul className="product-list product_list_ul">
                                    <li className={currentIndex === 0 ? 'panel active' : 'panel'} onClick={() => caro.current?.slickGoTo(0)}>Insurance</li>
                                    <li className={currentIndex === 1 ? 'panel active' : 'panel'} onClick={() => caro.current?.slickGoTo(1)}>Block Chain</li>
                                    <li className={currentIndex === 2 ? 'panel active' : 'panel'} onClick={() => caro.current?.slickGoTo(2)}>Health Care</li>
                                    <li className={currentIndex === 3 ? 'panel active' : 'panel'} onClick={() => caro.current?.slickGoTo(3)}>Banking & Fintech </li>
                                    <li className={currentIndex === 4 ? 'panel active' : 'panel'} onClick={() => caro.current?.slickGoTo(4)}>Education</li>
                                    <li className={currentIndex === 5 ? 'panel active' : 'panel'} onClick={() => caro.current?.slickGoTo(5)}>Supply Chain</li>
                                    <li className={currentIndex === 6 ? 'panel active' : 'panel'} onClick={() => caro.current?.slickGoTo(6)}>Travel & Hospitality</li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} className='text-center sm-d-none'>
                            <div>
                                <Slider ref={caro} {...Image} beforeChange={(currentSlide, nextSlide) => setCurrentIndex(nextSlide)}>
                                    {
                                        products.map((item, index) => (
                                            <div key={index} className='imag_box position-relative' onClick={handleClick}>
                                                <img className='imag_height pointer-div' src={item.image} alt="img" />
                                                <div className='product_content_add'>
                                                    <h1>{item.product_head}</h1>
                                                    <p>{item.product_text}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Container>
            <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
        </div>
    );
}

export default Products;
