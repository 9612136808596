export const retail_realtime = [
    {
        title: "Real-Time Example: Optimizing Performance and Conversion for a Food & Beverage Brand",
        content: "A well-known Food & Beverage retailer was experiencing sluggish website performance and subpar conversion rates during peak sale events, which significantly hampered customer satisfaction and sales."    
    },

  ];

  export const edutech_usecase = [
    {
        title: "Use Case",
        boldContent: "Optimizing Learning Outcomes for an Online Education Leader",
        content: "A leading online education provider faced challenges with course management, student feedback delays, and inconsistent communication, negatively impacting satisfaction and learning outcomes. Here's how our solution made a difference."    
    },

  ];

  export const logictics_usecase = [
    {
        title: "Use Case 1",
        boldContent: "Elevating Efficiency for a Leading Logistics Provider",
        content: "A top logistics provider faced issues with inefficient fleet management, delayed deliveries, and inconsistent customer communication, negatively impacting satisfaction and operational costs. Here's how our solution made a difference."    
    },

  ];