import React from 'react'
import './ExploreLife.css'
import { Col, Container, Row } from 'react-bootstrap'
import { EXPLORE_LIFE } from '../../assets/images'
import { Fade } from 'react-awesome-reveal'

const ExploreLife = () => {
    return (
        <>
            <div className='my-5'>
                <Container>
                    <Row>
                        <Col xl='6' lg='6'>
                            <Fade direction='left' duration={0}>
                                <div className='explore-content'>
                                    <h2>Explore life at Technogenesis</h2>
                                    <p>Imagine a platform giving equal employment opportunities to all.
                                        Jobs at Technogenesis offers just that and a lot more. We nurture
                                        a workplace defining passion where you get the chance to work in
                                        global teams and be at the forefront of business and technology.</p>

                                    <p className='mt-3'>A typical Technogenesis year is spiced with project kickoffs,
                                        knowledge enhancing development cycles, app launches, entrepreneurial
                                        games, knowledge sharing sessions, communication workshops, and
                                        company outings.</p>
                                </div>
                            </Fade>
                        </Col>

                        <Col xl='6' lg='6' className='explore-img'>
                            <Fade direction='right' duration={0}>
                                <img src={EXPLORE_LIFE} alt="explore-left-image" />
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ExploreLife