import { ReactComponent as Training } from "./../assets/images/industry/insurance/training_apps.svg";
import { ReactComponent as Induction } from "./../assets/images/industry/insurance/induction.svg";
import { ReactComponent as Engagement } from "./../assets/images/industry/insurance/engagement_apps.svg";
import { ReactComponent as Booking } from "./../assets/images/industry/insurance/skill_booking.svg";
import { ReactComponent as Tution } from "./../assets/images/industry/insurance/tution_apps.svg";
import { ReactComponent as Virtual } from "./../assets/images/industry/insurance/virutual_classroom.svg";
import { ReactComponent as E_LEARN } from "./../assets/images/industry/insurance/e-learning.svg";
import { ReactComponent as VIDEO } from "./../assets/images/industry/insurance/video-player.svg";
import { ReactComponent as VIDEO_PLATER } from "./../assets/images/industry/insurance/video-player-2.svg";
import { ReactComponent as SOF } from "./../assets/images/industry/fintech/layers_2251502 1.svg";
import { ReactComponent as BLCH} from "./../assets/images/industry/fintech/blockchain_7452737 1.svg";
import { ReactComponent as MAD } from "./../assets/images/industry/fintech/mobile-development_7075373 1.svg";
import { ReactComponent as MLAI } from "./../assets/images/industry/fintech/artificial-intelligence_900961 1.svg";
import { ReactComponent as ITC } from "./../assets/images/industry/fintech/setting_8914438 (1) 1.svg";
import { ReactComponent as DS } from "./../assets/images/industry/fintech/padlock_3055754 (1) 1.svg";
import { ReactComponent as MBS } from "./../assets/images/industry/fintech/mobile_13184422 1.svg";
import { ReactComponent as OE } from "./../assets/images/industry/fintech/user_5010497 1.svg";
import { ReactComponent as TQA } from "./../assets/images/industry/fintech/quality_1212158 (1) 1.svg";
import { OURMISSION_FINTECH, WHATWEDOEDUCATION, WHATWEDOEDUCATION_1, WHATWEDOEDUCATION_2 } from "../assets/images";


export const FinTechWhatwedo = [
  {
    image: WHATWEDOEDUCATION,
    ques:"What We Do?",
    ans : "Techno Genesis provides a comprehensive suite of FinTech solutions, covering software development, blockchain, mobile apps, AI, machine learning, IT consulting, data security, mobile banking, and testing—an integrated approach for your FinTech needs."
  },
  {
    image: WHATWEDOEDUCATION_1,
    ques: "Who We Serve?",
    ans : "We tailor FinTech solutions for a diverse financial clientele. Whether you're a startup or an established enterprise, our services enhance your digital presence and customer experiences. Serving businesses of all sizes, we understand and address FinTech's unique challenges."
  },
  {
    image: WHATWEDOEDUCATION_2,
    ques: "What Sets Us Apart?",
    ans : "Choosing Techno Genesis means opting for tailored solutions that leverage cutting-edge technology for innovation, efficiency, and growth in the evolving FinTech landscape. What distinguishes us is our commitment to crafting adaptable solutions, prioritizing your goals with transparency, innovation, and shared dedication to your FinTech success"
  },
]

export const FinTechAccord = [
  {
    id:1,
    accHead:"Ready to transform your FinTech experience?",
    accContent:"Dive into our suite of innovative solutions designed to optimize financial processes, fortify security, and foster groundbreaking innovation. Contact Techno Genesis today and embark on a journey towards FinTech excellence. Let's redefine finance together!"
  }
]

export const FinTechMissons = {
  title :"Our Missions",
  content:"Experience a transformation in FinTech with Techno Genesis, where we reshape the industry through advanced services. Our solutions are designed to enhance financial processes, ensuring security, efficiency, and unparalleled innovation. With Techno Genesis, anticipate the seamless integration of technology and finance, delivering exceptional experiences to your clients while optimizing internal processes for success.",
  Image : OURMISSION_FINTECH
}
export const KeyserviceFintech = [
  {
    Image: SOF,
    title: "Software Development",
    content :"Our Software Development services are meticulously crafted to meet the specific needs of the FinTech industry. From robust financial systems to agile applications, we deliver innovative software solutions that streamline operations and elevate the user experience."
  },
  {
    Image:BLCH,
    title:"Blockchain Technology",
    content :"Explore the potential of Blockchain Technology with Techno Genesis. Our FinTech solutions leverage blockchain for secure and transparent transactions, enhancing trust and efficiency in financial processes."
  },
  {
    Image:MAD,
    title:"Mobile App Development",
    content :"Stay ahead in the mobile-driven FinTech era with Techno Genesis's top-notch Mobile App Development services. We design and develop custom mobile applications tailored to the unique needs of the FinTech industry, ensuring user-friendly interfaces and enhanced customer experiences."
  },
  {
    Image:MLAI,
    title:"AI and Machine Learning",
    content :"Techno Genesis specializes in harnessing the power of AI and Machine Learning for FinTech applications. From predictive analytics to intelligent automation, our solutions bring unparalleled efficiency and insights to your financial processes."
  },
  {
    Image:ITC,
    title:"IT Consulting",
    content :"Our IT Consulting services provide strategic guidance and expertise to navigate the complexities of the digital FinTech landscape. Whether developing a digital transformation roadmap or optimizing existing processes, our team is committed to driving tangible results and maximizing ROI for our clients."
  },
  {
    Image:DS,
    title:"Data Security",
    content :"Security is paramount in FinTech, and our Data Security solutions ensure the highest level of protection for financial data. With robust measures including encryption and continuous monitoring, we safeguard against cyber threats and unauthorized access."
  },
  {
    Image:MBS,
    title:"Mobile Banking Solutions",
    content :"Experience the future of banking with our Mobile Banking Solutions. We deliver secure, user-friendly mobile banking platforms that empower financial institutions to provide convenient services to their customers."
  },
  {
    Image:OE,
    title:"Omnichannel Experiences",
    content :"Techno Genesis understands the importance of delivering a seamless and consistent experience across all channels, whether customers engage with you online channels, whether customers engage with you online, through your mobile app, or in person. Our solutions are designed to bridge the gap between different channels, ensuring your customers have a positive and unified experience at every touchpoint."
  },
  {
    Image:TQA,
    title:"Testing and Quality Assurance",
    content :"Our Testing and Quality Assurance services ensure the reliability and performance of your FinTech solutions. Through rigorous testing processes, we guarantee that your financial systems operate seamlessly and securely."
  }
  

]

export const fintech = [
    {
        title: "Corporate Training Apps",
        Image: Training
      },
      {
        title: "Induction and Orientation Apps",
        Image: Induction
      },
      {
        title: "Employee Engagement Apps",
        Image: Engagement
      },
      {
        title: "Skill Booking Apps",
        Image: Booking
      },
      {
        title: "Tuition Apps ",
        Image: Tution
      },
      {
        title: "Virtual Classrooms",
        Image: Virtual
      },
      {
        title: "LMS Development",
        Image: E_LEARN
      },
      {
        title: "eLearning Apps for Disabled",
        Image: VIDEO
      },
      {
        title: "On-demand eLearning Apps",
        Image: VIDEO_PLATER
      }
    ]

    export const whyChooseTgssDetails = [
      {
      Title:"Why Choose Techno Genesis?",
      Details: [{
        List: "Expertise",
        ListItem: "Our team takes pride in housing seasoned developers and instructional designers who possess extensive expertise in the field of education and edutech."
      },
      {
        List: "Innovation",
        ListItem: "Our commitment to innovation is evident in our proactive approach, where we leverage the newest technologies and stay abreast of emerging learning trends, resulting in the development of solutions that push the boundaries of what's possible."
      },
      {
        List: "Customization",
        ListItem: "We customize our solutions to align seamlessly with your distinct requirements and financial parameters, guaranteeing an ideal match for your one-of-a-kind learning setting."
      },
      {
        List: "Scalability",
        ListItem: "Our solutions are crafted to scale alongside your requirements, adjusting seamlessly to accommodate a growing user base or evolving educational objectives."
      },
      {
        List: "Support",
        ListItem: "We provide continuous support and maintenance to guarantee the seamless operation of your eLearning solution, ensuring it consistently delivers optimal results."
      },
      {
        List: "Omnichannel Marketing Solutions",
        ListItem: "Boost your brand's impact with Techno Genesis Omnichannel Marketing Solutions. We craft seamless strategies across all channels, ensuring a unified and effective approach. Elevate your online and offline presence with us, your trusted partner for comprehensive marketing success."
      },]
    }
    ]   