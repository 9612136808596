import { ReactComponent as Training } from "./../assets/images/industry/insurance/training_apps.svg";
import { ReactComponent as Induction } from "./../assets/images/industry/insurance/induction.svg";
import { ReactComponent as Engagement } from "./../assets/images/industry/insurance/engagement_apps.svg";
import { ReactComponent as Booking } from "./../assets/images/industry/insurance/skill_booking.svg";
import { ReactComponent as Tution } from "./../assets/images/industry/insurance/tution_apps.svg";
import { ReactComponent as Virtual } from "./../assets/images/industry/insurance/virutual_classroom.svg";
import { ReactComponent as E_LEARN } from "./../assets/images/industry/insurance/e-learning.svg";
import { ReactComponent as VIDEO } from "./../assets/images/industry/insurance/video-player.svg";
import { ReactComponent as VIDEO_PLATER } from "./../assets/images/industry/insurance/video-player-2.svg";
import { ReactComponent as SYRINGE } from "./../assets/images/industry/healthcare/syringe_1550066 1.svg";
import { ReactComponent as HOS } from "./../assets/images/industry/healthcare/health_8134020 1.svg";
import { ReactComponent as MASK } from "./../assets/images/industry/healthcare/medical-mask_2853824 1.svg";
import { ReactComponent as SERVICE } from "./../assets/images/industry/healthcare/operator_271045 1.svg";
import { OURMISSION_HEALTHCARE, WHATWEDOEDUCATION, WHATWEDOEDUCATION_1, WHATWEDOEDUCATION_2 } from "../assets/images";


export const HealthcareWhatwedo = [
  {
    image: WHATWEDOEDUCATION,
    ques:"What We Do?",
    ans : "At Techno Genesis, we guide health organizations into the new age of digitally-enhanced, personalized care. Our experts collaborate with biopharma, MedTech and healthcare leaders to spark digital revolutions within their enterprises—unlocking growth, optimizing patient experiences, and accelerating life-changing innovation to market."
  },
  {
    image: WHATWEDOEDUCATION_1,
    ques: "Who We Do It For?",
    ans : "Our solutions are designed for biopharmaceutical companies, medical device manufacturers, healthcare systems, insurers, and other health sector organizations seeking leading-edge solutions."
  },
  {
    image: WHATWEDOEDUCATION_2,
    ques: "What They Get?",
    ans : "With Techno Genesis, health sector partners can gain data-driven insights, elevated patient engagement, optimized clinical trials, future-ready health technologies, and strategic advisory."
  },
]

export const HealthcareAccord = [
  {
    id:1,
    accHead:"How does Techno Genesis drive digital transformation in healthcare?",
    accContent:"We drive digital transformation by first understanding our clients’ specific vision and needs, then developing tailored roadmaps leveraging the latest advancements in cloud, AI/ML, big data analytics, IoT, and more. Our experts manage full lifecycle implementation across platforms, connected devices, trials decentralization, and patient experiences."

  },
  {
    id:2,
    accHead:"What emerging technologies does Techno Genesis integrate?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:3,
    accHead: "What types of organizations does Techno Genesis serve?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
    

  },
  {
    id:4,
    accHead: "How can Techno Genesis improve clinical trials execution?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  },
  {
    id:5,
    accHead:"What experience does Techno Genesis have in healthcare? ",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  }
]

export const HealthcareMissons = {
  title :"Our Missions",
  content:"At Techno Genesis, our mission is to propel the healthcare ecosystem into the digital age. Our experts leverage deep domain knowledge and emerging technologies to help biopharmaceutical, medical device, and healthcare organizations transform patient and health provider experiences, clinical trials, enterprise operations, and business growth opportunities. Our comprehensive solutions pave the path toward data-driven, digitally-enhanced, and patient-centric care.",
  Image : OURMISSION_HEALTHCARE
}
export const KeyserviceHealthcare = [
  {
    Image: SYRINGE,
    title: "Enterprise Medical Solutions",
    content :"Our enterprise medical solutions modernize legacy health systems and enhance patient experiences. We implement intuitive patient portal platforms, seamless data interoperability, and self-service tools driving engagement."
  },
  {
    Image:HOS,
    title:"Enterprise Clinical Solutions",
    content:"Our enterprise clinical solutions facilitate efficient design and execution of trials leveraging eClinical systems and cutting-edge digital biomarkers. We ensure quality, security, and compliance throughout."

  },
  {
    Image:MASK,
    title:"Omnichannel Patient Engagement Solutions",
    content :"Our omnichannel patient engagement solutions create personalized and consistent interaction across web, mobile, wearables, telehealth platforms. This drives patient retention and medication adherence."
  },
  {
    Image:SERVICE,
    title:"Strategic Consulting & Centers of Excellence",
    content :"We provide specialized strategy consulting across clinical, patient experience, data analytics, health platforms, implementation, cybersecurity, and other key areas through our Centers of Excellence."
  }

]

export const healthcare = [
    {
        title: "Corporate Training Apps",
        Image: Training
      },
      {
        title: "Induction and Orientation Apps",
        Image: Induction
      },
      {
        title: "Employee Engagement Apps",
        Image: Engagement
      },
      {
        title: "Skill Booking Apps",
        Image: Booking
      },
      {
        title: "Tuition Apps ",
        Image: Tution
      },
      {
        title: "Virtual Classrooms",
        Image: Virtual
      },
      {
        title: "LMS Development",
        Image: E_LEARN
      },
      {
        title: "eLearning Apps for Disabled",
        Image: VIDEO
      },
      {
        title: "On-demand eLearning Apps",
        Image: VIDEO_PLATER
      }
    ]

    export const whyChooseTgssDetails = [
      {
      Title:"Why Choose Techno Genesis?",
      Details: [{
        List: "Expertise",
        ListItem: "Our team takes pride in housing seasoned developers and instructional designers who possess extensive expertise in the field of education and edutech."
      },
      {
        List: "Innovation",
        ListItem: "Our commitment to innovation is evident in our proactive approach, where we leverage the newest technologies and stay abreast of emerging learning trends, resulting in the development of solutions that push the boundaries of what's possible."
      },
      {
        List: "Customization",
        ListItem: "We customize our solutions to align seamlessly with your distinct requirements and financial parameters, guaranteeing an ideal match for your one-of-a-kind learning setting."
      },
      {
        List: "Scalability",
        ListItem: "Our solutions are crafted to scale alongside your requirements, adjusting seamlessly to accommodate a growing user base or evolving educational objectives."
      },
      {
        List: "Support",
        ListItem: "We provide continuous support and maintenance to guarantee the seamless operation of your eLearning solution, ensuring it consistently delivers optimal results."
      },
      {
        List: "Omnichannel Marketing Solutions",
        ListItem: "Boost your brand's impact with Techno Genesis Omnichannel Marketing Solutions. We craft seamless strategies across all channels, ensuring a unified and effective approach. Elevate your online and offline presence with us, your trusted partner for comprehensive marketing success."
      },]
    }
    ]   