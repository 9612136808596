import {
    STO_DEVELOPMENT_DETAIL,
    SMART_CONTRACT_DETAIL,
    CRYPTOCURRENCY_DETAIL,
    CRYPTOTRADE_EXCHANGE,
    PRIVATE_BLOCKCHAIN_DETAIL,
  } from "../assets/images/index";


export const blockchaindetail = [
    {
      title: "Smart Contract Development & Integration",
      img: SMART_CONTRACT_DETAIL,
      id: "smart-contract",
      listItems: [
        "Welcome to our 'Smart Contract Development & Integration' hub, where we dive into the transformative power of blockchain technology. ",
        "Explore the seamless fusion of innovation and security as we unlock the potential of smart contracts.",
        "Discover how our expert developers are shaping the future of agreements—self-executing, tamper-proof contracts that redefine trust. ",
        "From conceptualization to deployment, our smart contract development is a meticulous process, ensuring transparency and efficiency. ",
      ],
    },
    {
      title: "Cryptocurrency Development",
      img: CRYPTOCURRENCY_DETAIL,
      reverseClass: "flex_reverse",
      id: "cryptocurrency-development",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },

    {
      title: "Crypto Trade Exchange",
      img: CRYPTOTRADE_EXCHANGE,
      id: "trade-exchange",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },
    {
      title: "Private Blockchain & Framework Specific Development",
      img: PRIVATE_BLOCKCHAIN_DETAIL,
      reverseClass: "flex_reverse",
      id: "private-blockchain",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },

    {
      title: "ICO / STO Development and Support",
      img: STO_DEVELOPMENT_DETAIL,
      id: "sto-developement",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },


  ];