import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './CareerBanner.css'

const CareerBanner = () => {
    return (
        <>
            <div className='careerbanner-bg'>
                <Container>
                    <Row>
                        <Col xl='7' lg='8' className='banner-content'>
                            <h1><span>Working At</span> Technogenesis</h1>
                            <p>Together we can build cutting-edge products and curate technology
                                 solutions for everyone. Opt for the best careers in technology and 
                                 make people's lives better.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default CareerBanner