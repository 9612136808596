import React, { useEffect } from 'react'
import Newnavigation from '../../components/new-navigation/new_navigation'
import ContactNew from '../../components/contact-form/ContactNew'
import Footer from '../../components/footer/Footer'
import { motion } from 'framer-motion';
import CareerBanner from '../../components/CareerBanner/CareerBanner'
import ExploreLife from '../../components/Explore-life/ExploreLife'
import CareerPositions from '../../components/Career-positions/CareerPositions';
import { JobCard } from '../../components/job-card/job-card';
import { Container } from 'react-bootstrap';

const Careers = () => {

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const jobs = [
        // { title: "UX/UI Designer", description: "Job Title: UX/UI Designer Resource Required: 2 Experience Needed: 3+ Years Responsibilities: Investigating user experience design requirements for our suite of digital assets. Developing and conceptualizing a comprehensive UI/UX design strategy for the brand. Producing high quality UX design solutions through wireframes, visual and graphic designs, flow diagrams, storyboards, site maps, and prototypes. Create user-friendly…", exp: "3 Years ago", location: "Chennai", type: "Full time", url: "/career-detail" },
        // { title: "Frontend Web Developer", description: "Job Title: Frontend Web Developer Resource Required: 2 Experience Needed: 2+ Years Responsibilities: Develop new user-facing features Build reusable code and libraries for future use Ensure the technical feasibility of UI/UX designs Optimize application for maximum speed and scalability Assure that all user input is validated before submitting to back-end Collaborate with other team members…", exp: "3 Years ago", location: "Chennai", type: "Full time", url: "/career-detail" },
        // { title: "AngularJS Developer", description: "Job Title: AngularJs Developer Resource Required: 2 Experience Needed: 2+ Years Responsibilities: Developing new user-facing features using Angular.js Building reusable components and front-end libraries for future use Translating designs and wireframes into high-quality code Optimizing components for maximum performance across a vast array of web-capable devices and browsers Skills Required: Strong proficiency in JavaScript and…", exp: "3 Years ago", location: "Chennai", type: "Full time", url: "/career-detail" },
        // { title: "Android Mobileapp Developer", description: "Job Title: Java Springboot Developer Resource Required: 3 Experience Needed: 2+ Years Responsibilities: Develop Rest Based API Services on Springboot to interact with Web & Mobile Based Applications Understand the customer requirements and build the core services as required Building reusable components and back-end libraries for future use Optimizing components for maximum performance across a…", exp: "3 Years ago", location: "Chennai", type: "Full time", url: "/career-detail" },

    ]

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
        >
            <div>
                <Newnavigation routeName="solutions" />
                <CareerBanner />
                <ExploreLife />
                <Container>
                    <div>
                        <Container>
                            <h2 className='section_title'>Featured Positions</h2>
                        </Container>
                        <JobCard data={jobs} />
                    </div>
                </Container>
                <ContactNew />
                <Footer />
            </div>
        </motion.div>
    )
}

export default Careers