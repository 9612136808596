import React, { useState } from 'react';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';
import './core-services.css';
import ContactForm from '../contactus-popup/ContactForm';
const CoreServicesList = ({ data,title }) => {
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   
    return (
        <div class="service-section">
            <Container>
              <h3>{title}</h3>
            </Container>
            
            <div class="bg-grey">
            <Container>
            <Row>
                {data.map((client, index) => (
                    <React.Fragment key={index}>
                        <Col lg={12} className='mt-5'>
                            <Card className="service-card">
                                <Row className={client.className}>
                                  <Col lg={7} data-aos="fade-right" data-aos-duration="1000">
                                    <Card.Body className='service-card-body'>
                                      <Card.Title className='service-card-title'>{client.title}</Card.Title>
                                       <Card.Text className='service-card-content mt-3'>{client.content}</Card.Text>
                                       <Card.Subtitle className='service-card-subtitle'>{client.keytitile}</Card.Subtitle>
                                       <ul>
                                       {client.Key_technologies.map((technology, index) => (
                                        <li>{technology.items}</li>
                                       ))}
                                       </ul>
                                       <Button type="button" onClick={handleShow} className="service-card-button">{client.button_name}</Button>
                                    </Card.Body>
                                   </Col>
                                   <Col lg={1}></Col>
                                   <Col lg={4} data-aos="fade-right" data-aos-duration="1000">
                                     <Card.Img src={client.image} alt={client.title} className='client-card-image' />
                                   </Col> 
                                </Row>
                            </Card>
                        </Col>
                    </React.Fragment>
                ))}
            </Row>
      
        </Container>
            </div>
            <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
        </div>
        
    );
}
export default CoreServicesList;