import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Newnavigation from '../../../components/new-navigation/new_navigation';
import AboutusBanner from '../../../components/Aboutus-banner/AboutusBanner';
import ContactNew from '../../../components/contact-form/ContactNew';
import Footer from '../../../components/footer/Footer';
import { DATAMINING_BANNER, RETAIL_ECOMMERCE} from '../../../assets/images';
import { datamining_heading, DataminingAccord, CoreServices,WhyTechno,TestimonialData } from '../../../utils/datamining-crm';
import {  MiningSeo, Retail_Ecommerce} from '../../../utils/Seo';
import HeaderSeo from '../../../components/HeaderSeo/Headerseo';
import ComAccord from '../../../components/Accordian/ComAccord';
import RetailProgress from '../../../components/RetailProgress/RetailProgress';
import CoreServicesList from '../../../components/core-serices/core-services';
import Testimonials from '../../../components/Testimonials/Testimonials';

const DataMiningCRM = () => {
  const sectionRef = useRef()
  const refcustom = sectionRef.current
    useEffect(() => {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }, []);
    return (
        <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
        <div className='service-page' ref={sectionRef}>
            <Newnavigation routeName="services"/>
            <HeaderSeo data={MiningSeo}/>
            <AboutusBanner bg={DATAMINING_BANNER} data={datamining_heading}/>
            <CoreServicesList title="Data Mining and CRM" data={CoreServices} />
            <RetailProgress steps={WhyTechno} heading={"Why Choose Techno Genesis?"}/>
            <Testimonials data={TestimonialData} />
            <div className="mb-50">
              <ComAccord data={DataminingAccord} />
            </div>
            <ContactNew />
            <Footer />
        </div>
        </motion.div>
    )
}

export default DataMiningCRM