import React from 'react'
import WorkCard from '../../components/work-card/WorkCard'
import Newnavigation from '../../components/new-navigation/new_navigation'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { WORKCARD_BG_1, WORKCARD_BG_2, COVER_WHITE, WORKCARD_BG_3, WORKCARD_BG_4, WORKCARD_BG_5, WORKCARD_BG_6, WORKCARD_IMG_1, WORKCARD_IMG_2, WORKCARD_IMG_3, WORKCARD_IMG_4, WORKCARD_IMG_5, WORKCARD_IMG_6 } from '../../assets/images';
import Footer from '../../components/footer/Footer';
import { useEffect } from 'react';
import Headerseo from '../../components/HeaderSeo/Headerseo'
import { motion } from "framer-motion";
import { WorkSeo } from '../../utils/Seo';
import { Fade } from 'react-awesome-reveal';

const WorkPage = () => {

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

 const workcardDetails = [
    {
        smalltext: 'Case Study - 1',
        title: 'E Commerce',
        content: 'The success of an e-commerce platform depends not only on its technical aspects but also on effective marketing, customer service, and continuous improvement based on user feedback and market trends.',
        link:'e-commerce',
        bg: WORKCARD_BG_1, image: WORKCARD_IMG_1,
        text_clr:"#A5B9FF",
        icon:COVER_WHITE
    },
    {
        smalltext: 'Case Study - 2',
        title: 'Share Market',
        content: 'A prominent share market platform, catering to a diverse range of investors, sought to overcome challenges in executing trades efficiently, managing client portfolios, and providing real-time market insights.',
        link:'share-market',
        bg: WORKCARD_BG_2, image: WORKCARD_IMG_2,
        text_clr:"#fff",
        icon:COVER_WHITE
    },
    {
        smalltext: 'Case Study - 3',
        title: 'Jewellery Manufacturing',
        content: 'A jewelry manufacturing unit tackled challenges in quality control, vendor onboarding, and defect identification. Streamlined processes led to consistent quality and improved efficiency.',
        link:'jewellery-manufacturing',
        bg: WORKCARD_BG_3, image: WORKCARD_IMG_3,
        text_clr:"#A5B9FF",
        icon:COVER_WHITE
    },
    {
      smalltext: 'Case Study - 4',
      title: 'Finance',
      content: 'A leading finance company addressed a various aspects of personal finance including spent analysis, budget allocation, cash flow management, receipt tracking and managing credit card.',
      link:'finance',
      bg: WORKCARD_BG_4, image: WORKCARD_IMG_4,
      text_clr:"#fff",
      icon:COVER_WHITE
  },
  {
    smalltext: 'Case Study - 5',
    title: 'Case Management',
    content: 'An established Case Management firm streamlined operations with digital solutions, improving case tracking, document management, communication, and user prioritization, resulting in enhanced efficiency and productivity. Improved efficiency and document accessibility, resulting in a more productive and streamlined operational model for a Case Management Firm.',
    link:'case-management',
    bg: WORKCARD_BG_5, image: WORKCARD_IMG_5,
    text_clr:"#A5B9FF",
    icon:COVER_WHITE
},
{
  smalltext: 'Case Study - 6',
  title: 'Healthcare',
  content: 'A Healthcare Organization modernized healthcare records, enhancing patient care coordination, data accessibility, and patient engagement, ultimately improving patient outcomes and accessibility. Modernizing healthcare records management and data accessibility for more efficient, patient-centric healthcare system in a Healthcare Organization.',
  link:'healthcare',
  bg: WORKCARD_BG_6, image: WORKCARD_IMG_6,
  text_clr:"#A5B9FF",
  icon:COVER_WHITE
},
 ]

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
    <div className='work_page'>
        <Newnavigation routeName="Work"/>
        <Headerseo data={WorkSeo}/>
        <Container>
      <Row>
        {
             workcardDetails.map((item,i)=>{
                return(
                  <>
                  {i%2==0?
                  <Fade direction='left'  duration={0}>
                    <Col lg={12} className='mb-60 workcard workcardadd' > 
                    <WorkCard 
                    key={i} 
                    text_clr={item?.text_clr} 
                    image={item?.image} 
                    bg={item?.bg} 
                    smalltext={item?.smalltext}  
                    title={item?.title} 
                    content={item?.content} 
                    icon={item?.icon}
                    link ={item?.link}   /></Col>
                    </Fade> :
                    <Fade direction='right' duration={0}>
                    <Col lg={12} className='mb-60 workcard workcardadd' > 
                    <WorkCard 
                    key={i} 
                    text_clr={item?.text_clr} 
                    image={item?.image} 
                    bg={item?.bg} 
                    smalltext={item?.smalltext}  
                    title={item?.title} 
                    content={item?.content} 
                    icon={item?.icon}
                    link ={item?.link}   /></Col>
                    </Fade>
                    }
                    </>
                )
            })
        }
       
      </Row>
    </Container>
       
    <Footer/>
    </div>
    </motion.div>
   
  )
}

export default WorkPage