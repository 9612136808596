import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './timeLine.css';
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const UseCase = ({customRef}) => {

    useEffect(() => {
         
        gsap.timeline({

            scrollTrigger:{
                trigger:`.usecase_timeline`,
                start:`top top`,
                end:`100%  bottom`,
                // pin:true,
                scroller: customRef,
                // markers:true
          
           }
          })
          .to(".custom_fill_line",{

            // y:"2000",
            height:"100%",
            

          scrollTrigger:{
                trigger:`.usecase_timeline`,
                start:`-15% top`,
                end:`100%  bottom`,
                scrub: true,
                scroller: customRef,
                // markers:true
               
           }
          })
        
      
      
    
      return () => {
        
      }
    }, [])


  return (
    <div className='usecase_section'>
        <Container>
            <Row>
                <Col lg={11}>
                   <h2 className='use_case_section_header'>Use Case </h2>
                   <p className='use_case_content'>Our comprehensive approach combines integrated healthcare systems, seamless technology migration, omnichannel marketing, and interactive digital tools. This multi-faceted solution not only enhances operational efficiency but also significantly boosts HCP engagement, supporting sales representatives in their efforts.</p>
                
                </Col>
            </Row>
        </Container>

        <div className='usecase_timeline'>
            <Container className='relative'>

            <Row className='responsive_row_odd' >
                <Col lg={6}>
                    
                    <div className='problems odd'>
                        <h3 className='problem_stage'>Problem 1</h3>
                        <h3 className='problem_name'>Inefficient Data Management and Delayed Communications</h3>
                        <p className='problem_content'>A major pharmaceutical company struggled with inefficient data management and delayed communications, leading to inconsistent engagement with HCPs and operational setbacks.</p>

                       


                    </div>

                </Col>
                <Col lg={6}>
                <div className='solution odd'>
                    <div className='solutions_content'>
                        <h2 className='solutions_content_title'>Solution</h2>
                        <p> <b>Integrated Healthcare</b>  Systems We implemented a unified system that synchronized data management with clinical operations, ensuring real-time updates and seamless coordination. </p>
                        <p> <b> Advanced Analytics</b>  By deploying sophisticated analytics tools, we gained insights into HCP engagement, prescription trends, and treatment outcomes. </p>
                        <p> <b> Omnichannel </b>   Marketing We developed an omnichannel marketing strategy that unified all communication channels, enabling HCPs to receive updates via their preferred platforms. </p>
                    </div>



                  

                </div>
                
                
                </Col>
            </Row>
            <Row className='time_row new_row responsive_row_even' >

            <Col lg={6}>
                <div className='solution even'>
                    <div className='solutions_content'>
                        <h2 className='solutions_content_title'>Solution</h2>
                        <p> <b>Integrated Healthcare Systems</b> Implementing a platform that provides end-to-end visibility across clinical operations, including real-time tracking of patient data, treatment progress, and research updates.  </p>
                        <p> <b> Advanced Analytics Utilizing </b>  advanced tools to monitor patient management performance, predict potential disruptions, and optimize operations.  </p>
                        <p> <b>Omnichannel Marketing</b>  Enhancing communication with HCPs through a unified approach. Real-time updates on patient data were delivered via preferred channels, such as email, SMS, or mobile apps, ensuring HCPs had the latest information at their fingertips.  </p>

                        <p> <b>Interactive Dashboards</b>  Creating interactive dashboards offering a comprehensive view of patient data, providing real-time insights for better decision-making and improved efficiency.</p>

                    </div>



                  

                </div>
                
                
                </Col>


                <Col lg={6}>
                    
                    <div className='problems even'>
                        <h3 className='problem_stage'>Problem 2</h3>
                        <h3 className='problem_name'>Challenges in Patient Management and Data Visibility</h3>
                        <p className='problem_content'>A prominent healthcare company faced significant challenges with patient management, resulting in delays, miscommunications, and dissatisfied HCPs.</p>

                       


                    </div>

                </Col>
            
            </Row>
            <Row className='time_row new_row responsive_row_odd' >
                <Col lg={6}>
                    
                    <div className='problems odd'>
                        <h3 className='problem_stage'>Problem 3</h3>
                        <h3 className='problem_name'>Inconsistent HCP Engagement and Support</h3>
                        <p className='problem_content'>Healthcare companies often face difficulties in maintaining consistent engagement and providing adequate support to HCPs, impacting their relationships and overall service quality.</p>

                       


                    </div>

                </Col>
                <Col lg={6}>
                <div className='solution odd'>
                    <div className='solutions_content'>
                        <h2 className='solutions_content_title'>Solution</h2>
                        <p> <b>Omnichannel Activation for Sales</b>  Reps  We enabled omnichannel activation to help sales representatives connect with HCPs through multiple touchpoints, including emails, social media, mobile apps, and direct messaging.</p>
                        <p> <b>Enhanced User Experience</b>  We revamped the digital interface to focus on usability and speed. Improvements included streamlined information access, faster load times, and intuitive navigation, collectively reducing bottlenecks and improving HCP satisfaction. </p>
                        <p> <b> Interactive Digital Tools</b> Utilizing virtual conferences, live webinars, and interactive dashboards to provide engaging, hands-on experiences that enhance understanding and satisfaction. </p>
                    </div>



                  

                </div>
                
                
                </Col>
            </Row>
            <div className='time-line_path new_path'>
            <svg xmlns="http://www.w3.org/2000/svg" width="66" height="1495" viewBox="0 0 66 1495" fill="none">
            <rect x="29" y="35" width="8" height="1460" rx="4" fill="#D1D9F4"/>
            <circle cx="32" cy="29" r="29" fill="#D1D9F4"/>
            <circle cx="32" cy="29" r="14.5" fill="#3A65FF"/>
            <circle cx="37" cy="453" r="29" fill="#D1D9F4"/>
            <circle cx="37" cy="453" r="14.5" fill="#3A65FF"/>
            <circle cx="29" cy="1061" r="29" fill="#D1D9F4"/>
            <circle cx="29" cy="1061" r="14.5" fill="#3A65FF"/>
            </svg>

                <div className='custom_fill_line'></div>
           </div>


            </Container>



        </div>

    </div>
  )
}

export default UseCase