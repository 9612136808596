import {
    ADVANCE_ANALYTICS, ANALYTICS, EASE_OF_USE, EASE_OF_USE_BG, ENHANCED_USER,
    ENHANCED_USER_BG, LOGISTICS_2, LOGISTICS_CARD_BG, LOGISTICS_ICON_1, MARKETING, MORDEN_BG_1, OMNICHANNEL_MARKTING,
    USE_CASE_1,
    USE_CASE_1_ICON,
    USE_CASE_2,
    USE_CASE_3,
    USE_CASE_3_ICON,
    USE_CASE_4,
    USE_CASE_4_ICON,
    USE_CASE_5
} from "../assets/images";

export const retail_realtime_cards = [
    {
        content: "We initiated an integrated marketing strategy that seamlessly connected the client’s online store, mobile app, and physical outlets, creating a unified brand experience. Customers could access real-time stock information and receive personalized offers based on their shopping history and preferences.",
        title: "Omnichannel Marketing",
        cardBg: OMNICHANNEL_MARKTING,
        cardIcon: MARKETING
    },
    {
        content: "By deploying sophisticated analytics tools, we were able to glean insights into customer behavior, such as preferred shopping times and buying patterns. This information was used to optimize marketing campaigns and inventory distribution, enhancing the effectiveness of promotional efforts.",
        title: "Advanced Analytics",
        cardBg: ADVANCE_ANALYTICS,
        cardIcon: ANALYTICS
    },
    {
        content: "We overhauled the website's user interface, focusing on usability and speed. Improvements included a simplified checkout process, faster loading times, and more intuitive navigation, which collectively worked to reduce bounce rates and abandoned carts.",
        title: "Enhanced User Experience",
        cardBg: ENHANCED_USER_BG,
        cardIcon: ENHANCED_USER
    },
    {
        content: "The implementation of features like one-click ordering, easy access to order history, and streamlined customer service interactions made the online platform much more user-friendly, encouraging repeat visits and increasing customer loyalty.",
        title: "Ease of Use",
        cardBg: EASE_OF_USE_BG,
        cardIcon: EASE_OF_USE
    },
];

export const usecase_cards = [
    {
        content: "We implemented an integrated learning strategy that connected their learning management system with administrative operations, ensuring real-time updates and seamless coordination. This allowed students to track their progress and receive timely feedback.",
        title: "Integrated Learning",
        cardBg: USE_CASE_1,
        cardIcon: USE_CASE_1_ICON
    },
    {
        content: "By deploying sophisticated analytics tools, we gained insights into student performance, engagement patterns, and learning preferences. This data was used to optimize course content, improve learning outcomes, and enhance overall educational efficiency.",
        title: "Advanced Analytics",
        cardBg: USE_CASE_2,
        cardIcon: ANALYTICS
    },
    {
        content: "We developed an omnichannel marketing strategy that unified communication channels, allowing students to receive updates via their preferred platforms. Personalized marketing campaigns and responsive student support improved engagement and loyalty.",
        title: "Omnichannel Marketing",
        cardBg: USE_CASE_3,
        cardIcon: USE_CASE_3_ICON
    },
    {
        content: "We revamped their digital interface, focusing on usability and speed. Enhancements included a streamlined enrollment process, faster load times, and intuitive navigation, which collectively reduced operational bottlenecks and improved user satisfaction.",
        title: "Enhanced User Experience",
        cardBg: USE_CASE_4,
        cardIcon: USE_CASE_4_ICON
    },
    {
        content: "Features such as one-click enrollment, easy access to course materials, and responsive student support interactions made the digital platform more user-friendly, fostering repeat enrollments and increasing student loyalty.",
        title: "Ease of Use",
        cardBg: USE_CASE_5,
        cardIcon: EASE_OF_USE
    },
];

export const logistics_cards = [
    {
        content: "We rolled out an integrated logistics strategy that synchronized their fleet management system with warehouse operations. This ensured real-time updates and seamless coordination, allowing customers to track shipments and receive accurate delivery estimates.",
        title: "Integrated Logistics",
        cardBg: LOGISTICS_CARD_BG,
        cardIcon: LOGISTICS_ICON_1
    },
    {
        content: "By implementing sophisticated analytics tools, we obtained insights into fleet performance, delivery patterns, and customer preferences. This data was instrumental in optimizing routes, enhancing delivery times, and improving overall service efficiency.",
        title: "Advanced Analytics",
        cardBg: LOGISTICS_2,
        cardIcon: ANALYTICS
    },
    {
        content: "We created an omnichannel marketing strategy that unified communication channels, enabling customers to receive updates via their preferred platforms. Personalized marketing campaigns and responsive customer support significantly boosted engagement and loyalty.",
        title: "Omnichannel Marketing",
        cardBg: USE_CASE_3,
        cardIcon: USE_CASE_3_ICON
    },
    {
        content: "We redesigned their digital interface to focus on usability and speed. Improvements included a streamlined booking process, faster load times, and intuitive navigation, which collectively reduced operational bottlenecks and enhanced customer satisfaction.",
        title: "Enhanced User Experience",
        cardBg: USE_CASE_4,
        cardIcon: USE_CASE_4_ICON
    },
    {
        content: "Features such as one-click booking, easy access to shipment history, and responsive customer support interactions made the digital platform more user-friendly, fostering repeat business and increasing customer loyalty.",
        title: "Ease of Use",
        cardBg: USE_CASE_5,
        cardIcon: EASE_OF_USE
    },
];