import React, { useEffect } from "react";
import Newnavigation from "../../components/new-navigation/new_navigation";
import PageBanner from "../../components/page-banner/page-banner";
import ContactNew from "../../components/contact-form/ContactNew";
import Footer from "../../components/footer/Footer";
import { InsuranceWhatwedo, InsuranceOurMissons, KeyserviceInsurance, InsuranceAccord } from "../../utils/insurance";
import PageSmallBanner from "../../components/page-small-banner/page-small-banner";
import KeyServices from "../../components/key-services/KeyServices";
import OurMissions from "../../components/Our-missions/OurMissions";
import ComAccord from "../../components/Accordian/ComAccord";
import WhatweDo from "../../components/what-we-do-img/whatweDo";
import { INSURANCE_BG, BG7 } from "../../assets/images/index";
import { motion } from "framer-motion";
import { InsuranceSeo } from "../../utils/Seo";
import HeaderSeo from "../../components/HeaderSeo/Headerseo";

const Insurance = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
    <div>
      <HeaderSeo data={InsuranceSeo}/>
      <Newnavigation routeName="Insurance" />
      <PageBanner
        bg={INSURANCE_BG}
        title="Transform Your Insurance Business with Techno Genesis"
        contact="Contact Us"
      />
      <WhatweDo data={InsuranceWhatwedo} />
      <OurMissions data={InsuranceOurMissons} />
      <KeyServices data={KeyserviceInsurance} heading={"Key Services"} />
      <div className="mt-100 mb-50">
        <PageSmallBanner
          isBtn={true}
          btn_text="Contact Us"
          title="Elevate your insurance business today with Techno Genesis. Our goal is to empower you with the right technological tools, ensuring efficient operations, and delivering enhanced customer experiences."
          bg={BG7}
          height="357px"
        />
      </div>
      <div className="mb-50">
        <ComAccord data={InsuranceAccord} />
      </div>
      <ContactNew />
      <Footer />
    </div>
    </motion.div>
  );
};

export default Insurance;
