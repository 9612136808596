import React from 'react'

const BlackLogo = () => {
  return (

    // technowhitecolor
    <svg className='logo' xmlns="http://www.w3.org/2000/svg" width="736" height="214" viewBox="0 0 735 214" fill="none">
<g clip-path="url(#clip0_522_207)">
<mask id="mask0_522_207" maskUnits="userSpaceOnUse" x="0" y="0" width="736" height="214">
<path d="M734.465 0H0.464844V214H734.465V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_522_207)">
<path d="M692.41 19.2463V4.51029H687.442V2.44629H699.898V4.51029H694.954V19.2463H692.41Z" fill="white"/>
<path d="M701.198 19.2463V2.44629H704.198L709.886 13.6303L715.55 2.44629H718.55V19.2463H716.006V6.83829L710.87 16.8463H708.902L703.742 6.86229V19.2463H701.198Z" fill="white"/>
<path d="M325.353 92.2455V15.8295H299.241V2.64551H367.977V15.8295H341.737V92.2455H325.353Z" fill="white"/>
<path d="M390.118 93.7817C383.718 93.7817 378.044 92.4164 373.094 89.6857C368.145 86.9551 364.262 83.1151 361.446 78.1657C358.63 73.2164 357.222 67.4991 357.222 61.0137C357.222 54.4431 358.588 48.5977 361.318 43.4777C364.134 38.3577 367.974 34.3897 372.838 31.5737C377.788 28.6724 383.59 27.2217 390.246 27.2217C396.476 27.2217 401.98 28.5871 406.758 31.3177C411.537 34.0484 415.249 37.8031 417.894 42.5817C420.625 47.2751 421.99 52.5231 421.99 58.3257C421.99 59.2644 421.948 60.2457 421.862 61.2697C421.862 62.2937 421.82 63.3604 421.734 64.4697H373.478C373.82 69.4191 375.526 73.3017 378.598 76.1177C381.756 78.9337 385.553 80.3417 389.99 80.3417C393.318 80.3417 396.092 79.6164 398.31 78.1657C400.614 76.6297 402.321 74.6671 403.43 72.2777H420.07C418.876 76.2884 416.87 79.9577 414.054 83.2857C411.324 86.5284 407.91 89.0884 403.814 90.9657C399.804 92.8431 395.238 93.7817 390.118 93.7817ZM390.246 40.5337C386.236 40.5337 382.694 41.6857 379.622 43.9897C376.55 46.2084 374.588 49.6217 373.734 54.2297H405.35C405.094 50.0484 403.558 46.7204 400.742 44.2457C397.926 41.7711 394.428 40.5337 390.246 40.5337Z" fill="white"/>
<path d="M457.914 93.7817C451.429 93.7817 445.712 92.3737 440.762 89.5577C435.813 86.7417 431.888 82.8164 428.986 77.7817C426.17 72.7471 424.762 66.9871 424.762 60.5017C424.762 54.0164 426.17 48.2564 428.986 43.2217C431.888 38.1871 435.813 34.2617 440.762 31.4457C445.712 28.6297 451.429 27.2217 457.914 27.2217C466.021 27.2217 472.848 29.3551 478.394 33.6217C483.941 37.8031 487.482 43.6057 489.018 51.0297H471.738C470.885 47.9577 469.178 45.5684 466.618 43.8617C464.144 42.0697 461.2 41.1737 457.786 41.1737C453.264 41.1737 449.424 42.8804 446.266 46.2937C443.109 49.7071 441.53 54.4431 441.53 60.5017C441.53 66.5604 443.109 71.2964 446.266 74.7097C449.424 78.1231 453.264 79.8297 457.786 79.8297C461.2 79.8297 464.144 78.9764 466.618 77.2697C469.178 75.5631 470.885 73.1311 471.738 69.9737H489.018C487.482 77.1417 483.941 82.9017 478.394 87.2537C472.848 91.6057 466.021 93.7817 457.914 93.7817Z" fill="white"/>
<path d="M494.6 92.2459V0.0859375H510.984V38.4859C513.118 34.9873 515.976 32.2566 519.56 30.2939C523.23 28.2459 527.411 27.2219 532.104 27.2219C539.955 27.2219 546.014 29.6966 550.28 34.6459C554.632 39.5953 556.808 46.8486 556.808 56.4059V92.2459H540.552V57.9419C540.552 52.4806 539.443 48.2993 537.224 45.3979C535.091 42.4966 531.678 41.0459 526.984 41.0459C522.376 41.0459 518.536 42.6673 515.464 45.9099C512.478 49.1526 510.984 53.6753 510.984 59.4779V92.2459H494.6Z" fill="white"/>
<path d="M563.515 92.2457V28.7577H577.979L579.259 39.5097C581.222 35.7551 584.038 32.7684 587.707 30.5497C591.462 28.3311 595.857 27.2217 600.891 27.2217C608.742 27.2217 614.843 29.6964 619.195 34.6457C623.547 39.5951 625.723 46.8484 625.723 56.4057V92.2457H609.339V57.9417C609.339 52.4804 608.23 48.2991 606.011 45.3977C603.793 42.4964 600.337 41.0457 595.643 41.0457C591.035 41.0457 587.238 42.6671 584.251 45.9097C581.35 49.1524 579.899 53.6751 579.899 59.4777V92.2457H563.515Z" fill="white"/>
<path d="M662.894 93.7817C656.75 93.7817 651.204 92.3737 646.254 89.5577C641.39 86.7417 637.508 82.8591 634.606 77.9097C631.79 72.8751 630.382 67.0724 630.382 60.5017C630.382 53.9311 631.833 48.1711 634.734 43.2217C637.636 38.1871 641.518 34.2617 646.382 31.4457C651.332 28.6297 656.878 27.2217 663.022 27.2217C669.081 27.2217 674.542 28.6297 679.406 31.4457C684.356 34.2617 688.238 38.1871 691.054 43.2217C693.956 48.1711 695.406 53.9311 695.406 60.5017C695.406 67.0724 693.956 72.8751 691.054 77.9097C688.238 82.8591 684.356 86.7417 679.406 89.5577C674.457 92.3737 668.953 93.7817 662.894 93.7817ZM662.894 79.5737C667.161 79.5737 670.873 77.9951 674.03 74.8377C677.188 71.5951 678.766 66.8164 678.766 60.5017C678.766 54.1871 677.188 49.4511 674.03 46.2937C670.873 43.0511 667.204 41.4297 663.022 41.4297C658.67 41.4297 654.916 43.0511 651.758 46.2937C648.686 49.4511 647.15 54.1871 647.15 60.5017C647.15 66.8164 648.686 71.5951 651.758 74.8377C654.916 77.9951 658.628 79.5737 662.894 79.5737Z" fill="white"/>
<path d="M343.657 213.782C335.209 213.782 327.828 211.862 321.513 208.022C315.199 204.182 310.292 198.849 306.793 192.022C303.295 185.11 301.545 177.089 301.545 167.958C301.545 158.742 303.337 150.635 306.921 143.638C310.591 136.555 315.753 131.051 322.409 127.126C329.151 123.115 337.129 121.11 346.345 121.11C356.841 121.11 365.545 123.627 372.457 128.662C379.369 133.697 383.849 140.651 385.897 149.526H367.593C366.228 145.345 363.753 142.102 360.169 139.798C356.585 137.409 351.977 136.214 346.345 136.214C337.385 136.214 330.473 139.073 325.609 144.79C320.745 150.422 318.313 158.187 318.313 168.086C318.313 177.985 320.703 185.665 325.481 191.126C330.345 196.502 336.916 199.19 345.193 199.19C353.3 199.19 359.401 197.014 363.497 192.662C367.679 188.225 370.196 182.422 371.049 175.254H348.393V162.966H387.561V212.246H372.457L371.177 200.47C368.276 204.737 364.649 208.022 360.297 210.326C355.945 212.63 350.399 213.782 343.657 213.782Z" fill="white"/>
<path d="M424.993 213.782C418.593 213.782 412.919 212.417 407.969 209.686C403.02 206.955 399.137 203.115 396.321 198.166C393.505 193.217 392.097 187.499 392.097 181.014C392.097 174.443 393.463 168.598 396.193 163.478C399.009 158.358 402.849 154.39 407.713 151.574C412.663 148.673 418.465 147.222 425.121 147.222C431.351 147.222 436.855 148.587 441.633 151.318C446.412 154.049 450.124 157.803 452.769 162.582C455.5 167.275 456.865 172.523 456.865 178.326C456.865 179.265 456.823 180.246 456.737 181.27C456.737 182.294 456.695 183.361 456.609 184.47H408.353C408.695 189.419 410.401 193.302 413.473 196.118C416.631 198.934 420.428 200.342 424.865 200.342C428.193 200.342 430.967 199.617 433.185 198.166C435.489 196.63 437.196 194.667 438.305 192.278H454.945C453.751 196.289 451.745 199.958 448.929 203.286C446.199 206.529 442.785 209.089 438.689 210.966C434.679 212.843 430.113 213.782 424.993 213.782ZM425.121 160.534C421.111 160.534 417.569 161.686 414.497 163.99C411.425 166.209 409.463 169.622 408.609 174.23H440.225C439.969 170.049 438.433 166.721 435.617 164.246C432.801 161.771 429.303 160.534 425.121 160.534Z" fill="white"/>
<path d="M461.685 212.246V148.758H476.149L477.429 159.51C479.392 155.755 482.208 152.769 485.877 150.55C489.632 148.331 494.027 147.222 499.061 147.222C506.912 147.222 513.013 149.697 517.365 154.646C521.717 159.595 523.893 166.849 523.893 176.406V212.246H507.509V177.942C507.509 172.481 506.4 168.299 504.181 165.398C501.963 162.497 498.507 161.046 493.813 161.046C489.205 161.046 485.408 162.667 482.421 165.91C479.52 169.153 478.069 173.675 478.069 179.478V212.246H461.685Z" fill="white"/>
<path d="M561.448 213.782C555.048 213.782 549.374 212.417 544.424 209.686C539.475 206.955 535.592 203.115 532.776 198.166C529.96 193.217 528.552 187.499 528.552 181.014C528.552 174.443 529.918 168.598 532.648 163.478C535.464 158.358 539.304 154.39 544.168 151.574C549.118 148.673 554.92 147.222 561.576 147.222C567.806 147.222 573.31 148.587 578.088 151.318C582.867 154.049 586.579 157.803 589.224 162.582C591.955 167.275 593.32 172.523 593.32 178.326C593.32 179.265 593.278 180.246 593.192 181.27C593.192 182.294 593.15 183.361 593.064 184.47H544.808C545.15 189.419 546.856 193.302 549.928 196.118C553.086 198.934 556.883 200.342 561.32 200.342C564.648 200.342 567.422 199.617 569.64 198.166C571.944 196.63 573.651 194.667 574.76 192.278H591.4C590.206 196.289 588.2 199.958 585.384 203.286C582.654 206.529 579.24 209.089 575.144 210.966C571.134 212.843 566.568 213.782 561.448 213.782ZM561.576 160.534C557.566 160.534 554.024 161.686 550.952 163.99C547.88 166.209 545.918 169.622 545.064 174.23H576.68C576.424 170.049 574.888 166.721 572.072 164.246C569.256 161.771 565.758 160.534 561.576 160.534Z" fill="white"/>
<path d="M624.38 213.782C618.748 213.782 613.799 212.886 609.532 211.094C605.266 209.217 601.852 206.657 599.292 203.414C596.732 200.171 595.196 196.417 594.684 192.15H611.196C611.708 194.625 613.074 196.758 615.292 198.55C617.596 200.257 620.54 201.11 624.124 201.11C627.708 201.11 630.311 200.385 631.932 198.934C633.639 197.483 634.492 195.819 634.492 193.942C634.492 191.211 633.298 189.377 630.908 188.438C628.519 187.414 625.191 186.433 620.924 185.494C618.194 184.897 615.42 184.171 612.604 183.318C609.788 182.465 607.186 181.398 604.796 180.118C602.492 178.753 600.615 177.046 599.164 174.998C597.714 172.865 596.988 170.262 596.988 167.19C596.988 161.558 599.207 156.822 603.644 152.982C608.167 149.142 614.482 147.222 622.588 147.222C630.098 147.222 636.071 148.971 640.508 152.47C645.031 155.969 647.719 160.79 648.572 166.934H633.084C632.146 162.241 628.604 159.894 622.46 159.894C619.388 159.894 616.999 160.491 615.292 161.686C613.671 162.881 612.86 164.374 612.86 166.166C612.86 168.043 614.098 169.537 616.572 170.646C619.047 171.755 622.332 172.779 626.428 173.718C630.866 174.742 634.919 175.894 638.588 177.174C642.343 178.369 645.33 180.203 647.548 182.678C649.767 185.067 650.876 188.523 650.876 193.046C650.962 196.971 649.938 200.513 647.804 203.67C645.671 206.827 642.599 209.302 638.588 211.094C634.578 212.886 629.842 213.782 624.38 213.782Z" fill="white"/>
<path d="M665.262 138.902C662.276 138.902 659.801 138.006 657.838 136.214C655.961 134.422 655.022 132.161 655.022 129.43C655.022 126.699 655.961 124.481 657.838 122.774C659.801 120.982 662.276 120.086 665.262 120.086C668.249 120.086 670.681 120.982 672.558 122.774C674.521 124.481 675.502 126.699 675.502 129.43C675.502 132.161 674.521 134.422 672.558 136.214C670.681 138.006 668.249 138.902 665.262 138.902ZM657.07 212.246V148.758H673.454V212.246H657.07Z" fill="white"/>
<path d="M707.835 213.782C702.203 213.782 697.254 212.886 692.987 211.094C688.721 209.217 685.307 206.657 682.747 203.414C680.187 200.171 678.651 196.417 678.139 192.15H694.651C695.163 194.625 696.529 196.758 698.747 198.55C701.051 200.257 703.995 201.11 707.579 201.11C711.163 201.11 713.766 200.385 715.387 198.934C717.094 197.483 717.947 195.819 717.947 193.942C717.947 191.211 716.753 189.377 714.363 188.438C711.974 187.414 708.646 186.433 704.379 185.494C701.649 184.897 698.875 184.171 696.059 183.318C693.243 182.465 690.641 181.398 688.251 180.118C685.947 178.753 684.07 177.046 682.619 174.998C681.169 172.865 680.443 170.262 680.443 167.19C680.443 161.558 682.662 156.822 687.099 152.982C691.622 149.142 697.937 147.222 706.043 147.222C713.553 147.222 719.526 148.971 723.963 152.47C728.486 155.969 731.174 160.79 732.027 166.934H716.539C715.601 162.241 712.059 159.894 705.915 159.894C702.843 159.894 700.454 160.491 698.747 161.686C697.126 162.881 696.315 164.374 696.315 166.166C696.315 168.043 697.553 169.537 700.027 170.646C702.502 171.755 705.787 172.779 709.883 173.718C714.321 174.742 718.374 175.894 722.043 177.174C725.798 178.369 728.785 180.203 731.003 182.678C733.222 185.067 734.331 188.523 734.331 193.046C734.417 196.971 733.393 200.513 731.259 203.67C729.126 206.827 726.054 209.302 722.043 211.094C718.033 212.886 713.297 213.782 707.835 213.782Z" fill="white"/>
<path d="M26.2779 85.8613H201.013L175.439 128.628H0.703125L26.2779 85.8613Z" fill="#3A65FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M161.733 43.2153C175.561 43.2153 188.365 47.5984 198.832 55.0512L225.766 22.9855C207.988 9.45477 185.799 1.42188 161.733 1.42188C120.154 1.42187 84.1746 25.4023 66.8717 60.2871H118.203C129.627 49.6924 144.924 43.2153 161.733 43.2153ZM66.8662 154.203C84.1682 189.09 120.149 213.072 161.73 213.072C220.177 213.072 267.557 165.691 267.557 107.245L225.763 107.246C225.763 142.61 197.095 171.279 161.73 171.279C144.919 171.279 129.62 164.8 118.195 154.203H66.8662Z" fill="#3A65FF"/>
</g>
</g>
<defs>
<clipPath id="clip0_522_207">
<rect width="734" height="214" fill="white" transform="translate(0.464844)"/>
</clipPath>
</defs>
</svg>
    
  
  
    
  )
}

export default BlackLogo