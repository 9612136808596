import React, { useEffect, useState } from "react";
import {E_COMMERCE, RIGHT_ARROW , MARKET, JEWELLERY,FINTECH,CASH_MANAGEMENT , HEALTH_CARE, REAL_WORLD_1, REAL_WORLD_2, REAL_WORLD_3, REAL_WORLD_4, REAL_WORLD_5, REAL_WORLD_6 } from '../../assets/images/index'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import './RealWorld.css'
import { Link, useNavigate } from 'react-router-dom'
import { Fade } from "react-awesome-reveal";
import { MdArrowOutward } from "react-icons/md";
// const navigate = useNavigate()



 

const RealWorld = () => {
    const navigate = useNavigate()

      const cardInfo = [
        {
            title: "E-Commerce",
      content:"E-commerce platform faced challenges as maintaining a smooth vendor..",
      link:"Read Case Study",
      icon:RIGHT_ARROW,
      image:E_COMMERCE,
      link_navigate:'e-commerce'
          },
        {
            title: "Share Market",
      content:"Share Market Platform, faced challenges in executing trades efficiently..",
      link:"Read Case Study",
      icon:RIGHT_ARROW,
      image:MARKET,
      link_navigate:'share-market'
          },
          {
            title: "Jewellery Manufacturing",
      content:"The jewellery manufacturing unit faced challenges in maintaining consistent..",
      link:"Read Case Study",
      icon:RIGHT_ARROW,
      image:JEWELLERY,
      link_navigate:'jewllwery-manufacturing'
          },
          {
            title: "Fintech",
      content:"The Financial sought to enhance transparency and traceability in their payout processes..",
      link:"Read Case Study",
      icon:RIGHT_ARROW,
      image:FINTECH,
      link_navigate:'finance'
          },
          {
            title: "Case Management",
      content:"The firm relied heavily on manual processes, and ensuring effective communication ..",
      link:"Read Case Study",
      icon:RIGHT_ARROW,
      image:CASH_MANAGEMENT,
      link_navigate:'case-management'
          },
          {
            title: "Healthcare",
      content:"They struggled with managing paper-based medical records, which led to inefficiencies..",
      link:"Read Case Study",
      icon:RIGHT_ARROW,
      image:HEALTH_CARE,
      link_navigate:'healthcare'
          },


      ];

      const RowOne = [
        {
          title : "Finance",
          content: "A leading finance company addressed a various aspects of personal finance including spent analysis, budget allocation, cash flow management, receipt tracking and managing credit card.",
          image: REAL_WORLD_1,
          link:"/work/case-study/finance"
        },
        {
          title : "Share Market",
          content: "A prominent share market platform, catering to a diverse range of investors, sought to overcome challenges in executing trades efficiently, managing client portfolios, and providing real-time market insights.",
          image: REAL_WORLD_2,
          link:"/work/case-study/share-market"
        },
        
        {
          title : "E-Commerce",
          content: "The success of an e-commerce platform depends not only on its technical aspects but also on effective marketing, customer service, and continuous improvement based on user feedback and market trends.",
          image: REAL_WORLD_3,
          link:"/work/case-study/e-commerce"
        },
        

      ]
      const RowTwo = [
        {
          title : "Healthcare",
          content: "A Healthcare Organization modernized healthcare records, enhancing patient care coordination, data accessibility, and patient engagement, ultimately improving patient outcomes and accessibility.",
          image: REAL_WORLD_4,
          link:"/work/case-study/healthcare"
        },
        {
          title : "Legal",
          content: "An established Case Management firm streamlined operations with digital solutions, improving case tracking, document management, communication, and user prioritization, resulting in enhanced efficiency and productivity..",
          image: REAL_WORLD_5,
          link:"/work/case-study/case-management"
        },
        
        {
          title : "Manufacturing",
          content: "A jewelry manufacturing unit tackled challenges in quality control, vendor onboarding, and defect identification. Streamlined processes led to consistent quality and improved efficiency.",
          image: REAL_WORLD_6,
          link:"/work/case-study/jewellery-manufacturing"
        },
        

      ]

      const [firsindex, setFirstindex] = useState()

      const [secondIndex, setSecondtindex] = useState();

      const handleHover = (index) =>{

        setFirstindex(index)
     


      }

      const handleHoverSecond = (index) =>{

     
        setSecondtindex(index)


      }



      useEffect(()=>{

        setFirstindex(0)
        setSecondtindex(2)

      },[])



   
      return (
        
        <div className="box" style={{ marginBottom: "5%" }}>
          <Container className="mt-80">
    


            <div className=''>
            <h1 className='product-title'>   Discover Our Real-World Scenarios</h1>
            <p className='product-subtitle'>Case studies</p>
         </div>
         <div className="new_real_world_case_row_one">
              <div className="new_real_world_slides">
                
                {
                    RowOne.map((item,index)=>(

                      <div key={index} className= { index === firsindex ? " new_real_world_slide expand" : " new_real_world_slide"  }  onMouseOver={()=>handleHover(index)}> 

                      <div className="new_real_world_slide_content">
                        <div className="new_real_world_slide_content_main">

                          <div className="new_real_world_slide_content_main_text">
                            <h2>{item?.title}</h2>
                            <p>{item?.content}</p>
                            

                          </div>
                          <div className="new_real_footer">
                            <Link to={item?.link}>View Case Study</Link>
                             <MdArrowOutward className="new_real_footer_icon" />
                          </div>

                          
                       

                        </div>
                        <div className="new_real_world_slide_content_image">

                          <img src={item?.image} alt={item?.title} />
                          
                          </div>

                      </div>
                      
                      </div>

                    ))
                }



              </div>
            </div>

            <div className="new_real_world_case_row_two">
              <div className="new_real_world_slides">
                
                {
                    RowTwo.map((item,index)=>(

                      <div key={index} className= { index === secondIndex ? " new_real_world_slide expand" : " new_real_world_slide"  }  onMouseOver={()=>handleHoverSecond(index)}> 

                      <div className="new_real_world_slide_content">
                        <div className="new_real_world_slide_content_main">

                          <div className="new_real_world_slide_content_main_text">
                            <h2>{item?.title}</h2>
                            <p>{item?.content}</p>
                            

                          </div>
                          <div className="new_real_footer">
                            <Link to={item?.link}>View Case Study</Link>
                             <MdArrowOutward className="new_real_footer_icon" />
                          </div>

                          
                       

                        </div>
                        <div className="new_real_world_slide_content_image">

                          <img src={item?.image} alt={item?.title} />
                          
                          </div>

                      </div>
                      
                      </div>

                    ))
                }



              </div>
            </div>




          </Container>
        </div>
      
      );

}

export default RealWorld;




//  old design 

{/* <Row>
              {cardInfo.map((cardInfo, index) => {
                // card section
                return (
                  <Col sm={12} md={6} lg={4} key={index}>
                    <Fade direction='up' duration={0} cascade  >
                    <Card className="position-relative realworld_card">                      
                      <Card.Body>
                    
                    <div className='realworld'>
                           <h1>{cardInfo.title}</h1>
                              <p>{cardInfo.content}</p>
                              <a className="realworld_hover_active" onClick={()=>navigate(`/case-study/${cardInfo.link_navigate}`)}  >Read Case Study  <img className='icon' src={cardInfo.icon} alt="img" />  </a>
                              <img className='realworld_img_style' src={cardInfo.image} alt="img" /> 
                           </div>
                      </Card.Body>
                    </Card>
                    </Fade>
                  </Col>
                );
              })}
            </Row> */}