import React, { useEffect } from 'react';
import { motion } from "framer-motion";
import { DigitalSeo, ServicesSeo } from '../../utils/Seo';
import HeaderSeo from '../../components/HeaderSeo/Headerseo';
import Newnavigation from '../../components/new-navigation/new_navigation';
import ServiceBanner from '../../components/service-banner/ServiceBanner';
import ContactNew from '../../components/contact-form/ContactNew';
import Footer from '../../components/footer/Footer';
import { DIGITAL_BG } from '../../assets/images';
import { DigitalServicesBanner } from '../../utils/servicebanner';
import AboutusBanner from '../../components/Aboutus-banner/AboutusBanner';
import ServiceSteps from '../../components/service-steps/ServiceSteps';
import { DigitalKeyService, DigitalServiceOurMissons, digitalstepsdata } from '../../utils/services';
import OurMissions from '../../components/Our-missions/OurMissions';
import ServicesKey from '../../components/services-key/ServicesKey';

const DigitalTransfer = () => {
    useEffect(() => {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }, []);
      return (
        <motion.div initial={{ opacity: 0 }}animate={{ opacity: 1, transition: { duration: 1 } }}>
        <div className='service-page '>
            <HeaderSeo data={DigitalSeo}/>
            <Newnavigation routeName="services"/>
            <AboutusBanner bg={DIGITAL_BG} data={DigitalServicesBanner}/>
            <ServiceSteps 
            title="Enhance Scalability, Improve Reliability, and Secure Your IT Environment"
            stepsData={digitalstepsdata}
             />
              <OurMissions  data={DigitalServiceOurMissons} />
              <ServicesKey data={DigitalKeyService} />
    
              
            <ContactNew />
            <Footer />
        </div>
        </motion.div>
    )
}

export default DigitalTransfer