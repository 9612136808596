import { PROG_MANAGE_1, PROG_MANAGE_2, PROG_MANAGE_3, PROG_MANAGE_4, TECH_1, TECH_2, TECH_3, TECH_4} from '../assets/images';
export const PMO_heading = [
    {
        title: "Program Management"      
    },
    {
        title: "Outsourcing(PMO)"
    },
  ];
  export const CoreServices = [
    {
  
    title : "PMO Consulting and Assessment",
    content :"Our PMO consulting services provide a thorough assessment of your current project management capabilities, identifying areas for improvement and opportunities for outsourcing.",
    image: PROG_MANAGE_1,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Comprehensive PMO assessment'},
      {items: 'Strategic outsourcing recommendations'},
      {items: 'Customized PMO solutions'}
    ],
    button_name: "Schedule a PMO Consultation Today"
},
{
  
    title : "PMO Implementation and Setup",
    content :"We design and implement tailored PMO solutions that align with your business objectives. Our implementation services ensure seamless integration and optimal performance of the PMO function.",
    image: PROG_MANAGE_2,
    keytitile: "Technologies Used",
    Key_technologies: [
      {items: 'Project Management Tools (Microsoft Project, Jira)'},
      {items: 'Resource Management Software'},
      {items: 'Risk Management Platforms'}
    ],
    button_name: "Seamlessly Integrate PMO Solutions",
    className: "row-reverse"
},
{
   title : "Ongoing PMO Management and Support",
    content: "Our ongoing management and support services ensure that your PMO continues to deliver value. We provide continuous monitoring, performance tuning, and updates to keep your PMO optimized.",
    image: PROG_MANAGE_3,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Continuous PMO support and management'},
      {items: 'Performance monitoring and optimization'},
      {items: 'Regular updates and process improvements'}
    ],
    button_name: "Ensure PMO Efficiency with Our Support Services",
},
{
  title : "Training and Development",
   content: "We offer training and development programs to upskill your internal teams on PMO best practices and tools, ensuring they can effectively collaborate with the outsourced PMO.",
   image: PROG_MANAGE_4,
   keytitile: "Key Benefits",
   Key_technologies: [
     {items: 'Enhanced project management skills'},
     {items: 'Better collaboration with outsourced PMO'},
     {items: 'Continuous professional development'}
   ],
   button_name: "Enhance Your Team’s PMO Skills",
    className: "row-reverse"
}
]
export const WhyTechno = [
  {
    title: "Expertise and Experience",
    content: "Our team comprises seasoned project management professionals with extensive experience in managing complex projects across various industries. We use our expertise to provide you with customised PMO solutions that meet the specific needs of your business.",
    serialno: "01"
  },
  {
    title: "Customer-Centric Approach",
    content: "We focus on understanding your business goals and delivering PMO solutions that drive success through effective project management and strategic alignment.",
    serialno: "02"
  },
  {
    title: "Cutting-Edge Technology",
    content: "We utilize the latest project management tools and methodologies to provide innovative PMO solutions that enhance efficiency and effectiveness.",
    serialno: "03"
  },
 
];
export const TestimonialData = [
  {
      content: '“ Techno Genesis has helped us to significantly improve how we deliver projects by providing PMO Services. Their expertise and support have allowed us to complete projects on time and within budget.”',
      client: 'Client A'
  }
]
  export const PMOAccord = [
    {
      id:1,
      accHead:"What is Program Management Outsourcing (PMO)?",
      accContent:"We offer comprehensive data mining and CRM solutions, including consulting, implementation, and ongoing support."
  
    },
    {
      id:2,
      accHead:"How can your PMO services benefit my business?",
      accContent:"Our PMO services help you access expertise, scale efficiently, reduce costs, ensure consistency, and enhance focus on core activities."
  
    },
    {
      id:3,
      accHead: "What technologies do you use for PMO implementation?",
      accContent:"We use project management tools like Microsoft Project and Jira, resource management software, and risk management platforms."
    },
    {
      id:4,
      accHead: "Do you provide ongoing support for PMO services?",
      accContent:"Yes, we offer continuous support and management to ensure the smooth operation and optimization of your PMO."
    },
    {
      id:5,
      accHead: "What sets you apart from other PMO service providers?",
      accContent: "Our expertise, customer-centric approach, and use of cutting-edge technologies set us apart in the industry."
    }
  ]