import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import './notfound.css';
import { NotFundImg } from '../../assets/images';

const NotFound = () => {

  return (
    <div className='nimaalan-not-found'>
      
      <Logo />
      <img src={NotFundImg} alt="404 not found" />
      
      <div className="back-to-home">
        <Link to="/" className="btn-back-home btn-primary">Back to Home</Link>
      </div>
    </div>
  );
};

export default NotFound;
