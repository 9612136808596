import { DIGI_SOLUTIONS_1, DIGI_SOLUTIONS_2, DIGI_SOLUTIONS_3, DIGI_SOLUTIONS_4, LOGISTICS_BG_1, LOGISTICS_BG_2, LOGISTICS_BG_3, LOGISTICS_BG_4, MORDEN_BG_1, MORDEN_BG_2, MORDEN_BG_3, OMNICHANNEL, SEAMLESS, VIRTUAL_STORES } from "../assets/images";

export const awardwinning_title = [
    {
        title: "Human-Centric Strategies for",
        title_2: "Omnichannel Engagement, Seamless Migration, and Immersive 3D Virtual Stores",
        content: "In the dynamic world of e-commerce, businesses constantly seek pioneering solutions that not only streamline operations but also enhance customer engagement. At the forefront of this innovation are omnichannel strategies, seamless migration processes, and immersive 3D virtual store experiences. This comprehensive case study delves into how businesses can integrate these advanced technologies to revolutionize their online presence, focusing on diverse product lines such as Food & Beverages, Food Delivery, Apparel & Footwear, Beauty & Personal Care, Healthcare & Medicines, and Jewelry.",
      
       
    },
  
  ]; 

  export  const awarwinning_bg = [
    {
        bgUrl: OMNICHANNEL,
        title: "Omnichannel Engagement",
      },
      {
        bgUrl: SEAMLESS,
        title: "Seamless Migration",
      },
      {
        bgUrl: VIRTUAL_STORES,
        title: "Immersive 3D Virtual Stores",
      },
]

export const modern_title = [
    {
        title: "Modern Strategies for",
        title_2: "Integrated Learning, Omnichannel Engagement, and Interactive Digital Experiences",
        content: "Our comprehensive solution package combines integrated learning systems, seamless technology migration, omnichannel marketing, and interactive digital tools to revolutionize educational experiences. This approach not only enhances operational efficiency but also significantly improves student engagement and learning outcomes.",
      
         
    },
  
  ]; 

  export  const modern_bg = [
    {
        bgUrl: MORDEN_BG_1,
        title: "Integrated Learning",
      },
      {
        bgUrl: MORDEN_BG_2,
        title: "Omnichannel Engagement",
      },
      {
        bgUrl: MORDEN_BG_3,
        title: "Interactive Digital Experiences",
      },
]

export const comprehensive_title = [
    {
        title: "Comprehensive Logistics Solutions:",
        title_2: " Integration, Migration, Omnichannel, and Interaction",
        content: "Our all-in-one solution integrates logistics systems, ensures seamless technology migration, employs omnichannel marketing, and utilizes interactive digital tools to revolutionize logistics operations. This multifaceted approach enhances operational efficiency and significantly boosts customer engagement and satisfaction.",
      
        
    },
  
  ]; 

  export  const comprehensive_bg = [
    {
        bgUrl: LOGISTICS_BG_1,
        title: "Integration",
      },
      {
        bgUrl: LOGISTICS_BG_2,
        title: "Migration",
      },
      {
        bgUrl: LOGISTICS_BG_3,
        title: "Omnichannel",
      },
      {
        bgUrl: LOGISTICS_BG_4,
        title: "Interaction",
      },
]

export const digitalsolution_title = [
  {
      title: "Digital Solutions for Pharma and Healthcare:",
      title_2: "Integration, Migration, Omnichannel, and Interaction",
      content: "Our all-in-one solution integrates logistics systems, ensures seamless technology migration, employs omnichannel marketing, and utilizes interactive digital tools to revolutionize logistics operations. This multifaceted approach enhances operational efficiency and significantly boosts customer engagement and satisfaction.",
    
      
  }, 

]; 

export  const digitalsolution_bg = [
  {
      bgUrl: DIGI_SOLUTIONS_1,
      title: "Integration",
    },
    {
      bgUrl: DIGI_SOLUTIONS_2,
      title: "Migration",
    },
    {
      bgUrl: DIGI_SOLUTIONS_3,
      title: "Omnichannel",
    },
    {
      bgUrl: DIGI_SOLUTIONS_4,
      title: "Interaction",
    },
]
