import React from 'react'
import { Row, Col, Container } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import "./RetailProgress.css";

const RetailProgress = ({ steps, heading }) => {
  console.log(steps, "dfghjklfdfghjkl")
  return (
    <>
      <Fade direction='up' duration={0}>
        <div className="mt-100">
          <div className="mt-50">
            <Container>
            <h2 className='section_title'>{heading}</h2>
              <Row>
                {steps.map((item, index) => {
                  return(
                    <Col className="our-core-col mt-3 p-0" lg={4} md={6} xs={12} key={index}>
                    <div className={index == 0 ? "our-core-card-one" : index == 1 ? "our-core-card-two" : index == 2 ? "our-core-card-third" : index == 3 ? "our-core-card-four" : ""}>
                      <div className="our-core-card-content">
                        <h1>{item.serialno}</h1>
                        <h2>{item.title}</h2>
                        <h3 className='pe-3'>{item.content}</h3>
                      </div>
                    </div>
                  </Col>
                  )
                }



                )}

              </Row>
            </Container>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default RetailProgress