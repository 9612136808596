export const HomeSeo = {
    title : "Home | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"

}

export const AboutSeo = {
    title : "About Us | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const WorkSeo = {
    title : "Case Studies | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const ServicesSeo = {
    title : "Services | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const CaseManagementSeo = {
    title : "Case-Management | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const CaseStudySeo = {
    title : "E-commerce | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const FinanceSeo = {
    title : "Finance | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const HealthcareSeo = {
    title : "Health-care | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const JewelSeo= {
    title : "Jewel | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const ShareMarketSeo = {
    title : "ShareMarket | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const BlockChainSeo = {
    title : "BlockChain | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const EducationSeo = {
    title : "Education | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const FintechSeo = {
    title : "Fintech | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const HealthCareSeo = {
    title : "HealthCare | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const InsuranceSeo = {
    title : "Insurance | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const LogisticsSeo = {
    title : "Logistics | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const TravelSeo = {
    title : "Travel | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const ExpertiseSeo = {
    title : "Expertise | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const Retail_Ecommerce = {
    title : "Retail & e-commerce | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const HealthCare_Pharam = {
    title : "Healthcare & Pharmaceutical | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const DigitalSeo = {
    title : "Digital Transformation | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const StrategySeo = {
    title : "Strategy and Architecture | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const BusinessSeo = {
    title : "Business Transformation | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}
export const EnterpriseSeo = {
    title : "Enterprise Processes | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}
export const ChangeSeo = {
    title : "Change and Learning | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}
export const GenerativeSeo = {
    title : "Generative AI | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const TransformingBusinessesSeo = {
    title : "Transforming Businesses with Innovative IT Solutions| Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}
export const ServiceSolutionsseo = {
    title : "Service-Oriented Architecture(SOA) Solutions| Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}
export const TransformingDataSeo = {
    title : " Business Intelligence Transforming Data into Actionable Insights | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}
export const WarehousingSeo = {
    title : "Comprehensive Data  Warehousing Solutions  | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}
export const MiningSeo = {
    title : "Comprehensive Data Mining and CRM Solutions  | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}
export const OptimizingSeo = {
    title : "UNIX & Microsoft Platforms Design | Optimizing Your IT Infrastructure  | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}


export const InformationTechnologySeo = {
    title : "Information Technology Outsourcing(ITO) | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const ProgramManagementSeo = {
    title : "Program Management  Outsourcing(PMO) | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}

export const BusinessProcessSeo = {
    title : "Business Process  Outsourcing(BPO) | Techno Genesis - Empowering businesses to thrive in the digital era", 
    keywords : "Propelling brands towards growth with a decade of technological expertise, exceptional experience, and groundbreaking innovation",
    description : "Transforming Businesses With Innovation"
}