import React from 'react';
import CountUp from 'react-countup';
import './Aboutbannercount.css';
import { Col, Container, Row } from 'react-bootstrap';

const Aboutbannercount = () => {
  const countDetail = [
    {
      count: '7',
      titile: 'Successful Years'
    },
    {
      count: '100',
      titile: 'Completed projects'
    },
    {
      count: '15',
      titile: 'Partner Networks'
    },
    {
      count: '100',
      titile: 'Clients'
    },
    {
      count: '20',
      titile: 'Active Projects'
    },
    {
      count: '50',
      titile: 'Employees'
    }
  ];

  return (
    <div className='count_section_about'>
      <Container>
        <Row>
          <Col lg={7}>
           <div className='about_content_main'>
            <p> <b>At Techno Genesis, we believe in the power of</b> <span> innovation to transform businesses and create extraordinary experiences.</span><b>  Our journey began with a simple mission: to revolutionize industries through cutting-edge technology and tailored solutions. Today, we stand as a beacon of digital excellence, serving diverse sectors like retail, fintech, travel and hospitality, healthcare, education, insurance, and blockchain technology.</b></p>

           </div>
          </Col>
          <Col lg={5}>
          <Row>
            {
              countDetail?.map((item, index)=>(
                <Col lg={6} md={4} key={index} xs={6}>
                  <div className='count_about'>
                     <h3>{item?.count}+</h3>
                     <p>{item?.titile}</p>

                  </div>

                </Col>
              ))
            }

          </Row>
          
          </Col>
        </Row>
      </Container>
      
    </div>
  );
};

export default Aboutbannercount;
