import React, { useEffect } from 'react'
import Newnavigation from '../../components/new-navigation/new_navigation'
import ServiceBanner from '../../components/service-banner/ServiceBanner'
import Services from '../../components/services/Services'
import ServiceSubBanner from '../../components/service-sub-banner/ServiceSubBanner'
import ContactNew from '../../components/contact-form/ContactNew'
import Footer from '../../components/footer/Footer'
import { SERVICE_BG, SERVICE_SUB_BG } from '../../assets/images'
import { heading } from '../../utils/servicebanner'
import { services } from '../../utils/services'
import HeaderSeo from '../../components/HeaderSeo/Headerseo'
import { ServicesSeo } from '../../utils/Seo'
import { motion } from "framer-motion";


const ServicePage = () => {
    useEffect(() => {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }, []);
    return (
        <motion.div
initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
        <div className='service-page '>
            <HeaderSeo data={ServicesSeo}/>
            <Newnavigation routeName="services"/>
            <ServiceBanner bg={SERVICE_BG} data={heading}/>
            <Services data={services} />
            <ServiceSubBanner
                bg={SERVICE_SUB_BG}
                title="Kickstart Your Dream Project With Us"
                content="We have worked with some of the best innovative ideas and brands in theworld across industries."
                contact="Get your free quote"
                padding="20%" 
                contentPadding= "30%"/>
            <ContactNew />
            <Footer />
        </div>
        </motion.div>
    )
}

export default ServicePage