import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Newnavigation from "../../components/new-navigation/new_navigation";
import PageBanner from "../../components/page-banner/page-banner";
import LearnMore from "../../components/common-learn-more/LearnMore";
import Footer from "../../components/footer/Footer";
import { SUPPLY_CHAIN_BG } from "../../assets/images/index";
import { supplychaindetail } from "../../utils/supplychainlearnmore";

const SupplyChainDetail = () => {
  const type = useParams()?.type;
  useEffect(() => {
    document.getElementById(type).scrollIntoView();
  }, []);

  return (
    <div className="supplychain_detail">

  
      <Newnavigation />
      <PageBanner
        bg={SUPPLY_CHAIN_BG}
        title="Supply Chain, Retail & Logistics"
      />
      <div className="supply_chain_head">
      <LearnMore data={supplychaindetail} />
      </div>
      <Footer />
    </div>
  );
};

export default SupplyChainDetail;
