import React from "react";
import "./timeLine.css";
import { Col, Container, Row } from "react-bootstrap";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

const LogisticsTimeLine = ({ customRef }) => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `.time_line_section`,
          start: `top top`,
          end: `100%  bottom`,
          // pin:true,
          scroller: customRef,
          // markers:true
        },
      })
      .to(".second>h1", {
        color: "#3A65FF",

        scrollTrigger: {
          trigger: `.time_line_section`,
          start: `-3% top`,
          end: `10%  top`,
          scrub: true,
          scroller: customRef,
          //   markers:true
        },
      })
      .to(".third>h1", {
        color: "#3A65FF",

        scrollTrigger: {
          trigger: `.time_line_section`,
          start: `15% top`,
          end: `30%  top`,
          scrub: true,
          scroller: customRef,
          //   markers:true
        },
      })
      .to(".four > h1", {
        color: "#3A65FF",

        scrollTrigger: {
          trigger: `.time_line_section`,
          start: `43% top`,
          end: `60%  top`,
          scrub: true,
          scroller: customRef,

        },
      })
      .to(".custom_fill_line", {
        // y:"2000",
        height: "100%",

        scrollTrigger: {
          trigger: `.time_line_section`,
          start: `-15% top`,
          end: `100%  bottom`,
          scrub: true,
          scroller: customRef,
          // markers:true
        },
      });

    return () => { };
  }, []);

  return (
    <div className="time_line_section">
      <Container className='relative'>
        <Row className="responsive_row_odd">
          <Col lg={6}>
            <div className="description_box">
              <p>
                Streamlining Operations for Maximum Efficiency In today’s competitive global market,
                integrated logistics is key to maintaining efficiency and staying ahead. By synchronizing
                physical and digital touchpoints, logistics companies can provide seamless service
                experiences that meet modern demands for speed and reliability. Imagine a freight
                forwarding company that synchronizes inventory across multiple warehouses and
                transportation modes, facilitating real-time tracking and efficient cargo management.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="stages first">
              <h1>01</h1>
              <h3>
                Integrated <br /> Logistics
              </h3>
            </div>
          </Col>
        </Row>
        <Row className="time_row responsive_row_even">
          <Col lg={6}>
            <div className="stages even second">
              <h1>02</h1>
              <h3>
                Technology <br /> Migration
              </h3>
            </div>
          </Col>
          <Col lg={6}>
            <div className="description_box even">
              <p>
                Enhancing Operational Capabilities Upgrading to advanced logistics platforms is
                essential for companies aiming to elevate their operational capabilities.
                For warehousing operations, this involves adopting systems that support
                advanced inventory management, automated storage, and retrieval processes.
                These platforms handle high volumes of goods and streamline operations,
                ensuring smooth, error-free performance. A well-executed migration plan
                supports scalability, improves system performance, and integrates powerful
                analytical tools for deeper insights into supply chain efficiency.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="time_row responsive_row_odd">
          <Col lg={6}>
            <div className="description_box">
              <p>
                Engaging Customers Across Multiple Touchpoints Omnichannel marketing
                is vital in today’s logistics industry, ensuring a consistent and
                integrated customer experience across all channels. By leveraging
                omnichannel strategies, logistics companies can enhance customer
                engagement, improve loyalty, and streamline communication.
              </p>

              <ul>
                <li>Unified Communication</li>
                <li>Personalized Marketing</li>
                <li>Enhanced Student Support</li>
              </ul>
            </div>
          </Col>
          <Col lg={6}>
            <div className="stages third">
              <h1>03</h1>
              <h3>
                {" "}
                Omnichannel <br /> Marketing{" "}
              </h3>
            </div>
          </Col>
        </Row>
        <Row className="time_row responsive_row_even">
          <Col lg={6}>
            <div className="stages even four">
              <h1>04</h1>
              <h3>
                Interactive Digital <br /> Experiences
              </h3>
            </div>
          </Col>
          <Col lg={6}>
            <div className="description_box even">
              <p>
                Making Logistics Engaging and Transparent Interactive digital experiences,
                such as gamified tracking platforms and live virtual support, are
                revolutionizing logistics. These tools provide customers with engaging,
                hands-on experiences that enhance understanding and satisfaction. For
                instance, a fleet management company can use gamification to make tracking
                shipments more engaging, encouraging active participation and improving
                customer satisfaction.
              </p>
            </div>
          </Col>
        </Row>

        <div className="time-line_path">
          <div className="custom_fill_line"></div>
        </div>
      </Container>

      <div className="time-line_path">
      <svg xmlns="http://www.w3.org/2000/svg" width="58" height="1781" viewBox="0 0 58 1781" fill="none">
<rect x="26" y="35" width="8" height="1746" rx="4" fill="#D1D9F4"/>
<circle cx="29" cy="29" r="29" fill="#D1D9F4"/>
<circle cx="29" cy="29" r="14.5" fill="#3A65FF"/>
<circle cx="29" cy="487" r="29" fill="#D1D9F4"/>
<circle cx="29" cy="487" r="14.5" fill="#3A65FF"/>
<circle cx="29" cy="1513" r="29" fill="#D1D9F4"/>
<circle cx="29" cy="1513" r="14.5" fill="#3A65FF"/>
<circle cx="29" cy="1030" r="29" fill="#D1D9F4"/>
<circle cx="29" cy="1030" r="14.5" fill="#3A65FF"/>
</svg>

        <div className="custom_fill_line"></div>
      </div>
    </div>
  );
};

export default LogisticsTimeLine;
