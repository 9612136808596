import {
    LEARNMORE_IMG,
    FINANCIAL_RECOVERY_NEW,
    MEDICAL_MANAGEMENT_NEW,
    DATA_PRICING,
    VISITOR_COMPLAINT_NEW,
    DOCTOR_COMMUNICATION_NEW,
    DIGITAL_THERAPY_NEW,
    TOKEN_CREATION,
  } from "../assets/images/index";


export const healthcaredetail = [
    {
      title: "Pharmacy Benefit Management",
      img: LEARNMORE_IMG,
      id: "pharmacy-benefit",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },
    {
      title: "Financial Recovery",
      img: FINANCIAL_RECOVERY_NEW,
      reverseClass: "flex_reverse",
      id: "financial-recovery",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },

    {
      title: "Medical Management",
      img: MEDICAL_MANAGEMENT_NEW,
      id: "medical-management",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },
    {
      title: "Data & Pricing Transparency",
      img: DATA_PRICING,
      reverseClass: "flex_reverse",
      id: "data-printing-transparency",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },

    {
      title: "Vistor Complaints",
      img: VISITOR_COMPLAINT_NEW,
      id: "visitor-complaints",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },

    {
      title: "Doctor & Patient Communication",
      img: DOCTOR_COMMUNICATION_NEW,
      reverseClass: "flex_reverse",
      id: "patient-communication",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },

    {
      title: "Digital Therapy",
      img: DIGITAL_THERAPY_NEW,
      id: "digital-therapy",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },

    {
      title: "Health Token Creation",
      img: TOKEN_CREATION,
      reverseClass: "flex_reverse",
      id: "health-token-creation",
      liststyletype: "remove_dot",
      listItems: [
        "Welcome to our Insurance Integrated Customer Journey, where protection meets a seamless experience.",
        "Explore our user-friendly website, a portal into coverage options tailored just for you.",
        "From informative content to intuitive design, your journey starts with clarity and convenience.",
        "Navigate through personalized quotes, making the purchase phase a breeze.",
        "Our online platform ensures a secure and straightforward transaction.",
        "Your policy isn't just a document; it's a shield crafted to fit your unique needs.",
      ],
    },
  ];