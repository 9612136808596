import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {
  CONSULTING,
  OUTSOURCING,
  SPECTRUM_batch,
  SPECTRUM_star,
  TECHNOLOGY,
} from "../../assets/images/index";
import "./full_spectrum.css";
import ContactForm from "../contactus-popup/ContactForm";
import { Fade, Roll } from "react-awesome-reveal";

const FullSpectrum = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [showConsult, setShowConsult] = useState(true);
  const [showTech, setShowTech] = useState(false);
  const [showOutsource, setShowOutsource] = useState(false);

  const heading = "Consulting";
  const cardInfo = [
    {
      list_title: "Digital Transformation",
      link:"/services/consulting/digital-transformation"
    },
    {
      list_title: "Strategy and Architecture",
      link:"/services/consulting/strategy-and-architecture"
    },
    {
      list_title: "Business Transformation",
       link:"/services/consulting/business-transformation"
    },
    {
      list_title: "Enterprise Processes",
       link:"/services/consulting/business-transformation"
    },
    {
      list_title: "Change and Learning",
       link:"/services/consulting/change-and-learning"

    },
    {
      list_title: "Generative AI",
       link:"/services/consulting/generative-ai"
      
    },
  ];
  const TechnologyInfo = [
    {
      list_title: "Transforming Businesses with Innovative IT Solutions",
      link:""
    },
    {
      list_title:
        "Service-Oriented Architecture (SOA)",
           link:"/services/technology/soa-solutions"
    },
    {
      list_title: "Transforming Data into Actionable Insights",
         link:"/services/technology/business-intelligence"
    },
    {
      list_title: "Comprehensive Data Warehousing",
         link:"/services/technology/data-warehousing"
    },
    {
      list_title: "Comprehensive Data Mining and CRM",
         link:"/services/technology/data-mining-crm"
    },
    {
      list_title:
        "UNIX & Microsoft Platforms Design",
           link:"/services/technology/unix-microsoft-paltform"
    },
  ];
  const Outsourcing = [
    {
      list_title: "Information Technology Outsourcing (ITO)",
         link:"/services/outsourcing/ITO"
    },
    {
      list_title: "Program Management Outsourcing (PMO)",
         link:"/services/outsourcing/PMO"
    },
    {
      list_title: "Business Process Outsourcing (BPO)",
         link:"/services/outsourcing/BPO"
    },
  ];

  function handleClick() {
    navigate("/services");
  }

  const [activeIndex, setActiveIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);

 

  const handleMouseEnter = (index) => {
   setHoverIndex(index);
   console.log("click")
 };

 const handleMouseLeave = (index) => {
   setHoverIndex(index);
 };

 useEffect(()=>{
  setHoverIndex(0);
 },[])

 


  return (
    <div className="bg-section" id="services">
      <Container>
        <Fade direction="up" duration={0}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="">
              <h1 className="spectrum-title">Full-spectrum capability:</h1>
              <p className="spectrum-sub-title">Concept to project delivery</p>
            </div>

            <div className="position-relative">
              <div className="batch-icon">
                <img
                  src={SPECTRUM_batch}
                  alt="batch-img"
                  className="batch-img"
                />
                <img src={SPECTRUM_star} alt="star-img" className="star-img" />
              </div>
            </div>
          </div>
        </Fade>
       
        <div className="main" onMouseLeave={()=>handleMouseLeave(0)}>
          <div
            className={ hoverIndex === 0 ? `animation-box shetteractive` : 'animation-box'}
            style={{ backgroundImage: "url(" + `${CONSULTING}` + ")" }}
            onMouseOver={() => handleMouseEnter(0)}>
            
            <div className={ hoverIndex === 0 ? " spectrum_content ": " spectrum_content text-out"}
            >
              <h3>{heading}</h3>
              <ul>
                {cardInfo.map((cardInfo, index) => {
                  return (
                  
                  <li key={index}>

                    <Link to={`${cardInfo?.link}`}>{cardInfo.list_title}</Link>
                    
                    </li>
               
                  )
                })}
              </ul>
            </div>
            <h3 className={ hoverIndex === 0 ? " white_head d-none": " white_head"} >Consulting</h3>
          </div>

          <div
            className={  hoverIndex === 1 ? `animation-box shetteractive` : 'animation-box' }
            style={{ backgroundImage: "url(" + `${TECHNOLOGY}` + ")" }}
            onMouseOver={() => handleMouseEnter(1)} 
            >
            <div className={ hoverIndex === 1 ? " spectrum_content ": " spectrum_content text-out"}
            >
              <h3>Technology</h3>
              <ul>
                {TechnologyInfo.map((cardInfo, index) => {
                  return (
                   
                    <li key={index}>
  
                      <Link to={`${cardInfo?.link}`}>{cardInfo.list_title}</Link>
                      
                      </li>
                  
                  )
                })}
              </ul>
            </div>
            <h3 className={hoverIndex === 1 ? " white_head d-none": " white_head"} >Technology</h3>
          </div>

          <div
            className={hoverIndex === 2 ? `animation-box shetteractive` : 'animation-box'}
            style={{ backgroundImage: "url(" + `${OUTSOURCING}` + ")" }}
            onMouseOver={() => handleMouseEnter(2)} 
            >
            <div className={hoverIndex === 2 ? " spectrum_content ": " spectrum_content text-out"}>
              <h3>Outsourcing</h3>
              <ul>
                {Outsourcing.map((cardInfo, index) => (
               
               <li key={index}>

                 <Link to={`${cardInfo?.link}`}>{cardInfo.list_title}</Link>
                 
                 </li>
             
                ))}
              </ul>
            </div>
            <h3 className={hoverIndex === 2 ? " white_head d-none": " white_head"} >Outsourcing</h3>
          </div>
        </div>
        <div className="text-center mt-5">
          <div className=" btn_shadow">
            <div className="shadow_1"></div>
            <div className="polygon_btn_cont">
              {/* <Button className='polygon_btn spectrum_btn' variant="outline-primary" onClick={handleShow}> Contact Us</Button> */}

              <Button
                className="polygon_btn spectrum_btn"
                variant="outline-primary" onClick={handleShow}
              >
                {" "}
                Contact Us
              </Button>
              <div className="polygon_shadow"></div>
            </div>
          </div>
        </div>
      </Container>
      <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
    </div>
  );
};
export default FullSpectrum;



 {/* <Fade direction='up' duration={0}>
      <div className='spectrum_box'>
         <Row>
         {
         showConsult ? 
         <Col lg={6} md={12} sm={12} className='p-0 pointer-div' id="consulting-img" onClick={handleClick}>
             <div className='spectrum_content'>
            <h3>{heading}</h3>
            <ul>
               {cardInfo.map((cardInfo, index) => {
               return (
               <li key={index}>{cardInfo.list_title}</li>
               )
               })}
            </ul>
         </div>
         
          </Col> 
         : 
         <Col lg={3} md={12} sm={12} className='p-0' 
         onMouseEnter={() => {setShowConsult(true);setShowTech(false);setShowOutsource(false)}}
         >
         <div className='spectrum_style spectrum_tech position-relative' id="consulting-img">
             <h3>Consulting</h3>
             <img src={CONSULTING} className='img-fluid consult_img spectrum-full-width' alt="tech image" />
          </div>
         </Col> 
         }
     {
     showTech ?  
      <Col lg={6} md={12} sm={12} onClick={handleClick} className='pointer-div'>
            <div className='spectrum_content' id="technology-content" >
               <h3>Technology</h3>
               <ul>
                  {TechnologyInfo.map((cardInfo, index) => {
                  return (
                  <li key={index}>{cardInfo.list_title}</li>
                  )
                  })}
               </ul>
            </div>
            </Col> 
         :  
        <Col lg={3} md={12} sm={12} className='p-0' id="technology-img"  
        onMouseEnter={() => {setShowConsult(false);setShowTech(true);setShowOutsource(false)}}
        >
            <div className='spectrum_style spectrum_tech position-relative'>
               <h3>Technology</h3>
               <img src={TECHNOLOGY} className='img-fluid tech_img spectrum-full-width' alt="tech image" />
            </div>
        </Col>
      }
     {
     showOutsource ? 
     <Col lg={6} md={12} sm={12} onClick={handleClick} className='pointer-div' >
            <div className='spectrum_content' id="outsourcing-content">
               <h3>Outsourcing</h3>
               <ul>
                  {Outsourcing.map((cardInfo, index) => {
                  return (
                  <li key={index}>{cardInfo.list_title}</li>
                  )
                  })}
               </ul>
            </div>
            </Col> 
         :  
         <Col lg={3} md={12} sm={12} className='p-0' id="outsourcing-img"  
         onMouseEnter={() => {setShowConsult(false);setShowTech(false);setShowOutsource(true);}}
         >
            <div className='spectrum_style spectrum_source position-relative'>
               <h3>Outsourcing</h3>
               <img src={OUTSOURCING} className='img-fluid outsource_img spectrum-full-width' alt="tech image" />
            </div>
            </Col>
         }
            
           
           
           
           
            
         </Row>
      </div>
      </Fade> */}