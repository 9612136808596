import { ReactComponent as Training } from "./../assets/images/industry/insurance/training_apps.svg";
import { ReactComponent as Induction } from "./../assets/images/industry/insurance/induction.svg";
import { ReactComponent as Engagement } from "./../assets/images/industry/insurance/engagement_apps.svg";
import { ReactComponent as Booking } from "./../assets/images/industry/insurance/skill_booking.svg";
import { ReactComponent as Tution } from "./../assets/images/industry/insurance/tution_apps.svg";
import { ReactComponent as Virtual } from "./../assets/images/industry/insurance/virutual_classroom.svg";
import { ReactComponent as E_LEARN } from "./../assets/images/industry/insurance/e-learning.svg";
import { ReactComponent as VIDEO } from "./../assets/images/industry/insurance/video-player.svg";
import { ReactComponent as VIDEO_PLATER } from "./../assets/images/industry/insurance/video-player-2.svg";
import { ReactComponent as ONLINE_TRAIN } from "./../assets/images/industry/Education/online-learning.svg";
import { ReactComponent as MOBILE_DEV } from "./../assets/images/industry/Education/mobile-development.svg";
import { ReactComponent as COURSE } from "./../assets/images/industry/Education/course.svg";
import { ReactComponent as EDUAPP } from "./../assets/images/industry/Education/education-app.svg";
import { OURMISSION_EDUCATION, WHATWEDOEDUCATION, WHATWEDOEDUCATION_1, WHATWEDOEDUCATION_2 } from "../assets/images";


export const EducationWhatwedo = [
  {
    image: WHATWEDOEDUCATION,
    ques:"What We Do?",
    ans : "At Techno Genesis, we specialize in crafting tailored solutions for the education industry. Our expertise encompasses eLearning software development, mobile learning solutions, learning management systems (LMS), custom education app development, and comprehensive support for all facets of the educational technology landscape."
  },
  {
    image: WHATWEDOEDUCATION_1,
    ques: "Who We Serve?",
    ans : "Our services meet the needs of various educational institutions, including schools, higher education establishments, and eLearning platforms. Whether you're a forward-thinking educator or an institution aspiring for digital excellence, we are here to elevate your educational journey."
  },
  {
    image: WHATWEDOEDUCATION_2,
    ques: "What Sets Us Apart?",
    ans : "By choosing Techno Genesis, our partners experience a seamless integration of technology and education. Expect enhanced learning experiences, streamlined administrative processes, innovative teaching methodologies, and a future-ready educational environment."
  },
]

export const EducationAccord = [
  {
    id:1,
    accHead:"What EdTech services does Techno Genesis offer?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:2,
    accHead:"Is EdTech affordable for my school/business?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:3,
    accHead: "How does Techno Genesis support my EdTech implementation?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
    

  },
  {
    id:4,
    accHead: "What makes mobile learning solutions crucial for modern education?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  },
  {
    id:5,
    accHead:"Can you create custom educational apps for our specific needs?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  }
]

export const EducOurMissons = {
  title: "Our Missions",
  content:
    "In today's rapidly evolving world, education craves innovation. At Techno Genesis, we're passionate about fueling that transformation with cutting-edge EdTech solutions. We empower educators and institutions to create vibrant, interactive learning experiences that engage students, boost knowledge retention, and unlock their full potential.",
  Image: OURMISSION_EDUCATION,
};
export const KeyserviceEducation = [
  {
    Image: ONLINE_TRAIN,
    title: "eLearning Software Development",
    content:
      "Design and develop engaging and interactive eLearning modules, microlearning courses, and simulations for various learning styles and needs.",
  },
  {
    Image: MOBILE_DEV,
    title: "Mobile Learning Solutions",
    content:
      "Design and develop engaging and interactive eLearning modules, microlearning courses, and simulations for various learning styles and needs.",
  },
  {
    Image: COURSE,
    title: "Learning Management Systems (LMS)",
    content:
      "Implement robust and scalable LMS platforms to manage learning content, track learner progress, and deliver blended learning experiences.",
  },
  {
    Image: EDUAPP,
    title: "Custom Education App Development Technology",
    content:
      "Tailor-made education apps to address specific learning objectives and unique challenges faced by educational institutions and organizations.",
  },
];

export const Education = [
  {
    title: "Corporate Training Apps",
    Image: Training,
  },
  {
    title: "Induction and Orientation Apps",
    Image: Induction,
  },
  {
    title: "Employee Engagement Apps",
    Image: Engagement,
  },
  {
    title: "Skill Booking Apps",
    Image: Booking,
  },
  {
    title: "Tuition Apps ",
    Image: Tution,
  },
  {
    title: "Virtual Classrooms",
    Image: Virtual,
  },
  {
    title: "LMS Development",
    Image: E_LEARN,
  },
  {
    title: "eLearning Apps for Disabled",
    Image: VIDEO,
  },
  {
    title: "On-demand eLearning Apps",
    Image: VIDEO_PLATER,
  },
];

export const whyChooseTgssDetails = [
  {
    Title: "Why Choose Techno Genesis?",
    Details: [
      {
        List: "Expertise",
        ListItem:
          "Our team takes pride in housing seasoned developers and instructional designers who possess extensive expertise in the field of education and edutech.",
      },
      {
        List: "Innovation",
        ListItem:
          "Our commitment to innovation is evident in our proactive approach, where we leverage the newest technologies and stay abreast of emerging learning trends, resulting in the development of solutions that push the boundaries of what's possible.",
      },
      {
        List: "Customization",
        ListItem:
          "We customize our solutions to align seamlessly with your distinct requirements and financial parameters, guaranteeing an ideal match for your one-of-a-kind learning setting.",
      },
      {
        List: "Scalability",
        ListItem:
          "Our solutions are crafted to scale alongside your requirements, adjusting seamlessly to accommodate a growing user base or evolving educational objectives.",
      },
      {
        List: "Support",
        ListItem:
          "We provide continuous support and maintenance to guarantee the seamless operation of your eLearning solution, ensuring it consistently delivers optimal results.",
      },
      {
        List: "Omnichannel Marketing Solutions",
        ListItem:
          "Boost your brand's impact with Techno Genesis Omnichannel Marketing Solutions. We craft seamless strategies across all channels, ensuring a unified and effective approach. Elevate your online and offline presence with us, your trusted partner for comprehensive marketing success.",
      },
    ],
  },
];
