import { ReactComponent as Training } from "./../assets/images/industry/insurance/Claim Management.svg";
import { ReactComponent as Induction } from "./../assets/images/industry/insurance/Omnichannel Lead Generation.svg";
import { ReactComponent as Engagement } from "./../assets/images/industry/insurance/Insurance-Software-Development.svg";
import { ReactComponent as Booking } from "./../assets/images/industry/insurance/skill_booking.svg";
import { ReactComponent as Tution } from "./../assets/images/industry/insurance/tution_apps.svg";
import { ReactComponent as Virtual } from "./../assets/images/industry/insurance/virutual_classroom.svg";
import { ReactComponent as E_LEARN } from "./../assets/images/industry/insurance/e-learning.svg";
import { ReactComponent as VIDEO } from "./../assets/images/industry/insurance/video-player.svg";
import { ReactComponent as VIDEO_PLATER } from "./../assets/images/industry/insurance/video-player-2.svg";
import { ReactComponent as ONLINE_TRAIN } from "./../assets/images/industry/insurance/Insurance IT Solutions.svg";
import { ReactComponent as MOBILE_DEV } from "./../assets/images/industry/insurance/Digital Insurance Systems.svg";
import { ReactComponent as COURSE } from "./../assets/images/industry/insurance/B2B and B2C Insurance Solutions.svg";
import { ReactComponent as EDUAPP } from "./../assets/images/industry/insurance/Insurance Application Processing.svg";
import { OURMISSION_INSURANCE, WHATWEDOEDUCATION, WHATWEDOEDUCATION_1, WHATWEDOEDUCATION_2 } from "../assets/images";


export const InsuranceWhatwedo = [
  {
    image: WHATWEDOEDUCATION,
    ques:"What We Do?",
    ans : "At Techno Genesis, we excel in delivering comprehensive Insurance IT Solutions and Services meticulously crafted to meet the distinctive needs of the insurance industry. Our diverse offerings encompass cutting-edge digital insurance systems, specialized B2B and B2C insurance solutions, efficient insurance application processing, robust claim management, omnichannel lead generation, and the development of bespoke insurance software."
  },
  {
    image: WHATWEDOEDUCATION_1,
    ques: "Who We Serve?",
    ans : "Our services are tailored for a diverse range of insurance businesses, including carriers, brokers, and agencies. No matter where you stand on the business spectrum – whether you're a fresh-faced startup or a seasoned titan of industry Techno Genesis is your trusty companion on the digital journey ahead."
  },
  {
    image: WHATWEDOEDUCATION_2,
    ques: "What They Get?",
    ans : "By choosing Techno Genesis, insurance businesses can expect streamlined insurance application processing, efficient claim management, and seamless omnichannel lead generation. Our solutions are designed to enhance operational efficiency, improve customer experiences, and position your business for long-term success."
  },
]

export const InsuranceAccord = [
  {
    id:1,
    accHead:"How can technology improve efficiency in the insurance industry?",
    accContent:"Discover how innovative technology solutions can streamline processes and boost operational efficiency for insurance businesse."

  },
  {
    id:2,
    accHead:"How can insurance businesses benefit from digital insurance systems?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:3,
    accHead: "How can claim management systems contribute to fraud prevention?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
    

  },
  {
    id:4,
    accHead: "What benefits of efficient insurance application processing?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  }
]

export const InsuranceOurMissons = {
  title :"Our Mission",
  content:"Step into the future of insurance technology with Techno Genesis. Our tailored insurance IT solutions are designed to streamline operations, enhance customer experience, and unlock growth. We empower insurance companies, brokers, and agencies of all sizes to leverage technology and transform their business.",
  Image : OURMISSION_INSURANCE
}

export const KeyserviceInsurance = [
  {
    Image: ONLINE_TRAIN,
    title: "Insurance IT Solutions",
    content :"Embrace the future of insurance technology with our cutting-edge solutions. We provide a comprehensive suite of IT services tailored to the intricacies of the insurance sector, ensuring your business stays technologically"
  },
  {
    Image:MOBILE_DEV,
    title:"Digital Insurance Systems",
    content:"Navigate the digital age seamlessly with our transformative digital insurance systems. Elevate your business with our curated platforms – not just upgrades, but stars of the show. Witness operational efficiency, flawless data management, and an overall digital performance deserving of a standing ovation."

  },
  {
    Image:COURSE,
    title:"B2B and B2C Insurance Solutions",
    content :"Our specialized B2B and B2C insurance solutions are tailored to cater to the unique demands of different business models. Whether you are focused on business partnerships or direct consumer engagement, we have the right solutions for you."
  },
  {
    Image:EDUAPP,
    title:"Insurance Application Processing",
    content :"Streamline your insurance application processes with our efficient solutions. We leverage technology to simplify and expedite application procedures, ensuring a smooth and hassle-free experience for both clients and insurers."
  },
  {
    Image:Training,
    title:"Claim Management",
    content:"Trust our robust claim management systems to optimize your claims processing. Our solutions are designed to enhance accuracy, reduce processing times, and ultimately improve customer satisfaction through swift and reliable claims resolution."

  },
  {
    Image:Induction,
    title:"Omnichannel Lead Generation",
    content :"Elevate your lead generation strategies with our omnichannel approach. Seamlessly integrate and synchronize your lead generation efforts across various channels, creating a cohesive and impactful outreach to potential clients."
  },
  {
    Image:Engagement,
    title:"Insurance Software Development",
    content :"Harness the power of tailor-made solutions with our customized insurance software development services. Our team of experts collaborates closely with you to create software that aligns perfectly with your unique business requirements, ensuring a perfect fit."
  }

]

export const Education = [
    {
        title: "Corporate Training Apps",
        Image: Training
      },
      {
        title: "Induction and Orientation Apps",
        Image: Induction
      },
      {
        title: "Employee Engagement Apps",
        Image: Engagement
      },
      {
        title: "Skill Booking Apps",
        Image: Booking
      },
      {
        title: "Tuition Apps ",
        Image: Tution
      },
      {
        title: "Virtual Classrooms",
        Image: Virtual
      },
      {
        title: "LMS Development",
        Image: E_LEARN
      },
      {
        title: "eLearning Apps for Disabled",
        Image: VIDEO
      },
      {
        title: "On-demand eLearning Apps",
        Image: VIDEO_PLATER
      }
    ]

    export const whyChooseTgssDetails = [
      {
      Title:"Why Choose Techno Genesis?",
      Details: [{
        List: "Expertise",
        ListItem: "Our team takes pride in housing seasoned developers and instructional designers who possess extensive expertise in the field of education and edutech."
      },
      {
        List: "Innovation",
        ListItem: "Our commitment to innovation is evident in our proactive approach, where we leverage the newest technologies and stay abreast of emerging learning trends, resulting in the development of solutions that push the boundaries of what's possible."
      },
      {
        List: "Customization",
        ListItem: "We customize our solutions to align seamlessly with your distinct requirements and financial parameters, guaranteeing an ideal match for your one-of-a-kind learning setting."
      },
      {
        List: "Scalability",
        ListItem: "Our solutions are crafted to scale alongside your requirements, adjusting seamlessly to accommodate a growing user base or evolving educational objectives."
      },
      {
        List: "Support",
        ListItem: "We provide continuous support and maintenance to guarantee the seamless operation of your eLearning solution, ensuring it consistently delivers optimal results."
      },
      {
        List: "Omnichannel Marketing Solutions",
        ListItem: "Boost your brand's impact with Techno Genesis Omnichannel Marketing Solutions. We craft seamless strategies across all channels, ensuring a unified and effective approach. Elevate your online and offline presence with us, your trusted partner for comprehensive marketing success."
      },]
    }
    ]   