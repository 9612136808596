import React, { useEffect } from 'react';
import { motion } from "framer-motion";
import { ServicesSeo, StrategySeo } from '../../utils/Seo';
import HeaderSeo from '../../components/HeaderSeo/Headerseo';
import Newnavigation from '../../components/new-navigation/new_navigation';
import ContactNew from '../../components/contact-form/ContactNew';
import Footer from '../../components/footer/Footer';
import { Architecture_bg } from '../../assets/images';
import { ArchitectureServicesBanner } from '../../utils/servicebanner';
import AboutusBanner from '../../components/Aboutus-banner/AboutusBanner';
import ServiceSteps from '../../components/service-steps/ServiceSteps';
import { ArchitectureOurMissons, ArchitecturekeyService, Architecturestepsdata } from '../../utils/services';
import OurMissions from '../../components/Our-missions/OurMissions';
import ServicesKey from '../../components/services-key/ServicesKey';

const StrategyArchitecture = () => {
    useEffect(() => {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }, []);
      return (
        <motion.div initial={{ opacity: 0 }}animate={{ opacity: 1, transition: { duration: 1 } }}>
        <div className='service-page '>
            <HeaderSeo data={StrategySeo}/>
            <Newnavigation routeName="services"/>
            <AboutusBanner bg={Architecture_bg} data={ArchitectureServicesBanner}/>
            <ServiceSteps 
            title="Strategic Planning, Architectural Design, and Sustainable Growth"
            stepsData={Architecturestepsdata}
             />
              <OurMissions  data={ArchitectureOurMissons} />
              <ServicesKey data={ArchitecturekeyService} />
    
              
            <ContactNew />
            <Footer />
        </div>
        </motion.div>
    )
}

export default StrategyArchitecture