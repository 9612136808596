
import React, { useEffect } from 'react';
import { motion } from "framer-motion";
import { EnterpriseSeo, ServicesSeo } from '../../utils/Seo';
import HeaderSeo from '../../components/HeaderSeo/Headerseo';
import Newnavigation from '../../components/new-navigation/new_navigation';
import ContactNew from '../../components/contact-form/ContactNew';
import Footer from '../../components/footer/Footer';
import {  ENTERPRISE_bg } from '../../assets/images';
import { EnterpriseServicesBanner } from '../../utils/servicebanner';
import AboutusBanner from '../../components/Aboutus-banner/AboutusBanner';
import ServiceSteps from '../../components/service-steps/ServiceSteps';
import { EnterpriseOurMissons, EnterprisekeyService, Enterprisestepsdata } from '../../utils/services';
import OurMissions from '../../components/Our-missions/OurMissions';
import ServicesKey from '../../components/services-key/ServicesKey';

const EnterpriseProcesses = () => {
    useEffect(() => {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }, []);
      return (
        <motion.div initial={{ opacity: 0 }}animate={{ opacity: 1, transition: { duration: 1 } }}>
        <div className='service-page'>
            <HeaderSeo data={EnterpriseSeo}/>
            <Newnavigation routeName="services"/>
            <AboutusBanner bg={ENTERPRISE_bg} data={EnterpriseServicesBanner}/>
            <ServiceSteps title="Streamline, Optimize, and Excel" stepsData={Enterprisestepsdata}/>
             <OurMissions  data={EnterpriseOurMissons} />
              <ServicesKey data={EnterprisekeyService} />
    
              
            <ContactNew />
            <Footer />
        </div>
        </motion.div>
    )
}

export default EnterpriseProcesses