import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsChevronRight } from 'react-icons/bs'
import ContactForm from '../contactus-popup/ContactForm'
import { SERVICE_BG } from '../../assets/images'
import { Fade } from "react-awesome-reveal";
import './AboutusBanner.css'

const AboutusBanner = ({ data,bg }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className='about-banner' style={{ background: `url(${bg || SERVICE_BG})` }}>
                <Container>
                
                    <Row>
                        <Col lg='12' xl={12}>
                            {data.map((head, i) => {
                                return (
                                    <h1 key={i} id={head.id} className='banner-title'>{head.title}</h1>
                                )
                            }
                            )}
                            <div className='hero_section_btn sm-mt-20' onClick={handleShow}>
                                <a href="javascript:void(0)" >Get your free quote</a>
                                <span className='cursor-pointer'>  <BsChevronRight /></span>
                            </div>
                        </Col>
                    </Row>
               
                </Container>
            </div>
            <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
        </>
    )
}

export default AboutusBanner