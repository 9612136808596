import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import ContactForm from "../contactus-popup/ContactForm";
import { MOBILEBG } from "../../assets/images";
import "./page-small-banner.css";
import { Fade, } from "react-awesome-reveal";

const PageSmallBanner = (data) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    
    <>
     <Fade direction='up' duration={0}>
      <div
        className="banner-section mobile-height"
        style={{
          background: `url(${data?.bg || MOBILEBG})`,
          height: data?.height,
          backgroundSize: data?.size,
        }}
      >
        <Container>
          <Row>
            <Col lg={{span:8, offset:2}} className=""  role="img" aria-label="banner image for industry">
              <h1 className="page-title1">{data?.title}</h1>
              <p className="page-content1">{data?.content}</p>
              <div className=" text-center p-0">
                {data?.isBtn == true ? (
                  <Button
                    className="nav_bar_btn ml-0"
                    variant="outline-primary"
                    onClick={handleShow}
                  >
                    {data?.btn_text}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
      </Fade>
    </>
  
  );
};

export default PageSmallBanner;
