import { SOA_1, SOA_2, SOA_3, SOA_4 } from "../assets/images";

export const soa_solutions_banner = [
    {
        title: "Service-Oriented Architecture"
    },
    {
        title: "(SOA) Solutions"
    },
];


export const soa_consulting = [
    {

        title: "SOA Consulting",
        content: "Our SOA consulting services provide you with expert guidance on designing and implementing a service-oriented architecture that aligns with your business goals. We help you understand the benefits, challenges, and best practices of SOA to ensure a successful implementation.",
        image: SOA_1,
        keytitile: "Key Benefits",
        Key_technologies: [
            { items: 'Improved business agility' },
            { items: 'Enhanced interoperability' },  
            { items: 'Reduced IT costs' }
        ],
        button_name: "Get Expert SOA Consulting Today"
    },
    {

        title: "SOA Implementation",
        content: "We provide end-to-end SOA implementation services, from initial planning and design to deployment and maintenance. Our team ensures that your SOA environment is robust, scalable, and capable of supporting your business operations seamlessly.",
        image: SOA_2,
        keytitile: "Key Benefits",
        Key_technologies: [
            { items: 'SOAP, REST' },  
            { items: 'WSDL, UDDI' },
            { items: 'XML, JSON' }
        ],
        button_name: "Implement SOA with Our Experts",
         className: "row-reverse"
    },
    {

        title: "SOA Governance",
        content: "Our SOA governance services help you manage and monitor your SOA environment effectively. We provide tools and frameworks to ensure compliance, performance optimization, and continuous improvement of your SOA infrastructure.",
        image: SOA_3,
        keytitile: "Key Benefits",
        Key_technologies: [
            { items: 'Improved compliance and security' },  
            { items: 'Enhanced service performance' },
            { items: 'Effective resource management' }
        ],
        button_name: "Enhance Your SOA Governance"
    },
    {
        title: "SOA Testing and Maintenance",
        content: "We offer comprehensive SOA testing and maintenance services to ensure your SOA environment remains robust and efficient. Our services include functional testing, performance testing, and continuous maintenance to keep your systems running smoothly.",
        image: SOA_4,
        keytitile: "Key Benefits",
        Key_technologies: [
            { items: 'High system reliability' },  
            { items: 'Early detection of issues' }, 
            { items: 'Continuous improvement' }
        ],
        button_name: "Ensure Reliability with SOA Testing",
         className: "row-reverse"
    },
]

export const saocardInfo = [
    {
      title: "Expertise and Experience",
      content: "With years of experience in the IT industry, our team of experts delivers top-notch solutions tailored to your business needs, ensuring business growth and success.",
      serialno: "01"
    },
    {
      title: "Customer-Centric Approach",
      content: "We prioritize our clients' needs, ensuring that every solution we deliver aligns with your business goals and drives success through digital transformation.",
      serialno: "02"
    },
    {
      title: "Cutting-Edge Technology",
      content: "We leverage the latest technologies to provide innovative IT solutions that keep your business ahead of the competition.",
      serialno: "03"
    },
   
  ];


  export const soaFAQ = [
    {
      id:1,
      accHead:"What is Service-Oriented Architecture (SOA)?",
      accContent:"SOA is an architectural pattern for designing software in which services are provided by application components to other components using a networked communication protocol."
  
    },
    {
      id:2,
      accHead:"How can SOA benefit my business?",
      accContent:"SOA enhances business agility, interoperability, and reduces IT costs by enabling seamless integration and communication between different systems and services."
  
    },
    {
      id:3,
      accHead: "Do you offer customized SOA solutions?",
      accContent:"Yes, we offer tailored SOA solutions to meet your unique business needs and goals."
      
  
    },
    {
      id:4,
      accHead: "What is the process for implementing SOA?",
      accContent:"Our process includes initial consulting, design, implementation, testing, and ongoing maintenance to ensure a successful SOA deployment."
    },
    {
      id:5,
      accHead:"Can you help optimize our existing SOA infrastructure?",
      accContent:"Yes, we can enhance your existing SOA infrastructure to boost performance, compliance, and reliability."
  
    },
    {
      id:6,
      accHead: "Do you provide ongoing support after SOA implementation?",
      accContent:"We offer continuous support and maintenance services to ensure the smooth operation of your SOA environment."
      
  
    },
    {
      id:7,
      accHead: "What sets you apart from other SOA solution providers?",
      accContent:"Our expertise, customer-centric approach, and use of cutting-edge technologies set us apart in the industry."
    }
  ]

  export const soaTestimonials = [
    {
        content: '“Techno Genesis transformed our IT infrastructure with their SOA solutions, enhancing our business agility and operational efficiency.”',
        client: 'Client 1'
    },
    {
        content: '“Their SOA consulting services provided us with the guidance we needed to implement a successful service-oriented architecture.”',
        client: 'Client 2'
    }
  ]