import React from 'react'
import './timeLine.css'
import { Col, Container, Row } from 'react-bootstrap'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useEffect } from 'react';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger)


const TimeLine = ({customRef}) => {

    useEffect(() => {
         
        gsap.timeline({

            scrollTrigger:{
                trigger:`.time_line_section`,
                start:`top top`,
                end:`100%  bottom`,
                // pin:true,
                scroller: customRef,
                // markers:true
          
           }
          })
          
        //   .to(".first>h1",{

        //     color:"#949EC33B",

        //   scrollTrigger:{
        //         trigger:`.time_line_section`,
        //         start:`-10% top`,
        //         end:`30%  top`,
        //         scrub: true,
        //         scroller: customRef,
        //         markers:true
               
        //    }
        //   })
          .to(".second>h1",{

            color:"#3A65FF",

          scrollTrigger:{
                trigger:`.time_line_section`,
                start:`5% top`,
                end:`20%  top`,
                scrub: true,
                scroller: customRef,
                // markers:true
               
           }
          })
          .to(".third>h1",{

            color:"#3A65FF",

          scrollTrigger:{
                trigger:`.time_line_section`,
                start:`35% top`,
                end:`50%  top`,
                scrub: true,
                scroller: customRef,
                // markers:true
               
           }
          })
    .to(".custom_fill_line",{

            // y:"2000",
            height:"100%",
            

          scrollTrigger:{
                trigger:`.time_line_section`,
                start:`-15% top`,
                end:`100%  bottom`,
                scrub: true,
                scroller: customRef,
                // markers:true
               
           }
          })
        
      
      
    
      return () => {
        
      }
    }, [])
    



  return (
    <div className='time_line_section'>
        <Container className='relative'>
            <Row className='responsive_row_odd' >
                <Col lg={6}>
                    
                    <div className='description_box'>

                        <p>In today’s digital age, an <b> effective omnichannel strategy is no longer a luxury but a necessity</b>. By integrating physical and digital touchpoints, businesses can offer a seamless shopping experience that caters to the <b> modern consumer’s need for convenience and flexibility.</b> For instance, a Food & Beverage company can synchronize their inventory across online platforms and physical stores, allowing customers to purchase products online and pick them up in-store (BOPIS), or <b> enjoy the perks of on-demand food delivery services with real-time tracking.</b></p>


                    </div>

                </Col>
                <Col lg={6}>
                <div className='stages first'>

                    <h1>01</h1>
                    <h3>Omnichannel  <br /> E-commerce: <span>Seamless Integration for Enhanced Customer Experience</span> </h3>

                </div>
                
                
                </Col>
            </Row>
            <Row  className='time_row responsive_row_even' >
              
                <Col lg={6}>
                <div className='stages even second' >

                    <h1>02</h1>
                    <h3>E-commerce Migration: <br /> <span>A Strategic Approach to Technological Advancement</span> </h3>

                </div>
                
                
                </Col>
                <Col lg={6}>
                    
                    <div className='description_box even'>

                        <p>Migrating to a more robust e-commerce platform is a <b> critical step for businesses aiming to enhance their digital capabilities.</b> For Apparel & Footwear retailers, this migration not only means adopting a platform that supports diverse sizes and styles but also one that can handle high traffic volumes during peak shopping seasons, ensuring a smooth, glitch-free customer experience. <b> A well-executed migration supports increased scalability, improves site performance, and integrates advanced analytical tools to better understand consumer behavior.</b></p>


                    </div>

                </Col>


            </Row>
            <Row className='time_row responsive_row_odd' >
                <Col lg={6}>
                    
                    <div className='description_box'>

                        <p>The introduction of <b> 3D virtual stores has transformed the online shopping experience </b>, particularly in sectors like Beauty & Personal Care and Jewelry. These virtual stores offer customers a lifelike view of products from <b> the comfort of their homes</b>, enhancing decision-making and boosting customer satisfaction. For example, a jewelry retailer can implement <b>3D technology to allow customers to try on pieces virtually, combining the convenience of online shopping with the personalized experience </b> traditionally found in physical stores.</p>

                       
                </div>

                </Col>
                <Col lg={6}>
                <div className='stages third'>

                    <h1>03</h1>
                    <h3> 3D Virtual Stores: <br /> <span> Revolutionizing Online Shopping with Immersive Technology </span> </h3>

                </div>
                
                
                </Col>


            </Row>

           <div className='time-line_path'>
<svg xmlns="http://www.w3.org/2000/svg" width="58" height="1309" viewBox="0 0 58 1309" fill="none">
<g id="Group 8636 1" clip-path="url(#clip0_46_39)">
<path id="mainLine" d="M34 39C34 36.7909 32.2091 35 30 35C27.7909 35 26 36.7909 26 39V1305C26 1307.21 27.7909 1309 30 1309C32.2091 1309 34 1307.21 34 1305V39Z" fill="#D1D9F4"/>
<path id="Vector" d="M29 58C45.0163 58 58 45.0163 58 29C58 12.9837 45.0163 0 29 0C12.9837 0 0 12.9837 0 29C0 45.0163 12.9837 58 29 58Z" fill="#D1D9F4"/>
<path id="ball" d="M29 43.5C37.0081 43.5 43.5 37.0081 43.5 29C43.5 20.9919 37.0081 14.5 29 14.5C20.9919 14.5 14.5 20.9919 14.5 29C14.5 37.0081 20.9919 43.5 29 43.5Z" fill="#3A65FF"/>
<path id="Vector_2" d="M29 540C45.0163 540 58 527.016 58 511C58 494.984 45.0163 482 29 482C12.9837 482 0 494.984 0 511C0 527.016 12.9837 540 29 540Z" fill="#D1D9F4"/>
<path id="ball_2" d="M29 525.5C37.0081 525.5 43.5 519.008 43.5 511C43.5 502.992 37.0081 496.5 29 496.5C20.9919 496.5 14.5 502.992 14.5 511C14.5 519.008 20.9919 525.5 29 525.5Z" fill="#3A65FF"/>
<path id="Vector_3" d="M29 1043C45.0163 1043 58 1030.02 58 1014C58 997.984 45.0163 985 29 985C12.9837 985 0 997.984 0 1014C0 1030.02 12.9837 1043 29 1043Z" fill="#D1D9F4"/>
<path id="ball_3" d="M29 1028.5C37.0081 1028.5 43.5 1022.01 43.5 1014C43.5 1005.99 37.0081 999.5 29 999.5C20.9919 999.5 14.5 1005.99 14.5 1014C14.5 1022.01 20.9919 1028.5 29 1028.5Z" fill="#3A65FF"/>
<path id="inner_line" d="M34 35.1633C34 35.0731 32.2091 35 30 35C27.7909 35 26 35.0731 26 35.1633V42.8367C26 42.9269 27.7909 43 30 43C32.2091 43 34 42.9269 34 42.8367V35.1633Z" fill="#3A65FF"/>

</g>
<defs>
<clipPath id="clip0_46_39">
<rect width="58" height="1309" fill="white"/>
</clipPath>
</defs>

</svg>
<div className='custom_fill_line'></div>
           </div>

       
    
         

        </Container>
        
    </div>
  )
}

export default TimeLine