
import './App.css';
import Expertise from './pages/expertise';
import { Routes, Route, useLocation} from 'react-router-dom'
import Navigation from './components/navigation/Navigation'
import WorkPage from './pages/work-page/WorkPage';
import CaseStudy from './pages/case-study/CaseStudy';
import ShareMarket from './pages/case-study/ShareMarket';
import Jewel from './pages/case-study/Jewel';
import Finace from './pages/case-study/Finace';
import CaseManagement from './pages/case-study/CaseManagement';
import HealthCare from './pages/case-study/HealthCare';
import HomeNew from './pages/home-new/HomeNew';
import Insurance from './pages/industry/insurance';
import Blockchains from './pages/industry/blockchain';
import TravelandHospitality from './pages/industry/Travel & Hospitality';
import Healthcare from './pages/industry/Healthcare';
import Fintech from './pages/industry/fintech';
import Logistics from './pages/industry/logistics';
import Education from './pages/industry/education';
import HealthCareDetail from './pages/learn-more/HealthCareDetail';
import InsuranceDetail from './pages/learn-more/InsuranceDetail';
import EducationDetail from './pages/learn-more/EducationDetail';
import BlockChainDetail from './pages/learn-more/BlockChainDetail';
import FintechDetail from './pages/learn-more/FintechDetail';
import SupplyChainDetail from './pages/learn-more/SupplyChainDetail';
import ServicePage from './pages/service-main-page/ServicePage';
import NotFound from './pages/Not-found/NotFound';
import AboutusPage from './pages/About-us/About-us';
import RetailandEcommerce from './pages/solutions/Retail and E-commerce';
import { AnimatePresence } from 'framer-motion';
import Edutech from './pages/solutions/Edutech';
import TransportationandLogistics from './pages/solutions/Transportation and Logistics';
import HealthCarePharam from './pages/solutions/HealthCarePharam';
import PrivacyPolicy from './pages/static-pages/PrivacyPolicy';
import TermsConditions from './pages/static-pages/TermsConditions';
import DigitalTransfer from './pages/service-main-page/DigitalTransfer';
import DataWarehousing from './pages/service-main-page/technology/data-warehousing';
import DataMiningCRM from './pages/service-main-page/technology/data-mining-crm';
import BusinessIntelligence from './pages/service-main-page/technology/business-intelligence';
import UNIXMicrosoftPlatform from './pages/service-main-page/technology/unix-microsoft-platform';
import StrategyArchitecture from './pages/service-main-page/StrategyArchitecture';
import BusinessTransformation from './pages/service-main-page/BusinessTransformation';
import EnterpriseProcesses from './pages/service-main-page/EnterpriseProcesses';
import ChangeAndLearning from './pages/service-main-page/ChangeAndLearning';
import GenerativeAI from './pages/service-main-page/GenerativeAI';
import Careers from './pages/careers/Careers';
import SoaSolutions from './pages/service-main-page/technology/SoaSolutions';
import BPO from './pages/service-main-page/outsourcing/BPO';
import ITO from './pages/service-main-page/outsourcing/ITO';
import PMO from './pages/service-main-page/outsourcing/PMO';
import ITLandscape from './pages/service-main-page/technology/IT-landscape';
import CareerDetail from './pages/career-detail/Careerdetail';

function App() {

  const location = useLocation();
  
  return (
    <div >
      <AnimatePresence initial={false} mode='wait'>
      <Routes location={location} key={location?.pathname}>
      <Route path=''  index  element={<HomeNew />} />
        {/* <Route path=''  index  element={<Home />} /> */}
        <Route path='expertise'  element={<Expertise />} />
        <Route path='navigation'  element={<Navigation />} />
        <Route path='work'  element={<WorkPage />} />
        <Route path='work/case-study/e-commerce'  element={<CaseStudy />} />
        <Route path='work/case-study/share-market'  element={<ShareMarket />} />
        <Route path='work/case-study/jewellery-manufacturing'  element={<Jewel />} />
        <Route path='work/case-study/finance'  element={<Finace />} />
        <Route path='work/case-study/case-management'  element={<CaseManagement />} />
        <Route path='work/case-study/healthcare'  element={<HealthCare />} />
        <Route path='industry/insurance'  element={<Insurance />} />
        <Route path='industry/blockchain'  element={<Blockchains />} />
        <Route path='industry/healthcare'  element={<Healthcare />} />
        <Route path='industry/travel&hospitality'  element={<TravelandHospitality />} />
        <Route path='industry/fintech'  element={<Fintech />} />
        <Route path='industry/logistics'  element={<Logistics />} />
        <Route path='industry/education'  element={<Education />} />
        <Route path='industry/health-care/learn-more/:type'  element={<HealthCareDetail />} />
        <Route path='industry/insurance/learn-more/:type'  element={<InsuranceDetail />} />
        <Route path='industry/education/learn-more/:type'  element={<EducationDetail />} />
        <Route path='industry/block-chain/learn-more/:type'  element={<BlockChainDetail />} />
        <Route path='industry/banking-fintech/learn-more/:type'  element={<FintechDetail />} />
        <Route path='industry/supply-chain/learn-more/:type'  element={<SupplyChainDetail />} />
        <Route path='solutions/retail'  element={<RetailandEcommerce />} />
        <Route path='solutions/edutech'  element={<Edutech />} />
        <Route path='solutions/transportation'  element={<TransportationandLogistics />} />
        <Route path='solutions/healthcare-pharmaceutical' element={<HealthCarePharam />} />
     
        <Route path='services' element={< ServicePage/>} />
        <Route path='/services/digital-transformation' element={<DigitalTransfer/>} />
        <Route path='/services/technology/transforming-business' element={<ITLandscape/>} />
        <Route path='/services/technology/data-warehousing' element={<DataWarehousing/>} />
        <Route path='/services/technology/data-mining-crm' element={<DataMiningCRM/>} />
        <Route path='/services/technology/business-intelligence' element={<BusinessIntelligence/>} />
        <Route path='/services/technology/unix-microsoft-paltform' element={<UNIXMicrosoftPlatform/>} />
        <Route path='/services/technology/soa-solutions' element={<SoaSolutions/>} />
        <Route path='/services/outsourcing/BPO' element={<BPO/>} />
        <Route path='/services/outsourcing/ITO' element={<ITO/>} />
        <Route path='/services/outsourcing/PMO' element={<PMO/>} />
        <Route path='/services/consulting/digital-transformation' element={<DigitalTransfer/>} />
        <Route path='/services/consulting/strategy-and-architecture' element={<StrategyArchitecture/>} />
        <Route path='/services/consulting/business-transformation' element={<BusinessTransformation/>} />
        <Route path='/services/consulting/enterprise-processes' element={<EnterpriseProcesses/>} />
        <Route path='/services/consulting/change-and-learning' element={<ChangeAndLearning/>} />
        <Route path='/services/consulting/generative-ai' element={<GenerativeAI />} />
        <Route path='about-us' element={< AboutusPage/>} />

        <Route path='/Careers' element={<Careers/>}/>
        <Route path='/career-detail' element={<CareerDetail />}/>

        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-service' element={<TermsConditions />} />
        <Route path='*' element={<NotFound />} />

  
      </Routes>
      </AnimatePresence>
      {/* <Image src={LOGO} rounded /> */}
    </div>
  );
}

export default App;
