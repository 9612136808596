import { ReactComponent as Development } from "./../assets/images/industry/insurance/1.svg";
import { ReactComponent as Consulting } from "./../assets/images/industry/insurance/2.svg";
import { ReactComponent as Cryptocurrency } from "./../assets/images/industry/insurance/3.svg";
import { ReactComponent as Ethereum } from "./../assets/images/industry/insurance/4.svg";
import { ReactComponent as Multichain } from "./../assets/images/industry/insurance/5.svg";
import { ReactComponent as DAPP } from "./../assets/images/industry/insurance/6.svg";
import { ReactComponent as ONLINE_TRAIN } from "./../assets/images/industry/blockchain/exchange.svg";
import { ReactComponent as MOBILE_DEV } from "./../assets/images/industry/blockchain/stock-market.svg";
import { ReactComponent as COURSE } from "./../assets/images/industry/blockchain/operation.svg";
import { ReactComponent as EDUAPP } from "./../assets/images/industry/blockchain/supply-chain.svg";
import { ReactComponent as Training } from "./../assets/images/industry/blockchain/stock.svg";
import { ReactComponent as Induction } from "./../assets/images/industry/blockchain/online-shopping.svg";
import { ReactComponent as Engagement } from "./../assets/images/industry/blockchain/tablet.svg";
import { ReactComponent as Booking } from "./../assets/images/industry/blockchain/online-money.svg";
import { ReactComponent as Tution } from "./../assets/images/industry/blockchain/user.svg";
import { OURMISSION_BLOCKCHAIN, WHATWEDOEDUCATION, WHATWEDOEDUCATION_1, WHATWEDOEDUCATION_2 } from "../assets/images";


export const BlockchainWhatwedo = [
  {
    image: WHATWEDOEDUCATION,
    ques:"What We Do?",
    ans : "At Techno Genesis, we lead the charge in blockchain development, delivering tailored solutions that redefine the digital landscape. Our expertise encompasses a range of services, from crafting custom blockchain networks to developing innovative decentralized applications (DApps). We specialize in creating blockchain-based financial trading platforms, payment solutions, and non-fungible token (NFT) development, providing comprehensive services that meet the evolving needs of businesses across various industries."
  },
  {
    image: WHATWEDOEDUCATION_1,
    ques: "Who We Serve?",
    ans : "We serve a diverse range of clients, from ambitious startups to established enterprises, helping them harness the potential of blockchain technology. Collaborating with forward-thinking businesses, we provide tailored solutions for enhanced security, transparency, and efficiency across various industries, ensuring our clients stay at the forefront of technological innovation."
  },
  {
    image: WHATWEDOEDUCATION_2,
    ques: "What They Get?",
    ans : "Techno Genesis transforms businesses through seamless decentralized solutions. Choose our services to unlock cutting-edge technology, ensuring secure transactions, transparent processes, and innovation. Gain a competitive edge in finance, payments, and NFT development with transformative, tailored digital solutions."
  },
]

export const BlockchainAccord = [
  {
    id:1,
    accHead:"How does Techno Genesis specialize in blockchain development?",
    accContent:"Techno Genesis excels in crafting custom blockchain networks and developing innovative decentralized applications (DApps)."

  },
  {
    id:2,
    accHead:"How much does blockchain development cost? ",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"

  },
  {
    id:3,
    accHead: "How does blockchain enhance security in financial transactions?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
    

  },
  {
    id:4,
    accHead: "What are NFTs, and how do they work?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  },
  {
    id:5,
    accHead: "What industries can benefit from adopting blockchain technology?",
    accContent:"We create custom eLearning software, mobile learning apps, Learning Management Systems (LMS), and educational apps.&more!"
  }
]

export const BlockchainOurMissons = {
  title: "Our Mission",
  content: `In an era dominated by technological evolution, the potential of blockchain technology shines as a beacon of trust and efficiency. At Techno Genesis, our journey is a relentless pursuit to bridge the gap between imagination and reality. Fueled by the belief that innovation has no bounds, we navigate this digital frontier with blockchain as our guiding force. 
  From transforming financial ecosystems to empowering digital creators through NFTs, our commitment to excellence resonates through every line of code we craft. Join us in redefining what's possible in this digital landscape.`,
  Image: OURMISSION_BLOCKCHAIN
};


export const KeyserviceBlockchains = [
  {
    Image: ONLINE_TRAIN,
    title: "Custom Blockchain Development",
    content :"Tailored solutions to meet your unique business needs. Whether it's creating a new blockchain network or optimizing existing ones, our team of experts ensures seamless integration and unparalleled performance."
  },
  {
    Image:MOBILE_DEV,
    title:"Financial Trading Platforms",
    content:"Elevate your trading experience with our secure and intuitive platforms. With real-time data analysis and robust security measures, we empower traders to navigate the volatile markets with confidence."

  },
  {
    Image:COURSE,
    title:"Payment Platforms",
    content :"Revolutionize transactions with blockchain-based payment solutions. Say goodbye to lengthy processing times and exorbitant fees. Our payment platforms offer lightning-fast transactions and unparalleled security, ensuring a seamless experience for both businesses and consumers."
  },
  {
    Image:EDUAPP,
    title:"Blockchain Technology",
    content :"Unlock the power of transparent and tamper-proof ledgers with our blockchain technology solutions. From supply chain management to identity verification, our innovative applications leverage blockchain to streamline processes and enhance trust."
  },
  {
    Image:Training,
    title:"NFT Development",
    content:"Dive into the world of non-fungible tokens (NFTs) with our expert guidance. Whether you're an artist looking to tokenize your work or a business exploring new revenue streams, we offer comprehensive NFT development services tailored to your needs."

  },
  {
    Image:Induction,
    title:"Marketplace Development",
    content :"Build robust and scalable decentralized marketplaces with our proven expertise. From peer-to-peer trading platforms to decentralized exchanges, we empower businesses to harness the full potential of blockchain technology."
  },
  {
    Image:Engagement,
    title:"Decentralized Applications(DApps)",
    content :"Create innovative, user-centric decentralized applications (DApps) with Techno Genesis. Our team specializes in developing DApps that prioritize usability, scalability, and security, ensuring a seamless experience for end-users."
  },
  {
    Image:Booking,
    title:"Digital Asset Management",
    content :"Efficiently manage and secure your digital assets with our cutting-edge solutions. Whether it's cryptocurrencies, tokenized assets, or digital identities, our comprehensive digital asset management platform offers peace of mind and unparalleled security."
  },
  {
    Image:Tution,
    title:"Omnichannel Marketing Solutions",
    content :"Fuel your blockchain success with Techno Genesis Omnichannel Marketing Solutions. Tailored strategies for seamless integration across channels, ensuring your brand stands out in the blockchain industry"
  }
]

export const blockchain = [
    {
        title: "Blockchain Development",
        Image: Ethereum
      },
      {
        title: "Blockchain Consulting",
        Image: Development
      },
      {
        title: "Cryptocurrency Development",
        Image: Cryptocurrency
      },
      {
        title: "Ethereum Development",
        Image: DAPP
      },
      {
        title: "Hyper Ledger & Multichain ",
        Image: Multichain
      },
      {
        title: "DAPP Development",
        Image: Consulting
      }
    ];

    


 