import React, { useState } from "react";
import { Row, Col, Spinner, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./ContactNew.css";
import { Fade } from "react-awesome-reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { apiEnvironment } from "../../api";
import { SUCCESSGIF, THANKYOU } from "../../assets/images";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";

const ContactNew = () => {
  const [isChecked, setIsChecked] = React.useState(false);

  const [buttonloader, setButtonloader] = useState(false);
  const [showed, setShowed] = useState(false);
  const [showsuccess, setShowSuccess] = useState(false);

  const handleCloseSecond = () => setShowed(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      email: "",
      mobile: "",
      message: "",
      isAgree: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("First Name is required")
        .min(3, "First Name must be at least 3 characters")
        .max(100, "First Name cannot exceed 100 characters"),
      lastname: "",
      mobile: Yup.string()
        .trim()
        .matches(/^(?!(0{10}))[^1-5]\d{9}$/, "Please provide valid Phone No ")
        .required("Please provide Phone No"),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid Email Id"
        )
        .required("Email Id is required")
        .min(2, "Email Id must be at least 3 characters")
        .max(255, "Email Id  cannot exceed 255 characters"),
      message: Yup.string()
        .trim("Please provide the Message")
        .required("Please provide the Message"),
      isAgree: Yup.bool().oneOf([true], "Please agree to the Privacy Policy"),
    }),

    onSubmit: async (values) => {
      setButtonloader(true);

      const bodyData = {
        name: values?.name,
        lastName: values?.lastname,
        mobile: values?.mobile,
        email: values?.email,
        message: values?.message,
      };

      try {
        const res = await axios.post(
          `${apiEnvironment?.baseUrl}/contactus/create`,
          bodyData
        );

        console.log(res);
        if (!res) {
          console.error("Error: ", res);
          setButtonloader(false);
          formik.resetForm();
          setIsChecked(false);
          toast.error("Something went wrong.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          return;
        }

        if (res?.data.keyword === "success" || res?.data?.status === 200) {
          setButtonloader(false);
          setShowed(true);
          formik.resetForm();
          setIsChecked(false);
          setShowSuccess(true)
        } else {
          setButtonloader(false);
          toast.error("Something went wrong.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          formik.resetForm();
          setIsChecked(false);
        }
      } catch (e) {
        formik.resetForm();
        console.error("Error: ", e);
        setButtonloader(false);
        toast.error("Something went wrong.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
  });

  const handlekeydown = (evt) => {
    ["e", "E", "+", "-", ".", "ctrlKey", "vKey"].includes(evt.key) &&
      evt.preventDefault();
    if (
      evt.keyCode === 38 ||
      evt.keyCode === 40 ||
      evt.keyCode === 17 ||
      evt.keyCode === 86
    ) {
      evt.preventDefault();
    }
  };

  return (
    <div className="contect-page" role="img" aria-label="contact for enquiry">
      <Container>
        <Fade direction="left" duration={0}>
          <div className="pt-60">
            <Row>
              <Col sm="12" md="12" lg="7">
                <h1 class="main-title text-start contactform_title">
                  Contact us. We're here to help grow your business
                </h1>
                <Form onSubmit={formik.handleSubmit} autocomplete="off">
                  <div className="contactform_head mt-4">
                    <div className="contactnew_form">
                      <Row>
                        <Col md={6}>
                          <Form.Group className="" controlId="firstname-input">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="First Name *"
                              className="mb_4"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Full Name"
                                {...formik.getFieldProps("name")}
                                isInvalid={
                                  formik.touched.name && formik.errors.name
                                }
                              />
                            </FloatingLabel>
                            {formik.touched.name && formik.errors.name ? (
                              <small className="text-danger d-block mt-1 ms-1">
                                {formik.errors.name}
                              </small>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="" controlId="lastname-input">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Last Name"
                              className="mb_4"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Last Name"
                                {...formik.getFieldProps("lastname")}
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="py-3">
                        <Col md={6}>
                          <Form.Group className="" controlId="mobile">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Phone Number *"
                              className="mb_4"
                            >
                              <Form.Control
                                type="number"
                                placeholder="Phone Number"
                                onKeyDown={(evt) => handlekeydown(evt)}
                                {...formik.getFieldProps("mobile")}
                                isInvalid={
                                  formik.touched.mobile && formik.errors.mobile
                                }
                              />
                            </FloatingLabel>
                            {formik.touched.mobile && formik.errors.mobile ? (
                              <small className="text-danger d-block mt-1 ms-1">
                                {formik.errors.mobile}
                              </small>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="" controlId="email">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Business Email *"
                              className="mb_4"
                            >
                              <Form.Control
                                type="email"
                                placeholder="Business Email"
                                {...formik.getFieldProps("email")}
                                isInvalid={
                                  formik.touched.email && formik.errors.email
                                }
                              />
                            </FloatingLabel>

                            {formik.touched.email && formik.errors.email ? (
                              <small className="text-danger d-block mt-1 ms-1">
                                {formik.errors.email}
                              </small>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="" controlId="message">
                        <FloatingLabel
                          controlId="floatingTextarea"
                          label="Message *"
                        >
                          <Form.Control
                            as="textarea"
                            placeholder="Message"
                            style={{ height: "72px" }}
                            isInvalid={
                              formik.touched.message && formik.errors.message
                            }
                            {...formik.getFieldProps("message")}
                          />
                        </FloatingLabel>

                        {formik.touched.message && formik.errors.message ? (
                          <small className="text-danger d-block mt-1 ms-1">
                            {formik.errors.message}
                          </small>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="link_section check_box mt-3">
                      <span>
                        By submitting this form, you explicitly agree to our{" "}
                        <a className="underline_link" href="/privacy-policy">
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a className="underline_link" href="/terms-of-service">
                          Terms of Service.
                        </a>
                      </span>
                      <div className="d-flex align-items-center mt-4">
                        <div>
                          <Form.Group className="" controlId="isAgree">
                            <Form.Check
                              type="checkbox"
                              {...formik.getFieldProps("isAgree")}
                              isInvalid={
                                formik.touched.isAgree && formik.errors.isAgree
                              }
                              checked={isChecked}
                              onChange={(e) => {
                                setIsChecked(e.target.checked);
                                formik.setFieldValue(
                                  "isAgree",
                                  e.target.checked
                                );
                              }}
                            />
                          </Form.Group>
                        </div>
                        <div htmlFor="" className="ml_1">
                          I agree to the{" "}
                          <a
                            href="/privacy-policy"
                            className="underline_link clr_blue"
                          >
                            Privacy Policy
                          </a>{" "}
                          *
                        </div>
                      </div>
                      {formik.touched.isAgree && formik.errors.isAgree ? (
                        <small className="text-danger d-block mt-1 ms-1">
                          {formik.errors.isAgree}
                        </small>
                      ) : null}
                    </div>
                    <div className="text-center">
                      <Button
                        type="button"
                        className="contactform_btn"
                        onClick={formik.handleSubmit}
                      >
                        {buttonloader === true ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            variant="light"
                          />
                        ) : null}
                        <> {buttonloader === true ? null : "Submit"} </>
                      </Button>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </Fade>
      </Container>

      <Modal show={showed} onHide={handleCloseSecond} centered>
      {showsuccess === true ? (
          <div className="success_popup_form"> 
           
                <AiOutlineClose className="close_btn_success" onClick={handleCloseSecond}  />
           
            <div className="success_popup_content">
              <img src={SUCCESSGIF} alt="success_gif" />
              <h1>Thank You!</h1>
              <p>We will <span>get back to you shortly</span>. Meanwhile, feel free to <span>explore our website</span>.</p>

            </div>

             

          </div>
        ) : "" }
      </Modal>
    </div>
  );
};

export default ContactNew;
