import {Helmet} from "react-helmet";

const HeaderSeo = ({ data }) => {
return (

    <>

       {
          

              <Helmet>
                <title>{data?.title || "Techno Genesis - Empowering businesses to thrive in the digital era"}</title>
                <meta name="description" content={data?.description || "Transforming Businesses With Innovation"} />
                <meta name="title" content="Techno Genesis Software Solutions Pvt. Ltd. | Custom Software Development Company " />
                <meta name="keywords" content="fintech solutions, custom app development, e-commerce platforms, mobile app development, web development, financial technology, fintech app development, e-commerce integration, custom software solutions, mobile commerce, web applications, digital transformation, online payment systems, fintech innovation, responsive web design, mobile-first development, secure payment gateways, enterprise solutions, app modernization, cross-platform apps, SaaS development, cloud-based solutions, AI in fintech, fintech startup support, custom e-commerce solutions, fintech consulting, custom web apps, mobile banking solutions, API development, fintech security, progressive web apps, user experience design, fintech analytics, fintech software development, web security, mobile app security, digital wallets, fintech compliance, UX/UI design, agile development, fintech automation, fintech APIs, web performance optimization, digital banking solutions, fintech partnerships, fintech ecosystems, custom mobile solutions, technology consulting" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Techno Genesis Software Solutions" />
              </Helmet>

           
       }



    
    
    
    </>




  );
};

export default HeaderSeo;
