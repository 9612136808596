import {
    CONSULTING_IMG,
    TECHNOLOGY_IMG,
    OUTSOURCING_IMG,
    DIGITAL_Img,
    DIGITAL_KEY_SERVICE_1,
    DIGITAL_KEY_SERVICE_2,
    DIGITAL_KEY_SERVICE_3,
    DIGITAL_KEY_SERVICE_4,
    DIGITAL_KEY_SERVICE_5,
    DIGITAL_KEY_SERVICE_6,
    Architecture_Img,
    Arch_KEY_SERVICE_1,
    Arch_KEY_SERVICE_2,
    Arch_KEY_SERVICE_3,
    Arch_KEY_SERVICE_4,
    Arch_KEY_SERVICE_5,
    Arch_KEY_SERVICE_6,
    BUSINESS_Img,
    BUSINESS_KEY_SERVICE_1,
    BUSINESS_KEY_SERVICE_2,
    BUSINESS_KEY_SERVICE_3,
    BUSINESS_KEY_SERVICE_4,
    BUSINESS_KEY_SERVICE_5,
    BUSINESS_KEY_SERVICE_6,
    ENTERPRISE_Img,
    ENTERPRISE_KEY_SERVICE_1,
    ENTERPRISE_KEY_SERVICE_2,
    ENTERPRISE_KEY_SERVICE_3,
    ENTERPRISE_KEY_SERVICE_4,
    ENTERPRISE_KEY_SERVICE_5,
    ENTERPRISE_KEY_SERVICE_6,
    CHANGE_AND_LEARNING_Img,
    CHANGE_AND_LEARNING_KEY_SERVICE_1,
    CHANGE_AND_LEARNING_KEY_SERVICE_2,
    CHANGE_AND_LEARNING_KEY_SERVICE_3,
    CHANGE_AND_LEARNING_KEY_SERVICE_4,
    CHANGE_AND_LEARNING_KEY_SERVICE_5,
    CHANGE_AND_LEARNING_KEY_SERVICE_6,
    GENERATIVE_AI_Img,
    GENERATIVE_AI_KEY_SERVICE_1,
    GENERATIVE_AI_KEY_SERVICE_2,
    GENERATIVE_AI_KEY_SERVICE_3,
    GENERATIVE_AI_KEY_SERVICE_4,
    GENERATIVE_AI_KEY_SERVICE_5,
    GENERATIVE_AI_KEY_SERVICE_6,
  } from "../assets/images/index";


export const services = [
    {
      title: "Consulting",
      content: "consultants assess an organization's technology needs and provide recommendations for hardware, software, and infrastructure improvements.",
      img: CONSULTING_IMG,
      id: "consulting",
      listItems: [
        {
          name: "Digital Transformation",
          link: "/services/consulting/digital-transformation"
        },
        {
          name: "Strategy and Architecture",
          link: "/services/consulting/strategy-and-architecture"
        },
        {
          name: "Business Transformation",
          link: "/services/consulting/business-transformation"
        },
        {
          name: "Enterprise Processes",
          link: "/services/consulting/enterprise-processes"
        },
        {
          name: "Change and Learning",
          link: "/services/consulting/change-and-learning"
        },
        {
          name: "Generative AI",
          link: "/services/consulting/generative-ai"
        },
      ],
    },
    {
      title: "Technology",
      content: "These services are crucial for ensuring that an organization's technology functions smoothly and effectively to support its business operations.",
      img: TECHNOLOGY_IMG,
      reverseClass: "flex_reverse",
      id: "technology",
      listItems: [
        {
          name: "Transforming Businesses with Innovative IT Solutions",
          link: "/services/technology/transforming-business"
        },
        {
          name: "Service-Oriented Architecture (SOA)",
          link: "/services/technology/soa-solutions"
        },
        {
          name: "Transforming Data into Actionable Insights",
          link: "/services/technology/business-intelligence"
        },
        {
          name: "Comprehensive Data Warehousing",
          link: "/services/technology/data-warehousing"
        },
        {
          name: "Comprehensive Data Mining and CRM",
          link: "/services/technology/data-mining-crm"
        },
       
        {
          name: "UNIX & Microsoft Platforms Design, Implementation, and Support Infrastructure",
          link: "/services/technology/unix-microsoft-paltform"
        },

   
      ],
    },

    {
      title: "Outsourcing",
      content: "IT outsourcing is the practice of contracting out certain information technology functions or services to external service providers or third-party organizations.",
      img: OUTSOURCING_IMG,
      id: "outsourcing",
      listItems: [
        {
          name: "Information Technology Outsourcing (ITO)",
          link: "/services/outsourcing/ITO"
        },
        {
          name: "Program Management Outsourcing (PMO)",
          link: "/services/outsourcing/PMO"
        },
        {
          name: "Business Process Outsourcing (BPO)",
          link: "/services/outsourcing/BPO"
        },
      ],
    },
  
  ];


  export const digitalstepsdata = [

    {
       id: "01",
       title: "IT Challenges with Our Expertise",
       content :"We cater to businesses across a wide range of industries, including healthcare, manufacturing, financial services, and more. Whether you are looking to modernize your IT infrastructure, automate processes, or enhance customer engagement, our tailored solutions meet your unique needs and objectives. "
       
    },
    {
      id: "02",
      title: "Why Choose Techno Genesis?",
      content :"At Techno Genesis, we recognize that a robust IT landscape is crucial for your business's success. Our IT landscape consulting services are designed for the assessment, optimisation and future-proofing of your IT infrastructure. By leveraging advanced technologies and industry best practices, we help you build a scalable, reliable, and secure IT environment."
      
   },
   {
    id: "03",
    title: "Transform Your IT Landscape ",
    content :"Partnering with Techno Genesis means gaining access to a team of expert consultants dedicated to your success. We provide you with innovative digital solutions that drive efficiency, boost productivity, and ensure sustained growth."
    
 },
  ]

  export const DigitalServiceOurMissons = {
    title :"Our Mission",
    content:"In today's rapidly evolving digital landscape, staying ahead of the competition requires more than just keeping up with technological advancements. At Techno Genesis, we provide comprehensive digital transformation consulting services that empower your business to embrace the future. We work closely with you to develop and implement strategies that help you innovate, improve efficiency and gain competitive advantage. Let us help you transform your operations, enhance customer experiences, and achieve sustainable growth.",
    Image : DIGITAL_Img,
    classname:"sevices_layout"
  
  }

  export const DigitalKeyService = [ 
    {
      title:"Digital Transformation Strategy Development",
      content :"Our team collaborates with you to create a tailored digital transformation strategy that aligns with your business goals. We provide a clear roadmap for achieving digital success, ensuring that you can navigate the digital landscape with confidence.",
      image: DIGITAL_KEY_SERVICE_1,
    },
    {
      title:"Technology and Platform Selection",
      content :"We help you evaluate and choose the best-fit technologies and platforms that meet your specific needs. Our goal is to ensure seamless integration and optimal performance, enabling you to leverage the full potential of digital tools.",
      image: DIGITAL_KEY_SERVICE_2,
    },
    {
      title:"Process Automation and Optimization",
      content :"To improve efficiency and reduce operating costs, we streamline and automate your business processes. Our process optimization services focus on identifying bottlenecks and implementing solutions that drive productivity and innovation.",
      image: DIGITAL_KEY_SERVICE_3,
    },
    {
      title:"Data Analytics, Business Intelligence, and Omnichannel Marketing",
      content :"Leverage the power of data with our analytics, business intelligence, and omnichannel marketing services. We can help you turn raw data into actionable insights that enable you to make informed decisions and plan your strategy. ",
      image: DIGITAL_KEY_SERVICE_4,
    },
    {
      title:"Customer Experience Enhancement",
      content :"Creating exceptional customer experiences is at the heart of digital transformation. We design and implement digital solutions that deliver personalized and seamless interactions across all touchpoints.",
      image: DIGITAL_KEY_SERVICE_5,
    },
    {
      title:"Change Management and Training",
      content :"We provide change management strategies and training programs to help your workforce adapt to new technologies and processes. Our focus is on empowering your team to embrace change and drive sustained growth.",
      image: DIGITAL_KEY_SERVICE_6,
    },
  ]
  


  // Architecture
  
  
  export const Architecturestepsdata = [

    {
       id: "01",
       title: "IT Strategy with Our Expertise",
       content :"We serve organisations in various industries including healthcare, manufacturing, financial services and others. Whether you need to revamp your existing IT architecture, develop a new strategy, or integrate innovative technologies, our tailored solutions meet your specific requirements and business objectives."
       
    },
    {
      id: "02",
      title: "Why Choose Techno Genesis?",
      content :"At Techno Genesis, we understand that a robust and adaptable IT strategy and architecture are crucial for achieving long-term business success. Our IT strategy consulting services help you develop a strategic IT roadmap, while our IT architecture design services ensure your framework is aligned with your business goals. "
      
   },
   {
    id: "03",
    title: "Transform Your IT Landscape ",
    content :"Partnering with Techno Genesis means working with a team of experienced consultants dedicated to transforming your IT strategy and architecture. We provide strategic insights and practical solutions that drive efficiency, reduce costs, and support your business's growth."
    
 },
  ]


  export const ArchitectureOurMissons = {
    title :"Our Mission",
    content:"In today's rapidly changing business environment, having a robust IT strategy and architecture is essential for sustained growth and competitive advantage. At Techno Genesis, we offer comprehensive IT strategy consulting and IT architecture design services to help you develop a strategic IT roadmap and create architectural blueprints that align with your business goals. Our expert consultants work closely with you to identify areas of improvement and implement strategies that ensure your IT framework is scalable, reliable, and future-proof.",
    Image : Architecture_Img,
    classname:"sevices_layout"
  
  }


  
  export const ArchitecturekeyService = [ 
    {
      title:"Strategic IT Roadmap Development",
      content :"Our team collaborates with you to create a tailored strategic IT roadmap that aligns with your business objectives. We provide a clear plan for achieving digital success, ensuring that your IT strategy supports your long-term goals.",
      image: Arch_KEY_SERVICE_1,
    },
    {
      title:"IT Architecture Design and Optimization",
      content :"We design and optimize your IT architecture to ensure it is robust, scalable, and aligned with your strategic goals. Our solutions enhance performance, reliability, and cost-effectiveness.",
      image: Arch_KEY_SERVICE_2,
    },
    {
      title:"Technology Integration and Implementation",
      content :"Seamlessly integrate new technologies with your existing IT infrastructure with our technology integration and implementation services.  We work to ensure a smooth transition and minimise any disruption to what you're doing.",
      image: Arch_KEY_SERVICE_3,
    },
    {
      title:"Enterprise Architecture Frameworks",
      content :"Development of a comprehensive enterprise architecture framework that is in line with your business processes and goals.. Our solutions help you manage and govern your IT assets effectively.",
      image: Arch_KEY_SERVICE_4,
    },
    {
      title:" IT Governance and Risk Management",
      content :"Make sure your IT practices comply with regulatory requirements and industry standards. Our IT governance and risk management services help you establish robust policies and procedures for effective IT management.",
      image: Arch_KEY_SERVICE_5,
    },
    {
      title:"Innovation and Emerging Technologies",
      content :"Stay ahead of the curve by embracing emerging technologies such as AI, IoT and Blockchain. We help you explore and implement innovative solutions that drive business growth and efficiency.",
      image: Arch_KEY_SERVICE_6,
    },
  ]



  // businees transfermation 


  export const Businessstepsdata = [

    {
       id: "01",
       title: "Tailored Solutions for Every Challenge",
       content :"Serving a wide array of industries, including healthcare, manufacturing, and financial services, Techno Genesis crafts bespoke solutions to address your unique challenges and aspirations. "
       
    },
    {
      id: "02",
      title: "Why Choose Techno Genesis?",
      content :"At Techno Genesis, we believe that true business transformation goes beyond incremental improvements. Our business transformation consulting services are designed to help you innovate, adapt, and thrive in an ever-changing market. By leveraging industry best practices and the latest technologies. "
      
   },
   {
    id: "03",
    title: "Empower Your Business",
    content :"Choosing Techno Genesis means gaining a strategic partner committed to your success. Our team of seasoned consultants brings deep expertise and innovative thinking to every project, ensuring that your transformation journey is efficient, effective, and aligned with your vision."
    
 },
  ]

  export const BusinessOurMissons = {
    title :"Our Mission",
    content:"In the fast-paced world of modern business, transformation is not just desirable—it is essential. At Techno Genesis, we offer comprehensive business transformation consulting services to help you reinvent your operations, integrate pioneering technologies, and inspire lasting change. Our experienced consultants collaborate with you to identify key areas for improvement and implement strategies that ensure your business remains agile, efficient, and future-ready.",
    Image : BUSINESS_Img,
    classname:"sevices_layout"
  
  }

  export const BusinesskeyService = [ 
    {
      title:"Comprehensive Process Redesign",
      content :"We work with you to fundamentally redesign your business processes for maximum efficiency and impact. By identifying inefficiencies and implementing cutting-edge methodologies, we help drive significant improvements across your organization.",
      image: BUSINESS_KEY_SERVICE_1,
    },
    {
      title:"Holistic Change Management",
      content :"Our holistic change management services prepare and support your workforce through every stage of transformation. We develop tailored strategies to foster a culture of adaptability and continuous improvement.",
      image: BUSINESS_KEY_SERVICE_2,
    },
    {
      title:"Advanced Digital Integration",
      content :"Harness the power of advanced digital technologies to revolutionize your business operations. From AI and automation to IoT and cloud computing, we integrate these technologies seamlessly to enhance efficiency and drive innovation.",
      image: BUSINESS_KEY_SERVICE_3,
    },
    {
      title:"Strategic Vision Crafting",
      content :"Our strategic vision crafting services help you define and articulate a clear, actionable vision for your business. We ensure that your strategic goals are aligned with your operational capabilities, providing a solid foundation for future growth.",
      image: BUSINESS_KEY_SERVICE_4,
    },
    {
      title:"Customer Experience Reimagining",
      content :"We help you reimagine the customer experience by designing and implementing strategies that deliver personalized, seamless interactions. Our solutions are crafted to meet the evolving needs of your customers, enhancing satisfaction and loyalty.",
      image: BUSINESS_KEY_SERVICE_5,
    },
    {
      title:"Continuous Performance Improvement",
      content :"Our continuous performance improvement services ensure that your transformation initiatives remain on track. We provide the tools and insights needed to monitor progress, make data-driven decisions, and sustain long-term improvements.",
      image: BUSINESS_KEY_SERVICE_6,
    },
  ]


  // Enterprsie 


  export const Enterprisestepsdata = [

    {
       id: "01",
       title: "Customized Solutions for Every Challenge",
       content :"Serving a diverse range of industries, including healthcare, manufacturing, and financial services, Techno Genesis crafts bespoke solutions to address your unique operational challenges. Whether you aim to enhance process efficiency, integrate new technologies, or improve service delivery, our services are customized to drive impactful results."
       
    },
    {
      id: "02",
      title: "Why Choose Techno Genesis?",
      content :"At Techno Genesis, we understand that efficient enterprise processes are the backbone of a successful organization. Our enterprise processes consulting services are designed to help you streamline operations, optimize workflows, and achieve operational excellence. we ensure your business processes are efficient, scalable, and aligned with your strategic goals. "
      
   },
   {
    id: "03",
    title: "Unlock Your Potential with Our Expertise",
    content :"Choosing Techno Genesis means gaining a strategic partner committed to your success. Our team of seasoned consultants brings deep expertise and innovative thinking to every project, ensuring that your process optimization journey is efficient, effective, and aligned with your vision."
    
 },
  ]


  export const EnterpriseOurMissons = {
    title :"Our Mission",
    content:"Optimising business processes is critical to maintaining efficiency and competitiveness in today's fast-paced business environment. At Techno Genesis, we provide comprehensive enterprise process consulting services to help you streamline operations, optimise workflows and achieve operational excellence. Our experienced consultants collaborate with you to identify key areas for improvement and implement strategies that ensure your business processes are agile, efficient, and future-ready.",
    Image : ENTERPRISE_Img,
    classname:"sevices_layout"
  
  }


  export const EnterprisekeyService = [ 
    {
      title:"Comprehensive Process Assessment",
      content :"We conduct an in-depth evaluation of your current business processes to identify inefficiencies and areas for improvement. Our assessment provides actionable insights and recommendations aimed at streamlining workflows and boosting productivity.",
      image: ENTERPRISE_KEY_SERVICE_1,
    },
    {
      title:"Workflow Automation Solutions",
      content :"We design and implement workflow automation systems to eliminate repetitive tasks and enhance process efficiency. Our solutions leverage advanced automation technologies to minimize manual efforts and reduce error rates.",
      image: ENTERPRISE_KEY_SERVICE_2,
    },
    {
      title:"Advanced Technology Integration",
      content :"We assist in seamlessly integrating the latest technologies into your existing systems. Our technology integration services ensure a smooth transition, minimizing disruptions and maximizing the benefits of new technological implementations.",
      image: ENTERPRISE_KEY_SERVICE_3,
    },
    {
      title:"Performance Metrics & Continuous Improvementg",
      content :"Our services include setting up performance metrics and continuous improvement frameworks to monitor and enhance process performance. We provide the tools and methodologies necessary to track progress and make data-driven improvements.",
      image: ENTERPRISE_KEY_SERVICE_4,
    },
    {
      title:"Lean and Six Sigma Implementation",
      content :"We use Lean and Six Sigma methodologies to eliminate waste, reduce variability in the process and improve the quality of our products. Our experts are at your side throughout the process. This will ensure that your operations become more efficient and effective.",
      image: ENTERPRISE_KEY_SERVICE_5,
    },
    {
      title:"Change Management and Employee Training",
      content :"We offer change management strategies and training programs to help your workforce adapt to new processes and technologies. Our approach ensures smooth transitions and equips your team with the skills necessary for sustained success",
      image: ENTERPRISE_KEY_SERVICE_6,
    },
  ]

  // change and learing 



  export const ChangeAndLearningstepsdata = [

    {
       id: "01",
       title: "Innovative Solutions for Challenges",
       content :"We serve a diverse range of industries, including healthcare, manufacturing, and financial services, providing tailored solutions to address your unique change management and learning needs. our services are crafted to deliver impactful results."
       
    },
    {
      id: "02",
      title: "Why Choose Techno Genesis?",
      content :"At Techno Genesis, we understand that successful change management and continuous learning are crucial for fostering organizational resilience and growth. Our consulting services are designed to help you navigate transitions smoothly, cultivate a culture of ongoing learning, and achieve sustainable success."
      
   },
   {
    id: "03",
    title: "Empower Your Team with Techno Genesis",
    content :"Partnering with Techno Genesis means accessing deep expertise and innovative approaches. Our experienced consultants offer fresh perspectives and creative solutions to ensure your change management and learning initiatives are effective, engaging, and perfectly aligned with your organizational goals."
    
 },
  ]

  
  export const ChangeAndLearningOurMissons = {
    title :"Our Mission",
    content:"In today’s dynamic business landscape, mastering change and fostering continuous learning are vital to maintaining a competitive edge. At Techno Genesis, we offer bespoke change and learning consulting services to help you manage transitions effectively, cultivate a culture of continuous improvement, and empower your workforce. Our expert consultants work closely with you to design and implement strategies that ensure your organization remains agile, resilient, and future-ready.",
    Image : CHANGE_AND_LEARNING_Img,
    classname:"sevices_layout"
  
  }


  export const ChangeAndLearningkeyService = [ 
    {
      title:"Custom Change Management Strategy",
      content :"We conduct an in-depth evaluation of your current business processes to identify inefficiencies and areas for improvement. Our We collaborate with you to develop a bespoke change management strategy that aligns with your organizational goals.",
      image: CHANGE_AND_LEARNING_KEY_SERVICE_1,
    },
    {
      title:"Cultural Transformation Programs",
      content :"Transform your organizational culture to one that thrives on change and continuous improvement. Our services include comprehensive cultural assessments, leadership development programs, and initiatives to foster a positive, adaptable culture.",
      image: CHANGE_AND_LEARNING_KEY_SERVICE_2,
    },
    {
      title:"Personalized Employee Training Programs",
      content :"We design and deliver customized training programs tailored to your team’s needs. Covering a wide range of areas such as leadership development, technical skills, and soft skills, our programs ensure your employees are equipped to excel.",
      image: CHANGE_AND_LEARNING_KEY_SERVICE_3,
    },
    {
      title:"Leadership Coaching and Mentoring",
      content :"Elevate your leadership team with our coaching and mentoring services. We offer personalized coaching sessions, leadership workshops, and development programs to help your leaders drive change and inspire their teams.",
      image: CHANGE_AND_LEARNING_KEY_SERVICE_4,
    },
    {
      title:"Continuous Learning Ecosystems",
      content :"Create a robust ecosystem for continuous learning within your organization. Our services include setting up learning management systems, developing continuous learning plans, and providing tools to track and measure learning outcomes.",
      image: CHANGE_AND_LEARNING_KEY_SERVICE_5,
    },
    {
      title:"Data-Driven Performance Feedback Systems",
      content :"Implement effective performance feedback systems to foster a culture of continuous improvement. Our services help you create frameworks for regular feedback, performance tracking, and data-driven improvements.",
      image: CHANGE_AND_LEARNING_KEY_SERVICE_6,
    },
  ]


  // generative ai 



  export const Generative_AI_stepsdata = [

    {
       id: "01",
       title: "Tailored Solutions for Your Business",
       content :"We serve a wide array of industries, including healthcare, manufacturing, and financial services, providing bespoke solutions to address your specific AI requirements. Whether you aim to develop custom AI models, enhance knowledge management, or integrate AI-driven automation, our services are designed to deliver impactful results."
       
    },
    {
      id: "02",
      title: "Why Choose Techno Genesis?",
      content :"At Techno Genesis, we understand that harnessing the power of Generative AI is crucial for driving innovation and achieving sustainable growth in today's competitive landscape. Our Generative AI consulting services are tailored to help you leverage advanced AI technologies to enhance operations, improve customer experiences, and create new business opportunities."
      
   },
   {
    id: "03",
    title: "Empower Your Organization",
    content :"Partnering with Techno Genesis means accessing a team of seasoned experts who bring deep technical knowledge and innovative thinking to every project. We ensure that your AI initiatives are strategically aligned with your business goals, delivering measurable value and competitive advantage."
    
 },
  ]

  export const Generative_AI_OurMissons = {
    title :"Our Mission",
    content:"Generative AI leads innovation as digital transformation continues. At Techno Genesis, we provide cutting-edge Generative AI consulting services that empower businesses to unlock new levels of efficiency and creativity. Our expert consultants work closely with you to design and implement AI strategies that drive transformation and deliver sustainable growth. Let us help you harness the power of AI to revolutionize your business operations and customer interactions.",
    Image : GENERATIVE_AI_Img,
    classname:"sevices_layout"
  
  }


  export const  Generative_AI_keyService = [ 
    {
      title:"Custom Model Development",
      content :"We are specialists in developing custom AI models tailored to your specific business needs. From initial data collection to deployment, our team ensures that the models are robust, scalable, and integrated seamlessly into your existing systems.",
      image: GENERATIVE_AI_KEY_SERVICE_1,
    },
    {
      title:"Enterprise Search and Discovery",
      content :"Enhance your information retrieval capabilities with AI-powered search and discovery solutions. Our services can help you implement systems that understand natural language queries and deliver results that are highly relevant to you.",
      image: GENERATIVE_AI_KEY_SERVICE_2,
    },
    {
      title:"Knowledge Management",
      content :"Implement advanced knowledge management tools that leverage Generative AI to organize, store, and retrieve valuable business information. Our solutions enable natural language interaction and generate insights from your data.",
      image: GENERATIVE_AI_KEY_SERVICE_3,
    },
    {
      title:"Advisory and Mentoring Services",
      content :"Navigate the complexities of AI adoption with our expert advisory and mentoring services. We provide strategic guidance on AI technology selection, implementation, and workforce training to ensure successful integration.",
      image: GENERATIVE_AI_KEY_SERVICE_4,
    },
    {
      title:"Generative AI for Customer Engagement",
      content :"Transform customer interactions with AI-driven solutions such as AI sales reps, virtual try-ons, and personalized customer support. Our tools use advanced NLP and machine learning to provide tailored experiences.",
      image: GENERATIVE_AI_KEY_SERVICE_5,
    },
    {
      title:"AI-Driven Automation",
      content :"Automate routine tasks and optimize operations with AI-powered automation solutions. Our services help you implement intelligent systems that reduce manual efforts and increase efficiency.",
      image: GENERATIVE_AI_KEY_SERVICE_6,
    },
  ]