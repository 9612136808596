import { TECH_1, TECH_2, TECH_3, TECH_4, WAREHOUSE_1, WAREHOUSE_2, WAREHOUSE_3, WAREHOUSE_4} from '../assets/images';
export const datawarehousing_heading = [
    {
        title: "Comprehensive Data"      
    },
    {
        title: "Warehousing Solutions"
    },
  ];
  export const CoreServices = [
    {
    title : "Data Warehouse Consulting",
    content: "Our consulting services can help you with the design, implementation and optimisation of your data warehouse. We help you choose the right technology stack, plan the architecture, and ensure your data warehouse meets business requirements.",
    image: WAREHOUSE_1,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Expert guidance on technology selection'},
      {items: 'Optimized data warehouse design'},
      {items: 'Improved data management and analysis'}
    ],
    button_name: "Get Expert Data Warehouse Consulting Today"
},
{
  
    title : "Data Warehouse Implementation",
    content :"We provide comprehensive implementation services, building a robust data warehouse tailored to your business needs. Our team ensures a seamless integration of data sources and efficient data storage and retrieval mechanisms.",
    image: WAREHOUSE_2,
    keytitile: "Key Technologies",
    Key_technologies: [
      {items: 'SQL, NoSQL Databases'},
      {items: 'ETL Tools (Informatica, Talend)'},
      {items: 'Data Integration Platforms'}
    ],
    button_name: "Seamlessly Integrate Your Data Sources",
    className: "row-reverse"
},
{
  
    title : "Data Warehouse Maintenance and Support",
    content: "Our maintenance and support services will ensure that your data warehouse runs as smoothly and efficiently as possible. We offer ongoing monitoring, performance tuning, and updates to keep your data warehouse optimized and reliable.",
    image: WAREHOUSE_3,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Continuous monitoring and support'},
      {items: 'Performance tuning and optimization'},
      {items: 'Regular updates and maintenance'}
    ],
    button_name: "Experience Seamless Data Operations",
    
},
{
    title : "Data Warehouse Migration and Modernization",
    content: "We can help you to migrate your data warehouse to a modern platform and to optimise your existing data warehouse. Our services include data migration planning, execution, and post-migration support to ensure minimal disruption and enhanced performance.",
    image: WAREHOUSE_4,
    keytitile: "Key Technologies",
    Key_technologies: [
      {items: 'Cloud Platforms (AWS, Azure, Google Cloud)'},
      {items: 'Data Migration Tools'},
      {items: 'Advanced Analytics Integration'}
    ],
    button_name: "Modernize Your Data Management Infrastructure",
    className: "row-reverse"
},
]
export const WhyTechno = [
  {
    title: "Expertise and Experience",
    content: "With extensive experience in data warehousing, our team delivers top-notch solutions tailored to your business needs, ensuring efficient data management and analysis.",
    serialno: "01"
  },
  {
    title: "Customer-Centric Approach",
    content: "We prioritize our clients' needs, ensuring every solution aligns with your business goals and drives success through effective data warehousing.",
    serialno: "02"
  },
  {
    title: "Cutting-Edge Technology",
    content: "We leverage the latest technologies and best practices to provide innovative data warehousing solutions that keep your business ahead of the competition.",
    serialno: "03"
  },
 
];
export const TestimonialData = [
  {
      content: '“Techno Genesis transformed our data management with their advanced data warehousing solutions, significantly enhancing our analytics capabilities.”',
      client: 'Client A'
  },
  {
      content: "“Their data warehouse implementation services provided us with a robust and scalable data infrastructure, improving our operational efficiency.”",
      client: 'Client B'
  }
]
  export const DataWarehousingAccord = [
    {
      id:1,
      accHead:"What services do you offer for data warehousing?",
      accContent:"We offer consulting, implementation, maintenance, and migration services for data warehousing."
  
    },
    {
      id:2,
      accHead:"How can your data warehousing services benefit my business?",
      accContent:"Our services help you manage and analyze your data efficiently, leading to better decision-making and business growth."
  
    },
    {
      id:3,
      accHead: "Do you provide customized data warehousing solutions?",
      accContent:"Yes, we offer tailored solutions to meet your unique business needs and goals."
    },
    {
      id:4,
      accHead: "What is the process for implementing a data warehouse?",
      accContent:"Our process includes consulting, design, implementation, testing, and ongoing support to ensure a successful deployment."
    },
    {
      id:5,
      accHead: "Can you help migrate our existing data warehouse?",
      accContent: "o	Yes, we provide data migration services to transition your data warehouse to modern platforms with minimal disruption."
    },
    {
      id:6,
      accHead: "Do you offer ongoing support after data warehouse implementation?",
      accContent: "We offer continuous support and maintenance to ensure the smooth operation of your data warehouse"
    },
    {
      id:7,
      accHead: "What sets you apart from other data warehousing solution providers?",
      accContent: "Our expertise, customer-centric approach, and use of cutting-edge technologies set us apart in the industry."
    }
  ]