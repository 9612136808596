import React, { useState } from 'react';
import { Row, Col, Container, Button } from "react-bootstrap";
import './our-missions.css';
import { Fade } from "react-awesome-reveal";
import ContactForm from '../contactus-popup/ContactForm';

const OurMissions = ({ data }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(data);

  return (
    <>
    <Container fluid className={`pr-0 mt-110 ${data?.classname}`}>
      <Row className='ps-5 our-misson-row'>
        <Col lg={6} md={12} xs={12} className='position-relative mb-5 p-5 col_padd' style={{ alignSelf: "center" }}>
        <Fade direction='left' duration={0}>
          <div className='position-relative h-100 w-100'>
            <div className='our_missons'>
              <h2>{data?.title}</h2>
              <p>{data?.content}</p>
              <Button className='nav_bar_btn ml-0 banner_btn mt-3' variant="outline-primary" onClick={handleShow}>Get in Touch</Button>
            </div>
          </div>
          </Fade>  
        </Col>
        
        <Col style={{ paddingRight: "0px" }} lg={6} md={12} xs={12}>
           <Fade duration={0} direction='right'>
          <img className='w-100 Our-Missions-img-col' src={data?.Image} alt='Our Missions' />
        </Fade>
        </Col>
      </Row>
    </Container>
    <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
    </>
  );
};

export default OurMissions;
