import React from 'react'
import './banner.css'
import { Col, Container, Row } from 'react-bootstrap'
import { MOBILEBG } from '../../assets/images'
import { Fade } from 'react-awesome-reveal'



const Banner = (data) => {
return (
  
<div className='banner-section' style={{background : `url(${data?.bg || MOBILEBG })`}}>
  <Container className='h-100'>
    <Row className='h-100'>
      <Col lg='7' style={{alignSelf: 'center'}}> <p className='smalltext'>{data?.smalltext}</p> <h1 className='page-title'>{data?.title}</h1></Col>
      <Col lg='5' className='img_col_case' style={{alignSelf: `${data?.posistion}`}}><img className='banner_img_case' src={data?.img} style={{maxWidth : `${data?.width}`}} alt="" /></Col>
    </Row>
  </Container>
 

</div>

)
}

export default Banner