import { TECH_1, TECH_2, TECH_3, TECH_4, UNIX_1, UNIX_2, UNIX_3, UNIX_4} from '../assets/images';
export const unixmicrosoft_heading = [
    {
        title: "UNIX & Microsoft Platforms Design:"      
    },
    {
        title: "Optimizing Your IT Infrastructure"  
    },
  ];
  export const UnixServices = [
    {
  
    title : "Consulting and Assessment",
    content :"Our consulting services provide a thorough assessment of your current IT infrastructure, identifying areas for improvement and opportunities for integrating UNIX and Microsoft systems effectively.",
    image: UNIX_1,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Detailed assessment of current infrastructure'},
      {items: 'Strategic recommendations for integration'},
      {items: 'Customized solutions to meet business needs'}
    ],
    button_name: "Schedule a Consultation Today"
},
{
  
    title : "System Design and Implementation",
    content :"We design and implement tailored solutions that integrate UNIX and Microsoft platforms, ensuring seamless operation and optimal performance.",
    image: UNIX_2,
    keytitile: "Technologies Used",
    Key_technologies: [
      {items: 'UNIX/Linux (Red Hat, Ubuntu)'},
      {items: 'Microsoft Windows Server'},
      {items: 'Virtualization (VMware, Hyper-V)'},
    ],
    button_name: "Achieve High Performance with Expert Design",
    className: "row-reverse"
},
{
  
    title : "Security and Compliance",
    content: "Our security services focus on safeguarding your IT environment with robust security measures, ensuring compliance with industry standards and regulations.",
    image: TECH_3,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Enhanced data protection'},
      {items: 'Compliance with industry standards'},
      {items: 'Reduced risk of security breaches'}
    ],
    button_name: "Secure Your IT Environment Today",
    
},
{
    title : "Maintenance and Support",
    content: "We provide ongoing maintenance and support to ensure your UNIX and Microsoft systems continue to operate efficiently and securely.",
    image: UNIX_4,
    keytitile: "Key Benefits",
    Key_technologies: [
      {items: 'Continuous monitoring and support'},
      {items: 'Performance tuning and optimization'},
      {items: 'Regular updates and maintenance'}
    ],
    button_name: "Keep Your Systems Optimized",
    className: "row-reverse"
},
]

export const UnixcardInfo = [
  {
    title: "Expertise and Experience",
    content: "Expertise and experience Our team has a wealth of experience in the design and implementation of integrated UNIX and Microsoft solutions. We use our expertise to provide you with tailor-made solutions that meet the specific needs of your business.",
    serialno: "01"
  },
  {
    title: "Customer-Centric Approach",
    content: "We focus on understanding your business goals and delivering solutions that drive success through effective system integration and optimization.",
    serialno: "02"
  },
  {
    title: "Cutting-Edge Technology ",
    content: "We use the latest technologies and best practices to deliver innovative solutions that keep your business ahead of the competition.",
    serialno: "03"
  },
 
];

export const UnixTestimonials = [
  {
      content: '"Techno Genesis transformed our IT infrastructure with their expert design and integration of UNIX and Microsoft platforms, significantly improving our system performance and security."',
      client: 'Client A'
  },
]

  export const UnixMicrosoftAccord = [
    {
      id:1,
      accHead:"What is UNIX & Microsoft Platforms Design?",
      accContent:"It involves creating IT infrastructure solutions that integrate UNIX-based systems and Microsoft environments for optimal performance and security."
  
    },
    {
      id:2,
      accHead:"How can your services benefit my business?",
      accContent:"Our services help you achieve scalability, enhanced security, high performance, cost efficiency, and reliability in your IT infrastructure."
  
    },
    {
      id:3,
      accHead: "What technologies do you use for system design and implementation?",
      accContent:"We use UNIX/Linux (Red Hat, Ubuntu), Microsoft Windows Server, and virtualization technologies like VMware and Hyper-V."
      
  
    },
    {
      id:4,
      accHead: "Do you provide ongoing support for UNIX and Microsoft systems?",
      accContent:"Yes, we offer continuous support and maintenance to ensure the smooth operation of your systems."
    },
    {
      id:4,
      accHead: "What is it that sets you apart from other providers of IT infrastructure solutions?",
      accContent:"Our expertise, customer-centric approach, and use of cutting-edge technologies set us apart in the industry."
    }
  ]