import {
    LEARNING_MANAGE,
    ONLINE_ASSESSMENT,
    E_LEARNING,
    VIRTUALCLASS,
  } from "../assets/images/index";

export const educationDetail = [
    {
      title: "Learning Management Solution",
      img: LEARNING_MANAGE,
      id: "management-solution",
      listItems: [
        "Welcome to our expertise hub in Education, where we specialize in crafting Learning Management Solutions through cutting-edge software development.",
        "Dive into the future of education technology designed to empower learners and educators alike.",
        "Our dedicated software development team excels in creating robust Learning Management Solutions.",
        "Explore the power of our solutions, where intuitive interfaces, personalized learning paths, and advanced analytics converge to create a dynamic and engaging educational experience.",
        " Discover the efficiency of our software in managing courses, tracking progress, and facilitating collaborative learning.",
        ],
    
    },
    {
      title: "Virtual Classroom Software",
      img: VIRTUALCLASS,
      reverseClass: "flex_reverse",
      id: "virtual-classroom",
      listItems: [
        "Step into the future of education with our specialized expertise in crafting Virtual Classroom Software through innovative software development.",
        "Explore the dynamic realm of digital learning environments designed to connect educators and learners seamlessly.",
        "Our software development team excels in creating robust Virtual Classroom Solutions.",
        "Dive into the power of our solutions, where real-time interaction, multimedia engagement, and collaborative tools converge to redefine the traditional classroom experience.",
        "Discover the efficiency of our software in facilitating live sessions, recording lectures, and providing interactive features for enhanced student participation.",
      ],
 
    },

    {
      title: "E-Learning Authoring Tools",
      img: E_LEARNING,
      id: "e-learning",
      listItems: [
        "Welcome to our expertise hub in Education, where we specialize in crafting E-Learning Authoring Tools through advanced software development.",
        "Dive into the realm of educational content creation with tools designed to empower educators and trainers.",
        "Our dedicated software development team excels in creating robust E-Learning Authoring Tools.",
        "Explore the power of our solutions, where intuitive interfaces, multimedia integration, and seamless content creation converge to simplify the process of developing engaging educational materials.",
        "Discover the efficiency of our software in facilitating the creation of interactive lessons, quizzes, and multimedia-rich courses.",
      ],
     
  

    },
    {
      title: "Online Assessment Platforms",
      img: ONLINE_ASSESSMENT,
      reverseClass: "flex_reverse",
      id: "online-assessment",
      listItems: [
        "Welcome to our expertise hub in Education, where we excel in crafting Online Assessment Platforms through cutting-edge software development.",
        "Dive into the future of assessments designed to make testing seamless, efficient, and insightful.",
        "Our dedicated software development team specializes in creating robust Online Assessment Platforms.",
        "Explore the power of our solutions, where intuitive interfaces, diverse question formats, and advanced analytics converge to redefine the assessment experience.",
        "Discover the efficiency of our software in facilitating secure and customizable assessments, providing instant feedback, and generating comprehensive performance reports.",
      ],
 
    },
  ];