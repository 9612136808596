import React, { useState } from 'react'
import './job-card.css'
import { JOBTYPE, EXP, MAP, NO_OPENINGS } from '../../assets/images/index'
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import JobApply from '../job-apply-model/JobApply';


export const JobCard = ({ data }) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/career-detail`; 
    navigate(path);
  }


  return (
    <>
      <Container>
        <div className='career-position'>
      
          {data.length != 0 ? data.map((job, i) => {

            return (
              <Card className='job-card' key={i}>
                <Card.Title className='job-title mb-2'>{job.title}</Card.Title>
                <div className='job-d-flex align-items-center'>
                  <p class='d-flex align-items-center'><img src={EXP} width="25px" height="28px" /><span class="ms-1">{job.exp}</span></p>
                  <p class="ml-30 d-flex align-items-center"><img src={MAP} width="28px" height="28px" /><span class="ms-1">{job.location}</span></p>
                  <p class="ml-30 d-flex align-items-center"><img src={JOBTYPE} width="28px" height="28px" /><span class="ms-1">{job.type}</span></p>
                </div>
                <p className='description'>{job.description}</p>

               <div className='action-btn'>
               <Button className='apply_now_btn' onClick={handleShow} variant="">Apply Now</Button>
               <Button className='view_detail_btn' onClick={routeChange}>View Details</Button>
               </div>


              </Card>
            )
          }) : <div className='positions'>
            <div className='no-positions'>
              <img src={NO_OPENINGS} alt="no-openings-image" />
              <p>Currently no openings in <br /> technogenesis</p>
            </div>
          </div>
          }

        </div>
      </Container>
      <JobApply show={show} onClose={handleClose} placement='end' onShow={handleShow} />
    </>
  )
}
